import React, { FC } from 'react';
import { useFormik } from 'formik';

import { useDivisionRefQuery } from '../../hooks/useBackendQueries';
import FormikAutocomplete from '../FormikAutocomplete/FormikAutocomplete';

interface DivisionAutocompleteProps {
  formik: ReturnType<typeof useFormik<any>>;
  field?: string;
}

const DivisionAutocomplete: FC<DivisionAutocompleteProps> = ({ formik, field }) => {
  const { data: divisionRefs, isPending: isDivisionRefsQueryPending } = useDivisionRefQuery();

  const divisionMap: Map<number, string> = (divisionRefs ?? [])
    .sort((a, b) => (a.divisionName ?? '').localeCompare(b.divisionName ?? ''))
    .reduce((map, curr) => {
      if (curr.divisionId && curr.divisionName) {
        map.set(curr.divisionId, curr.divisionName);
      }
      return map;
    }, new Map());

  const validate = (name: string) => async () => formik.setFieldTouched(name);

  return (
    <FormikAutocomplete
      formik={formik}
      field={field || 'divisionId'}
      optionsAsMap={divisionMap}
      openOnFocus
      loading={isDivisionRefsQueryPending}
      onValueChange={validate('divisionId')}
    ></FormikAutocomplete>
  );
};

export default DivisionAutocomplete;
