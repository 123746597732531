import React, { FC } from 'react';
import { getIn } from 'formik';

import { CircularProgress } from '@mui/material';

import { useFuturesBrokersViewQuery } from '../../hooks';
import FormikAutocomplete from '../FormikAutocomplete/FormikAutocomplete';

export interface FuturesBrokerAutocompleteProps {
  formik: any;
  openOnFocus?: boolean;
}

const FuturesBrokerAutocomplete: FC<FuturesBrokerAutocompleteProps> = (props) => {
  const allOptions = useFuturesBrokersViewQuery().data;
  if (!allOptions) {
    return <CircularProgress />;
  }
  let activeOptions = allOptions.sort((a, b) =>
    (a.brokerName ?? '').localeCompare(b.brokerName ?? '')
  );

  let optionsById = new Map(
    activeOptions?.map((ta) => [ta.brokerId as number, ta.brokerName as string])
  );
  const value = getIn(props.formik.values, 'brokerId');
  if (value && !optionsById.get(value)) {
    const missingOption = allOptions.find((t) => t.brokerId === value);
    if (missingOption) {
      activeOptions = [...activeOptions, missingOption];
      optionsById = new Map(
        activeOptions?.map((ta) => [ta.brokerId as number, ta.brokerName as string])
      );
    }
  }

  return (
    <FormikAutocomplete
      formik={props.formik}
      openOnFocus={props.openOnFocus}
      field="brokerId"
      optionsAsMap={optionsById}
    ></FormikAutocomplete>
  );
};

export default FuturesBrokerAutocomplete;
