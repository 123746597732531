// I copied most of this code from https://mui.com/material-ui/react-drawer/#mini-variant-drawer
import React, { FC, PropsWithChildren } from 'react';

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MuiDrawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import Box from '@mui/system/Box';

const drawerWidth = 240;
const headerHeight = 64;
const buttonWidth = 40;
const buttonCenter = buttonWidth / 2;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

interface MovingDrawerProps extends PropsWithChildren {
  open: boolean;
  toggleOpen: () => void;
}

const MovingDrawer: FC<MovingDrawerProps> = ({ children, open, toggleOpen }) => {
  return (
    <>
      <Fab
        size="small"
        sx={{
          position: 'fixed',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          left: (theme) =>
            open
              ? drawerWidth - buttonCenter
              : `calc(${theme.spacing(8)} + 1px - ${buttonCenter}px)`,
          top: `${headerHeight + buttonCenter / 2}px`,
          transition: (theme) =>
            theme.transitions.create('left', {
              easing: theme.transitions.easing.sharp,
              duration: open
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
            }),
        }}
        onClick={toggleOpen}
        data-testid="MovingButton"
      >
        {open ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
      </Fab>
      <Drawer variant="permanent" open={open} data-testid="MovingDrawer">
        {open ? children : <Box />}
      </Drawer>
    </>
  );
};

export default MovingDrawer;
