/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreatePositionEntryRequest,
  PositionEntriesByUserResponse,
  PositionEntriesResponse,
  PositionFilteredEntriesResponse,
  UpdatePositionEntryRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PositionEntries<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name OptionsPositionEntries
   * @request OPTIONS:/positionEntries/entryUser
   */
  optionsPositionEntries = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/positionEntries/entryUser`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetPositionEntries
   * @request GET:/positionEntries
   * @secure
   */
  getPositionEntries = (params: RequestParams = {}) =>
    this.request<PositionEntriesResponse, any>({
      path: `/positionEntries`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name CreatePositionEntry
   * @request POST:/positionEntries
   * @secure
   */
  createPositionEntry = (data: CreatePositionEntryRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/positionEntries`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsPositionEntries2
   * @request OPTIONS:/positionEntries
   * @originalName optionsPositionEntries
   * @duplicate
   */
  optionsPositionEntries2 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/positionEntries`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetPositionEntriesByUser
   * @request GET:/positionEntries/entryUser/{entryUser}
   * @secure
   */
  getPositionEntriesByUser = (entryUser: string, params: RequestParams = {}) =>
    this.request<PositionEntriesByUserResponse, any>({
      path: `/positionEntries/entryUser/${entryUser}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsPositionEntries3
   * @request OPTIONS:/positionEntries/entryUser/{entryUser}
   * @originalName optionsPositionEntries
   * @duplicate
   */
  optionsPositionEntries3 = (entryUser: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/positionEntries/entryUser/${entryUser}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdatePositionEntry
   * @request PUT:/positionEntries/{id}
   * @secure
   */
  updatePositionEntry = (
    id: string,
    data: UpdatePositionEntryRequest,
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/positionEntries/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name DeletePositionEntry
   * @request DELETE:/positionEntries/{id}
   * @secure
   */
  deletePositionEntry = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/positionEntries/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsPositionEntries4
   * @request OPTIONS:/positionEntries/{id}
   * @originalName optionsPositionEntries
   * @duplicate
   */
  optionsPositionEntries4 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/positionEntries/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFilteredPositionEntries
   * @request GET:/positionEntries/filter
   * @secure
   */
  getFilteredPositionEntries = (
    query: {
      commodityId?: string;
      sortOrder?: string;
      limit: string;
      offset?: string;
      transactionTypeId?: string;
      locationId?: string;
      purchaseSale?: string;
      search?: string;
      sortBy?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<PositionFilteredEntriesResponse, any>({
      path: `/positionEntries/filter`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsPositionEntries5
   * @request OPTIONS:/positionEntries/filter
   * @originalName optionsPositionEntries
   * @duplicate
   */
  optionsPositionEntries5 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/positionEntries/filter`,
      method: 'OPTIONS',
      ...params,
    });
}
