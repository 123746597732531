import { FC } from 'react';
import { AxiosResponse } from 'axios';
import { object, string } from 'yup';

import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';

import {
  ChangeableDisplay,
  dateValueFormatter,
  errorCellClassName,
  stringComparatorWithBlanksLast,
  yesNoGridColTypeDef,
} from '../../../components';
import { UseRefDataQueryResult } from '../../../hooks';
import {
  CommodityGroupsResponse,
  CreateCommodityGroupsRequest,
  UpdateCommodityGroupsByIdRequest,
} from '../../../services/backend/data-contracts';
import { RequestParams } from '../../../services/backend/http-client';

export const commodityGroupsSchema = object({
  commodityGroupName: string().required().max(35),
  commodityGroupCode: string().required().max(10),
});

interface CommodityGroupsDisplayProps {
  userName: string;
  getCommodityGroups: () => UseRefDataQueryResult<CommodityGroupsResponse['result']>;
  createCommodityGroups: (
    data: CreateCommodityGroupsRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  updateCommodityGroups: (
    data: UpdateCommodityGroupsByIdRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  hideUnsavedPrompt?: boolean;
}

const CommodityGroupsDisplay: FC<CommodityGroupsDisplayProps> = ({
  userName,
  getCommodityGroups,
  createCommodityGroups,
  updateCommodityGroups,
  hideUnsavedPrompt,
}) => {
  const columns: GridColDef[] = [
    {
      field: 'commodityGroupCode',
      headerName: 'Commodity Group Code',
      flex: 1,
      type: 'string',
      editable: true,
      cellClassName: errorCellClassName,
      sortComparator: stringComparatorWithBlanksLast,
    },
    {
      field: 'commodityGroupName',
      headerName: 'Commodity Group Name',
      flex: 1,
      type: 'string',
      editable: true,
      cellClassName: errorCellClassName,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 1,
      editable: true,
      ...yesNoGridColTypeDef('isActive'),
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      flex: 1,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
    },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      flex: 1,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      flex: 1,
    },
  ];

  const add = (fakeId: number | undefined) => {
    const now = new Date().toISOString();
    return {
      commodityGroupId: fakeId,
      commodityGroupCode: '',
      commodityGroupName: '',
      isActive: true,
      affectsPosition: true,
      createdOn: now,
      updatedOn: now,
      createdBy: userName,
      updatedBy: userName,
      positionType: 'P',
    };
  };

  const create = (newRows: GridValidRowModel[]) => {
    const now = new Date().toISOString();
    return newRows?.length
      ? createCommodityGroups({
          groups: newRows.map((r) => ({
            commodityGroupCode: r.commodityGroupCode,
            commodityGroupName: r.commodityGroupName,
            createdBy: r.createdBy,
            createdOn: r.createdOn,
            updatedBy: userName,
            updatedOn: now,
            isActive: r.isActive,
            affectsPosition: r.affectsPosition,
          })),
        })
      : Promise.resolve();
  };

  const update = (oldRows: GridValidRowModel[]) => {
    const now = new Date().toISOString();
    return oldRows?.length
      ? updateCommodityGroups({
          groups: oldRows.map((r) => ({
            commodityGroupId: r.commodityGroupId,
            commodityGroupCode: r.commodityGroupCode,
            commodityGroupName: r.commodityGroupName,
            updatedBy: userName,
            updatedOn: now,
            isActive: r.isActive,
            affectsPosition: r.affectsPosition,
          })),
        })
      : Promise.resolve();
  };

  return (
    <ChangeableDisplay
      pageTitle="Commodity Groups"
      columns={columns}
      getRowId={(row) => row['commodityGroupId']}
      onAddButtonClick={add}
      create={create}
      update={update}
      fnUseQuery={getCommodityGroups}
      schema={commodityGroupsSchema}
      uniqueFields={['commodityGroupCode']}
      sortField="commodityGroupCode"
      hideUnsavedPrompt={hideUnsavedPrompt}
    />
  );
};

export default CommodityGroupsDisplay;
