export const TRADE_DATE_REQUIRED = 'Trade Date Required';
export const TRADE_ACCOUNT_REQUIRED = 'Trade Account Required';
export const BUY_SELL_REQUIRED = 'Buy Sell Required';
export const HEDGED_COMMODITY_REQUIRED = 'Hedged Commodity Required';
export const CONTRACTS_REQUIRED = 'Contracts Required';
export const BROKER_REQUIRED = 'Broker Required';
export const FUTURES_CONTRACT_REQUIRED = 'Futures Contract Required';
export const COMMISSION_RATE_TYPE_REQUIRED = 'Type of Commission Required';
export const MONTH_REQUIRED = 'Month Required';
export const MONTH_VALID =
  'The referenced Option Month for the Selected Futures Contract is either not set or no longer exists';
export const COMMISSION_RATE_REQUIRED = 'Commission Rate Required';
export const UNIT_PRICE_REQUIRED = 'Price Required';
export const COMMISSION_CALCULATED_BY_REQUIRED = 'Commission Calculated By is Required';
export const CONTRACTS_BOUNDS = 'Contracts must be no more than 99,999';
export const CONTRACTS_POSITIVE = 'The number of Contracts should be a positive value';
export const CONTRACTS_NOT_ZERO = 'The number of Contracts cannot be zero';
export const COMMISSION_RATE_BOUNDS =
  'The Unit Price should be between -$9999.99999 and $9999.99999';
export const UNIT_PRICE_BOUNDS =
  'The Unit Price should be a positive value between $0.00001 and $99999.99999';
export const COMMENT_LENGTH = 'Length cannot be more than 256 characters';

export const UNIT_PRICE_WARNING = 'Unit Price: Please check Price - is $0.00 appropriate?';
