/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BrokerAdjustmentTypeRef,
  BrokerRefResponse,
  CommodityFuturesContractsRefResponse,
  CommodityRefResponse,
  DivisionRefResponse,
  ExchangeRefResponse,
  FuturesContractOptionMonthRefResponse,
  FuturesContractRefResponse,
  FuturesContractTradeAccountRefResponseModel,
  HedgedCommodityRefResponse,
  LocationCommodityRefResponse,
  LocationRefResponse,
  OptionMonthsRefResponse,
  PositionPeriodRefResponse,
  PositionTransactionTypeRefResponse,
  SwappableCommoditiesRefResponse,
  TradeAccountCommodityRefResponseModel,
  TradeAccountRefResponse,
  UnitofmeasureRefResponse,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Views<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name OptionsViews
   * @request OPTIONS:/views/{id}
   */
  optionsViews = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetPositionTransactionTypeRef
   * @request GET:/views/positionTransactionTypeRef
   * @secure
   */
  getPositionTransactionTypeRef = (params: RequestParams = {}) =>
    this.request<PositionTransactionTypeRefResponse, any>({
      path: `/views/positionTransactionTypeRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews2
   * @request OPTIONS:/views/positionTransactionTypeRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews2 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/positionTransactionTypeRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetCommodityFuturesContract
   * @request GET:/views/commodityFuturesContractRef
   * @secure
   */
  getCommodityFuturesContract = (params: RequestParams = {}) =>
    this.request<CommodityFuturesContractsRefResponse, any>({
      path: `/views/commodityFuturesContractRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews3
   * @request OPTIONS:/views/commodityFuturesContractRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews3 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/commodityFuturesContractRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetCommodityOptionMonths
   * @request GET:/views/commodityOptionMonthsRef
   * @secure
   */
  getCommodityOptionMonths = (
    query?: {
      commodityId?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<OptionMonthsRefResponse, any>({
      path: `/views/commodityOptionMonthsRef`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews4
   * @request OPTIONS:/views/commodityOptionMonthsRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews4 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/commodityOptionMonthsRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetBrokerAdjustmentTypeRef
   * @request GET:/views/brokerAdjustmentTypeRef
   * @secure
   */
  getBrokerAdjustmentTypeRef = (params: RequestParams = {}) =>
    this.request<BrokerAdjustmentTypeRef, any>({
      path: `/views/brokerAdjustmentTypeRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews5
   * @request OPTIONS:/views/brokerAdjustmentTypeRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews5 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/brokerAdjustmentTypeRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetPositionPeriodRef
   * @request GET:/views/positionPeriodRef
   * @secure
   */
  getPositionPeriodRef = (
    query?: {
      optionMonthId?: string;
      optionMonthCode?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<PositionPeriodRefResponse, any>({
      path: `/views/positionPeriodRef`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews6
   * @request OPTIONS:/views/positionPeriodRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews6 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/positionPeriodRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFuturesContractRef
   * @request GET:/views/futuresContractRef
   * @secure
   */
  getFuturesContractRef = (params: RequestParams = {}) =>
    this.request<FuturesContractRefResponse, any>({
      path: `/views/futuresContractRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews7
   * @request OPTIONS:/views/futuresContractRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews7 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/futuresContractRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetDivisionRef
   * @request GET:/views/divisionRef
   * @secure
   */
  getDivisionRef = (params: RequestParams = {}) =>
    this.request<DivisionRefResponse, any>({
      path: `/views/divisionRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews8
   * @request OPTIONS:/views/divisionRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews8 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/divisionRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetExchangeRef
   * @request GET:/views/exchangeRef
   * @secure
   */
  getExchangeRef = (params: RequestParams = {}) =>
    this.request<ExchangeRefResponse, any>({
      path: `/views/exchangeRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews9
   * @request OPTIONS:/views/exchangeRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews9 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/exchangeRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFuturesContractOptionMonthRef
   * @request GET:/views/futuresContractOptionMonthRef
   * @secure
   */
  getFuturesContractOptionMonthRef = (
    query?: {
      futuresContractId?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<FuturesContractOptionMonthRefResponse, any>({
      path: `/views/futuresContractOptionMonthRef`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews10
   * @request OPTIONS:/views/futuresContractOptionMonthRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews10 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/futuresContractOptionMonthRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetHedgedCommodityRef
   * @request GET:/views/hedgedCommodityRef
   * @secure
   */
  getHedgedCommodityRef = (params: RequestParams = {}) =>
    this.request<HedgedCommodityRefResponse, any>({
      path: `/views/hedgedCommodityRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews11
   * @request OPTIONS:/views/hedgedCommodityRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews11 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/hedgedCommodityRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews12
   * @request OPTIONS:/views
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews12 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetLocationCommodityRef
   * @request GET:/views/locationCommodityRef
   * @secure
   */
  getLocationCommodityRef = (params: RequestParams = {}) =>
    this.request<LocationCommodityRefResponse, any>({
      path: `/views/locationCommodityRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews13
   * @request OPTIONS:/views/locationCommodityRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews13 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/locationCommodityRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetBrokerRef
   * @request GET:/views/brokerRef
   * @secure
   */
  getBrokerRef = (params: RequestParams = {}) =>
    this.request<BrokerRefResponse, any>({
      path: `/views/brokerRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews14
   * @request OPTIONS:/views/brokerRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews14 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/brokerRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetTradeAccountCommodityRef
   * @request GET:/views/tradeAccountCommodityRef
   * @secure
   */
  getTradeAccountCommodityRef = (params: RequestParams = {}) =>
    this.request<TradeAccountCommodityRefResponseModel, any>({
      path: `/views/tradeAccountCommodityRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews15
   * @request OPTIONS:/views/tradeAccountCommodityRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews15 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/tradeAccountCommodityRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetCommodityRef
   * @request GET:/views/commodityRef
   * @secure
   */
  getCommodityRef = (params: RequestParams = {}) =>
    this.request<CommodityRefResponse, any>({
      path: `/views/commodityRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews16
   * @request OPTIONS:/views/commodityRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews16 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/commodityRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetUnitOfMeasureRef
   * @request GET:/views/unitofmeasureRef
   * @secure
   */
  getUnitOfMeasureRef = (params: RequestParams = {}) =>
    this.request<UnitofmeasureRefResponse, any>({
      path: `/views/unitofmeasureRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews17
   * @request OPTIONS:/views/unitofmeasureRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews17 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/unitofmeasureRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetSwappableCommodities
   * @request GET:/views/commoditySwappableCommodityRef
   * @secure
   */
  getSwappableCommodities = (params: RequestParams = {}) =>
    this.request<SwappableCommoditiesRefResponse, any>({
      path: `/views/commoditySwappableCommodityRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews18
   * @request OPTIONS:/views/commoditySwappableCommodityRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews18 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/commoditySwappableCommodityRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetTradeAccountRef
   * @request GET:/views/tradeAccountRef
   * @secure
   */
  getTradeAccountRef = (params: RequestParams = {}) =>
    this.request<TradeAccountRefResponse, any>({
      path: `/views/tradeAccountRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews19
   * @request OPTIONS:/views/tradeAccountRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews19 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/tradeAccountRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetLocationRef
   * @request GET:/views/locationRef
   * @secure
   */
  getLocationRef = (params: RequestParams = {}) =>
    this.request<LocationRefResponse, any>({
      path: `/views/locationRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews20
   * @request OPTIONS:/views/locationRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews20 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/locationRef`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFuturesContractTradeAccountRef
   * @request GET:/views/futuresContractTradeAccountRef
   * @secure
   */
  getFuturesContractTradeAccountRef = (params: RequestParams = {}) =>
    this.request<FuturesContractTradeAccountRefResponseModel, any>({
      path: `/views/futuresContractTradeAccountRef`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsViews21
   * @request OPTIONS:/views/futuresContractTradeAccountRef
   * @originalName optionsViews
   * @duplicate
   */
  optionsViews21 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/views/futuresContractTradeAccountRef`,
      method: 'OPTIONS',
      ...params,
    });
}
