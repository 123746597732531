import React, { FC, useState } from 'react';
import { AxiosResponse } from 'axios';
import { FormikTouched, FormikValues, setNestedObjectValues, useFormik } from 'formik';

import TransactionEntryModal from '../../../../components/TransactionEntryModal/TransactionEntryModal';
import { usePositionPeriodOptionMonths } from '../../../../hooks';
import { useToastMutation } from '../../../../hooks/useToastMutations';
import {
  CreateSwapEntryRequest,
  SwappableCommoditiesRefResponse,
  UpdateSwapEntryRequest,
} from '../../../../services/backend/data-contracts';
import { RequestParams } from '../../../../services/backend/http-client';
import { isTradeAccountWithPositionPeriod } from '../../FuturesEntries/FuturesEntryUtils';
import { UNSWAPPABLE, validationSchema } from '../SwapEntryDetails/SwapEntry.schema';
import SwapEntryDetails, {
  AutoFields,
  SwapEntryDetailsProps,
} from '../SwapEntryDetails/SwapEntryDetails';

export interface SwapEntryDetailPageProps {
  isOpen: boolean;
  onClose: () => void;
  updateSwapEntry: (
    id: string,
    data: UpdateSwapEntryRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  createSwapEntry: (
    data: CreateSwapEntryRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  deleteSwapEntry: (id: string, params?: RequestParams) => Promise<AxiosResponse<void, any>>;
  invalidateQueries: string[];
  swapEntry?: any;
  swappableCommodities?: SwappableCommoditiesRefResponse['result'];
}

const SwapEntryDetailPage: FC<
  SwapEntryDetailPageProps &
    Omit<
      SwapEntryDetailsProps,
      | 'formik'
      | 'isCreate'
      | 'ref'
      | 'periodsMap'
      | 'setCurrentAutoField'
      | 'isBuyPeriodEnabled'
      | 'isSellPeriodEnabled'
    >
> = ({
  isOpen,
  onClose,
  updateSwapEntry,
  createSwapEntry,
  deleteSwapEntry,
  invalidateQueries,
  swapEntry,
  swappableCommodities,
  ...props
}) => {
  const [currentAutoField, setCurrentAutoField] = useState<AutoFields>();

  const updateMutation = useToastMutation({
    mutationFn: ({ id, data }: any) => {
      return updateSwapEntry(id.toString(), data);
    },
    queryKey: invalidateQueries,
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Swap Entry Updated',
  });

  const createMutation = useToastMutation({
    mutationFn: createSwapEntry,
    queryKey: invalidateQueries,
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Swap Entry Created',
  });

  const deleteMutation = useToastMutation({
    mutationFn: (id: number) => deleteSwapEntry(id.toString()),
    queryKey: invalidateQueries,
    onMutateMessage: 'Deleting...',
    onSuccessMessage: 'Swap Entry Deleted',
    isDelete: true,
  });

  const swappableCommoditiesMap = swappableCommodities?.reduce(
    (swapMap, swappable) => {
      const { commodityId, swapCommodityId } = swappable;
      if (commodityId && swapCommodityId) {
        swapMap[commodityId] = [...(swapMap[commodityId] ?? []), swapCommodityId];
      }
      return swapMap;
    },
    {} as Record<number, number[]>
  );

  const doSave = (currentDetails: any) => {
    const buyTradeAccount = props?.tradeAccountSummaries?.find(
      (t) => t.tradeAccountId == Number(currentDetails?.buyTradeAccountId)
    );
    const sellTradeAccount = props?.tradeAccountSummaries?.find(
      (t) => t.tradeAccountId == Number(currentDetails?.sellTradeAccountId)
    );
    const entry = {
      ...currentDetails,
      contracts: Number(currentDetails?.contracts) ?? null,
      futuresPrice: Number(currentDetails?.futuresPrice) ?? null,
      buyCommodityId: Number(currentDetails?.buyCommodityId) ?? null,
      buyTradeAccountId: Number(currentDetails?.buyTradeAccountId) ?? null,
      buyAffectsPosition: buyTradeAccount?.affectsPosition,
      buyPeriodDimId: currentDetails.buyPeriodDimId
        ? Number(currentDetails.buyPeriodDimId)
        : undefined,
      buyPositionMonth: currentDetails.sellPositionMonth ?? undefined,
      sellCommodityId: Number(currentDetails?.sellCommodityId) ?? null,
      sellTradeAccountId: Number(currentDetails?.sellTradeAccountId) ?? null,
      sellAffectsPosition: sellTradeAccount?.affectsPosition,
      sellPeriodDimId: currentDetails.sellPeriodDimId
        ? Number(currentDetails.sellPeriodDimId)
        : undefined,
      sellPositionMonth: currentDetails.sellPositionMonth ?? undefined,
      futuresContractId: Number(currentDetails?.futuresContractId) ?? null,
      optionMonthId: currentDetails?.optionMonthId,
    };

    if (swapEntry.id) {
      updateMutation.mutate(
        {
          id: swapEntry.id,
          data: { entry },
        },
        { onSuccess: () => handleModalClose() }
      );
    } else {
      createMutation.mutate(
        { entry },
        {
          onSuccess: () => {
            formik.resetForm();
          },
        }
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      ...swapEntry,
      buyCommodityId: swapEntry?.buyCommodityId?.toString() ?? '',
      buyTradeAccountId: swapEntry?.buyTradeAccountId?.toString() ?? '',
      sellCommodityId: swapEntry?.sellCommodityId?.toString() ?? '',
      sellTradeAccountId: swapEntry?.sellTradeAccountId?.toString() ?? '',
      futuresContractId: swapEntry?.futuresContractId?.toString() ?? '',
      optionMonthId: swapEntry?.optionMonthId,
      comment: swapEntry?.comment ?? '',
    },
    enableReinitialize: true,
    validate: (value) => {
      return swappableCommoditiesMap?.[Number(value.buyCommodityId)]?.includes(
        Number(value.sellCommodityId)
      )
        ? undefined
        : { '': UNSWAPPABLE };
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      doSave({ ...formik.values });
    },
  });

  const handleModalClose = () => {
    onClose();
    formik.resetForm();
  };

  const handleClearClick = async () => {
    formik.resetForm();
    // Trigger touched on all fields
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      // Form is valid, do any success call
    } else {
      formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
    }
  };
  const handleSaveClick = () => {
    formik.submitForm();
  };

  const handleDelete = () => {
    deleteMutation.mutate(swapEntry.id, { onSuccess: handleModalClose });
  };

  const isBuyPeriodEnabled = isTradeAccountWithPositionPeriod(formik.values.buyTradeAccountId);
  const isSellPeriodEnabled = isTradeAccountWithPositionPeriod(formik.values.sellTradeAccountId);

  const autoUpdateBuyPeriod = () => {
    if (!isBuyPeriodEnabled) {
      formik.setFieldValue('buyPeriodDimId', undefined, true);
    } else if (periodMonths?.length && !formik.values.buyPeriodDimId) {
      formik.setFieldValue(
        'buyPeriodDimId',
        periodMonths[periodMonths?.length - 1].positionPeriodDimId,
        true
      );
    }
    setCurrentAutoField(
      currentAutoField === AutoFields.BOTH_PERIODS ? AutoFields.SELL_PERIOD : undefined
    );
  };

  const autoUpdateSellPeriod = () => {
    if (!isSellPeriodEnabled) {
      formik.setFieldValue('sellPeriodDimId', undefined, true);
    } else if (periodMonths?.length && !formik.values.sellPeriodDimId) {
      formik.setFieldValue(
        'sellPeriodDimId',
        periodMonths[periodMonths?.length - 1].positionPeriodDimId,
        true
      );
    }
    setCurrentAutoField(
      currentAutoField === AutoFields.BOTH_PERIODS ? AutoFields.BUY_PERIOD : undefined
    );
  };

  // POSITION PERIOD (for selected month)
  const periodsQuery = usePositionPeriodOptionMonths(formik.values.optionMonthId);
  const periodsMap = new Map<number, string>();
  const periodMonths = periodsQuery.data?.months;
  // nothing in tables to lookup for this. Hard coding
  periodMonths?.forEach((p) => {
    if (p.positionPeriodDimId) {
      periodsMap.set(p.positionPeriodDimId, p.positionMonth ?? '');
    }
  });
  if (
    (AutoFields.BUY_PERIOD === currentAutoField || AutoFields.BOTH_PERIODS === currentAutoField) &&
    !periodsQuery.isPending
  ) {
    autoUpdateBuyPeriod();
  }
  if (
    (AutoFields.SELL_PERIOD === currentAutoField || AutoFields.BOTH_PERIODS === currentAutoField) &&
    !periodsQuery.isPending
  ) {
    autoUpdateSellPeriod();
  }

  return (
    <TransactionEntryModal
      data-testid="SwapEntryDetailPage"
      isOpen={isOpen}
      canSave={!updateMutation.isPending && !createMutation.isPending}
      isCreateModal={!swapEntry?.id}
      onCancel={handleModalClose}
      onClear={handleClearClick}
      onCreate={handleSaveClick}
      onUpdate={handleSaveClick}
      onDelete={handleDelete}
    >
      <SwapEntryDetails
        formik={formik}
        isCreate={!swapEntry?.id}
        {...props}
        setCurrentAutoField={setCurrentAutoField}
        isBuyPeriodEnabled={isBuyPeriodEnabled}
        isSellPeriodEnabled={isSellPeriodEnabled}
        periodsMap={periodsMap}
      ></SwapEntryDetails>
    </TransactionEntryModal>
  );
};

export default SwapEntryDetailPage;
