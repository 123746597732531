import React, { PropsWithChildren, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import { ColorModeContext } from '../../Context';
import { darkTheme, lightTheme } from '../AppTheme';
import { UserWrapper } from '../UserWrapper';

import HeaderBar from './HeaderBar/HeaderBar';
import MovingDrawer from './MovingDrawer/MovingDrawer';
import Navbar from './Navbar/Navbar';

import '../App.css';

const Layout = ({ children }: PropsWithChildren) => {
  const openDrawerWidth = '240px';
  const closedDrawerWidth = '64px';
  const [open, setOpen] = useState(true);
  const toggleDrawerOpen = () => {
    setOpen(!open);
  };

  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const theme = React.useMemo(() => (mode === 'light' ? lightTheme : darkTheme), [mode]);
  const contentWidthString = open
    ? `calc(100% - ${openDrawerWidth})`
    : `calc(100% - ${closedDrawerWidth})`;
  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <UserWrapper>
            <CssBaseline />
            <Box sx={{ display: 'flex' }}>
              <HeaderBar />
              <MovingDrawer toggleOpen={toggleDrawerOpen} open={open}>
                <Navbar />
              </MovingDrawer>
              <Box
                component="main"
                sx={{
                  transition: (theme) =>
                    theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: open
                        ? theme.transitions.duration.enteringScreen
                        : theme.transitions.duration.leavingScreen,
                    }),
                  width: contentWidthString,
                }}
              >
                <Toolbar />
                {children}
              </Box>
            </Box>
          </UserWrapper>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
};

export default Layout;
