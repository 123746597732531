import React, { useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { LogoutContext } from '../../Context';

// const WARNING_PROMPT_MS = 300000; // 5 minutes: 5 * 60 * 1000;
const WARNING_PROMPT_MS = 600000; // 10 minutes: 15 * 60 * 1000;
const TIMOUT_MS = 7200000; // 2 hours: 2 * 60 * 60 * 1000;

export default function useSessionTimeout() {
  const { logout } = useContext(LogoutContext);

  const [showDialog, setShowDialog] = React.useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    logout();
  };

  const idleTimer = useIdleTimer({
    debounce: 500,
    promptBeforeIdle: WARNING_PROMPT_MS,
    timeout: TIMOUT_MS,
    onIdle: () => {
      setShowDialog(false);
      handleLogout();
    },
    onPrompt: () => {
      setShowDialog(true);
    },
  });

  const handleRestart = () => {
    idleTimer.activate();
    setShowDialog(false);
  };

  return { showDialog, handleRestart, handleLogout };
}
