/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AllocatedCommissionCalcResponse,
  CreateAllocatedCommissionRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AllocatedCommission<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name CreateAllocatedCommission
   * @request POST:/allocatedCommission
   * @secure
   */
  createAllocatedCommission = (
    data: CreateAllocatedCommissionRequest,
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/allocatedCommission`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsAllocatedCommission
   * @request OPTIONS:/allocatedCommission
   */
  optionsAllocatedCommission = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/allocatedCommission`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsAllocatedCommission2
   * @request OPTIONS:/allocatedCommission/{id}
   * @originalName optionsAllocatedCommission
   * @duplicate
   */
  optionsAllocatedCommission2 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/allocatedCommission/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name CalculateAllocatedCommission
   * @request GET:/allocatedCommission/calc
   * @secure
   */
  calculateAllocatedCommission = (
    query: {
      startDate: string;
      amount: string;
      brokerId: string;
      endDate: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<AllocatedCommissionCalcResponse, any>({
      path: `/allocatedCommission/calc`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsAllocatedCommission3
   * @request OPTIONS:/allocatedCommission/calc
   * @originalName optionsAllocatedCommission
   * @duplicate
   */
  optionsAllocatedCommission3 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/allocatedCommission/calc`,
      method: 'OPTIONS',
      ...params,
    });
}
