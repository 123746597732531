import { FC, useContext } from 'react';

import { Link } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

import {
  PositionAdministratorRole,
  PositionClerkRole,
} from '../../../../app/UserWrapper/UserWrapper';
import {
  currencyValueFormatter,
  dateValueFormatter,
  errorCellClassName,
  numberWithComma2DecimalsFormatter,
  purchaseOrSaleFormatter,
} from '../../../../components';
import ChangeableGrid from '../../../../components/ChangeableGrid/ChangeableGrid';
import { UserContext } from '../../../../Context';

export interface PositionEntriesGridProps {
  userName: string;
  rows: GridRowsProp[];
  loading: boolean;
  rowCountState?: number;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  onNewButtonClick: () => void;
  onEditButtonClick: (id: string) => void;
  onExportButtonClick: (onFinish: () => void) => void;
}

const PositionEntriesGrid: FC<PositionEntriesGridProps> = ({
  rows,
  loading,
  rowCountState,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  onNewButtonClick,
  onEditButtonClick,
  onExportButtonClick,
}) => {
  const userContext = useContext(UserContext);
  const canCreate = userContext.haveRole([PositionClerkRole, PositionAdministratorRole]);
  let handleAdd;
  if (canCreate) {
    handleAdd = () => {
      onNewButtonClick();
    };
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      renderCell: ({ value, row }) =>
        canCreate ? (
          <Link
            onClick={() => onEditButtonClick(row.id)}
            sx={{ cursor: 'pointer' }}
            data-testid="LinkToModal"
          >
            {value || '______'}
          </Link>
        ) : (
          value || '______'
        ),
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'commodity',
      headerName: 'Commodity',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'purchaseSale',
      headerName: 'P / S',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: purchaseOrSaleFormatter,
    },
    {
      field: 'transactionType',
      headerName: 'Transaction Type',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'contract',
      headerName: 'Contract',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'customer',
      headerName: 'Customer',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: numberWithComma2DecimalsFormatter,
    },
    {
      field: 'flatPrice',
      headerName: 'Flat Price',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'basisPrice',
      headerName: 'Basis Price',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'futuresPrice',
      headerName: 'Futures Price',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'optionMonth',
      headerName: 'Month',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'entryUser',
      headerName: 'Entry User',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'entryDateTime',
      headerName: 'Entry Date Time',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: dateValueFormatter,
    },
  ];

  return (
    <div data-testid="PositionEntriesGrid">
      <ChangeableGrid
        initialRows={rows}
        isLoading={loading}
        columns={columns}
        onAdd={handleAdd}
        onExport={onExportButtonClick}
        rowCount={rowCountState}
        pageSizeOptions={[25, 50, 100]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        onProcessRowUpdateError={console.error}
      />
    </div>
  );
};

export default PositionEntriesGrid;
