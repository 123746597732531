/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { UpdateTransactionTypesByIdRequest } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class TransactionTypesMassUpdates<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name UpdateTransactionTypes
   * @request POST:/transactionTypes-mass-updates
   * @secure
   */
  updateTransactionTypes = (data: UpdateTransactionTypesByIdRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/transactionTypes-mass-updates`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsTransactionTypesMassUpdates
   * @request OPTIONS:/transactionTypes-mass-updates
   */
  optionsTransactionTypesMassUpdates = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/transactionTypes-mass-updates`,
      method: 'OPTIONS',
      ...params,
    });
}
