import React, { FC, MutableRefObject, useState } from 'react';
import { Schema, ValidationError } from 'yup';

import { AppBar, Box, Paper, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  GridCellParams,
  GridColDef,
  GridRowSelectionModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';

import ChangeableGrid from '../ChangeableGrid/ChangeableGrid';

// type SmallRow = {
//   id: number | string;
//   code: string;
// };

interface SmallChangeableGridProps {
  tableTitle?: string;
  columns: GridColDef[];
  data: GridValidRowModel[];
  setDataChanged?: (newData: GridValidRowModel[]) => void;
  getRowId?: (row: GridValidRowModel) => number;
  onAddButtonClick?: (id: number) => GridValidRowModel;
  schema?: Schema;
  uniqueFields?: string[];
  gridFilter?: (row: GridValidRowModel) => boolean;
  isCellEditable?: (params: GridCellParams) => boolean;
  infoIndicator?: (row: GridValidRowModel) => string | null;
  apiRef?: MutableRefObject<GridApiCommunity>;
  canRemoveAny?: boolean;
}

const FootlessGrid = styled(ChangeableGrid)({
  '& .MuiDataGrid-footerContainer': {
    display: 'none',
  },
});

FootlessGrid.defaultProps = {
  dense: true,
};

const SmallChangeableGrid: FC<SmallChangeableGridProps> = ({
  tableTitle,
  columns,
  data,
  getRowId = (r) => r.id,
  onAddButtonClick: add,
  schema,
  uniqueFields,
  gridFilter,
  isCellEditable,
  infoIndicator,
  setDataChanged,
  apiRef,
  canRemoveAny,
}) => {
  const [changedRows, setChangedRows] = useState<GridValidRowModel[]>([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

  const firstNewRowId = Math.pow(2, 50);
  const [generatedRowId, setGeneratedRowId] = useState<number>(firstNewRowId);

  // Assign lower IDs to existing rows, in case data not present on first render
  let existingRowIds = firstNewRowId - 1;
  const assignIdsToExistingRows = () => {
    if (data && (data.length == 0 || !getRowId(data[0]))) {
      return data.map((r) => ({ ...r, id: r.id ? r.id : existingRowIds-- }));
    }
    return data;
  };
  const initialRows = assignIdsToExistingRows();

  const generateNextId = () => {
    setGeneratedRowId((cur) => cur + 1);
    return generatedRowId;
  };

  const handleAdd = add
    ? () => {
        const newRow = { ...add(generateNextId()), isNew: true };
        setChangedRows([...changedRows, newRow]);
        setRowSelectionModel([generatedRowId]);
      }
    : undefined;

  const handleRemove =
    add || canRemoveAny
      ? () => {
          const selectedId = rowSelectionModel.length && rowSelectionModel[0];
          let toRemove = undefined;
          if (canRemoveAny) {
            toRemove =
              initialRows?.find((d) => getRowId(d) === selectedId) ??
              changedRows?.find((d) => getRowId(d) === selectedId);
          } else {
            toRemove = changedRows?.find((d) => d.isNew && getRowId(d) === selectedId);
          }
          toRemove &&
            validateAndSetChangedRows([
              ...changedRows.filter((d) => getRowId(d) !== selectedId),
              { id: getRowId(toRemove), remove: true },
            ]);
        }
      : undefined;

  const validateAndSetChangedRows = (unvalidated: GridValidRowModel[]) => {
    const changedIds = unvalidated.map(getRowId);
    const allRows = unvalidated.concat(
      (initialRows || []).filter((d) => !changedIds.includes(getRowId(d)))
    );
    const validated: GridValidRowModel[] = unvalidated.map((r) => {
      let errors: string[] | null = null;
      try {
        schema?.validateSync(r, { abortEarly: false });
      } catch (ex) {
        const err = ex as ValidationError;
        errors = err.errors;
      }
      (uniqueFields || []).forEach((f) => {
        if (
          allRows
            .filter((ar) => getRowId(ar) !== getRowId(r))
            .map((ar) => ar[f])
            .includes(r[f])
        ) {
          errors = (errors || []).concat(`${f} must be unique`);
        }
      });

      return {
        ...r,
        errors: errors,
      };
    });
    const sortedRows = validated.sort((a, b) => getRowId(a) - getRowId(b));
    setChangedRows(sortedRows);
    if (setDataChanged && initialRows) {
      const changedIds = validated.map(getRowId);
      setDataChanged([
        ...validated,
        ...initialRows.filter((d) => !changedIds.includes(getRowId(d))),
      ]);
    }
  };

  return (
    <Box data-testid="SmallChangeableGrid">
      <Paper>
        {tableTitle ? (
          <AppBar color="secondary" position="static">
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                minHeight: 'unset',
              }}
              variant="dense"
            >
              <Typography variant="h6">{tableTitle}</Typography>
            </Toolbar>
          </AppBar>
        ) : null}
        <FootlessGrid
          columns={columns}
          initialRows={initialRows || []}
          isLoading={false}
          getRowId={getRowId}
          onAdd={handleAdd}
          onRemove={handleRemove}
          changedRows={changedRows}
          setChangedRows={validateAndSetChangedRows}
          onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          gridFilter={gridFilter}
          isCellEditable={isCellEditable}
          infoIndicator={infoIndicator}
          apiRef={apiRef}
          canRemoveAny={canRemoveAny}
        />
      </Paper>
    </Box>
  );
};

export default SmallChangeableGrid;
