/* eslint-disable */
import React, { FC, useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';

import { UserContext } from '../../../Context';
import {
  CompuWeighRole,
  FuturesMaintenanceRole,
  FuturesUserRole,
  GrainTraderRole,
  GranelerasTraderRole,
  HedgeDeskRole,
  MillingTraderRole,
  PositionAdministratorRole,
  PositionClerkRole,
  PositionRecRole,
  SuperUserRole,
  ViewOnlyRole,
} from '../../UserWrapper/UserWrapper';
import NestedList from './NestedList/NestedList';

import './Navbar.css';

const Navbar: FC = () => {
  const [showNav, setShowNav] = useState(true);
  const location = useLocation();
  const sectionFromLocation = location.pathname.match(/^\/(\w+)\//i)?.[1] ?? 'transactions';
  const [openSection, setOpenSection] = useState(sectionFromLocation);

  const userContext = useContext(UserContext);
  if (!userContext?.userName) {
    return null;
  }

  const toggleMenu = (s: boolean) => {
    setShowNav(!s);
  };

  if (!showNav) {
    return (
      <div className="navBar navBarCompressed" role="navigation">
        <div className="navBarExpandButton">
          <KeyboardDoubleArrowRightIcon
            data-testid="navBarExpandButton"
            onClick={() => toggleMenu(showNav)}
          />
        </div>
      </div>
    );
  }
  return (
    <>
      <Toolbar />
      <List component="nav" dense>
        {userContext.haveRole([
          CompuWeighRole,
          HedgeDeskRole,
          PositionClerkRole,
          PositionRecRole,
          ViewOnlyRole,
          PositionAdministratorRole,
        ]) ? (
          <ListSubheader sx={{ color: 'inherit', backgroundColor: 'inherit' }}>
            <strong>Positions</strong>
          </ListSubheader>
        ) : null}
        <NestedList
          title="Daily Transactions"
          name="transactions"
          opened={openSection}
          setOpen={setOpenSection}
          display={userContext.haveRole([
            PositionClerkRole,
            ViewOnlyRole,
            PositionAdministratorRole,
            HedgeDeskRole,
          ])}
        >
          {userContext.haveRole([PositionClerkRole, ViewOnlyRole, PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="transactions/positionEntries">
              <ListItemText>Position Entries</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([HedgeDeskRole, ViewOnlyRole, PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="transactions/futuresEntries">
              <ListItemText>Futures Entries</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([HedgeDeskRole, ViewOnlyRole, PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="transactions/swapEntries">
              <ListItemText>Swap Entries</ListItemText>
            </ListItemButton>
          ) : null}
        </NestedList>

        <NestedList
          title="Daily Processing"
          name="dailyProcessing"
          opened={openSection}
          setOpen={setOpenSection}
          display={userContext.haveRole([
            CompuWeighRole,
            PositionRecRole,
            PositionAdministratorRole,
          ])}
        >
          {userContext.haveRole([PositionRecRole, PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="dailyProcessing/endOfDayProcess">
              <ListItemText>End of Day Process</ListItemText>
            </ListItemButton>
          ) : null}
          {userContext.haveRole([CompuWeighRole, PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="dailyProcessing/compuweigh">
              <ListItemText>CompuWeigh Spot Pricing</ListItemText>
            </ListItemButton>
          ) : null}
        </NestedList>

        <NestedList
          title="Reference Data"
          name="referenceData"
          opened={openSection}
          setOpen={setOpenSection}
          display={userContext.haveRole([PositionAdministratorRole])}
        >
          {userContext.haveRole([PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="referenceData/commodityGroups">
              <ListItemText>Commodity Groups</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="referenceData/commodities">
              <ListItemText>Commodities</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="referenceData/locationGroups">
              <ListItemText>Location Groups</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="referenceData/locations">
              <ListItemText>Locations</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="referenceData/transactionTypes">
              <ListItemText>Transaction Types</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="referenceData/futuresBrokers">
              <ListItemText>Futures Brokers</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="referenceData/futuresContracts">
              <ListItemText>Futures Contracts</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="referenceData/futuresMonths">
              <ListItemText>Futures Months</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="referenceData/futuresTradeAccounts">
              <ListItemText>Futures Trade Accounts</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([PositionAdministratorRole]) ? (
            <ListItemButton component={NavLink} to="referenceData/importFuturesReference">
              <ListItemText>Import Futures Reference</ListItemText>
            </ListItemButton>
          ) : null}
        </NestedList>

        <NestedList
          title="Administration"
          name="administration"
          opened={openSection}
          setOpen={setOpenSection}
          display={userContext.haveRole([PositionAdministratorRole, SuperUserRole])}
        >
          {userContext.haveRole([PositionAdministratorRole, SuperUserRole]) ? (
            <ListItemButton component={NavLink} to="administration/users">
              <ListItemText>Users</ListItemText>
            </ListItemButton>
          ) : null}
        </NestedList>
        <ListItemButton component={NavLink} to="about">
          <ListItemText>About</ListItemText>
        </ListItemButton>

        {userContext.haveRole([
          GrainTraderRole,
          GrainTraderRole,
          GranelerasTraderRole,
          MillingTraderRole,
          SuperUserRole,
          FuturesMaintenanceRole,
          FuturesUserRole,
        ]) ? (
          <ListSubheader sx={{ color: 'inherit', backgroundColor: 'inherit' }}>
            <strong>Futures</strong>
          </ListSubheader>
        ) : null}

        <NestedList
          title="Trades"
          name="trades"
          opened={openSection}
          setOpen={setOpenSection}
          display={userContext.haveRole([
            GrainTraderRole,
            GrainTraderRole,
            GranelerasTraderRole,
            MillingTraderRole,
            FuturesUserRole,
            SuperUserRole,
          ])}
        >
          {userContext.haveRole([
            GrainTraderRole,
            GranelerasTraderRole,
            MillingTraderRole,
            FuturesUserRole,
            SuperUserRole,
          ]) ? (
            <ListItemButton component={NavLink} to="trades/futuresTrades">
              <ListItemText>Futures Trades</ListItemText>
            </ListItemButton>
          ) : null}
        </NestedList>

        <NestedList
          title="Broker Adjustments"
          name="brokerAdjustments"
          opened={openSection}
          setOpen={setOpenSection}
          display={userContext.haveRole([SuperUserRole, FuturesUserRole])}
        >
          {userContext.haveRole([SuperUserRole, FuturesUserRole]) ? (
            <ListItemButton component={NavLink} to="brokerAdjustments/cashAdjustments">
              <ListItemText>Cash Adjustments</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([SuperUserRole, FuturesUserRole]) ? (
            <ListItemButton
              component={NavLink}
              to="brokerAdjustments/allocatedCommissionAdjustment"
            >
              <ListItemText>Allocated Commission Adjustment</ListItemText>
            </ListItemButton>
          ) : null}
        </NestedList>

        <NestedList
          title="Maintenance"
          name="maintenance"
          opened={openSection}
          setOpen={setOpenSection}
          display={userContext.haveRole([FuturesMaintenanceRole, SuperUserRole, FuturesUserRole])}
        >
          {userContext.haveRole([FuturesMaintenanceRole, SuperUserRole, FuturesUserRole]) ? (
            <ListItemButton component={NavLink} to="maintenance/brokers" className="navBar">
              <ListItemText>Brokers</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([FuturesMaintenanceRole, SuperUserRole, FuturesUserRole]) ? (
            <ListItemButton component={NavLink} to="maintenance/futuresContracts">
              <ListItemText>Futures Contracts</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([FuturesMaintenanceRole, SuperUserRole, FuturesUserRole]) ? (
            <ListItemButton component={NavLink} to="maintenance/hedgedCommodities">
              <ListItemText>Hedged Commodities</ListItemText>
            </ListItemButton>
          ) : null}

          {userContext.haveRole([FuturesMaintenanceRole, SuperUserRole, FuturesUserRole]) ? (
            <ListItemButton component={NavLink} to="maintenance/tradeAccounts">
              <ListItemText>Trade Accounts</ListItemText>
            </ListItemButton>
          ) : null}
        </NestedList>
      </List>
    </>
  );
};

export default Navbar;
