import React, { useEffect } from 'react';

import SwapEntriesDisplay from './SwapEntriesDisplay/SwapEntriesDisplay';
import useSwapEntries from './useSwapEntries';

export default function SwapEntriesPage() {
  useEffect(() => {
    document.title = 'Swap Entries | Positions and Futures';
  }, []);
  const displayProps = useSwapEntries();
  return (
    <>
      <main data-testid="SwapEntriesPage" style={{ minWidth: 1400, padding: '24px' }}>
        <SwapEntriesDisplay {...displayProps}></SwapEntriesDisplay>
      </main>
    </>
  );
}
