import React, { FC, useContext } from 'react';

import Link from '@mui/material/Link';
import {
  DataGridProps,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import { HedgeDeskRole, PositionAdministratorRole } from '../../../../app/UserWrapper/UserWrapper';
import {
  ChangeableGrid,
  currencyValueFormatter,
  dateValueFormatter,
  errorCellClassName,
  numberWithCommaFormatter,
} from '../../../../components';
import { UserContext } from '../../../../Context';
import { SwapFilteredEntriesResponse } from '../../../../services/backend/data-contracts';
import { isTradeAccountWithPositionPeriod } from '../../FuturesEntries/FuturesEntryUtils';

export interface SwapEntriesGridProps {
  rows: SwapFilteredEntriesResponse['result']['entries'];
  isLoadingSwapEntries?: boolean;
  rowCountState?: number;
  userName: string;
  paginationModel?: GridPaginationModel;
  onPaginationModelChange?: DataGridProps['onPaginationModelChange'];
  onSortModelChange?: DataGridProps['onSortModelChange'];
  onFilterModelChange?: DataGridProps['onFilterModelChange'];
  openModalWith?: (selectedEntry?: any) => void;
}

const SwapEntriesGrid: FC<SwapEntriesGridProps> = ({
  rows,
  isLoadingSwapEntries,
  rowCountState,
  userName,
  paginationModel,
  onPaginationModelChange,
  onSortModelChange,
  onFilterModelChange,
  openModalWith,
}) => {
  const userContext = useContext(UserContext);
  const canCreate = userContext.haveRole([HedgeDeskRole, PositionAdministratorRole]);
  let handleAdd, editLink;
  if (canCreate) {
    handleAdd = () => {
      openModalWith?.({
        sellCommodityId: null,
        entryUser: userName,
        optionMonth: '',
        sellTradeAccountId: null,
        futuresPrice: 0.0,
        futuresContractId: null,
        buyTradeAccountId: null,
        contracts: 0,
        buyCommodityId: null,
        id: undefined,
      });
    };
    editLink = ({ value, row }: GridRenderCellParams) => (
      <Link
        onClick={() => {
          openModalWith && openModalWith(row);
        }}
        sx={{ cursor: 'pointer' }}
        data-testid="LinkToModal"
      >
        {value || '______'}
      </Link>
    );
  } else {
    editLink = ({ value }: GridRenderCellParams) => value || '______';
  }
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      renderCell: editLink,
    },
    {
      field: 'futuresContract',
      headerName: 'Futures Contract',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'optionMonth',
      headerName: 'Month',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'futuresPrice',
      headerName: 'Futures Price',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'contracts',
      headerName: 'Contracts',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: numberWithCommaFormatter,
    },
    {
      field: 'buyCommodity',
      headerName: 'Buy Commodity',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'buyTradeAccount',
      headerName: 'Buy Trade Account',
      flex: 2,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'buyPositionMonth',
      headerName: 'Buy Position Period',
      flex: 2,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueGetter: (params: GridValueGetterParams) => {
        return isTradeAccountWithPositionPeriod(params.row.buyTradeAccountId) ? params.value : '-';
      },
      sortable: false,
    },
    {
      field: 'sellCommodity',
      headerName: 'Sell Commodity',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'sellTradeAccount',
      headerName: 'Sell Trade Account',
      flex: 2,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'sellPositionMonth',
      headerName: 'Sell Position Period',
      flex: 2,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueGetter: (params: GridValueGetterParams) => {
        return isTradeAccountWithPositionPeriod(params.row.sellTradeAccountId) ? params.value : '-';
      },
      sortable: false,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'entryUser',
      headerName: 'Entry User',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'entryDateTime',
      headerName: 'Entry Date Time',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: dateValueFormatter,
    },
  ];

  return (
    <div data-testid="SwapEntriesGrid">
      <ChangeableGrid
        initialRows={rows}
        isLoading={isLoadingSwapEntries}
        columns={columns}
        rowCount={rowCountState}
        pageSizeOptions={[25, 50, 100]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        onProcessRowUpdateError={console.error}
        onAdd={handleAdd}
      ></ChangeableGrid>
    </div>
  );
};

export default SwapEntriesGrid;
