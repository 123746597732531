import * as yup from 'yup';

import { handleClearedAutocomplete } from '../../../../components/formUtils';

export const TRADE_ACCOUNT_REQUIRED = 'Trade Account Required';
export const TRADE_ACCOUNT_VALID =
  'The referenced Trade Account is either not set of no longer exists';
export const BROKER_REQUIRED = 'Broker Required';
export const BROKER_VALID = 'The referenced Futures Broker is either not set or no longer exists';
export const ORDER_NUMBER_LENGTH = 'Length cannot be more than 10 characters';
export const COMMODITY_REQUIRED = 'Commodity Required';
export const COMMODITY_VALID = 'The referenced Commodity is either not set or no longer exists';
export const PURCHASE_SALE_REQUIRED = 'Purchase or Sale Required';
export const FUTURES_CONTRACT_REQUIRED = 'Futures Contract Required';
export const FUTURES_CONTRACT_VALID =
  'The referenced Futures Contract for the Selected Commodity is either not set or no longer exists';
export const CONTRACTS_REQUIRED = 'Contracts Required';
export const MONTH_REQUIRED = 'Month Required';
export const PERIOD_REQUIRED = 'Period Required';
export const MONTH_VALID =
  'The referenced Option Month for the Selected Futures Contract is either not set or no longer exists';
export const PERIOD_INVALID =
  'The referenced Period for the Selected Month is either not set or no longer exists';
export const FUTURES_PRICE_REQUIRED = 'Futures Price Required';

export const CONTRACTS_BOUNDS = 'Contracts must be between -200,000 and 200,000';
export const CONTRACTS_NOT_ZERO = 'The number of Contracts cannot be zero';
export const FUTURES_PRICE_BOUNDS =
  'The Futures Price should be a positive value between $0.00001 and $9,999.99999';
export const COMMENT_LENGTH = 'Length cannot be more than 256 characters';

// Max is Contracts * ContractUnits for FuturesContract. Calc can not exceed INT
// Max in db was 10,000 at time this was defined
const MAX_CONTRACTS = 200000;

export const getValidationSchema = (
  tradeAccountOptions: number[],
  brokerOptions: number[],
  getValidCommodityOptions: () => Array<number>,
  getValidContractOptions: () => Array<number>,
  getValidMonths: () => Array<number>,
  isPeriodRequired: () => boolean,
  getValidPeriods: () => Array<number>
) => {
  return yup.object().shape({
    tradeAccountId: yup
      .number()
      .transform(handleClearedAutocomplete)
      .required(TRADE_ACCOUNT_REQUIRED)
      .oneOf(tradeAccountOptions, TRADE_ACCOUNT_VALID),
    commodityId: yup
      .number()
      .transform(handleClearedAutocomplete)
      .required(COMMODITY_REQUIRED)
      .test({
        test: (value) => getValidCommodityOptions().includes(value),
        message: COMMODITY_VALID,
      }),
    futuresContractId: yup
      .number()
      .transform(handleClearedAutocomplete)
      .required(FUTURES_CONTRACT_REQUIRED)
      .test({
        test: (value) => getValidContractOptions().includes(value),
        message: FUTURES_CONTRACT_VALID,
      }),
    brokerId: yup.number().required(BROKER_REQUIRED).oneOf(brokerOptions),
    contracts: yup
      .number()
      .transform(handleClearedAutocomplete)
      .required(CONTRACTS_REQUIRED)
      .moreThan(-1 * MAX_CONTRACTS - 1, CONTRACTS_BOUNDS)
      .lessThan(MAX_CONTRACTS + 1, CONTRACTS_BOUNDS)
      .test({
        skipAbsent: true,
        message: CONTRACTS_NOT_ZERO,
        test: (value) => value !== 0,
      }),
    optionMonthId: yup
      .number()
      .transform(handleClearedAutocomplete)
      .required(MONTH_REQUIRED)
      .test({
        test: (value) => getValidMonths().includes(value),
        message: MONTH_VALID,
      }),
    positionPeriodDimId: yup
      .number()
      .nullable()
      .transform(handleClearedAutocomplete)
      .test({
        test: (value) => !isPeriodRequired() || !!value,
        message: PERIOD_REQUIRED,
      })
      .test({
        test: (value) => (isPeriodRequired() ? !!value && getValidPeriods().includes(value) : true),
        message: PERIOD_INVALID,
      }),
    futuresPrice: yup
      .number()
      .moreThan(0, FUTURES_PRICE_BOUNDS)
      .lessThan(10000, FUTURES_PRICE_BOUNDS)
      .required(FUTURES_PRICE_REQUIRED),
    comment: yup.string().max(256, COMMENT_LENGTH).notRequired(),
  });
};
