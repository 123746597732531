import React, { useContext, useEffect } from 'react';
import { AxiosError } from 'axios';

import { Button, Typography } from '@mui/material';

import { PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';
import { useFuturesReferences } from '../../../hooks/backendDataClients/index';

export default function ImportFuturesReferencePage() {
  const dataClient = useFuturesReferences();

  useEffect(() => {
    document.title = 'Import Futures Reference | Positions and Futures';
  }, []);

  const importFuturesReferences = () =>
    dataClient.importFuturesReference().catch((err: AxiosError) => console.error(err));
  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="ImportFuturesReferencePage">
          <h2>Import Futures Reference</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  return (
    <>
      <main style={{ padding: '24px' }} data-testid="ImportFuturesReferencePage">
        <h2>Import Futures Reference</h2>
        <Typography>This option will Import Futures Reference Data</Typography>
        <Button variant="outlined" onClick={() => importFuturesReferences()}>
          Import
        </Button>
      </main>
    </>
  );
}
