import React, { FC } from 'react';

import { Link } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { UseQueryResult } from '@tanstack/react-query';

import { ChangeableGrid, dateOnlyValueFormatter } from '../../../../components';
import { UsersResponse } from '../../../../services/backend/data-contracts';

interface UsersGridProps {
  usersQueryFn?: () => Partial<UseQueryResult<UsersResponse['result']>>;
  handleModalOpen: (userId: number) => void;
}

const UsersGrid: FC<UsersGridProps> = (props) => {
  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'Username',
      flex: 1,
      type: 'string',
      editable: false,
      renderCell: ({ value, row }) => (
        <Link
          onClick={() => {
            props.handleModalOpen(row?.userId);
          }}
          sx={{ cursor: 'pointer' }}
          data-testid="LinkToModal"
        >
          {value || '_____'}
        </Link>
      ),
    },
    {
      field: 'userEmail',
      headerName: 'Email',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'lastLogin',
      headerName: 'Last Login',
      flex: 1,
      type: 'string',
      editable: false,
      valueFormatter: dateOnlyValueFormatter,
    },
  ];
  const { isPending, data: rows } = (props.usersQueryFn && props.usersQueryFn()) ?? {
    isPending: true,
    data: [],
  };
  const handleAdd = () => props.handleModalOpen(0);
  return (
    <ChangeableGrid
      data-testid="UsersGrid"
      initialRows={rows}
      getRowId={(row) => row['userId']}
      isLoading={isPending}
      columns={columns}
      onAdd={handleAdd}
    ></ChangeableGrid>
  );
};

export default UsersGrid;
