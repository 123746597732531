import { useContext, useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

import {
  FuturesUserRole,
  GrainTraderRole,
  GranelerasTraderRole,
  MillingTraderRole,
  SuperUserRole,
} from '../../../../app/UserWrapper/UserWrapper';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { UserContext } from '../../../../Context';
import TradeAccountDetailsPage from '../TradeAccountDetails/TradeAccountDetailsPage';

import TradeAccountsDisplay from './TradeAccountsDisplay/TradeAccountsDisplay';
import useTradeAccountsPage from './useTradeAccountsPage';

export default function TradeAccountsPage() {
  useEffect(() => {
    document.title = 'Trade Accounts | Positions and Futures';
  }, []);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const pageData = useTradeAccountsPage();
  const [selectedEntry, setSelectedEntry] = pageData.selectedEntryState;

  const userContext = useContext(UserContext);
  if (!userContext.userName) {
    return (
      <>
        <main data-testid="TradeAccountsPage">
          <h2>Trade Accounts Page</h2>
          <CircularProgress />
        </main>
      </>
    );
  }
  const hasViewTradeAccountsPermission = userContext.haveRole([
    GrainTraderRole,
    GranelerasTraderRole,
    MillingTraderRole,
    SuperUserRole,
    FuturesUserRole,
  ]);

  if (!hasViewTradeAccountsPermission) {
    return (
      <>
        <main data-testid="TradeAccountsPage">
          <h2>Trade Accounts Page</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  const handleNewButtonClick = () => {
    setSelectedEntry('');
    setIsDetailsModalOpen(true);
  };
  const handleEditButtonClick = (id: string) => {
    setSelectedEntry(id);
    setIsDetailsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsDetailsModalOpen(false);
    pageData.detailsQuery.refetch();
    pageData.listQuery.refetch();
  };

  return (
    <>
      <main data-testid="TradeAccountsPage" style={{ minWidth: 1400 }}>
        <TradeAccountsDisplay
          userName={pageData.userName}
          listQuery={pageData.listQuery}
          paginationModel={pageData.paginationModel}
          setPaginationModel={pageData.onPaginationModelChange}
          onSortModelChange={pageData.onSortModelChange}
          onFilterModelChange={pageData.onFilterModelChange}
          onEditButtonClick={handleEditButtonClick}
          onNewButtonClick={handleNewButtonClick}
        ></TradeAccountsDisplay>

        {
          // I'm thinking of moving the isPending check to be inside the details page
          pageData.detailsQuery.isPending ? (
            <LoadingSpinner />
          ) : isDetailsModalOpen ? (
            <TradeAccountDetailsPage
              isOpen={isDetailsModalOpen}
              isEditingExistingEntry={!!selectedEntry}
              onClose={handleModalClose}
              existingTradeDetails={pageData.detailsQuery.data}
            ></TradeAccountDetailsPage>
          ) : null
        }
      </main>
    </>
  );
}
