import { alpha, styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';

import DataGridWithSingleClickCellEdit from '../DataGridWithSingleClickCellEdit/DataGridWithSingleClickCellEdit';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridWithSingleClickCellEdit)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  '& .Mui-error': {
    outlineStyle: 'solid',
    outlineWidth: '1px',
    outlineColor: theme.palette.error[theme.palette.mode],
    outlineOffset: '-1px',
    backgroundColor: theme.palette.background.default,
  },
}));

StripedDataGrid.defaultProps = {
  getRowClassName: (params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'),
  autoHeight: true,
};

export default StripedDataGrid;
