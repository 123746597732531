import React, { FC } from 'react';
import { useFormik } from 'formik';

import { Box, Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';

import CurrencyTextField from '../../../../components/CurrencyTextField/CurrencyTextField';
import FuturesBrokerAutocomplete from '../../../../components/FuturesBrokerAutocomplete/FuturesBrokerAutocomplete';

export interface AllocatedCommissionAdjustmentFilterFormProps {
  formik: ReturnType<typeof useFormik<any>>;
  handleBackDateButtonClick: () => void;
  handleClear: () => void;
  disableButtons: boolean;
}

const AllocatedCommissionAdjustmentFilterForm: FC<AllocatedCommissionAdjustmentFilterFormProps> = ({
  formik,
  handleBackDateButtonClick,
  handleClear,
  disableButtons,
}) => {
  return (
    <Box data-testid="AllocatedCommissionAdjustmentFilterForm">
      <form autoComplete="off">
        <Grid container spacing={2}>
          <Grid container item alignItems="Center" xs={12}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
              <b>Adjustment Date: </b>
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                value={formik?.values['adjustmentDate']}
                onChange={(value) => {
                  formik.setFieldValue('adjustmentDate', value);
                  formik.setFieldValue('isBackDated', false);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                    name: 'adjustmentDate',
                    onBlur: (e) => {
                      formik.handleBlur(e);
                    },
                    error: formik.touched.adjustmentDate && Boolean(formik.errors.adjustmentDate),
                    helperText:
                      formik.touched.adjustmentDate && formik.errors.adjustmentDate?.toString(),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Button onClick={handleBackDateButtonClick} disabled={disableButtons}>
                Back Date Adjustment
              </Button>
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={12}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
              <Typography>Adjustment Type: </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Allocated Commission Adjustment</Typography>
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={12}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
              <b>Broker: </b>
            </Grid>
            <Grid item xs={3}>
              <FuturesBrokerAutocomplete formik={formik} openOnFocus></FuturesBrokerAutocomplete>
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={12}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
              <b>Amount: </b>
            </Grid>
            <Grid item xs={3}>
              <CurrencyTextField
                name="amount"
                value={formik.values['amount']}
                inputProps={{
                  step: '0.01',
                  maxLength: 14,
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount?.toString()}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={12}>
            <Typography>
              <b>Allocate Adjustment over New Trades in Date Range</b>
            </Typography>
          </Grid>
          <Grid container item alignItems="Center" xs={12}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
              <b>Start Date: </b>
            </Grid>
            <Grid item xs={3}>
              <b>End Date: </b>
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={12}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
              <DatePicker
                value={formik?.values['startDate']}
                onChange={(value) => {
                  formik.setFieldValue('startDate', value);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                    name: 'startDate',
                    onBlur: (e) => {
                      formik.handleBlur(e);
                    },
                    error: formik.touched.startDate && Boolean(formik.errors.startDate),
                    helperText: formik.touched.startDate && formik.errors.startDate?.toString(),
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                value={formik?.values['endDate']}
                onChange={(value) => {
                  formik.setFieldValue('endDate', value);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                    name: 'endDate',
                    onBlur: (e) => {
                      formik.handleBlur(e);
                    },
                    error: formik.touched.endDate && Boolean(formik.errors.endDate),
                    helperText: formik.touched.endDate && formik.errors.endDate?.toString(),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" onClick={formik.submitForm} disabled={disableButtons}>
                Calculate Allocation
              </Button>
              <Button variant="outlined" onClick={handleClear} disabled={disableButtons}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AllocatedCommissionAdjustmentFilterForm;
