import React, { useContext } from 'react';

import Typography from '@mui/material/Typography';

import { PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';
import { useLocationGroupsQuery, useOktaUser } from '../../../hooks';
import {
  useLocationGroupsMassCreates,
  useLocationGroupsMassUpdates,
} from '../../../hooks/backendDataClients/index';

import LocationGroupsDisplay from './LocationGroupsDisplay';

export default function LocationGroupsPage() {
  const { user } = useOktaUser();
  const locationGroupsMassUpdates = useLocationGroupsMassUpdates();
  const locationGroupsMassCreates = useLocationGroupsMassCreates();
  const userName = user?.preferred_username ?? '';
  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="LocationGroupsPage">
          <h2>Location Groups</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  return (
    <div style={{ padding: '24px' }} data-testid="LocationGroupsPage">
      <LocationGroupsDisplay
        userName={userName}
        getLocationGroups={useLocationGroupsQuery}
        createLocationGroups={locationGroupsMassCreates.createLocationGroups}
        updateLocationGroups={locationGroupsMassUpdates.updateLocationGroups}
      />
    </div>
  );
}
