import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import { LoginCallback } from '@okta/okta-react';

import Loading from '../../components/Loading/Loading';
import { lightTheme } from '../AppTheme';

export default function NotLoggedIn() {
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <div className="header">
          <h2>Positions and Futures</h2>
        </div>
        <div className="navAndBodyWrapper">
          <div className="mainBody">
            <Routes>
              <Route path="*" element={<Loading />} />
              <Route
                path="login/callback"
                element={<LoginCallback loadingElement={<Loading />} />}
              />
            </Routes>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}
