import React, { FC, useContext } from 'react';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';

import { ColorModeContext, LogoutContext } from '../../../Context';
import { useOktaUser } from '../../../hooks';

const HeaderBar: FC = () => {
  const { logout } = useContext(LogoutContext);
  const { toggleColorMode } = useContext(ColorModeContext);
  const { user } = useOktaUser();
  const theme = useTheme();

  const userName = user?.name;
  const isTest = process.env.REACT_APP_DEPLOYMENT !== 'production';
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 2,
        bgcolor: isTest ? 'warning.main' : 'primary.main',
      }}
      data-testid="HeaderBar"
    >
      <Toolbar>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} role="heading">
          Positions and Futures
        </Typography>
        <Typography>{userName}</Typography>
        {userName && (
          <IconButton sx={{ ml: 1 }} onClick={logout} color="inherit" aria-label="logout">
            <LogoutIcon />
          </IconButton>
        )}
        <IconButton
          sx={{ ml: 1 }}
          onClick={toggleColorMode}
          color="inherit"
          aria-label="light/dark mode"
        >
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
