/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { FuturesOptionMonthSortedResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class FuturesOptionMonths<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetFuturesOptionMonthsByYear
   * @request GET:/futuresOptionMonths
   * @secure
   */
  getFuturesOptionMonthsByYear = (params: RequestParams = {}) =>
    this.request<FuturesOptionMonthSortedResponse, any>({
      path: `/futuresOptionMonths`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFuturesOptionMonths
   * @request OPTIONS:/futuresOptionMonths
   */
  optionsFuturesOptionMonths = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futuresOptionMonths`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFuturesOptionMonths2
   * @request OPTIONS:/futuresOptionMonths/{id}
   * @originalName optionsFuturesOptionMonths
   * @duplicate
   */
  optionsFuturesOptionMonths2 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futuresOptionMonths/${id}`,
      method: 'OPTIONS',
      ...params,
    });
}
