import React, { useState } from 'react';

import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

import { useOktaUser } from '../../../../hooks';
import {
  useFuturesContractDetailsForFuturesQuery,
  useFuturesContractsListQuery,
} from '../../../../hooks/useFuturesDataQueries';

export interface FuturesContractsFilterForm {
  sortOrder?: string;
  limit: number;
  offset?: number;
  search?: string;
  sortBy?: string;
}

export default function useFuturesContractsPage() {
  const { user } = useOktaUser();
  const selectedEntryState = useState<string>('');
  const [entryToEdit] = selectedEntryState;

  const userName = user?.preferred_username ?? '';

  const filterFormState = useState<FuturesContractsFilterForm>({
    sortOrder: '',
    limit: 50,
    offset: 0,
    search: '',
    sortBy: '',
  });
  const [filterForm, setFilterForm] = filterFormState;
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: filterForm.limit,
  });

  const listQuery = useFuturesContractsListQuery(filterForm);

  const detailsQuery = useFuturesContractDetailsForFuturesQuery(entryToEdit);

  const onPaginationModelChange = (pageChange: GridPaginationModel) => {
    if (
      !isNaN(pageChange.page) &&
      !isNaN(pageChange.pageSize) &&
      (pageChange.page != paginationModel.page || pageChange.pageSize != paginationModel.pageSize)
    ) {
      const offset = +pageChange.page * +pageChange.pageSize;
      setFilterForm({ ...filterForm, limit: pageChange.pageSize, offset });
      setPaginationModel(pageChange);
    }
  };
  const onSortModelChange = (model: GridSortModel) => {
    if (model[0] && model[0].field && model[0].sort) {
      setFilterForm({ ...filterForm, sortBy: model[0].field, sortOrder: model[0].sort });
    } else {
      setFilterForm({ ...filterForm, sortOrder: '', sortBy: '' });
    }
  };
  const onFilterModelChange = (model: GridFilterModel) => {
    if (model && model.quickFilterValues && model.quickFilterValues.length > 0) {
      setFilterForm({ ...filterForm, search: model.quickFilterValues[0] });
    } else {
      setFilterForm({ ...filterForm, search: '' });
    }
  };
  return {
    userName,
    filterFormState,
    selectedEntryState,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
    listQuery,
    detailsQuery,
  };
}
