import React, { useContext } from 'react';

import Typography from '@mui/material/Typography';

import { PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';
import { useOktaUser, useTransactionTypesQuery } from '../../../hooks';
import {
  useTransactionTypes,
  useTransactionTypesMassUpdates,
} from '../../../hooks/backendDataClients/index';

import TransactionTypesDisplay from './TransactionTypesDisplay';

export default function TransactionTypesPage() {
  const { user } = useOktaUser();
  const transactionTypesDataClient = useTransactionTypes();
  const transactionTypesMassUpdatesDataClient = useTransactionTypesMassUpdates();
  const userName = user?.preferred_username ?? '';
  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="TransactionTypesPage">
          <h2>Transaction Types</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  return (
    <main style={{ padding: '24px' }} data-testid="TransactionTypesPage">
      <TransactionTypesDisplay
        userName={userName}
        getTransactionTypes={useTransactionTypesQuery}
        createTransactionTypes={transactionTypesDataClient.createTransactionTypes}
        updateTransactionTypes={transactionTypesMassUpdatesDataClient.updateTransactionTypes}
      />
    </main>
  );
}
