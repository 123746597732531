import { useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import {
  backendDataClients,
  useCommoditiesQuery,
  useLocationCommodityRefQuery,
  useLocationsSummaryQuery,
  useOktaUser,
  useTransactionTypesQuery,
} from '../../../../hooks';
import { useToastMutation } from '../../../../hooks/useToastMutations';
import { PositionEntriesByUserResponse } from '../../../../services/backend/data-contracts';

const { usePositionEntries } = backendDataClients;

export default function usePositionEntryDetailFunctions(positionEntryId?: string) {
  const { user } = useOktaUser();
  const userName = user ? user.preferred_username : '';

  const queryClient = useQueryClient();
  const positionEntriesDataClient = usePositionEntries();

  const locationSummaries = useLocationsSummaryQuery().data?.map((c) => ({ ...c }));
  const commodities =
    useCommoditiesQuery()
      ?.data?.sort((a, b) => (a.commodityCode ?? '').localeCompare(b.commodityCode ?? ''))
      ?.map((c) => ({ ...c })) ?? [];
  const transactionTypes = useTransactionTypesQuery()
    .data?.sort((a, b) =>
      (a.transactionTypeValue ?? '').localeCompare(b.transactionTypeValue ?? '')
    )
    .map((c) => ({ ...c }));

  const positionEntriesForUserQuery = useQuery({
    queryKey: ['Position Entries', 'My Recent Positions', userName],
    queryFn: async () => {
      if (userName) {
        return (await positionEntriesDataClient.getPositionEntriesByUser(userName)).data.result;
      } else {
        return [] as PositionEntriesByUserResponse['result'];
      }
    },
  });

  const locationCommodityRefQuery = useLocationCommodityRefQuery();

  const commodityById = new Map(commodities?.map((c) => [c.commodityId, c]));

  const getCommoditiesMapByLocation = (
    id: string
  ): { isPending: boolean; commoditiesMap: Map<number, string> } => ({
    isPending: locationCommodityRefQuery.isPending,
    commoditiesMap: new Map<number, string>(
      locationCommodityRefQuery.data
        ?.filter((c) => c.locationId === Number.parseInt(id))
        .sort((a, b) => (a.commodityValue ?? '').localeCompare(b.commodityValue ?? ''))
        ?.reduce((cr, c) => {
          if (c.commodityId) {
            cr.set(c.commodityId, commodityById.get(c.commodityId)?.commodityCode ?? '');
          }
          return cr;
        }, new Map<number, string>()) ?? []
    ),
  });

  const getEntryQuery = useSuspenseQuery({
    queryKey: ['Position Entries', 'Details', positionEntryId],
    queryFn: async () => {
      if (!positionEntryId) {
        return null; // no enabled prop for suspenseQueries
      }
      const response = await positionEntriesDataClient.getPositionEntries();
      return response.data.result.find((p: any) => p.id + '' == positionEntryId);
    },
  });

  const createMutation = useToastMutation({
    mutationFn: positionEntriesDataClient.createPositionEntry,
    queryKey: ['Position Entries', 'List'],
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Position Created',
    afterSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['Position Entries', 'My Recent Positions'] });
    },
  });

  const updateMutation = useToastMutation({
    mutationFn: ({ id, data }: any) => {
      return positionEntriesDataClient.updatePositionEntry(id, data);
    },
    queryKey: ['Position Entries', 'Details', positionEntryId],
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Position Updated',
    afterSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['Position Entries', 'List'] });
    },
  });

  const deleteMutation = useToastMutation({
    mutationFn: positionEntriesDataClient.deletePositionEntry,
    queryKey: ['Position Entries', 'Details', positionEntryId],
    onMutateMessage: 'Deleting...',
    onSuccessMessage: 'Position Deleted',
    afterSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['Position Entries', 'List'] });
    },
    isDelete: true,
  });

  return {
    userName,
    locationSummaries,
    commodities,
    transactionTypes,
    getCommoditiesMapByLocation,
    getEntryQuery,
    updateMutation,
    createMutation,
    deleteMutation,
    myRecentPositionsData: positionEntriesForUserQuery,
  };
}
