import React, { useEffect, useState } from 'react';

import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  backendDataClients,
  useCommoditiesQuery,
  useCommodityOptionMonthsQuery,
  useLocationCommodityRefQuery,
  useLocationsSummaryQuery,
  useOktaUser,
  useTransactionTypesQuery,
} from '../../../hooks';

const { usePositionEntries } = backendDataClients;

export interface PositionEntriesFilterForm {
  sortOrder?: string;
  limit: number;
  transactionTypeId?: string;
  offset?: number;
  purchaseSale?: string;
  search?: string;
  sortBy?: string;
  locationId?: string;
  commodityId?: string;
}

export default function usePositionEntriesPage() {
  const { user } = useOktaUser();
  const userName = user?.preferred_username ?? '';
  const queryClient = useQueryClient();

  const [filterForm, setFilterForm] = useState<PositionEntriesFilterForm>({
    sortOrder: '',
    limit: 50,
    transactionTypeId: '',
    offset: 0,
    purchaseSale: '',
    search: '',
    sortBy: '',
    locationId: '',
    commodityId: '',
  });

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: filterForm.limit,
  });

  // Prefetch for details
  useLocationCommodityRefQuery();
  useCommodityOptionMonthsQuery(true);

  const locationSummaries = useLocationsSummaryQuery()
    .data?.filter((c) => c.isActive)
    ?.sort((a, b) => (a.locationValue ?? '').localeCompare(b.locationValue ?? ''))
    ?.map((c) => ({ ...c }));
  const commodities =
    useCommoditiesQuery()
      .data?.filter((c) => c.isActive)
      ?.sort((a, b) => (a.commodityCode ?? '').localeCompare(b.commodityCode ?? '')) ?? [];
  const transactionTypes = useTransactionTypesQuery().data?.sort((a, b) =>
    (a.transactionTypeValue ?? '').localeCompare(b.transactionTypeValue ?? '')
  );
  const queryParams = Object.assign(
    {
      limit: filterForm.limit, // number of rows per page
      offset: filterForm.offset, // what row to start at
    },
    filterForm.locationId === '' ? null : { locationId: filterForm.locationId },
    filterForm.commodityId === '' ? null : { commodityId: filterForm.commodityId },
    filterForm.transactionTypeId === ''
      ? null
      : { transactionTypeId: filterForm.transactionTypeId },
    filterForm.purchaseSale === '' ? null : { purchaseSale: filterForm.purchaseSale },
    filterForm.search === '' ? null : { search: filterForm.search },
    filterForm.sortOrder === '' ? null : { sortOrder: filterForm.sortOrder },
    filterForm.sortBy === '' ? null : { sortBy: filterForm.sortBy }
  );
  const dataClient = usePositionEntries();
  const positionEntriesQuery = useQuery({
    queryKey: ['Position Entries', 'List', filterForm],
    queryFn: async () => {
      const response = await dataClient.getFilteredPositionEntries(queryParams);
      const entriesWrapper = response.data?.result;
      entriesWrapper.entries?.forEach((entry) => {
        // Safe because the data format's the same between this call and the details call.
        // If we were to use a details endpoint with more information, we might want to instead have the list
        //   page pass that entry's details down to be used as initialData for the details query.
        queryClient.setQueryData(['Position Entries', 'Details', entry.id], entry);
      });
      return entriesWrapper;
    },
  });
  const getAllFilteredPositions = async () => {
    return dataClient.getFilteredPositionEntries({
      ...queryParams,
      limit: Number.MAX_SAFE_INTEGER,
      offset: 0,
    });
  };

  const rows: GridRowsProp[] =
    positionEntriesQuery.data?.entries?.map((t) => {
      return { id: t.id, ...t } as unknown as GridRowsProp;
    }) ?? [];

  const totalCount = positionEntriesQuery.data?.totalCount;
  const [rowCountState, setRowCountState] = React.useState(totalCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);
  const onPaginationModelChange = (pageChange: GridPaginationModel) => {
    if (!isNaN(pageChange.page) && !isNaN(pageChange.pageSize)) {
      const offset = +pageChange.page * +pageChange.pageSize;
      setFilterForm({ ...filterForm, limit: pageChange.pageSize, offset });
      setPaginationModel(pageChange);
    }
  };
  const onSortModelChange = (model: GridSortModel) => {
    if (model[0] && model[0].field && model[0].sort) {
      setFilterForm({ ...filterForm, sortBy: model[0].field, sortOrder: model[0].sort });
    } else {
      setFilterForm({ ...filterForm, sortOrder: '', sortBy: '' });
    }
  };
  const onFilterModelChange = (model: GridFilterModel) => {
    if (model && model.quickFilterValues && model.quickFilterValues.length > 0) {
      setFilterForm({ ...filterForm, search: model.quickFilterValues[0] });
    } else {
      setFilterForm({ ...filterForm, search: '' });
    }
  };
  return {
    userName,
    filterForm,
    rowCountState,
    setFilterForm,
    rows,
    getAllFilteredPositions,
    isLoadingRows: positionEntriesQuery.isPending,
    locationSummaries,
    commodities,
    transactionTypes,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
  };
}
