import { FC, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Link } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { ChangeableDisplay } from '../../../../components';
import { UseRefDataQueryResult } from '../../../../hooks';
import { LocationsResponse } from '../../../../services/backend/data-contracts';
import { RequestParams } from '../../../../services/backend/http-client';
import LocationModal, { LocationModalProps } from '../LocationModal/LocationModal';

interface LocationsListProps
  extends Omit<
    LocationModalProps,
    'isModalOpen' | 'onClose' | 'hideUnsavedPrompt' | 'locationId' | 'isNewLocation' | 'getLocation'
  > {
  getLocations: () => UseRefDataQueryResult<LocationsResponse['result']>;
  getLocationDetails: (id: string, params?: RequestParams) => Promise<AxiosResponse<any, any>>;
  hideUnsavedPrompt?: boolean;
}

const LocationsList: FC<LocationsListProps> = ({
  getLocations,
  getLocationDetails,
  hideUnsavedPrompt,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedLocationId, setSelectedLocationId] = useState<string>('');
  const locationGroupValueGetter = (params: GridValueGetterParams): string => {
    const code = params.row.locationGroup?.locationGroupCode;
    const group = props.locationGroups?.find((lg) => lg.locationGroupCode === code);
    return code + ' - ' + group?.locationGroupName;
  };

  const columns: GridColDef[] = [
    {
      field: 'locationCode',
      headerName: 'Location Code',
      flex: 1,
      type: 'string',
      editable: false,
      renderCell: ({ value, row }) => (
        <Link
          onClick={() => {
            console.log('opening modal');
            setIsModalOpen(true);
            setSelectedLocationId(row.locationId);
          }}
          sx={{ cursor: 'pointer' }}
          data-testid="LinkToModal"
        >
          {value || '______'}
        </Link>
      ),
    },
    {
      field: 'locationName',
      headerName: 'Location Name',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'locationGroup', // field is required by the type, but is overridden by the valueGetter.
      valueGetter: locationGroupValueGetter,
      headerName: 'Location Group',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      flex: 1,
      type: 'string',
      editable: false,
    },
  ];

  const handleModalClose = () => {
    setSelectedLocationId('');
    setIsModalOpen(false);
  };
  const add = () => {
    setSelectedLocationId('');
    setIsModalOpen(true);
  };

  return (
    <ChangeableDisplay
      pageTitle="Locations"
      columns={columns}
      getRowId={(row) => row['locationId']}
      onAddButtonClick={add}
      fnUseQuery={getLocations}
      uniqueFields={['locationCode']}
      sortField="locationCode"
      hideUnsavedPrompt={hideUnsavedPrompt}
      modalEdit={() => (
        <LocationModal
          isNewLocation={!selectedLocationId}
          locationId={selectedLocationId}
          isModalOpen={isModalOpen}
          onClose={handleModalClose}
          getLocation={getLocationDetails}
          {...props}
        ></LocationModal>
      )}
    ></ChangeableDisplay>
  );
};

export default LocationsList;
