import React, { FC, PropsWithChildren, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadingIcon from '@mui/icons-material/Downloading';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

interface ToolbarProps {
  onSave?: () => void;
  onAdd?: () => void;
  onExport?: (onFinish: () => void) => void;
  onRemove?: () => void;
  hasChanges?: boolean;
  canRemove?: boolean;
  small?: boolean;
  hideSearch?: boolean;
}

const Toolbar: FC<PropsWithChildren<ToolbarProps>> = ({
  onSave,
  onAdd,
  onExport,
  onRemove,
  hasChanges,
  canRemove,
  small,
  hideSearch,
  children,
}) => {
  const [isExporting, setIsExporting] = useState(false);

  const handleDownloadButtonClick = async () => {
    if (onExport) {
      setIsExporting(true);
      onExport(() => setIsExporting(false));
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {onSave ? (
        <IconButton
          aria-label="save"
          sx={{ fontSize: small ? 24 : 56 }}
          onClick={onSave}
          disabled={!hasChanges}
        >
          <SaveIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <></>
      )}
      {onAdd ? (
        <IconButton aria-label="add" sx={{ fontSize: small ? 24 : 56 }} onClick={onAdd}>
          <AddIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <></>
      )}
      {onRemove ? (
        <IconButton
          aria-label="remove"
          sx={{ fontSize: small ? 24 : 56 }}
          onClick={onRemove}
          disabled={!canRemove}
        >
          <RemoveIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <></>
      )}
      {children}
      <div style={{ flex: '1 0 0' }} />
      {!!onExport && (
        <IconButton
          aria-label="download"
          disabled={isExporting}
          onClick={handleDownloadButtonClick}
        >
          {isExporting ? (
            <DownloadingIcon fontSize="inherit" />
          ) : (
            <DownloadIcon fontSize="inherit" />
          )}
        </IconButton>
      )}
      {!hideSearch && <GridToolbarQuickFilter variant="outlined" sx={{ float: 'right', p: 1 }} />}
    </div>
  );
};

export default Toolbar;
