import React, { FC } from 'react';
import { useFormik } from 'formik';

import { Alert, Box, Grid, TextField } from '@mui/material';

import CurrencyTextField from '../../../../components/CurrencyTextField/CurrencyTextField';
import FormikAutocomplete from '../../../../components/FormikAutocomplete/FormikAutocomplete';
import NumericFormatCustom from '../../../../components/NumericFormatCustom/NumericFormatCustom';
import {
  CommoditiesSummaryResponse,
  FuturesContractsSummaryResponse,
  FuturesContractTradeAccountRefResponseModel,
  FuturesTradeAccountSummaryResponse,
  TradeAccountCommodityRefResponseModel,
} from '../../../../services/backend/data-contracts';

export enum AutoFields {
  BUY_PERIOD,
  SELL_PERIOD,
  BOTH_PERIODS,
}

export interface SwapEntryDetailsProps {
  formik: ReturnType<typeof useFormik<any>>;
  isCreate: boolean;
  commodities?: CommoditiesSummaryResponse['result'];
  futuresContractSummaries?: FuturesContractsSummaryResponse['result'];
  tradeAccountSummaries?: FuturesTradeAccountSummaryResponse['result'];
  futuresContractsToMonths?: Record<number, Map<number, string>>;
  futuresContractTradeAccounts?: FuturesContractTradeAccountRefResponseModel['result'];
  tradeAccountCommodities?: TradeAccountCommodityRefResponseModel['result'];
  periodsMap: Map<number, string>;
  setCurrentAutoField: (autoField: AutoFields) => void;
  isBuyPeriodEnabled: boolean;
  isSellPeriodEnabled: boolean;
}

const SwapEntryDetails: FC<SwapEntryDetailsProps> = ({
  formik,
  isCreate,
  commodities,
  futuresContractSummaries,
  tradeAccountSummaries,
  futuresContractsToMonths,
  futuresContractTradeAccounts,
  tradeAccountCommodities,
  periodsMap,
  setCurrentAutoField,
  isBuyPeriodEnabled,
  isSellPeriodEnabled,
}) => {
  const firstInputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (isCreate && !formik.touched.futuresContractId) {
      firstInputRef.current?.focus();
    }
  }, [formik.touched.futuresContractId, isCreate]);

  const tradeAccountCommoditiesMap = (tradeAccountCommodities ?? []).reduce(
    (tacMap, tac) => {
      const { tradeAccountId, commodityId } = tac;
      if (tradeAccountId && commodityId) {
        tacMap[tradeAccountId.toString()] = (tacMap[tradeAccountId.toString()] ?? []).concat(
          commodityId.toString()
        );
      }
      return tacMap;
    },
    {} as Record<string, string[]>
  );

  const futuresContractTradeAccountsMap = (futuresContractTradeAccounts ?? []).reduce(
    (fctaMap, fcta) => {
      const { futuresContractId, tradeAccountId } = fcta;
      if (futuresContractId && tradeAccountId) {
        fctaMap[futuresContractId.toString()] = (fctaMap[futuresContractId] ?? []).concat(
          tradeAccountId.toString()
        );
      }
      return fctaMap;
    },
    {} as Record<string, string[]>
  );

  const futuresContractsMap = new Map(
    futuresContractSummaries?.map((ta) => [
      ta.futuresContractId?.toString() as string,
      ta.futuresContractValue as string,
    ])
  );

  const tradeAccountsMap = (tradeAccountSummaries ?? [])
    .filter(
      (t) =>
        t.tradeAccountId &&
        futuresContractTradeAccountsMap[formik.values.futuresContractId]?.includes(
          t.tradeAccountId.toString()
        )
    )
    .reduce((tradeAccountsMap, tradeAccount) => {
      const { tradeAccountId, tradeAccountName } = tradeAccount;
      if (tradeAccountId) {
        tradeAccountsMap.set(tradeAccountId.toString(), tradeAccountName ?? '');
      }
      return tradeAccountsMap;
    }, new Map<string, string>());

  const commoditiesRecord = (commodities ?? []).reduce(
    (commoditiesMap, commodity) => {
      const { commodityId, commodityName, commodityCode } = commodity;
      if (commodityId) {
        commoditiesMap[commodityId.toString()] =
          (commodityName && `${commodityCode} - ${commodityName}`) ?? '';
      }
      return commoditiesMap;
    },
    {} as Record<string, string>
  );

  const buyCommoditiesMap = Object.keys(commoditiesRecord).reduce((bcMap, key) => {
    if (tradeAccountCommoditiesMap[formik.values.buyTradeAccountId]?.includes(key)) {
      bcMap.set(key, commoditiesRecord[key]);
    }
    return bcMap;
  }, new Map<string, string>());

  const sellCommoditiesMap = Object.keys(commoditiesRecord).reduce((scMap, key) => {
    if (tradeAccountCommoditiesMap[formik.values.sellTradeAccountId]?.includes(key)) {
      scMap.set(key, commoditiesRecord[key]);
    }
    return scMap;
  }, new Map<string, string>());

  return (
    <Box data-testid="SwapEntryDetails">
      {!formik.values ? null : (
        <form autoComplete="off">
          {formik.errors?.[''] &&
          formik.touched.buyCommodityId &&
          formik.touched.sellCommodityId ? (
            <Alert severity="error">{formik.errors?.['']?.toString()}</Alert>
          ) : (
            <></>
          )}
          <Grid container spacing={2}>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="futuresContractId-select-label">
                <b>Futures Contract:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="futuresContractId"
                  optionsAsMap={futuresContractsMap}
                  onValueChange={(e, value) => {
                    formik.setValues({
                      ...formik.values,
                      futuresContractId: value,
                      optionMonthId: null,
                      buyTradeAccountId: null,
                      buyCommodityId: null,
                      sellTradeAccountId: null,
                      sellCommodityId: null,
                    });
                  }}
                  inputRef={firstInputRef}
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="optionMonthId-select-label">
                <b>Month:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="optionMonthId"
                  optionsAsMap={
                    futuresContractsToMonths?.[formik.values.futuresContractId] ??
                    new Map<number, string>()
                  }
                  onValueChange={() => {
                    formik.setFieldValue('buyPeriodDimId', null);
                    formik.setFieldValue('sellPeriodDimId', null);
                    setCurrentAutoField(AutoFields.BOTH_PERIODS);
                  }}
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="futures-price-text-label">
                <b>Futures Price:</b>
              </Grid>
              <Grid item xs={6}>
                <CurrencyTextField
                  id="futures-price-text"
                  name="futuresPrice"
                  value={formik.values.futuresPrice ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  inputProps={{
                    maxLength: 11,
                  }}
                  error={formik.touched.futuresPrice && Boolean(formik.errors.futuresPrice)}
                  helperText={formik.touched.futuresPrice && formik.errors.futuresPrice?.toString()}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="contracts-input-label">
                <b>Contracts:</b>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="contracts-input"
                  size="small"
                  name="contracts"
                  value={formik.values.contracts ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  inputProps={{ maxLength: 5 }}
                  error={formik.touched.contracts && Boolean(formik.errors.contracts)}
                  helperText={formik.touched.contracts && formik.errors.contracts?.toString()}
                  InputProps={{
                    'aria-labelledby': 'contracts-input-label',
                    inputComponent: NumericFormatCustom as any,
                  }}
                ></TextField>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="buyTradeAccountId-select-label">
                <b>Buy Trade Account:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="buyTradeAccountId"
                  optionsAsMap={tradeAccountsMap}
                  onValueChange={() => {
                    formik.setFieldValue('buyCommodityId', null);
                    setCurrentAutoField(AutoFields.BUY_PERIOD);
                  }}
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="buyCommodityId-select-label">
                <b>Buy Commodity:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="buyCommodityId"
                  optionsAsMap={buyCommoditiesMap}
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="buyPeriodDimId-select-label">
                <b>Buy Position Period:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="buyPeriodDimId"
                  optionsAsMap={periodsMap}
                  disabled={!isBuyPeriodEnabled}
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="sellTradeAccountId-select-label">
                <b>Sell Trade Account:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="sellTradeAccountId"
                  optionsAsMap={tradeAccountsMap}
                  onValueChange={() => {
                    formik.setFieldValue('sellCommodityId', null);
                    setCurrentAutoField(AutoFields.SELL_PERIOD);
                  }}
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="sellCommodityId-select-label">
                <b>Sell Commodity:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="sellCommodityId"
                  optionsAsMap={sellCommoditiesMap}
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="sellPeriodDimId-select-label">
                <b>Sell Position Period:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="sellPeriodDimId"
                  optionsAsMap={periodsMap}
                  disabled={!isSellPeriodEnabled}
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="comment-input-label">
                Comment:
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline={true}
                  id="comment-text"
                  minRows={3}
                  name="comment"
                  value={formik.values.comment ?? null}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Comment"
                  inputProps={{ maxLength: 256 }}
                  error={formik.touched.comment && Boolean(formik.errors.comment)}
                  helperText={formik.touched.comment && formik.errors.comment?.toString()}
                  InputProps={{ 'aria-labelledby': 'comment-input-label' }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default SwapEntryDetails;
