import React, { FC } from 'react';

import { InputBaseComponentProps, StandardTextFieldProps, TextField } from '@mui/material';

import NumericFormatCustom from '../NumericFormatCustom/NumericFormatCustom';

interface NumericFormatTextFieldProps extends Omit<StandardTextFieldProps, 'InputProps' | 'type'> {
  inputProps: InputBaseComponentProps;
}

const NumericFormatTextField: FC<NumericFormatTextFieldProps> = ({ inputProps, ...props }) => (
  <TextField
    InputProps={{
      inputComponent: NumericFormatCustom as any,
      inputProps: inputProps,
    }}
    {...props}
  />
);

export default NumericFormatTextField;
