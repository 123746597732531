import * as yup from 'yup';

export const brokerSwapEntrySchema = yup.object().shape({
  buyBrokerId: yup.string().required('Buy broker required'),
  sellBrokerId: yup.string().required('Sell broker required'),
  tradeDate: yup.object().required('Trade Date required'),
  tradeAccountId: yup.string().required('Trade Account required'),
  contracts: yup.string().required('Contracts required'),
  hedgedCommodityId: yup.string().required('Hedged Commodity required'),
  futuresContractId: yup.string().required('Futures Contract required'),
  optionMonthId: yup.string().required('Option Month required'),
  unitPrice: yup.string().required('Price required'),
  comment: yup.string().notRequired(),
});
