import React, { Suspense, useContext, useEffect, useState } from 'react';
import xlsx from 'json-as-xlsx';
import { useToast } from 'use-toast-mui';

import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

import {
  FuturesUserRole,
  GrainTraderRole,
  GranelerasTraderRole,
  MillingTraderRole,
  SuperUserRole,
} from '../../../../app/UserWrapper/UserWrapper';
import {
  dateOnlyStringFormatter,
  dateStringFormatter,
  excelFormatString_Accounting_2Decimals,
  excelFormatString_Number_5Decimals,
  shortLongToBuySellStringFormatter,
  zeroIfBlank,
} from '../../../../components';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { UserContext } from '../../../../Context';
import BrokerSwapPage from '../../BrokerSwap/BrokerSwapPage';
import FuturesTradeDetailsPage from '../FuturesTradeDetails/FuturesTradeDetailsPage';

import FuturesTradesDisplay from './FuturesTradesDisplay/FuturesTradesDisplay';
import useFuturesTradesPage from './useFuturesTradesPage';

export default function FuturesTradesPage() {
  useEffect(() => {
    document.title = 'Futures Trades | Positions and Futures';
  }, []);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [isSwapsModalOpen, setIsSwapsModalOpen] = useState<boolean>(false);
  const pageData = useFuturesTradesPage();
  const [selectedEntry, setSelectedEntry] = pageData.selectedEntryState;

  const toast = useToast();

  const handleExportButtonClick = async (onFinish: () => void) => {
    try {
      const futuresEntries = (await pageData.getAllFilteredFuturesTrades()).data?.result?.entries;
      if (futuresEntries) {
        const data = [
          {
            sheet: 'Pending Futures Trades',
            columns: [
              { label: 'Trade ID', value: 'tradeId' },
              { label: 'Trade Date', value: (r: any) => dateOnlyStringFormatter(r?.tradeDate) },
              {
                label: 'Buy Sell',
                value: (r: any) => shortLongToBuySellStringFormatter(r?.longShort),
              },
              { label: 'Futures Contract', value: 'futuresContractName' },
              { label: 'Futures Month', value: 'optionMonthName' },
              { label: 'Contracts', value: 'contracts' },
              {
                label: 'Price',
                value: (r: any) => zeroIfBlank(r, 'unitPrice'),
                format: excelFormatString_Number_5Decimals,
              },
              { label: 'Trade Account', value: 'tradeAccountName' },
              { label: 'Hedged Commodity', value: 'hedgedCommodityName' },
              { label: 'Broker', value: 'brokerName' },
              {
                label: 'Commission',
                value: (r: any) => zeroIfBlank(r, 'commission'),
                format: excelFormatString_Accounting_2Decimals,
              },
              {
                label: 'Commission Rate Type',
                value: (r: any) =>
                  r?.commissionRateType === 'N/A' ? 'Not Applicable' : r?.commissionRateType,
              },
              {
                label: 'Commission Rate',
                value: (r: any) => zeroIfBlank(r, 'commissionRate'),
                format: excelFormatString_Accounting_2Decimals,
              },
              { label: 'Comment', value: 'comment' },
              { label: 'Division', value: 'divisionName' },
              {
                label: 'Updated On',
                value: (r: any) => dateStringFormatter(r?.updatedOn),
              },
              { label: 'Updated By', value: 'updatedBy' },
            ],
            content: futuresEntries,
          },
        ];
        const settings = {
          fileName: 'Pending Futures Trades',
        };
        xlsx(data, settings);
      } else {
        toast.error('Export failed.');
      }
    } finally {
      onFinish();
    }
  };
  const userContext = useContext(UserContext);
  if (!userContext.userName) {
    return (
      <>
        <main style={{ padding: '24px' }} data-testid="FuturesTradesPage">
          <h2>Futures Trades Page</h2>
          <CircularProgress />
        </main>
      </>
    );
  }
  const hasViewFuturesTradesPermission = userContext.haveRole([
    GrainTraderRole,
    GranelerasTraderRole,
    MillingTraderRole,
    SuperUserRole,
    FuturesUserRole,
  ]);

  if (!hasViewFuturesTradesPermission) {
    return (
      <>
        <main style={{ padding: '24px' }} data-testid="FuturesTradesPage">
          <h2>Futures Trades Page</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  const handleNewButtonClick = () => {
    setSelectedEntry('');
    setIsDetailsModalOpen(true);
    setIsSwapsModalOpen(false);
  };
  const handleBrokerSwapButtonClick = () => {
    setIsDetailsModalOpen(false);
    setIsSwapsModalOpen(true);
  };
  const handleEditButtonClick = (id: string) => {
    setSelectedEntry(id);
    setIsDetailsModalOpen(true);
    setIsSwapsModalOpen(false);
  };
  const handleModalClose = () => {
    setIsDetailsModalOpen(false);
    setIsSwapsModalOpen(false);
  };

  return (
    <>
      <main data-testid="FuturesTradesPage" style={{ minWidth: 1400, padding: '24px' }}>
        <FuturesTradesDisplay
          userName={pageData.userName}
          listQuery={pageData.listQuery}
          paginationModel={pageData.paginationModel}
          setPaginationModel={pageData.onPaginationModelChange}
          onSortModelChange={pageData.onSortModelChange}
          onFilterModelChange={pageData.onFilterModelChange}
          onNewButtonClick={handleNewButtonClick}
          onEditButtonClick={handleEditButtonClick}
          onBrokerSwapButtonClick={handleBrokerSwapButtonClick}
          onExportButtonClick={handleExportButtonClick}
        ></FuturesTradesDisplay>

        {isDetailsModalOpen && (
          <Suspense fallback={<LoadingSpinner />}>
            <FuturesTradeDetailsPage
              selectedEntryId={selectedEntry}
              onClose={handleModalClose}
            ></FuturesTradeDetailsPage>
          </Suspense>
        )}
        {isSwapsModalOpen && (
          <BrokerSwapPage isOpen={isSwapsModalOpen} onClose={handleModalClose}></BrokerSwapPage>
        )}
      </main>
    </>
  );
}
