import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface SaveUnsavedChangesDialogProps {
  isActive: boolean;
  onSave: () => void;
  onDiscard: () => void;
  onCancel: () => void;
}

const SaveUnsavedChangesDialog: FC<SaveUnsavedChangesDialogProps> = ({
  isActive,
  onSave,
  onDiscard,
  onCancel,
}) => (
  <Dialog
    open={isActive}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle
      id="alert-dialog-title"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {'Unsaved Changes'}
      <IconButton onClick={onCancel} aria-label="cancel">
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Save changes before closing? Closing will cause any unsaved changes to be discarded
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onSave} variant="contained">
        Save
      </Button>
      <Button
        onClick={onDiscard}
        variant="contained"
        color="warning"
        startIcon={<DeleteForeverIcon />}
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default SaveUnsavedChangesDialog;
