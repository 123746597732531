import { format, parseISO } from 'date-fns';
import moment from 'moment';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from '@mui/material/Typography';
import {
  GridCellParams,
  GridColTypeDef,
  GridComparatorFn,
  GridRenderEditCellParams,
  GridValueFormatterParams,
  renderEditSingleSelectCell,
} from '@mui/x-data-grid';

export const yesNoGridColTypeDef: (field: string) => GridColTypeDef = (field: string) => ({
  type: 'singleSelect',
  valueGetter: (params) => (params.row[params.field] ? 'Yes' : 'No'),
  valueSetter: (params) => ({ ...params.row, [field]: params.value === 'Yes' }),
  valueOptions: ['Yes', 'No'],
  renderCell: (params) => (
    <Typography sx={{ width: '100%' }} data-testid={`View-${field}`}>
      {params.formattedValue}
      {params.isEditable ? <ArrowDropDownIcon sx={{ float: 'right' }} /> : ''}
    </Typography>
  ),
});

export const excelFormatString_Currency_5Decimals = '$#,##0.00000';
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 5, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 5,
});
export const excelFormatString_Number_2Decimals = '#,##0.00';
const formatter2Decimals = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export const excelFormatString_Number_5Decimals = '#,##0.00';
const formatter5Decimals = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 5,
  maximumFractionDigits: 5,
});
export const currencyValueFormatter = (params: GridValueFormatterParams<string>) => {
  return currencyStringFormatter(params.value);
};

export const currencyStringFormatter = (value: string) => {
  if (!isNaN(+value)) {
    return formatter.format(+value);
  }
  return value;
};

export const excelFormatString_Currency_2Decimals = '$#,##0.00';
const formatterTo2 = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2,
});

// This is the Custom Excel Number Format String for the non-custom "Accounting" format with '$' and two decimal places
export const excelFormatString_Accounting_2Decimals =
  '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
const accountingFormatterTo2 = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting', // -$4.75 displayed as ($4.75)

  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const currencyValueFormatterTo2 = (params: GridValueFormatterParams<string>) => {
  const value = +params.value;
  if (!isNaN(value)) {
    return formatterTo2.format(value);
  }
  return params.value;
};

export const formatCurrencyTo2 = (orgValue: number) => {
  const value = +orgValue;
  if (!isNaN(value)) {
    return formatterTo2.format(value);
  }
  return orgValue;
};

export const formatCurrencyValueTo2WithNegativesInParens = (
  params: GridValueFormatterParams<string>
) => {
  return formatCurrencyTo2WithNegativesInParens(params.value);
};

export const formatCurrencyTo2WithNegativesInParens = (value: string) => {
  if (!isNaN(+value)) {
    return accountingFormatterTo2.format(+value);
  }
  return value;
};

export const numberWithCommaFormatter = (params: GridValueFormatterParams<string>) => {
  const value = +params.value;
  if (!isNaN(value)) {
    return value.toLocaleString();
  }
  return params.value;
};

export const zeroIfBlank = (line: any, propName: string) => {
  if (!line) {
    return undefined;
  }
  return line[propName] ?? 0;
};

export const numberWithComma2DecimalsFormatter = (params: GridValueFormatterParams<string>) => {
  return numberWithComma2DecimalsStringFormatter(params.value);
};

export const numberWithComma2DecimalsStringFormatter = (value: string) => {
  if (!isNaN(+value)) {
    return formatter2Decimals.format(+value);
  }
  return value;
};

export const numberWithComma5DecimalsFormatter = (params: GridValueFormatterParams<string>) => {
  const value = +params.value;
  if (!isNaN(value)) {
    return formatter5Decimals.format(+params.value);
  }
  return params.value;
};

export const dateValueFormatter = (params: GridValueFormatterParams<string>) => {
  return dateStringFormatter(params.value);
};

export const dateStringFormatter = (value: string) => {
  return value ? format(parseISO(value), 'M/d/yyyy h:mm a') : '';
};

export const dateOnlyValueFormatter = (params: GridValueFormatterParams<string>) => {
  return dateOnlyStringFormatter(params.value);
};

export const dateOnlyStringFormatter = (value: string) => {
  return value && moment.utc(value).format('M/D/YYYY');
};

export const shortLongToBuySellValueFormatter = (params: GridValueFormatterParams<string>) => {
  return shortLongToBuySellStringFormatter(params.value);
};

export const shortLongToBuySellStringFormatter = (value: string) => {
  if (value === 'L' || value === 'B') return 'Buy';
  if (value === 'S') return 'Sell';
  return null;
};

export const purchaseOrSaleFormatter = (params: GridValueFormatterParams<string>) => {
  const value = params.value;
  return purchaseOrSaleStringFormatter(value);
};

export const purchaseOrSaleStringFormatter = (value: string) => {
  if (value === 'P') {
    return 'Purchase';
  } else if (value === 'S' || value === 's') {
    return 'Sale';
  } else {
    return value;
  }
};

export const stringComparatorWithBlanksLast: GridComparatorFn<string> = (a, b) =>
  a ? (b ? a.localeCompare(b) : -1) : 1;

export const errorCellClassName = (params: GridCellParams) =>
  (params.row.errors || []).some((err: string) => err.startsWith(params.field)) ? 'Mui-error' : '';

export function renderSelectCell() {
  return (params: GridRenderEditCellParams) =>
    renderEditSingleSelectCell({
      ...params,
      onBlur: () => {
        const id = params.id;
        const field = params.field;
        params.api.stopCellEditMode({ id, field });
      },
    });
}
