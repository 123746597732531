import * as yup from 'yup';

export const brokerMarginAdjustmentEntrySchema = yup.object().shape({
  adjustmentDate: yup.date().required('Adjustment Date required'),
  brokerId: yup.string().required('Broker required'),
  adjustmentTypeId: yup.string().required('Adjustment Type required'),
  amount: yup
    .string()
    .required('Amount required')
    .test({
      test: (value) => {
        const amount = Number.parseFloat(value);
        return !isNaN(amount) && amount < 100000000 && amount >= -100000000;
      },
      message: 'Value has more digits before the decimal than allowed. The maximum is 8.',
    }),
  tradeAccountId: yup.string().notRequired(),
  hedgedCommodityId: yup.string().notRequired(),
  comment: yup.string().max(256, 'Length cannot be more than 256 characters').notRequired(),
});
