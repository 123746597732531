import React, { useEffect } from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';

import { useHelloClient } from '../../hooks';

import AboutText from './AboutText/AboutText';

export default function AboutPage() {
  const helloClient = useHelloClient();
  const { isPending, data } = useQuery({
    queryKey: ['hello', 'getHelloFromPositionsDb'],
    queryFn: () => helloClient.getHelloFromPositionsDb(),
  });
  useEffect(() => {
    document.title = 'About | Positions and Futures';
  });
  const version = `${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_BUILD}`;
  const dataFromDb = isPending ? 'loading...' : data?.data.result.date ?? '';
  return (
    <Container>
      <Typography variant="h2" mb="0.5em" role="heading">
        About Savage
      </Typography>
      <AboutText version={version} data={dataFromDb} />
    </Container>
  );
}
