/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateFuturesContractRequest,
  FilteredFuturesContractsResponse,
  FuturesContractResponse,
  UpdateFuturesContractRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class FuturesContracts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetFilteredFuturesContracts
   * @request GET:/futuresContracts/filter
   * @secure
   */
  getFilteredFuturesContracts = (
    query: {
      sortOrder?: string;
      limit: string;
      search?: string;
      sortBy?: string;
      offset?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<FilteredFuturesContractsResponse, any>({
      path: `/futuresContracts/filter`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFuturesContracts
   * @request OPTIONS:/futuresContracts/filter
   */
  optionsFuturesContracts = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futuresContracts/filter`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFuturesContractById
   * @request GET:/futuresContracts/{id}
   * @secure
   */
  getFuturesContractById = (id: string, params: RequestParams = {}) =>
    this.request<FuturesContractResponse, any>({
      path: `/futuresContracts/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateFuturesContractById
   * @request PUT:/futuresContracts/{id}
   * @secure
   */
  updateFuturesContractById = (
    id: string,
    data: UpdateFuturesContractRequest,
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/futuresContracts/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFuturesContracts2
   * @request OPTIONS:/futuresContracts/{id}
   * @originalName optionsFuturesContracts
   * @duplicate
   */
  optionsFuturesContracts2 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futuresContracts/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name CreateFuturesContract
   * @request POST:/futuresContracts
   * @secure
   */
  createFuturesContract = (data: CreateFuturesContractRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futuresContracts`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFuturesContracts3
   * @request OPTIONS:/futuresContracts
   * @originalName optionsFuturesContracts
   * @duplicate
   */
  optionsFuturesContracts3 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futuresContracts`,
      method: 'OPTIONS',
      ...params,
    });
}
