import { useContext } from 'react';

import Typography from '@mui/material/Typography';

import { PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';
import { useCommoditiesSummaryQuery, useFuturesContractsQuery } from '../../../hooks';
import { useRefFuturesContractsMassUpdates } from '../../../hooks/backendDataClients/index';

import FuturesContractsList from './FuturesContractsList/FuturesContractsList';

export default function FuturesContractsPage() {
  const dataClient = useRefFuturesContractsMassUpdates();
  const { data } = useCommoditiesSummaryQuery();
  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="FuturesContractsPage">
          <h2>Futures Contracts</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  return (
    <main style={{ padding: '24px' }} data-testid="FuturesContractsPage">
      <FuturesContractsList
        getFuturesContracts={useFuturesContractsQuery}
        updateFuturesContracts={dataClient.updateFuturesContracts}
        commodities={data}
      />
    </main>
  );
}
