import { useToast } from 'use-toast-mui';

import { QueryKey, useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

// TODO Add flexibility to queryKey.  I want to be able to invalidate multiple query keys, and invalidate a query with a variable ID
//   Another option is to get rid of queryKey here and force callers to specifically handle query invalidation with afterSuccess
interface useToastMutationProps {
  mutationFn: any;
  queryKey: QueryKey;
  onMutateMessage?: string;
  onSuccessMessage?: string;
  afterSuccess?: (data?: any, variables?: any) => void;
  isDelete?: boolean;
}

export const useToastMutation = (
  props: useToastMutationProps
): UseMutationResult<any, any, any, any> => {
  const {
    mutationFn,
    queryKey,
    onMutateMessage = 'Saving...',
    onSuccessMessage = 'Saved',
    afterSuccess = () => {},
    isDelete = false,
  } = props;

  const toast = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn,
    onMutate: () => {
      toast.info(onMutateMessage);
    },
    onSuccess: (data, variables) => {
      toast.success(onSuccessMessage);
      if (isDelete) {
        queryClient.removeQueries({ queryKey });
      } else {
        queryClient.invalidateQueries({ queryKey });
      }
      afterSuccess(data, variables);
    },
    onError: (e) => {
      toast.error('Error: ' + e);
    },
  });
  return mutation;
};
