import React, { FC, MutableRefObject, ReactNode } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import Tooltip from '@mui/material/Tooltip';
import {
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
  GridRowsProp,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/models/api/gridApiCommunity';

import { DataGridWithSingleClickCellEditProps } from '../DataGridWithSingleClickCellEdit/DataGridWithSingleClickCellEdit';
import StripedDataGrid from '../StripedDataGrid/StripedDataGrid';
import Toolbar from '../Toolbar/Toolbar';

interface ChangeableGridProps {
  isLoading?: boolean;
  initialRows?: GridRowsProp;
  changedRows?: GridRowsProp;
  setChangedRows?: (rows: GridValidRowModel[]) => void;
  onSave?: () => void;
  onAdd?: () => void;
  onExport?: (onFinish: () => void) => void;
  onRemove?: () => void;
  getRowId?: (row: GridValidRowModel) => GridRowId;
  gridFilter?: (row: GridValidRowModel) => boolean;
  infoIndicator?: (row: GridValidRowModel) => string | null;
  dense?: boolean;
  apiRef?: MutableRefObject<GridApiCommunity>;
  canRemoveAny?: boolean;
  extraToolbarComponents?: ReactNode | undefined;
  changesToTop?: boolean;
}

interface GetCurrentRowsProps {
  initialRows?: GridRowsProp;
  changedRows?: GridRowsProp;
  changesToTop?: boolean;
  getRowId: (row: GridValidRowModel) => GridRowId;
  gridFilter?: (row: GridValidRowModel) => boolean;
}
const getCurrentRows = ({
  initialRows,
  changedRows,
  changesToTop,
  gridFilter,
  getRowId,
}: GetCurrentRowsProps) => {
  const changes = (changedRows ?? []).map((r) => ({ ...r, changed: '*' }));
  const noChanges = (initialRows || []).filter(
    (r) => !(changedRows ?? []).map((r2) => getRowId(r2)).includes(getRowId(r))
  );
  return [...(changesToTop ? changes : noChanges), ...(changesToTop ? noChanges : changes)]
    .filter(gridFilter ? gridFilter : () => true)
    .filter((r: GridValidRowModel) => !r.remove);
};

const ChangeableGrid: FC<
  ChangeableGridProps &
    Omit<DataGridWithSingleClickCellEditProps, 'rows' | 'loading' | 'processRowUpdate'>
> = (props) => {
  const {
    initialRows,
    isLoading,
    changedRows,
    setChangedRows,
    onSave,
    onAdd,
    onExport,
    onRemove,
    infoIndicator,
    dense,
    apiRef,
    canRemoveAny,
    extraToolbarComponents,
    changesToTop,
  } = props;
  const getRowId = props.getRowId ?? ((r) => r.id);
  const gridFilter = props.gridFilter ?? (() => true);

  const changedColDef: GridColDef = {
    field: 'changed',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: ({ value, row }) => {
      const info = infoIndicator && infoIndicator(row);
      return value || info ? (
        <Tooltip describeChild title={row.errors ? row.errors.join('\n') : info ? info : 'Updated'}>
          <CircleIcon
            color={row.errors ? 'error' : info ? 'info' : 'secondary'}
            fontSize="small"
            data-testid="Changed"
            aria-label="changed"
          />
        </Tooltip>
      ) : (
        <span data-testid="Changed" />
      );
    },
  };

  const processRowUpdate = (newRow: GridValidRowModel) => {
    if (!getRowId(newRow)) {
      return Promise.reject('no rowId on deleted row');
    }
    const editableColumns = props.columns.filter((d) => d.editable).map((d) => d.field);
    const oldRow = initialRows?.find((r) => getRowId(r) === getRowId(newRow));
    const without = (changedRows ?? []).filter((r) => getRowId(r) !== getRowId(newRow));
    if (setChangedRows) {
      oldRow && editableColumns.every((f) => oldRow[f] === newRow[f])
        ? setChangedRows(without)
        : setChangedRows([...without, newRow]);
    }
    return Promise.resolve(newRow);
  };

  const currentRows = getCurrentRows({
    changedRows,
    initialRows,
    changesToTop,
    gridFilter,
    getRowId,
  });

  const isSelectedRowNotOneOfTheInitialRows: () => boolean = () => {
    const selectedRow = (props?.rowSelectionModel as GridRowSelectionModel)?.[0];
    return !!selectedRow && (initialRows || []).every((r) => getRowId(r) !== selectedRow);
  };

  return (
    <StripedDataGrid
      rows={currentRows}
      loading={isLoading}
      processRowUpdate={processRowUpdate}
      disableColumnFilter={props.disableColumnFilter ?? true}
      disableColumnMenu={props.disableColumnMenu ?? true}
      disableColumnSelector={props.disableColumnSelector ?? true}
      disableDensitySelector={props.disableDensitySelector ?? true}
      apiRef={apiRef}
      onProcessRowUpdateError={(err) => {
        if (err !== 'no rowId on deleted row') {
          console.error(err);
        }
      }}
      density={dense ? 'compact' : 'standard'}
      slots={{
        noRowsOverlay: () => <></>,
        toolbar: Toolbar,
        ...props.components,
      }}
      slotProps={{
        toolbar: {
          onSave: onSave,
          onAdd: onAdd,
          onRemove: onRemove,
          onExport: onExport,
          hasChanges: changedRows && !!changedRows.length,
          canRemove: canRemoveAny || isSelectedRowNotOneOfTheInitialRows(),
          small: dense,
          hideSearch: dense,
          children: extraToolbarComponents,
        },
      }}
      {...{
        ...props,
        columns: [changedColDef, ...props.columns],
      }}
    />
  );
};

export default ChangeableGrid;
