import { FC } from 'react';

import { GridColDef } from '@mui/x-data-grid';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

import {
  currencyValueFormatter,
  dateOnlyValueFormatter,
  dateValueFormatter,
  errorCellClassName,
} from '../../../../components';
import ChangeableGrid from '../../../../components/ChangeableGrid/ChangeableGrid';

export interface CashAdjustmentsGridProps {
  rows: GridRowsProp[];
  loading: boolean;
  rowCountState?: number;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  onExportButtonClick: (onFinish: () => void) => void;
  onAddButtonClick: () => void;
}

const CashAdjustmentsGrid: FC<CashAdjustmentsGridProps> = ({
  rows,
  loading,
  rowCountState,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  onExportButtonClick,
  onAddButtonClick,
}) => {
  const columns: GridColDef[] = [
    {
      field: 'adjustmentId',
      headerName: 'Id',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'adjustmentDate',
      headerName: 'Date',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: dateOnlyValueFormatter,
    },
    {
      field: 'brokerName',
      headerName: 'Broker',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'adjustmentName',
      headerName: 'Type',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'adjustmentAmount',
      headerName: 'Amount',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'tradeAccountName',
      headerName: 'Trade Account',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'hedgedCommodityName',
      headerName: 'Hedged Commodity',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'entryDateTime',
      headerName: 'Created On',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'entryUser',
      headerName: 'Created By',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
  ];
  return (
    <div data-testid="CashAdjustmentsGrid">
      <ChangeableGrid
        initialRows={rows}
        isLoading={loading}
        columns={columns}
        rowCount={rowCountState}
        pageSizeOptions={[25, 50, 100]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        onAdd={onAddButtonClick}
        onExport={onExportButtonClick}
      />
    </div>
  );
};

export default CashAdjustmentsGrid;
