import React, { useContext, useEffect } from 'react';

import { Badge } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { FuturesUserRole, SuperUserRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';

import AllocatedCommissionAdjustmentDisplay from './AllocatedCommissionAdjustmentDisplay/AllocatedCommissionAdjustmentDisplay';

export default function AllocatedCommissionAdjustmentPage() {
  useEffect(() => {
    document.title = 'Allocated Commission Adjustment | Positions and Futures';
  }, []);
  const userContext = useContext(UserContext);
  if (!userContext?.userName) {
    return (
      <Container maxWidth={false} data-testid="AllocatedCommissionAdjustmentPage">
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Allocated Commission Adjustment</Badge>
        </Typography>
      </Container>
    );
  }
  if (!userContext?.haveRole([SuperUserRole, FuturesUserRole])) {
    return (
      <Container maxWidth={false} data-testid="AllocatedCommissionAdjustmentPage">
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Allocated Commission Adjustment</Badge>
        </Typography>
        <Typography paragraph>You do not have access to this page</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth={false} data-testid="AllocatedCommissionAdjustmentPage">
      <Typography variant="h2" mb="0.5em" role="heading">
        <Badge color="secondary">Allocated Commission Adjustment</Badge>
      </Typography>
      <AllocatedCommissionAdjustmentDisplay></AllocatedCommissionAdjustmentDisplay>
    </Container>
  );
}
