import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GridValidRowModel } from '@mui/x-data-grid';

import { renderSelectCell } from '../../../../components';
import SmallChangeableGrid from '../../../../components/SmallChangeableGrid/SmallChangeableGrid';
import { CommoditiesSummaryResponse } from '../../../../services/backend/data-contracts';

export interface FuturesTradeAccountDetailsProps {
  futuresTradeAccount: any | null;
  setShowEditForRow?: any;
  commodities?: CommoditiesSummaryResponse['result'];
}

const FuturesTradeAccountDetails: FC<FuturesTradeAccountDetailsProps> = ({
  futuresTradeAccount,
  commodities,
  setShowEditForRow,
}) => {
  const handleIsActiveChange = (change: SelectChangeEvent) => {
    setShowEditForRow &&
      setShowEditForRow((prev: GridValidRowModel) => ({
        ...prev,
        isActive: !!change.target.value,
      }));
  };

  const handleAffectsPositionChange = (change: SelectChangeEvent) => {
    setShowEditForRow &&
      setShowEditForRow((prev: GridValidRowModel) => ({
        ...prev,
        affectsPosition: !!change.target.value,
      }));
  };

  const handleCommoditiesChange = (newCommodities: GridValidRowModel[]) => {
    setShowEditForRow &&
      setShowEditForRow((prev: GridValidRowModel) => ({
        ...prev,
        newCommodities: newCommodities
          .filter((c) => !c.remove)
          .map((c) => ({
            ...commodities?.find((r) => r.commodityCode === c.commodityCode),
            changed: c.changed,
          })),
      }));
  };

  return (
    <Box data-testid="FuturesTradeAccountDetails">
      {futuresTradeAccount ? (
        <Grid container spacing={2}>
          <Grid container item alignItems="center" xs={6}>
            <Grid item xs={6}>
              Trade Account Code:
            </Grid>
            <Grid item xs={6}>
              {futuresTradeAccount.tradeAccountCode}{' '}
            </Grid>
          </Grid>

          <Grid container item alignItems="center" xs={6}>
            <Grid item xs={6} sx={{ fontWeight: 'bold' }}>
              Active:
            </Grid>
            <Grid item xs={6}>
              <Select
                value={futuresTradeAccount.isActive + 0}
                size="small"
                onChange={handleIsActiveChange}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Grid container item alignItems="center" xs={6}>
            <Grid item xs={6}>
              Trade Account Name:
            </Grid>
            <Grid item xs={6}>
              {futuresTradeAccount.tradeAccountName}{' '}
            </Grid>
          </Grid>

          <Grid container item alignItems="center" xs={6}>
            <Grid item xs={6} sx={{ fontWeight: 'bold' }}>
              {' '}
              Affects Position:{' '}
            </Grid>
            <Grid item xs={6}>
              <Select
                value={futuresTradeAccount.affectsPosition + 0}
                size="small"
                onChange={handleAffectsPositionChange}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <SmallChangeableGrid
              tableTitle="Commodities"
              columns={[
                {
                  field: 'commodityCode',
                  headerName: 'Commodity',
                  type: 'singleSelect',
                  valueOptions: commodities?.map((c) => c.commodityCode ?? '') ?? [''],
                  editable: true,
                  renderEditCell: renderSelectCell(),
                  flex: 1,
                },
              ]}
              data={futuresTradeAccount.commodities}
              getRowId={(r) => r.commodityId}
              onAddButtonClick={(commodityId) => ({ commodityId, commodityCode: '' })}
              // isCellEditable={({ id }) => id > 2}
              uniqueFields={['commodityCode']}
              setDataChanged={handleCommoditiesChange}
              canRemoveAny
            />
          </Grid>

          <Grid container item xs={6}>
            <Grid item xs={6}>
              Status in Futures:
            </Grid>
            <Grid item xs={6}>
              {futuresTradeAccount.isActiveInFutures ? 'Active' : 'Inactive'}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export default FuturesTradeAccountDetails;
