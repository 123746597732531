import React, { useContext } from 'react';

import Typography from '@mui/material/Typography';

import { PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';
import { useFuturesBrokersQuery } from '../../../hooks';
import { useFuturesBrokersMassUpdates } from '../../../hooks/backendDataClients';

import FuturesBrokersDisplay from './FuturesBrokersDisplay';

export default function FuturesBrokersPage() {
  const dataClient = useFuturesBrokersMassUpdates();

  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="FuturesBrokersPage">
          <h2>Futures Brokers</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  return (
    <>
      <main style={{ padding: '24px' }} data-testid="FuturesBrokersPage">
        <FuturesBrokersDisplay
          getFuturesBrokers={useFuturesBrokersQuery}
          updateFuturesBrokers={dataClient.updateFuturesBrokers}
        ></FuturesBrokersDisplay>
      </main>
    </>
  );
}
