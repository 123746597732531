import { FC, useEffect, useState } from 'react';

import { Box, FormHelperText, Grid, TextField } from '@mui/material';

import CurrencyTextField from '../../../../components/CurrencyTextField/CurrencyTextField';
import FormikAutocomplete from '../../../../components/FormikAutocomplete/FormikAutocomplete';
import NumericFormatCustom from '../../../../components/NumericFormatCustom/NumericFormatCustom';
import {
  CommoditiesResponse,
  LocationsSummaryResponse,
  TransactionTypesResponse,
} from '../../../../services/backend/data-contracts';

import {
  PURCHASE_NEGATIVE_QUANTITY_WARNING,
  SALE_POSITIVE_QUANTITY_WARNING,
} from './PositionEntryDetailForm.schema';

interface PositionEntryDetailFormProps {
  formik: any;
  locationSummaries?: LocationsSummaryResponse['result'];
  commodities?: CommoditiesResponse['result'];
  transactionTypes?: TransactionTypesResponse['result'];
  getCommoditiesMapByLocation: (id: string) => {
    isPending: boolean;
    commoditiesMap: Map<number, string>;
  };
  monthOptions: Map<number, string>;
  monthsStatus: any;
  fieldToAutoFocus: string;
  onAutoFocus: () => void;
  openOnAutoFocus: boolean;
}

const INPUT_FIELD_XS = 9;
const FORM_LABEL_XS = 3;

const PositionEntryDetailForm: FC<PositionEntryDetailFormProps> = ({
  formik,
  locationSummaries,
  transactionTypes,
  getCommoditiesMapByLocation,
  commodities,
  monthOptions,
  monthsStatus,
  fieldToAutoFocus,
  onAutoFocus,
  openOnAutoFocus,
}) => {
  const [focusedElementWhileWindowBlurred, setFocusedElementWhileWindowBlurred] = useState(
    document.activeElement
  );
  useEffect(() => {
    setFocusedElementWhileWindowBlurred(null);
    const onFocus = () => setTimeout(() => setFocusedElementWhileWindowBlurred(null), 100);
    const onBlur = () => setFocusedElementWhileWindowBlurred(document.activeElement);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  const locationOptions = locationSummaries
    ?.filter((l) => l.isActive)
    .sort((a, b) => (a.locationValue ?? '').localeCompare(b.locationValue ?? ''));

  const locationOptionsMap = new Map(
    locationOptions?.map((lo) => [lo.locationId as number, lo.locationValue as string])
  );

  const transactionTypeOptions = transactionTypes
    ?.filter((tt) => tt.isActive && tt.positionType === 'P')
    ?.sort((a, b) => (a.transactionTypeValue ?? '').localeCompare(b.transactionTypeValue ?? ''));
  const transactionTypesMap = new Map(
    transactionTypeOptions?.map((tt) => [
      tt.transactionTypeId as number,
      tt.transactionTypeValue as string,
    ])
  );

  // These are only initial until their formik.value changes, but they're currently unused after that.
  const initialLocation = locationSummaries?.find(
    (ls) => ls.locationId === formik.values.locationId
  );
  const initialLocationOption = initialLocation
    ? ([initialLocation.locationId, initialLocation.locationValue] as [number, string])
    : undefined;
  const initialCommodity = commodities?.find((c) => c.commodityId === formik.values.commodityId);
  const initialCommodityOption = initialCommodity
    ? ([initialCommodity.commodityId, initialCommodity.commodityCode] as [number, string])
    : undefined;
  // I haven't found a way to retrieve the month when it's not in the commodity-optionmonth ref call, so this triggers the same code in FormikAutocomplete.
  const initialMonthOption = [formik.values.optionMonthId, ''] as [number, string];

  const purchaseSaleMap = new Map<string, string>();
  purchaseSaleMap.set('P', 'Purchase');
  purchaseSaleMap.set('S', 'Sale');

  const [quantityWarningText, setQuantityWarningText] = useState<string>('');

  // Retrieve Commodities associated with Location
  const commoditiesForLocationQuery = getCommoditiesMapByLocation(formik.values.locationId);

  useEffect(() => {
    if (formik.values.purchaseSale === 'S' && formik.values.quantity > 0) {
      setQuantityWarningText(SALE_POSITIVE_QUANTITY_WARNING);
    } else if (formik.values.purchaseSale === 'P' && formik.values.quantity < 0) {
      setQuantityWarningText(PURCHASE_NEGATIVE_QUANTITY_WARNING);
    } else {
      setQuantityWarningText('');
    }
  }, [formik.values]);

  return (
    <Box data-testid="PositionEntryDetails">
      {!formik.values ? null : (
        <form autoComplete="off">
          <Grid container item xs={6} spacing={1} flexDirection="column">
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="locationId-select-label">
                <b>Location:</b>
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <FormikAutocomplete
                  formik={formik}
                  field="locationId"
                  required
                  optionsAsMap={locationOptionsMap}
                  initialOption={initialLocationOption}
                  inputRef={(input) => {
                    if (input && fieldToAutoFocus === 'locationId') {
                      input.focus();
                      onAutoFocus();
                    }
                  }}
                  openOnFocus
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="commodityId-select-label">
                <b>Commodity:</b>
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <FormikAutocomplete
                  formik={formik}
                  field="commodityId"
                  required
                  optionsAsMap={commoditiesForLocationQuery.commoditiesMap}
                  initialOption={initialCommodityOption}
                  loading={commoditiesForLocationQuery.isPending}
                  inputRef={(input) => {
                    if (input && fieldToAutoFocus === 'commodityId') {
                      input.focus();
                      onAutoFocus();
                    }
                  }}
                  openOnFocus={
                    (fieldToAutoFocus ? openOnAutoFocus : true) &&
                    focusedElementWhileWindowBlurred?.getAttribute('name') !== 'commodityId'
                  }
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="purchaseSale-select-label">
                <b>Purchase Sale:</b>
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <FormikAutocomplete
                  formik={formik}
                  field="purchaseSale"
                  required
                  optionsAsMap={purchaseSaleMap}
                  openOnFocus
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="contract-input-label">
                Contract:
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <TextField
                  fullWidth
                  id="contract-text"
                  size="small"
                  name="contract"
                  value={formik.values.contract}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  inputProps={{ maxLength: 25 }}
                  error={
                    formik.touched.customer &&
                    formik.touched.contract &&
                    Boolean(formik.errors.contract)
                  }
                  helperText={
                    formik.touched.customer &&
                    formik.touched.contract &&
                    formik.errors.contract?.toString()
                  }
                  InputProps={{ 'aria-labelledby': 'contract-input-label' }}
                ></TextField>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="customer-input-label">
                Customer:
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <TextField
                  fullWidth
                  id="customer-text"
                  value={formik.values.customer}
                  size="small"
                  name="customer"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  inputProps={{ maxLength: 50 }}
                  error={formik.touched.customer && Boolean(formik.errors.customer)}
                  helperText={formik.touched.customer && formik.errors.customer?.toString()}
                  InputProps={{ 'aria-labelledby': 'customer-input-label' }}
                ></TextField>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="transactionTypeId-select-label">
                <b>Transaction Type:</b>
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <FormikAutocomplete
                  formik={formik}
                  field="transactionTypeId"
                  required
                  optionsAsMap={transactionTypesMap}
                  openOnFocus
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="quantity-input-label">
                <b>Quantity:</b>
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <TextField
                  fullWidth
                  id="quantity-text"
                  name="quantity"
                  required
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  size="small"
                  color={quantityWarningText ? 'warning' : undefined}
                  InputProps={{
                    inputProps: {
                      maxLength: 14,
                      min: -99999999.99,
                      max: 99999999.99,
                      step: '0.01',
                    },
                    'aria-labelledby': 'quantity-input-label',
                    inputComponent: NumericFormatCustom as any,
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                  helperText={formik.touched.quantity && formik.errors.quantity?.toString()}
                ></TextField>
                {quantityWarningText ? (
                  <FormHelperText color="warning">{quantityWarningText}</FormHelperText>
                ) : null}
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="flat-price-input-label">
                <b>Flat Price:</b>
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <CurrencyTextField
                  id="flat-price-text"
                  name="flatPrice"
                  required={true}
                  inputProps={{
                    maxLength: 10,
                    'aria-labelledby': 'flat-price-input-label',
                  }}
                  value={formik.values.flatPrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.flatPrice && Boolean(formik.errors.flatPrice)}
                  helperText={formik.touched.flatPrice && formik.errors.flatPrice?.toString()}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="basis-price-input-label">
                <b>Basis Price:</b>
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <CurrencyTextField
                  id="basis-price-text"
                  name="basisPrice"
                  value={formik.values.basisPrice ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.basisPrice && Boolean(formik.errors.basisPrice)}
                  helperText={formik.touched.basisPrice && formik.errors.basisPrice?.toString()}
                  inputProps={{ maxLength: 10, 'aria-labelledby': 'basis-price-input-label' }}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="optionMonthId-select-label">
                <b>Month:</b>
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <FormikAutocomplete
                  formik={formik}
                  field="optionMonthId"
                  required
                  optionsAsMap={monthOptions}
                  initialOption={initialMonthOption}
                  loading={monthsStatus.isPending}
                  queryError={monthsStatus.isError}
                  onManualRetry={monthsStatus.refetch}
                  openOnFocus
                ></FormikAutocomplete>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="futures-price-input-label">
                <b>Futures Price:</b>
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <CurrencyTextField
                  id="futures-price-text"
                  name="futuresPrice"
                  value={formik.values.futuresPrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  inputProps={{
                    maxLength: 10,
                    'aria-labelledby': 'futures-price-input-label',
                  }}
                  error={formik.touched.futuresPrice && Boolean(formik.errors.futuresPrice)}
                  helperText={formik.touched.futuresPrice && formik.errors.futuresPrice?.toString()}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={FORM_LABEL_XS} id="comment-input-label">
                Comment:
              </Grid>
              <Grid item xs={INPUT_FIELD_XS}>
                <TextField
                  fullWidth
                  multiline={true}
                  id="comment-text"
                  minRows={1}
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Comment"
                  inputProps={{ maxLength: 256 }}
                  error={formik.touched.comment && Boolean(formik.errors.comment)}
                  helperText={formik.touched.comment && formik.errors.comment?.toString()}
                  InputProps={{ 'aria-labelledby': 'comment-input-label' }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default PositionEntryDetailForm;
