import { AuthState } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

import { securityWorker } from '../../models/Security';
import { FuturesTradeAccountsMassUpdates } from '../../services/backend/FuturesTradeAccountsMassUpdates';

export const useFuturesTradeAccountsMassUpdates: () => FuturesTradeAccountsMassUpdates<AuthState> =
  () => {
    const { oktaAuth } = useOktaAuth();
    const dataClient = new FuturesTradeAccountsMassUpdates({
      securityWorker,
      baseURL: process.env.REACT_APP_BE_URL,
    });
    if (oktaAuth.authStateManager.getAuthState()?.isAuthenticated) {
      dataClient.setSecurityData(oktaAuth.authStateManager.getAuthState());
    }
    oktaAuth.authStateManager.subscribe((authState) => {
      dataClient.setSecurityData(authState);
    });
    return dataClient;
  };
