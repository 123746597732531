import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { backendDataClients, useOktaUser } from '../../../hooks';
import {
  EndOfDayEnabledResponse,
  ProcessEndOfDayEntryRequest,
} from '../../../services/backend/data-contracts';

const { useEndOfDay } = backendDataClients;

export const calculateEndOfDayStatus = (
  isLoading1: boolean,
  isLoading2: boolean,
  endOfDayError: string | undefined,
  processingEndOfDay: boolean,
  endOfDayResults: string[],
  isEnabledEndOfDay: EndOfDayEnabledResponse | undefined,
  rows: any
): string => {
  if (isLoading1 || isLoading2) {
    return 'loading';
  } else if (endOfDayError != '') {
    return 'error';
  } else if (processingEndOfDay) {
    return 'processing';
  } else if (
    endOfDayResults.length > 0 &&
    rows &&
    rows.length > 0 &&
    rows[0].marketPricesImported
  ) {
    return 'processed';
  } else if (isEnabledEndOfDay?.result === true) {
    return 'readyToProcess';
  } else if (!isLoading1 && !isLoading2) {
    return 'notReadyToProcess';
  }
  return '';
};

export default function useEndOfDayProcess() {
  const { user } = useOktaUser();

  // loading, notReadyToProcess, readyToProcess, procedding, processed, error
  const [endOfDayStatus, setEndOfDayStatus] = useState<string>('loading');
  const [endOfDayError, setEndOfDayError] = useState<string>('');
  const [processingEndOfDay, setProcessingEndOfDay] = useState<boolean>(false);
  const [endOfDayResults, setEndOfDayResults] = useState<any[]>([]);
  const [reload, setReload] = useState<number>(0);
  const positionDataClient = useEndOfDay();

  const isEnabledEndOfDayQuery = useQuery({
    queryKey: ['isEnabledEndOfDay', 'End of Day', reload],
    queryFn: positionDataClient.isEnabledEndOfDay,
    select: (response) => response?.data,
  });

  let latestTradingDay: number | undefined = 0;
  const endOfDayRecordsQuery = useQuery({
    queryKey: ['getEndOfDay', 'End of Day', reload],
    queryFn: positionDataClient.getEndOfDay,
    select: (response) =>
      response?.data?.result?.map((t: any) => {
        latestTradingDay = t.tradingDayId;
        return { id: t.tradingDayId, ...t };
      }),
  });
  const resetEndOfDay = () => {
    setReload(reload + 1);
  };
  const runEndOfDayProcess = () => {
    if (latestTradingDay && latestTradingDay > 0) {
      const data = {
        entryUser: user?.preferred_username ?? '',
        tradingDayId: latestTradingDay,
      } as unknown as ProcessEndOfDayEntryRequest;
      setProcessingEndOfDay(true);
      positionDataClient
        .processEndOfDay(data)
        .then((result: any) => {
          setProcessingEndOfDay(false);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore swagger seems to think this is wrong
          setEndOfDayResults(result.data?.result);
        })
        .catch((error: any) => {
          setProcessingEndOfDay(false);
          setEndOfDayResults([]);
          setEndOfDayError(error);
        });
    }
  };
  // loading, notReadyToProcess, readyToProcess, processing, processed, error
  useEffect(() => {
    setEndOfDayStatus(
      calculateEndOfDayStatus(
        isEnabledEndOfDayQuery.isPending,
        endOfDayRecordsQuery.isPending,
        endOfDayError,
        processingEndOfDay,
        endOfDayResults,
        isEnabledEndOfDayQuery.data,
        endOfDayRecordsQuery.data
      )
    );
  }, [
    isEnabledEndOfDayQuery.data,
    endOfDayResults,
    isEnabledEndOfDayQuery.isPending,
    endOfDayRecordsQuery.isPending,
    processingEndOfDay,
    endOfDayError,
    endOfDayRecordsQuery.data,
  ]);

  return {
    endOfDayStatus,
    endOfDayError,
    endOfDayRecords: endOfDayRecordsQuery.data,
    resetEndOfDay,
    runEndOfDayProcess,
  };
}
