import React, { FC } from 'react';
import moment from 'moment/moment';

import { Box, FormHelperText, Grid, InputAdornment, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import FormikAutocomplete from '../../../../components/FormikAutocomplete/FormikAutocomplete';
import FuturesBrokerAutocomplete from '../../../../components/FuturesBrokerAutocomplete/FuturesBrokerAutocomplete';
import FuturesContractAccountAutocomplete from '../../../../components/FuturesContractAccountAutocomplete/FuturesContractAccountAutocomplete';
import FuturesHedgedCommodityAutocomplete from '../../../../components/FuturesHedgedCommodityAutocomplete/FuturesHedgedCommodityAutocomplete';
import FuturesTradeAccountAutocomplete from '../../../../components/FuturesTradeAccountAutocomplete/FuturesTradeAccountAutocomplete';
import NumericFormatCustom from '../../../../components/NumericFormatCustom/NumericFormatCustom';
import TransactionEntryModal from '../../../../components/TransactionEntryModal/TransactionEntryModal';

import useFuturesTradeDetailsPage from './useFuturesTradeDetailsPage';

export interface FuturesTradeDetailPageProps {
  selectedEntryId: string;
  onClose: () => void;
}

const commissionRateTypes = new Map<string, string>();
commissionRateTypes.set('N/A', 'Not Applicable');
commissionRateTypes.set('LOT', 'Half-Turn per Lot');
commissionRateTypes.set('%', 'Percentage of Price');

const FuturesTradeDetailsPage: FC<FuturesTradeDetailPageProps> = ({ selectedEntryId, onClose }) => {
  const isEditingExistingEntry = !!selectedEntryId;
  const {
    updateInFlight,
    createInFlight,
    optionMonthsPending,
    formik,
    priceWarningText,
    commission,
    monthsMap,
    handleModalClose,
    handleClearClick,
    handleDeleteButtonClick,
    handleBackDateButtonClick,
    handleManualTradeDateChange,
    isAutoFocus,
    onAutoFocus,
  } = useFuturesTradeDetailsPage(selectedEntryId, onClose);
  const changeAndValidate = (name: string) => {
    return async (e: any, value: any) => {
      await formik.setFieldValue(name, value, true);
      formik.setFieldTouched(name);
    };
  };

  const buySellMap = new Map();
  buySellMap.set('L', 'Buy');
  buySellMap.set('S', 'Sell');

  const calcByMap = new Map();
  calcByMap.set('Default', 'Default Rate');
  calcByMap.set('Rate', 'Rate Specified on Trade');

  const labelWidth = 5;
  const inputWidth = 7;
  return (
    <TransactionEntryModal
      isOpen={true}
      canSave={!updateInFlight && !createInFlight}
      isCreateModal={!isEditingExistingEntry}
      onCancel={handleModalClose}
      onClear={handleClearClick}
      onCreate={() => formik.submitForm()}
      onUpdate={() => formik.submitForm()}
      onDelete={handleDeleteButtonClick}
      extraButtonText="Back Date to Prior Month"
      onExtraButtonClick={handleBackDateButtonClick}
    >
      <Box style={{ padding: '24px' }} data-testid="FuturesTradesDetailsPage">
        {!formik.values ? null : (
          <form autoComplete="off">
            <Grid container spacing={2}>
              <Grid container item spacing={1} xs={5}>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    Trade Date:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <DatePicker
                      value={moment.utc(formik.values?.tradeDate)}
                      onChange={(value) => {
                        formik.setFieldValue('tradeDate', value, true);
                        handleManualTradeDateChange();
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container item alignItems="Center" spacing={1}>
                  <Grid
                    item
                    xs={labelWidth}
                    sx={{ fontWeight: 'bold' }}
                    id="longShort-select-label"
                  >
                    Buy Sell:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <FormikAutocomplete
                      formik={formik}
                      field="longShort"
                      optionsAsMap={buySellMap}
                      onValueChange={changeAndValidate('longShort')}
                      openOnFocus
                      inputRef={(input) => {
                        if (input && isAutoFocus) {
                          input.focus();
                          onAutoFocus();
                        }
                      }}
                    ></FormikAutocomplete>
                  </Grid>
                </Grid>

                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }} id="contracts-input-label">
                    Contracts:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      fullWidth
                      id="contracts-input"
                      size="small"
                      name="contracts"
                      value={formik.values.contracts ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 25 }}
                      error={formik.touched.contracts && Boolean(formik.errors.contracts)}
                      helperText={formik.touched.contracts && formik.errors.contracts?.toString()}
                      InputProps={{
                        'aria-labelledby': 'contracts-input-label',
                        inputComponent: NumericFormatCustom as any,
                      }}
                    ></TextField>
                  </Grid>
                </Grid>

                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    Futures Contract:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <FuturesContractAccountAutocomplete
                      formik={formik}
                      openOnFocus
                    ></FuturesContractAccountAutocomplete>
                  </Grid>
                </Grid>

                <Grid container item alignItems="Center" spacing={1}>
                  <Grid
                    item
                    xs={labelWidth}
                    id="optionMonthId-select-label"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Month:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <FormikAutocomplete
                      formik={formik}
                      field="optionMonthId"
                      required
                      optionsAsMap={monthsMap}
                      loading={optionMonthsPending}
                      openOnFocus
                    />
                  </Grid>
                </Grid>

                <Grid container item alignItems="Center" spacing={1}>
                  <Grid
                    item
                    xs={labelWidth}
                    sx={{ fontWeight: 'bold' }}
                    id="unit-price-input-label"
                  >
                    Price:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      fullWidth
                      type="number"
                      id="unit-price-input"
                      name="unitPrice"
                      value={formik.values.unitPrice}
                      onChange={formik.handleChange}
                      onFocus={(event) => event.target.select()}
                      color={priceWarningText ? 'warning' : undefined}
                      size="small"
                      InputProps={{
                        inputProps: { min: 0, max: 9999.99, step: '0.00001' },
                        'aria-labelledby': 'unit-price-input-label',
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.unitPrice && Boolean(formik.errors.unitPrice)}
                      helperText={formik.touched.unitPrice && formik.errors.unitPrice?.toString()}
                    ></TextField>
                    {priceWarningText ? (
                      <FormHelperText error>{priceWarningText}</FormHelperText>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={1} xs={6}>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    Trade Account:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <FuturesTradeAccountAutocomplete
                      formik={formik}
                      openOnFocus
                    ></FuturesTradeAccountAutocomplete>
                  </Grid>
                </Grid>

                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    Hedged Commodity:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <FuturesHedgedCommodityAutocomplete
                      formik={formik}
                      openOnFocus
                    ></FuturesHedgedCommodityAutocomplete>
                  </Grid>
                </Grid>

                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    Broker:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <FuturesBrokerAutocomplete
                      formik={formik}
                      openOnFocus
                    ></FuturesBrokerAutocomplete>
                  </Grid>
                </Grid>

                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    Type of Commission:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <FormikAutocomplete
                      formik={formik}
                      field="commissionRateType"
                      optionsAsMap={commissionRateTypes}
                      onValueChange={changeAndValidate('commissionRateType')}
                      openOnFocus
                    ></FormikAutocomplete>
                  </Grid>
                </Grid>

                <Grid container item alignItems="Center" spacing={1}>
                  <Grid
                    item
                    xs={labelWidth}
                    sx={{ fontWeight: 'bold' }}
                    id="commission-rate-input-label"
                  >
                    Commission Rate:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      fullWidth
                      type="number"
                      id="commission-rate-input"
                      name="commissionRate"
                      value={formik.values.commissionRate}
                      onChange={formik.handleChange}
                      onFocus={(event) => event.target.select()}
                      size="small"
                      InputProps={{
                        inputProps: { min: 0, max: 9999.99, step: '0.00001' },
                        'aria-labelledby': 'commission-rate-input-label',
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.commissionRate && Boolean(formik.errors.commissionRate)}
                      helperText={
                        formik.touched.commissionRate && formik.errors.commissionRate?.toString()
                      }
                    ></TextField>
                  </Grid>
                </Grid>

                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} id="unit-price-input-label">
                    Commission:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      variant="standard"
                      fullWidth
                      id="commission"
                      name="commission"
                      disabled
                      value={commission}
                      size="small"
                      InputProps={{
                        'aria-labelledby': 'unit-price-input-label',
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    ></TextField>
                  </Grid>
                </Grid>

                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    Commission Calculated By:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <FormikAutocomplete
                      formik={formik}
                      field="commissionCalculatedBy"
                      optionsAsMap={calcByMap}
                      onValueChange={changeAndValidate('commissionCalculatedBy')}
                      openOnFocus
                    ></FormikAutocomplete>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <span id="comment-input-label">Comment:</span>
            <TextField
              fullWidth
              multiline={true}
              id="comment-text"
              minRows={3}
              name="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Comment"
              inputProps={{ maxLength: 256 }}
              error={formik.touched.comment && Boolean(formik.errors.comment)}
              helperText={formik.touched.comment && formik.errors.comment?.toString()}
              InputProps={{ 'aria-labelledby': 'comment-input-label' }}
            ></TextField>
          </form>
        )}
      </Box>
    </TransactionEntryModal>
  );
};

export default FuturesTradeDetailsPage;
