import { FC } from 'react';

import { Badge, Container, Typography } from '@mui/material';
import {
  GridCallbackDetails,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';

import TradeAccountsGrid from '../TradeAccountsGrid/TradeAccountsGrid';

export interface TradeAccountsDisplayProps {
  userName: string;
  listQuery: any;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  onNewButtonClick: () => void;
  onEditButtonClick: (id: string) => void;
}

const PositionEntriesDisplay: FC<TradeAccountsDisplayProps> = ({
  userName,
  listQuery,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  onNewButtonClick,
  onEditButtonClick,
}) => {
  return (
    <>
      <Container maxWidth={false}>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Trade Accounts</Badge>
        </Typography>
        <Typography paragraph />
        <TradeAccountsGrid
          userName={userName}
          listQuery={listQuery}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          onEditButtonClick={onEditButtonClick}
          onNewButtonClick={onNewButtonClick}
        ></TradeAccountsGrid>
      </Container>
    </>
  );
};

export default PositionEntriesDisplay;
