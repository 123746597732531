import { useEffect, useState } from 'react';
import { FormikTouched, FormikValues, setNestedObjectValues, useFormik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { useQuery } from '@tanstack/react-query';

import { nanToUndefined } from '../../../../components/formUtils';
import { backendDataClients, useFuturesContractsQuery } from '../../../../hooks';
import { useFuturesContractMonthsForFuturesQuery as useFuturesContractMonthsForFuturesQuery } from '../../../../hooks/useFuturesFuturesContractMonth';
import {
  useFuturesTradeCreateMutation,
  useFuturesTradeDeleteMutation,
  useFuturesTradeEntrySuspenseQuery,
  useFuturesTradeUpdateMutation,
} from '../../../../hooks/useFuturesTradeQueries';
import { FutureTradeEntryResponse } from '../../../../services/backend/data-contracts';
import {
  BROKER_REQUIRED,
  BUY_SELL_REQUIRED,
  COMMENT_LENGTH,
  COMMISSION_CALCULATED_BY_REQUIRED,
  COMMISSION_RATE_BOUNDS,
  COMMISSION_RATE_REQUIRED,
  COMMISSION_RATE_TYPE_REQUIRED,
  CONTRACTS_BOUNDS,
  CONTRACTS_NOT_ZERO,
  CONTRACTS_POSITIVE,
  CONTRACTS_REQUIRED,
  FUTURES_CONTRACT_REQUIRED,
  HEDGED_COMMODITY_REQUIRED,
  MONTH_REQUIRED,
  MONTH_VALID,
  TRADE_ACCOUNT_REQUIRED,
  TRADE_DATE_REQUIRED,
  UNIT_PRICE_BOUNDS,
  UNIT_PRICE_REQUIRED,
  UNIT_PRICE_WARNING,
} from '../FuturesTradeErrorMessages';

const { useBrokerEntries } = backendDataClients;

/**
 * @param commissionRateType LOT, %, or N/A
 * @param contracts: Input on form
 * @param commissionRate: input on form
 * @param units: contractUnits from the futuresContract table
 * @param unitPrice: input from form
 */
export const calculateCommission = (
  commissionRateType: string,
  contracts?: number,
  commissionRate?: number,
  units?: number,
  unitPrice?: number
) => {
  let num = 0;
  if (
    commissionRateType === 'LOT' &&
    contracts &&
    contracts > 0 &&
    commissionRate &&
    Math.abs(commissionRate) > 0
  ) {
    num = contracts * commissionRate;
  } else if (
    commissionRateType === '%' &&
    contracts &&
    contracts > 0 &&
    units &&
    unitPrice &&
    commissionRate
  ) {
    num = (contracts * units * unitPrice * commissionRate) / 100;
  }
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

const blankEntryDetails = {
  tradeDate: moment(),
  tradeAccountId: undefined,
  longShort: undefined,
  hedgedCommodityId: undefined,
  contracts: 0,
  brokerId: undefined,
  futuresContractId: undefined,
  commissionRateType: 'N/A',
  optionMonthId: undefined,
  commissionRate: 0,
  unitPrice: 0,
  commissionCalculatedBy: 'Default',
  comment: '',
};

export interface FuturesTradeDetailsPageHook {
  updateInFlight: boolean;
  createInFlight: boolean;
  optionMonthsPending: boolean;
  formik: ReturnType<typeof useFormik<any>>;
  priceWarningText: string;
  commission: number;
  monthsMap: Map<number, string>;
  handleModalClose: () => Promise<void>;
  handleClearClick: () => Promise<void>;
  handleDeleteButtonClick: () => void;
  handleBackDateButtonClick: () => void;
  handleManualTradeDateChange: () => void;
  isAutoFocus: boolean;
  onAutoFocus: () => void;
}

export default function useFuturesTradeDetailsPage(
  entryToEdit: string,
  onClose: () => void
): FuturesTradeDetailsPageHook {
  const backdateDate = moment().subtract(1, 'months').endOf('month');

  const [commission, setCommission] = useState(0);
  const [isAutoFocus, setIsAutoFocus] = useState<boolean>(!entryToEdit);
  const brokerEntriesDataClient = useBrokerEntries();
  const [priceWarningText, setPriceWarningText] = useState<string>('');

  // Other queries could be started before the suspense query, but they're currently down inside the display components.
  const futuresContracts = useFuturesContractsQuery().data;
  const updateMutation = useFuturesTradeUpdateMutation(entryToEdit);
  const createMutation = useFuturesTradeCreateMutation();
  const deleteMutation = useFuturesTradeDeleteMutation(entryToEdit);

  const detailsQuery = useFuturesTradeEntrySuspenseQuery(entryToEdit);

  const onAutoFocus = () => setIsAutoFocus(false);

  const handleModalClose = async () => {
    onClose();
    await formik.setValues(blankEntryDetails);
    formik.setTouched({}, false);
    formik.setErrors({});
  };

  const handleClearClick = async () => {
    formik.resetForm();
    // Trigger touched on all fields
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      // Form is valid, do any success call
    } else {
      formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
    }
    setIsAutoFocus(!entryToEdit);
  };

  const mapDetails = (existingDetails: FutureTradeEntryResponse['result']) => {
    return {
      ...existingTradeDetails,
      tradeDate: moment(existingDetails.tradeDate),
      commissionCalculatedBy: existingDetails.isCommissionOverride ? 'Rate' : 'Default',
      comment: existingDetails.comment ?? '',
    };
  };

  const existingTradeDetails = detailsQuery.data;
  const initialValues = existingTradeDetails ? mapDetails(existingTradeDetails) : blankEntryDetails;

  const doSave = (currentDetails: any) => {
    const entry = {
      tradeDate: moment.utc(currentDetails.tradeDate).format('YYYY-MM-DD'),
      tradeAccountId: Number(currentDetails.tradeAccountId),
      futuresContractId: Number(currentDetails.futuresContractId),
      hedgedCommodityId: Number(currentDetails.hedgedCommodityId),
      longShort: currentDetails.longShort,
      contracts: Number(currentDetails.contracts),
      brokerId: Number(currentDetails.brokerId),
      optionMonthId: Number(currentDetails.optionMonthId),
      units: Number(currentDetails.units),
      unitPrice: Number(currentDetails.unitPrice),
      commission: commission,
      commissionRate: Number(currentDetails.commissionRate),
      commissionRateType: currentDetails.commissionRateType,
      isCommissionOverridden: currentDetails.commissionCalculatedBy === 'Rate',
      comment: currentDetails.comment ?? '',
      isBackDated: currentDetails.isBackDated,
    };
    if (existingTradeDetails) {
      updateMutation.mutate(
        {
          id: existingTradeDetails.tradeId,
          data: { entry },
        },
        { onSuccess: handleModalClose }
      );
    } else {
      createMutation.mutate({ entry });
      formik.setValues(blankEntryDetails, false);
      formik.setTouched({});
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      tradeDate: yup.date().required(TRADE_DATE_REQUIRED),
      tradeAccountId: yup.number().transform(nanToUndefined).required(TRADE_ACCOUNT_REQUIRED),
      longShort: yup.string().required(BUY_SELL_REQUIRED),
      hedgedCommodityId: yup.number().transform(nanToUndefined).required(HEDGED_COMMODITY_REQUIRED),
      contracts: yup
        .number()
        .transform(nanToUndefined)
        .required(CONTRACTS_REQUIRED)
        .moreThan(-1, CONTRACTS_POSITIVE)
        .lessThan(100000, CONTRACTS_BOUNDS)
        .test({
          skipAbsent: true,
          message: CONTRACTS_NOT_ZERO,
          test: (value) => value !== 0,
        }),
      brokerId: yup.number().transform(nanToUndefined).required(BROKER_REQUIRED),
      futuresContractId: yup.number().transform(nanToUndefined).required(FUTURES_CONTRACT_REQUIRED),
      commissionRateType: yup.string().required(COMMISSION_RATE_TYPE_REQUIRED),
      optionMonthId: yup
        .string()
        .required(MONTH_REQUIRED)
        .test({
          test: (value) => validMonths.includes(Number(value)),
          message: MONTH_VALID,
        }),
      commissionRate: yup
        .number()
        .moreThan(-10000, COMMISSION_RATE_BOUNDS)
        .lessThan(10000, COMMISSION_RATE_BOUNDS)
        .required(COMMISSION_RATE_REQUIRED),
      unitPrice: yup
        .number()
        .min(0, UNIT_PRICE_BOUNDS)
        .lessThan(100000, UNIT_PRICE_BOUNDS)
        .required(UNIT_PRICE_REQUIRED),
      commissionCalculatedBy: yup.string().required(COMMISSION_CALCULATED_BY_REQUIRED),
      comment: yup.string().max(256, COMMENT_LENGTH).notRequired(),
    }),
    onSubmit: () => {
      doSave({ ...formik.values });
    },
  });

  const monthsQuery = useFuturesContractMonthsForFuturesQuery(
    Number(formik.values.futuresContractId)
  );

  const validMonths: number[] =
    monthsQuery.data?.map((m) => m.optionMonthId ?? 0).filter((id) => id !== 0) ?? [];
  const monthsMap = new Map<number, string>();
  monthsQuery.data
    ?.filter((m) => m.optionMonthId !== 0)
    .forEach((m) => {
      if (m.optionMonthId) {
        monthsMap.set(m.optionMonthId, m.optionMonthCode ?? '');
      }
    });

  const handleDeleteButtonClick = () => {
    deleteMutation.mutate(
      {
        id: existingTradeDetails?.tradeId,
      },
      {
        onSuccess: () => {
          handleModalClose();
        },
      }
    );
  };

  const handleBackDateButtonClick = () => {
    formik.setFieldValue('tradeDate', backdateDate.format('YYYY-MM-DD'));
    formik.setFieldValue('isBackDated', true);
  };
  const handleManualTradeDateChange = () => {
    if (formik.values.tradeDate !== backdateDate) {
      formik.setFieldValue('isBackDated', undefined);
    }
  };

  useEffect(() => {
    if (formik.values.unitPrice === 0 && formik.touched.unitPrice) {
      setPriceWarningText(UNIT_PRICE_WARNING);
    } else {
      setPriceWarningText('');
    }
  }, [formik.values.unitPrice, formik.touched.unitPrice]);

  useEffect(() => {
    const futuresContract = futuresContracts?.find(
      (t) => t.futuresContractId === formik.values.futuresContractId
    );
    setCommission(
      calculateCommission(
        formik.values.commissionRateType ?? 'N/A',
        formik.values.contracts,
        formik.values.commissionRate,
        futuresContract?.contractUnits,
        formik.values.unitPrice
      )
    );
  }, [
    formik.values.commissionRateType,
    formik.values.contracts,
    formik.values.commissionRate,
    formik.values.futuresContractId,
    formik.values.unitPrice,
    futuresContracts,
  ]);

  const brokerEntryQuery = useQuery({
    enabled: !!formik.values.brokerId,
    queryKey: ['FuturesTradesPage', 'brokerEntry', formik.values.brokerId],
    queryFn: async () => {
      if (formik.values.brokerId) {
        const response = await brokerEntriesDataClient.getBrokerEntry(formik.values.brokerId + '');
        return response.data?.result;
      }
    },
  });
  const commissionRates = brokerEntryQuery.data?.commissionRates ?? [];

  useEffect(() => {
    if (formik.values.brokerId && formik.values.futuresContractId && commissionRates.length > 0) {
      const commissionRate = commissionRates?.find(
        (t: any) => t.futuresContractId === formik.values.futuresContractId
      );
      if (
        commissionRate &&
        !formik.touched.commissionRateType &&
        formik.values.commissionRateType !== commissionRate.commissionRateType
      ) {
        formik.setFieldValue('commissionRateType', commissionRate.commissionRateType);
      }
      if (
        commissionRate &&
        !formik.touched.commissionRate &&
        formik.values.commissionRate !== commissionRate.commissionRate
      ) {
        formik.setFieldValue('commissionRate', commissionRate.commissionRate);
      }
    }
    // lint believes formik should be in these dependencies but that causes problems.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissionRates, formik.values.brokerId, formik.values.futuresContractId]);

  return {
    updateInFlight: updateMutation.isPending,
    createInFlight: createMutation.isPending,
    optionMonthsPending: monthsQuery.isPending,
    formik,
    priceWarningText,
    commission,
    monthsMap,
    handleModalClose,
    handleClearClick,
    handleDeleteButtonClick,
    handleBackDateButtonClick,
    handleManualTradeDateChange,
    isAutoFocus,
    onAutoFocus,
  };
}
