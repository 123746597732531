import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useRoles, useUser } from './backendDataClients';
import { THIRTY_MINUTES } from './useQueryClientWithNotifications';
import { useToastMutation } from './useToastMutations';

export const useUserListQuery = () => {
  const userDataClient = useUser();
  return useQuery({
    queryKey: ['Users', 'List'],
    queryFn: userDataClient.getUsers,
    select: (response) => response?.data?.result,
  });
};

export const useGetUserQuery = (selectedUserId: number) => {
  const userDataClient = useUser();
  return useSuspenseQuery({
    queryKey: ['Users', 'Details', selectedUserId],
    queryFn: async () => {
      const response = await userDataClient.getUser(selectedUserId?.toString());
      return response?.data?.result;
    },
  });
};

export const useRolesQuery = () => {
  const rolesDataClient = useRoles();
  return useQuery({
    queryKey: ['Roles'],
    staleTime: THIRTY_MINUTES,
    queryFn: rolesDataClient.getRoles,
    select: (response) => response?.data?.result,
  });
};

export const useCreateUserMutation = () => {
  const userDataClient = useUser();
  return useToastMutation({
    queryKey: ['Users', 'List'],
    mutationFn: (data: any) => userDataClient.createUser(data.entry),
    onMutateMessage: 'Creating User...',
    onSuccessMessage: 'User Created',
  });
};
