import { FC } from 'react';
import { useFormik } from 'formik';

import { Box, Grid, MenuItem, Select, TextField } from '@mui/material';
import {
  GridRenderEditCellParams,
  GridValidRowModel,
  renderEditSingleSelectCell,
} from '@mui/x-data-grid';

import { SmallChangeableGrid } from '../../../../components';
import FormikAutocomplete from '../../../../components/FormikAutocomplete/FormikAutocomplete';
import {
  CommoditiesSummaryResponse,
  CommodityGroupsSummaryResponse,
  FuturesContractsSummaryResponse,
  FuturesTradeAccountSummaryResponse,
  LocationsSummaryResponse,
} from '../../../../services/backend/data-contracts';

export interface CommodityDetailsProps {
  formik: ReturnType<typeof useFormik<any>>;
  commodityGroups?: CommodityGroupsSummaryResponse['result'];
  locationSummaries?: LocationsSummaryResponse['result'];
  futuresContractsSummaries?: FuturesContractsSummaryResponse['result'];
  futuresTradeAccountsSummaries?: FuturesTradeAccountSummaryResponse['result'];
  commoditySummaries?: CommoditiesSummaryResponse['result'];
}

const CommodityDetails: FC<CommodityDetailsProps> = ({
  formik,
  commodityGroups,
  futuresContractsSummaries,
  ...props
}) => {
  commodityGroups?.sort((a, b) =>
    (a.commodityGroupCode ?? '').localeCompare(b.commodityGroupCode ?? '')
  );
  const commodityGroupsMap = new Map<number, string>();
  commodityGroups?.forEach((cg) => {
    if (cg.commodityGroupId) {
      commodityGroupsMap.set(
        cg.commodityGroupId,
        cg.commodityGroupCode + ' - ' + cg.commodityGroupName
      );
    }
  });

  const updateLocationId = (location: GridValidRowModel) => {
    if (location.locationValue) {
      location.locationId = props.locationSummaries?.find(
        (c) => location.locationValue === c.locationValue
      )?.locationId;
    }
  };
  const updateDefaultContractIdForLocation = (location: GridValidRowModel) => {
    if (location.defaultFuturesContractValue) {
      location.defaultFuturesContractId = futuresContractsSummaries?.find(
        (fc) => location.defaultFuturesContractValue === fc.futuresContractValue
      )?.futuresContractId;
    }
  };
  const handleLocationChange = (selectedLocations: GridValidRowModel[]) => {
    selectedLocations.forEach(updateLocationId);
    selectedLocations.forEach(updateDefaultContractIdForLocation);

    const incompleteLocations = selectedLocations.filter(
      (l) => !l.defaultFuturesContractValue != !l.locationValue
    );
    const completeLocations = selectedLocations.filter((l) => incompleteLocations.indexOf(l) < 0);

    const errorMessage = 'Location or Default Contract Missing';
    incompleteLocations.forEach((l) => (l.errors = [errorMessage]));
    completeLocations.forEach((l) => (l.errors = undefined));

    formik.setFieldValue(
      'locations',
      selectedLocations.filter(
        (l) => (l.locationValue || l.defaulFuturesContractValue) && !l.remove
      )
    );
    formik.setFieldError('locations', incompleteLocations.length > 0 ? errorMessage : undefined);
  };

  const updateContractId = (contract: GridValidRowModel) => {
    if (contract.futuresContractValue) {
      contract.futuresContractId = futuresContractsSummaries?.find(
        (c) => contract.futuresContractValue === c.futuresContractValue
      )?.futuresContractId;
    }
  };
  const handleContractChange = (selectedContracts: GridValidRowModel[]) => {
    selectedContracts.forEach(updateContractId);
    formik.setFieldValue(
      'futuresContracts',
      selectedContracts.filter((c) => c.futuresContractId && !c.remove)
    );
  };

  const updateTradeAccountId = (selectedTradeAccount: GridValidRowModel) => {
    if (selectedTradeAccount.tradeAccountName) {
      const foundSummary = props.futuresTradeAccountsSummaries?.find(
        (a) => selectedTradeAccount.tradeAccountName === a.tradeAccountName
      );
      selectedTradeAccount.tradeAccountId = foundSummary?.tradeAccountId;
      selectedTradeAccount.tradeAccountCode = foundSummary?.tradeAccountCode;
    }
  };
  const handleTradeAccountChange = (selectedAccounts: GridValidRowModel[]) => {
    selectedAccounts.forEach(updateTradeAccountId);
    formik.setFieldValue(
      'tradeAccounts',
      selectedAccounts.filter((a) => a.tradeAccountId && !a.remove)
    );
  };

  const updateCommodityId = (commodity: GridValidRowModel) => {
    if (commodity.commodityCode) {
      commodity.commodityId = props.commoditySummaries?.find(
        (s) => s.commodityCode === commodity.commodityCode
      )?.commodityId;
    }
  };
  const handleSwappableCommodityChange = (selectedCommodities: GridValidRowModel[]) => {
    selectedCommodities.forEach(updateCommodityId);
    formik.setFieldValue(
      'swappableCommodities',
      selectedCommodities.filter((c) => c.commodityId && !c.remove)
    );
  };

  const isAlreadySelected = (selectedItems: any[], item: any, idName: string) => {
    return !!selectedItems?.find((si: any) => si[idName] === item[idName]);
  };

  const renderSingleSelectCell = () => {
    return (params: GridRenderEditCellParams) =>
      renderEditSingleSelectCell({
        ...params,
        onBlur: saveChangeToRowData(params),
      });
  };

  const saveChangeToRowData = (params: GridRenderEditCellParams) => {
    return () => {
      const id = params.id;
      const field = params.field;
      params.api.stopCellEditMode({ id, field });
    };
  };

  return (
    <Box data-testid="CommodityDetails">
      <Grid container spacing={2}>
        <Grid container item direction="column" xs={6} spacing={2}>
          <Grid container item alignItems="Center">
            <Grid item xs={6} sx={{ fontWeight: 'bold' }} id="commodityCode-label-id">
              Commodity Code:
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="commodityCode"
                value={formik.values.commodityCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size="small"
                required
                error={formik.touched.commodityCode && Boolean(formik.errors.commodityCode)}
                helperText={formik.touched.commodityCode && formik.errors.commodityCode?.toString()}
                aria-labelledby="commodityCode-label-id"
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="Center">
            <Grid item xs={6} sx={{ fontWeight: 'bold' }} id="commodityName-label-id">
              Commodity Name:
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="commodityName"
                value={formik.values.commodityName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size="small"
                error={formik.touched.commodityName && Boolean(formik.errors.commodityName)}
                helperText={formik.touched.commodityName && formik.errors.commodityName?.toString()}
                aria-labelledby="commodityName-label-id"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item direction="column" xs={6} spacing={2}>
          <Grid container item alignItems="Center" xs={6}>
            <Grid item xs={6} sx={{ fontWeight: 'bold' }} id="commodityGroupId-select-label">
              Commodity Group:
            </Grid>
            <Grid item xs={6}>
              <FormikAutocomplete
                formik={formik}
                field="commodityGroupId"
                optionsAsMap={commodityGroupsMap}
                openOnFocus
              ></FormikAutocomplete>
            </Grid>
          </Grid>

          <Grid container item alignItems="center" xs={6}>
            <Grid item xs={6} sx={{ fontWeight: 'bold' }} id="active-label-id">
              Active:
            </Grid>
            <Grid item xs={6}>
              <Select
                value={formik.values.isActive ? '1' : '0'}
                onChange={(event) =>
                  formik.setFieldValue('isActive', '' + event.target.value === '1')
                }
                size="small"
                SelectDisplayProps={{ 'aria-labelledby': 'active-label-id' }}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <SmallChangeableGrid
            tableTitle="Locations"
            columns={[
              {
                field: 'locationValue',
                headerName: 'Location',
                type: 'singleSelect',
                valueOptions: props.locationSummaries
                  ?.filter((l) => !isAlreadySelected(formik.values.locations, l, 'locationId'))
                  .map((l) => l.locationValue ?? '') ?? [''],
                editable: true,
                renderEditCell: renderSingleSelectCell(),
                flex: 1,
              },
              {
                field: 'defaultFuturesContractValue',
                headerName: 'Default Futures Contract',
                type: 'singleSelect',
                valueOptions: futuresContractsSummaries?.map(
                  (fc) => fc.futuresContractValue || ''
                ) ?? [''],
                editable: true,
                renderEditCell: renderSingleSelectCell(),
                flex: 1,
              },
            ]}
            data={formik.values.locations}
            onAddButtonClick={(rowId) => ({
              locationId: '',
              locationValue: '',
              defaultFuturesContractId: '',
              defaultFuturesContractValue: '',
              id: rowId,
            })}
            setDataChanged={handleLocationChange}
            isCellEditable={(props) => {
              return (
                props.field !== 'locationValue' ||
                !formik.values.initialLocationIds.includes(props.row.locationId)
              );
            }}
            canRemoveAny
          />
        </Grid>

        <Grid item xs={6}>
          <SmallChangeableGrid
            tableTitle="Futures Contracts"
            columns={[
              {
                field: 'futuresContractValue',
                headerName: 'Contract',
                type: 'singleSelect',
                valueOptions: futuresContractsSummaries
                  ?.filter(
                    (c) =>
                      !isAlreadySelected(formik.values.futuresContracts, c, 'futuresContractId')
                  )
                  .map((c) => c.futuresContractValue ?? '') ?? [''],
                editable: true,
                renderEditCell: renderSingleSelectCell(),
                flex: 1,
              },
            ]}
            data={formik.values.futuresContracts}
            onAddButtonClick={(rowId) => ({
              futuresContractId: '',
              futuresContractValue: '',
              id: rowId,
            })}
            setDataChanged={handleContractChange}
            isCellEditable={(props) =>
              !formik.values.initialContractIds.includes(props.row.futuresContractId)
            }
            canRemoveAny
          />
        </Grid>

        <Grid item xs={6}>
          <SmallChangeableGrid
            tableTitle="Futures Trade Accounts"
            columns={[
              {
                field: 'tradeAccountName',
                headerName: 'Trade Account',
                type: 'singleSelect',
                valueOptions: props.futuresTradeAccountsSummaries
                  ?.filter(
                    (t) => !isAlreadySelected(formik.values.tradeAccounts, t, 'tradeAccountId')
                  )
                  .map((t) => t.tradeAccountName ?? '') ?? [''],
                editable: true,
                renderEditCell: renderSingleSelectCell(),
                flex: 1,
              },
            ]}
            data={formik.values.tradeAccounts}
            onAddButtonClick={(rowId) => ({
              tradeAccountId: '',
              tradeAccountValue: '',
              id: rowId,
            })}
            setDataChanged={handleTradeAccountChange}
            isCellEditable={(props) =>
              !formik.values.initialAccountIds.includes(props.row.tradeAccountId)
            }
            canRemoveAny
          />
        </Grid>

        <Grid item xs={6}>
          <SmallChangeableGrid
            tableTitle="Swappable Commodity"
            columns={[
              {
                field: 'commodityCode',
                headerName: 'Commodity',
                type: 'singleSelect',
                valueOptions: props.commoditySummaries
                  ?.filter(
                    (c) => !isAlreadySelected(formik.values.swappableCommodities, c, 'commodityId')
                  )
                  .map((c) => c.commodityCode ?? '') ?? [''],
                editable: true,
                renderEditCell: renderSingleSelectCell(),
                flex: 1,
              },
            ]}
            data={formik.values.swappableCommodities}
            onAddButtonClick={(rowId) => ({ commodityId: '', commodityCode: '', id: rowId })}
            setDataChanged={handleSwappableCommodityChange}
            isCellEditable={(props) =>
              !formik.values.initialSwappableCommodityIds.includes(props.row.commodityId)
            }
            canRemoveAny
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommodityDetails;
