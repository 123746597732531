import { FormikProps } from 'formik';

import NumericFormatTextField from '../NumericFormatTextField/NumericFormatTextField';

type Props = {
  formik: FormikProps<any>;
};

export default function BrokerMarginAmountTextField({ formik }: Props) {
  const fieldName = 'amount';
  return (
    <NumericFormatTextField
      name={fieldName}
      inputProps={{
        step: '0.01',
        decimalScale: 2,
        maxLength: 12, // -.00 + 8 whole number characters
      }}
      size="small"
      fullWidth
      value={formik.values[fieldName]}
      onChange={formik.handleChange}
      onBlur={(event) => {
        if (event.target.value) {
          const numberStringWithoutCommas = event.target.value.replace(/,/g, '');
          formik.setFieldValue(fieldName, Number(numberStringWithoutCommas).toFixed(2), true);
        }
        formik.handleBlur(event);
      }}
      error={formik.touched.amount && Boolean(formik.errors.amount)}
      helperText={formik.touched.amount && formik.errors.amount?.toString()}
    />
  );
}
