import { FC, useEffect, useState } from 'react';
import { FormikTouched, FormikValues, setNestedObjectValues, useFormik } from 'formik';

import { Button, Dialog, DialogActions, DialogContent, Skeleton } from '@mui/material';
import { GridEventListener } from '@mui/x-data-grid';

import SaveUnsavedChangesDialog from '../../../../components/SaveUnsavedChangesDialog/SaveUnsavedChangesDialog';
import TransactionEntryModal from '../../../../components/TransactionEntryModal/TransactionEntryModal';
import { useOptionMonthsByCommodityQuery } from '../../../../hooks';
import { MyRecentPositions } from '../../MyRecentPositions/MyRecentPositions';
import PositionEntryDetailForm from '../PositionEntryDetailForm/PositionEntryDetailForm';
import { validationSchema } from '../PositionEntryDetailForm/PositionEntryDetailForm.schema';

import usePositionEntryDetailFunctions from './UsePositionEntryDetailFunctions';

export interface PositionEntryDetailPageProps {
  onClose: () => void;
  onReopenWith: (id: string) => void;
  positionEntryId: string;
}

const PositionEntryDetailPage: FC<PositionEntryDetailPageProps> = ({
  onClose,
  onReopenWith,
  positionEntryId,
}) => {
  const {
    getEntryQuery,
    userName,
    locationSummaries,
    commodities,
    transactionTypes,
    myRecentPositionsData,
    createMutation,
    updateMutation,
    deleteMutation,
    ...detailFunctions
  } = usePositionEntryDetailFunctions(positionEntryId);

  const [quickLoadEntryId, setQuickLoadEntryId] = useState<string>('');
  const [fieldToAutoFocus, setFieldToFocus] = useState<string>('');
  const [openOnAutoFocus, setOpenOnAutoFocus] = useState<boolean>(true);
  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] = useState<boolean>(false);
  useEffect(() => {
    if (!positionEntryId) {
      setFieldToFocus('locationId');
    } else {
      setFieldToFocus('');
    }
  }, [positionEntryId, setFieldToFocus]);

  const clearFieldToFocus = () => setFieldToFocus('');
  const blankEntryDetails = {
    flatPrice: '',
    futuresPrice: '',
    locationId: '',
    commodityId: '',
    purchaseSale: '',
    contract: '',
    customer: '',
    transactionTypeId: '',
    quantity: '',
    basisPrice: '',
    optionMonthId: '',
    comment: '',
  } as any;
  const initialDetails = getEntryQuery.data ?? blankEntryDetails;

  const doSave = (currentDetails: any) => {
    const transactionType = transactionTypes?.find(
      (t) => t.transactionTypeId === Number(currentDetails.transactionTypeId)
    );

    const entry = {
      ...currentDetails,
      locationId: Number(currentDetails.locationId),
      commodityId: Number(currentDetails.commodityId),
      transactionTypeId: Number(currentDetails.transactionTypeId),
      affectsPosition: transactionType?.affectsPosition || false,
      comment: currentDetails.comment ?? '',
      contract: currentDetails.contract ?? '',
      customer: currentDetails.customer ?? '',
      quantity: Number(currentDetails.quantity),
      flatPrice: Number(currentDetails.flatPrice),
      futuresPrice: Number(currentDetails.futuresPrice),
      basisPrice: Number(currentDetails.basisPrice) || undefined,
      optionMonthId: Number(currentDetails.optionMonthId),
      entryUser: userName,
    };

    if (positionEntryId) {
      updateMutation.mutate(
        {
          id: positionEntryId,
          data: { entry },
        },
        { onSuccess: () => handleModalClose() }
      );
    } else {
      createMutation.mutate({ entry }, { onSuccess: prepareForNextEntry });
    }
  };
  const formik = useFormik({
    initialValues: { ...initialDetails, purchaseSale: initialDetails.purchaseSale?.toUpperCase() },
    validationSchema: validationSchema,
    onSubmit: () => {
      doSave({ ...formik.values });
    },
  });

  const { data: monthsQueryData, ...monthsStatus } = useOptionMonthsByCommodityQuery(
    formik.values.commodityId
  );
  const monthsMap = new Map<number, string>();
  monthsQueryData
    ?.sort((a, b) => (a.optionMonthOrder ?? 0) - (b.optionMonthOrder ?? 0))
    .forEach((m) => {
      if (m.optionMonthId) {
        monthsMap.set(m.optionMonthId, m.optionMonthValue ?? '');
      }
    });

  const handleCancelClick = async () => {
    if (formik.dirty) {
      setIsUnsavedChangesDialogOpen(true);
    } else {
      handleModalClose();
    }
  };

  const handleModalClose = async () => {
    setIsUnsavedChangesDialogOpen(false);
    onClose();
    formik.resetForm({ values: blankEntryDetails });
  };

  const prepareForNextEntry = () => {
    setOpenOnAutoFocus(false);
    const values = {
      ...blankEntryDetails,
      locationId: formik.values.locationId,
      commodityId: formik.values.commodityId,
      transactionTypeId: formik.values.transactionTypeId,
      purchaseSale: formik.values.purchaseSale ?? '',
    };
    formik.resetForm({
      values,
    });
    setFieldToFocus('commodityId');
  };

  const handleClearClick = async () => {
    formik.resetForm({ values: blankEntryDetails });
    // Trigger touched on all fields
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      // Form is valid, do any success call
    } else {
      formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
    }
  };
  const handleSaveClick = () => {
    setIsUnsavedChangesDialogOpen(false);
    formik.submitForm();
  };

  const handleDelete = () => {
    deleteMutation.mutate(positionEntryId, { onSuccess: handleModalClose });
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const id = '' + params.id;
    if (id !== positionEntryId) {
      setQuickLoadEntryId(id);
    }
  };

  const handleLoadNewPositionEntry = () => {
    onReopenWith(quickLoadEntryId);
  };
  const cancelLoadNewPositionEntry = () => {
    setQuickLoadEntryId('');
  };

  return (
    <>
      <TransactionEntryModal
        isOpen={true}
        canSave={!updateMutation.isPending && !createMutation.isPending}
        isCreateModal={!positionEntryId}
        onCancel={handleCancelClick}
        onClear={handleClearClick}
        onCreate={handleSaveClick}
        onUpdate={handleSaveClick}
        onDelete={handleDelete}
        maxWidth="xl"
        formXs={6}
      >
        <PositionEntryDetailForm
          formik={formik}
          locationSummaries={locationSummaries}
          transactionTypes={transactionTypes}
          commodities={commodities}
          getCommoditiesMapByLocation={detailFunctions.getCommoditiesMapByLocation}
          monthOptions={monthsMap}
          monthsStatus={monthsStatus}
          fieldToAutoFocus={fieldToAutoFocus}
          onAutoFocus={clearFieldToFocus}
          openOnAutoFocus={openOnAutoFocus}
        ></PositionEntryDetailForm>
        {myRecentPositionsData.isPending ? (
          <Skeleton></Skeleton>
        ) : (
          <MyRecentPositions
            data={myRecentPositionsData.data || []}
            handleRowClick={handleRowClick}
          ></MyRecentPositions>
        )}
      </TransactionEntryModal>
      <Dialog open={!!quickLoadEntryId}>
        <DialogContent>Do you want to edit an existing transaction?</DialogContent>
        <DialogActions>
          <Button onClick={handleLoadNewPositionEntry}>Yes</Button>
          <Button onClick={cancelLoadNewPositionEntry}>No</Button>
        </DialogActions>
      </Dialog>
      <SaveUnsavedChangesDialog
        isActive={isUnsavedChangesDialogOpen}
        onSave={handleSaveClick}
        onDiscard={handleModalClose}
        onCancel={() => setIsUnsavedChangesDialogOpen(false)}
      ></SaveUnsavedChangesDialog>
    </>
  );
};
export default PositionEntryDetailPage;
