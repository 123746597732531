/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { WhoamiResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Whoami<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name OptionsWhoami
   * @request OPTIONS:/whoami/{id}
   */
  optionsWhoami = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/whoami/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name Whoami
   * @request GET:/whoami
   * @secure
   */
  whoami = (params: RequestParams = {}) =>
    this.request<WhoamiResponse, any>({
      path: `/whoami`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsWhoami2
   * @request OPTIONS:/whoami
   * @originalName optionsWhoami
   * @duplicate
   */
  optionsWhoami2 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/whoami`,
      method: 'OPTIONS',
      ...params,
    });
}
