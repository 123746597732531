import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useCompuweigh, useViews } from './backendDataClients';

export type UseBackendQueryResult<T = any[]> = Partial<UseQueryResult<T>>;

export const useCompuWeighQuery = () =>
  useQuery({
    queryKey: ['Backend', 'CompuWeigh'],
    queryFn: useCompuweigh().getCompuweigh,
    select: (data) => data?.data?.result,
  });
export const useDivisionRefQuery = () =>
  useQuery({
    queryKey: ['Backend', 'DivisionRef'],
    queryFn: useViews().getDivisionRef,
    select: (data) => data?.data?.result,
  });
