import React, { FC, useState } from 'react';

import TextField from '@mui/material/TextField';

export interface FuturesMonthsFormProps {
  futuresYear: number;
  setFuturesYear: (year: number) => void;
}

const FuturesMonthsForm: FC<FuturesMonthsFormProps> = ({ futuresYear, setFuturesYear }) => {
  const [year, setYear] = useState(futuresYear.toString());
  const [invalidData, setInvalidData] = useState<string | null>(null);

  const handleYear = () => {
    const parsed = Number(year);
    if (Number.isInteger(parsed)) {
      setFuturesYear(parsed);
      setInvalidData(null);
    } else {
      setYear(futuresYear.toString());
      setInvalidData(year);
    }
  };

  return (
    <TextField
      id="futures-year"
      label="Futures Year"
      variant="outlined"
      value={year}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setYear(event.target.value)}
      onKeyUpCapture={(event: React.KeyboardEvent<HTMLInputElement>) =>
        event.key === 'Enter' && handleYear()
      }
      onBlur={handleYear}
      error={!!invalidData}
      helperText={
        invalidData && `Value entered ('${invalidData}') is not valid, reverted to previous value.`
      }
    />
  );
};

export default FuturesMonthsForm;
