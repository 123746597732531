// import { FC, useContext, useEffect, useState } from 'react';
import { FC, useContext, useEffect, useState } from 'react';

import { Link } from '@mui/material';
import {
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid';

import {
  FuturesUserRole,
  GrainTraderRole,
  GranelerasTraderRole,
  MillingTraderRole,
  SuperUserRole,
} from '../../../../../app/UserWrapper/UserWrapper';
import { ChangeableGrid } from '../../../../../components';
import {
  dateValueFormatter,
  errorCellClassName,
  yesNoGridColTypeDef,
} from '../../../../../components/gridUtils';
import { UserContext } from '../../../../../Context';

export interface TradeAccountsGridProps {
  userName: string;
  listQuery: any;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  onNewButtonClick: () => void;
  onEditButtonClick: (id: string) => void;
}

const TradeAccountsGrid: FC<TradeAccountsGridProps> = ({
  // userName,
  listQuery,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  onNewButtonClick,
  onEditButtonClick,
}) => {
  let rows: GridRowsProp[] = [];
  if (listQuery.data?.result?.entries) {
    rows = listQuery.data?.result.entries.map((t: any) => {
      return {
        ...t,
        id: t.tradeAccountId,
      } as unknown as GridRowsProp;
    });
  }
  const rowCount = listQuery.data?.result?.totalCount;
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const userContext = useContext(UserContext);
  const hasEnterTradeAccountsPermission = userContext.haveRole([
    GrainTraderRole,
    GranelerasTraderRole,
    MillingTraderRole,
    FuturesUserRole,
    SuperUserRole,
  ]);

  const columns: GridColDef[] = [
    {
      field: 'tradeAccountCode',
      headerName: 'Trade Account Code',
      type: 'string',
      flex: 1,
      editable: false,
      sortable: false,
      cellClassName: errorCellClassName,
      renderCell: ({ value, row }) => {
        return hasEnterTradeAccountsPermission ? (
          <Link
            onClick={() => onEditButtonClick(row.id)}
            sx={{ cursor: 'pointer' }}
            data-testid="LinkToModal"
          >
            {value || '______'}
          </Link>
        ) : (
          <>{value || '______'}</>
        );
      },
    },
    {
      field: 'tradeAccountName',
      headerName: 'Trade Account Name',
      type: 'string',
      flex: 2,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'glAccount',
      headerName: 'GL Account',
      type: 'string',
      flex: 1,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'glSubAccount',
      headerName: 'GL SubAccount',
      type: 'string',
      flex: 1,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'glCompanyCode',
      headerName: 'GL Company',
      type: 'string',
      flex: 1,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'divisionName',
      headerName: 'Division',
      type: 'string',
      flex: 1,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 1,
      type: 'string',
      editable: false,
      ...yesNoGridColTypeDef('isActive'),
      cellClassName: errorCellClassName,
    },
    {
      field: 'grainPositionReports',
      headerName: 'Grain Position Reports',
      type: 'string',
      flex: 2,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'grainAPCPositionReports',
      headerName: 'Grain APC Position Reports',
      type: 'string',
      flex: 2,
      editable: false,
      sortable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      type: 'string',
      flex: 2,
      editable: false,
      valueFormatter: dateValueFormatter,
      cellClassName: errorCellClassName,
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      type: 'string',
      flex: 2,
      editable: false,
      cellClassName: errorCellClassName,
    },
  ];

  return (
    <div data-testid="TradeAccountsGrid">
      <ChangeableGrid
        initialRows={rows}
        isLoading={listQuery.isPending}
        columns={columns}
        onAdd={hasEnterTradeAccountsPermission ? onNewButtonClick : undefined}
        rowCount={rowCountState}
        pageSizeOptions={[25, 50, 100]}
        paginationModel={paginationModel}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        onProcessRowUpdateError={console.error}
        initialState={{
          sorting: {
            sortModel: [{ field: 'tradeAccountId', sort: 'desc' }],
          },
          pagination: { paginationModel: { pageSize: 50 } },
        }}
      />
    </div>
  );
};

export default TradeAccountsGrid;
