import React, { FC, useState } from 'react';
import { useFormik } from 'formik';

import { Box, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import FormikAutocomplete from '../../../../components/FormikAutocomplete/FormikAutocomplete';
import NumericFormatTextField from '../../../../components/NumericFormatTextField/NumericFormatTextField';
import {
  useBrokerRefsQuery,
  useFuturesContractRefsQuery,
  useHedgedCommodityRefsQuery,
  useTradeAccountRefsQuery,
} from '../../../../hooks/useFuturesDataQueries';
import { useFuturesContractMonthsForFuturesQuery } from '../../../../hooks/useFuturesFuturesContractMonth';

interface BrokerSwapEntryDetailProps {
  formik: ReturnType<typeof useFormik<any>>;
  isAutoFocus?: boolean;
  onAutoFocus?: () => void;
}

const BrokerSwapEntryDetail: FC<BrokerSwapEntryDetailProps> = ({
  formik,
  isAutoFocus,
  onAutoFocus,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const monthsQuery = useFuturesContractMonthsForFuturesQuery(
    Number(formik.values.futuresContractId)
  );
  const brokersQuery = useBrokerRefsQuery();
  const tradeAccountsQuery = useTradeAccountRefsQuery();
  const hedgedCommoditiesQuery = useHedgedCommodityRefsQuery();
  const futuresContractsQuery = useFuturesContractRefsQuery();

  const monthsMap = new Map<number, string>();
  monthsQuery.data
    ?.filter((m) => m.optionMonthId !== 0)
    .forEach((m) => {
      if (m.optionMonthId) {
        monthsMap.set(m.optionMonthId, m.optionMonthCode ?? '');
      }
    });

  const brokerRecords = (brokersQuery.data ?? [])
    .sort((a, b) => (a.brokerName ?? '').localeCompare(b.brokerName ?? ''))
    .reduce((rec, curr) => {
      // duplicate names is a data problem?
      // keeping since they have unique ids and should probably be enforced at the broker entry
      // if (curr.brokerId && curr.brokerName && !Object.values(rec).includes(curr.brokerName)) {
      if (curr.brokerId) {
        rec.set(curr.brokerId, curr.brokerName);
      }
      return rec;
    }, new Map());

  const tradeAccountRecords = (tradeAccountsQuery.data ?? [])
    .sort((a, b) => (a.tradeAccountName ?? '').localeCompare(b.tradeAccountName ?? ''))
    .reduce((rec, curr) => {
      if (curr.tradeAccountId) {
        rec.set(curr.tradeAccountId, curr.tradeAccountName);
      }
      return rec;
    }, new Map());

  const hedgedCommodityRecords = (hedgedCommoditiesQuery.data ?? [])
    .sort((a, b) => (a.hedgedCommodityName ?? '').localeCompare(b.hedgedCommodityName ?? ''))
    .reduce((rec, curr) => {
      if (curr.hedgedCommodityId) {
        rec.set(curr.hedgedCommodityId, curr.hedgedCommodityName);
      }
      return rec;
    }, new Map());

  const futuresContractRecords = (futuresContractsQuery.data ?? []).reduce((rec, curr) => {
    if (curr.futuresContractId) {
      rec.set(curr.futuresContractId, curr.futuresContractName);
    }
    return rec;
  }, new Map());

  const validate = (name: string) => {
    return async () => {
      formik.setFieldTouched(name);
    };
  };

  return (
    <Box data-testid="BrokerSwapEntryDetail">
      <form autoComplete="off">
        <Grid container spacing={2}>
          <Grid container item alignItems="Center" xs={6}>
            <Grid item xs={2} sx={{ fontWeight: 'bold' }} id="buyBrokerId-select-label">
              Buy:
            </Grid>
            <Grid item xs={10}>
              <FormikAutocomplete
                formik={formik}
                field="buyBrokerId"
                optionsAsMap={brokerRecords}
                onValueChange={validate('buyBrokerId')}
                openOnFocus
                inputRef={(input) => {
                  if (input && isAutoFocus) {
                    input.focus();
                    onAutoFocus && onAutoFocus();
                  }
                }}
                loading={brokersQuery.isPending}
              ></FormikAutocomplete>
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={6}>
            <Grid item xs={2} sx={{ fontWeight: 'bold' }} id="sellBrokerId-select-label">
              Sell:
            </Grid>
            <Grid item xs={10}>
              <FormikAutocomplete
                formik={formik}
                field="sellBrokerId"
                optionsAsMap={brokerRecords}
                onValueChange={validate('sellBrokerId')}
                loading={brokersQuery.isPending}
              ></FormikAutocomplete>
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={6}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }} id="tradeDate-label">
              Trade Date:
            </Grid>
            <Grid item xs={9}>
              <DatePicker
                value={formik?.values['tradeDate']}
                onChange={(value) => {
                  formik.setFieldValue('tradeDate', value);
                }}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                    name: 'tradeDate',
                    onBlur: (e) => {
                      formik.handleBlur(e);
                    },
                    onClick: () => setIsOpen(true),
                    error: formik.touched.tradeDate && Boolean(formik.errors.tradeDate),
                    helperText: formik.touched.tradeDate && formik.errors.tradeDate?.toString(),
                    'aria-labelledby': 'tradeDate-label',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={6}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }} id="tradeAccountId-select-label">
              Trade Account:
            </Grid>
            <Grid item xs={9}>
              <FormikAutocomplete
                formik={formik}
                field="tradeAccountId"
                optionsAsMap={tradeAccountRecords}
                onValueChange={validate('tradeAccountId')}
                loading={tradeAccountsQuery.isPending}
              ></FormikAutocomplete>
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={6}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }} id="contracts-label">
              Contracts:
            </Grid>
            <Grid item xs={9}>
              <NumericFormatTextField
                name="contracts"
                inputProps={{
                  'aria-labelledby': 'contracts-label',
                }}
                size="small"
                fullWidth
                value={formik.values['contracts']}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.contracts && Boolean(formik.errors.contracts)}
                helperText={formik.touched.contracts && formik.errors.contracts?.toString()}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={6}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }} id="hedgedCommodityId-select-label">
              Hedged Commodity:
            </Grid>
            <Grid item xs={9}>
              <FormikAutocomplete
                formik={formik}
                field="hedgedCommodityId"
                optionsAsMap={hedgedCommodityRecords}
                onValueChange={validate('hedgedCommodityId')}
                loading={hedgedCommoditiesQuery.isPending}
              ></FormikAutocomplete>
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={6}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }} id="futuresContractId-select-label">
              Futures Contract:
            </Grid>
            <Grid item xs={9}>
              <FormikAutocomplete
                formik={formik}
                field="futuresContractId"
                optionsAsMap={futuresContractRecords}
                onValueChange={validate('futuresContractId')}
                loading={futuresContractsQuery.isPending}
              ></FormikAutocomplete>
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={6}></Grid>
          <Grid container item alignItems="Center" xs={6}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }} id="optionMonthId-select-label">
              Futures Month:
            </Grid>
            <Grid item xs={9}>
              <FormikAutocomplete
                formik={formik}
                field="optionMonthId"
                required
                optionsAsMap={monthsMap}
                loading={monthsQuery.isPending}
                openOnFocus
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={6}></Grid>
          <Grid container item alignItems="Center" xs={6}>
            <Grid item xs={3} sx={{ fontWeight: 'bold' }} id="price-label">
              Price:
            </Grid>
            <Grid item xs={9}>
              <NumericFormatTextField
                name="unitPrice"
                inputProps={{ step: '0.01', 'aria-labelledby': 'price-label' }}
                size="small"
                fullWidth
                value={formik.values['unitPrice']}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.unitPrice && Boolean(formik.errors.unitPrice)}
                helperText={formik.touched.unitPrice && formik.errors.unitPrice?.toString()}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="Center" xs={6}></Grid>
          <Grid container item alignItems="Center" xs={12}>
            <Grid item xs={2} id="comment-label">
              Comment:
            </Grid>
            <Grid item xs={10}>
              <TextField
                name="comment"
                rows={3}
                size="small"
                fullWidth
                multiline
                value={formik.values['comment']}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.comment && Boolean(formik.errors.comment)}
                helperText={formik.touched.comment && formik.errors.comment?.toString()}
                inputProps={{
                  'aria-labelledby': 'comment-label',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default BrokerSwapEntryDetail;
