import { useState } from 'react';
import { useToast } from 'use-toast-mui';

import { useOktaAuth } from '@okta/okta-react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

export const THIRTY_MINUTES = 30 * 60 * 1000;

export const useQueryClientWithNotifications: () => QueryClient = () => {
  const toast = useToast();
  const { authState } = useOktaAuth();
  const [queryClient] = useState<QueryClient>(
    new QueryClient({
      defaultOptions: {
        queries: {
          enabled: authState?.isAuthenticated,
          // How long an inactive query stays in the cache before garbage collection.
          //    Some of our prefetching produces cache entries that are immediately inactive with setQueryData.
          //    Not the same as staleTime, which is how long an active query stays "fresh" (avoids refetching).
          gcTime: THIRTY_MINUTES,
        },
      },
      queryCache: new QueryCache({
        onError: (error, query) => {
          if (query.meta?.errorMessage) {
            toast.error(query.meta?.errorMessage.toString());
          } else {
            toast.error('Error retrieving data:' + error);
          }
        },
      }),
      mutationCache: new MutationCache({
        onMutate: (variables, mutation) => {
          if (mutation?.options.onMutate) return;

          if (mutation.meta?.mutateMessage) {
            toast.info(mutation.meta?.mutateMessage.toString());
          } else {
            toast.info('Saving...');
          }
        },
        onSuccess: (data, variables, context, mutation) => {
          if (mutation?.options.onSuccess) return;
          if (mutation.meta?.successMessage) {
            toast.success(mutation.meta?.successMessage.toString());
          } else {
            toast.success('Changes saved');
          }
        },
        onError: (e, variables, context, mutation) => {
          if (mutation.options.onError) return;

          if (mutation.meta?.errorMessage) {
            toast.error(mutation.meta?.errorMessage.toString());
          } else {
            toast.error('Error saving:' + e);
          }
        },
      }),
    })
  );
  return queryClient;
};
