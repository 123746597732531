import React, { useContext, useState } from 'react';

import {
  Badge,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';

import { FuturesUserRole, PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import UnsavedChangesPrompt from '../../../components/UnsavedChangesPrompt/UnsavedChangesPrompt';
import { UserContext } from '../../../Context';

import HedgedCommoditiesDisplay from './HedgedCommoditiesDisplay';
import useHedgedCommodities from './useHedgedCommodities';

interface ErrorMessageModalProps {
  errors: string[];
  setErrorModalErrors: (errors: string[]) => void;
  errorModalActive: boolean;
}
const ErrorMessageModal: React.FC<ErrorMessageModalProps> = ({
  errors,
  setErrorModalErrors,
  errorModalActive,
}) => (
  <Dialog open={errorModalActive}>
    <DialogTitle>Fix the following errors before saving...</DialogTitle>
    <DialogContent>
      {errors?.map((error, index) => (
        <DialogContentText key={index} style={{ color: 'red' }}>
          {error}
        </DialogContentText>
      ))}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setErrorModalErrors([])}>Close</Button>
    </DialogActions>
  </Dialog>
);

export default function HedgedCommoditiesPage() {
  const {
    userName,
    rowCountState,
    rows,
    isLoadingRows,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
    saveChanges,
    refetchHedgedCommodities,
    getNextId,
  } = useHedgedCommodities();

  const [errorModalErrors, setErrorModalErrors] = useState<string[]>([]);
  const [changedRows, setChangedRows] = useState<GridValidRowModel[]>([]);

  const onSave = async (changedRows: GridValidRowModel[]) => {
    const { errors } = changedRows.find((row: any) => row.errors) || { errors: [] };
    if (errors.length > 0) {
      setErrorModalErrors(errors);
    } else {
      return await saveChanges.mutate(changedRows, {
        onSuccess: () => {
          setChangedRows([]);
          refetchHedgedCommodities();
        },
      });
    }
  };

  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole, FuturesUserRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="HedgedCommoditiesPage">
          <h2>Hedged Commodities</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  return (
    <main style={{ padding: '24px' }} data-testid="HedgedCommoditiesPage">
      <Container>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Hedged Commodities</Badge>
        </Typography>
        <HedgedCommoditiesDisplay
          userName={userName}
          rows={rows}
          loading={isLoadingRows}
          rowCountState={rowCountState}
          paginationModel={paginationModel}
          setPaginationModel={onPaginationModelChange}
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          onSave={onSave}
          setChangedRows={setChangedRows}
          changedRows={changedRows}
          getNextId={getNextId}
        />
      </Container>
      <ErrorMessageModal
        errors={errorModalErrors}
        setErrorModalErrors={setErrorModalErrors}
        errorModalActive={errorModalErrors.length > 0}
      />
      <UnsavedChangesPrompt changedRows={changedRows} />
    </main>
  );
}
