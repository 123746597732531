/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CommoditiesSummaryResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class CommoditiesSummaries<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetCommoditiesSummary
   * @request GET:/commodities-summaries
   * @secure
   */
  getCommoditiesSummary = (params: RequestParams = {}) =>
    this.request<CommoditiesSummaryResponse, any>({
      path: `/commodities-summaries`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsCommoditiesSummaries
   * @request OPTIONS:/commodities-summaries
   */
  optionsCommoditiesSummaries = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/commodities-summaries`,
      method: 'OPTIONS',
      ...params,
    });
}
