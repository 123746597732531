import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { FuturesContractsFilterForm } from '../pages/Maintenance/FuturesContracts/FuturesContractList/useFuturesContractsPage';

import { useFuturesContracts, useFuturesOptionMonths, useViews } from './backendDataClients';
import { THIRTY_MINUTES } from './useQueryClientWithNotifications';

export type AdjustmentTypesQuery = UseQueryResult<
  {
    adjustmentName?: string | undefined;
    isTradeAcctRequired?: boolean | undefined;
    adjustmentTypeId?: number | undefined;
  }[],
  Error
>;
export const useBrokerRefsQuery = () =>
  useQuery({
    queryKey: ['RefData', 'BrokerRefs'],
    queryFn: useViews().getBrokerRef,
    select: (response) => response?.data?.result,
  });

export const useTradeAccountRefsQuery = () =>
  useQuery({
    queryKey: ['RefData', 'TradeAccountRefs'],
    queryFn: useViews().getTradeAccountRef,
    select: (response) => response?.data?.result,
  });

export const useHedgedCommodityRefsQuery = () =>
  useQuery({
    queryKey: ['RefData', 'HedgedCommodityRefs'],
    queryFn: useViews().getHedgedCommodityRef,
    select: (response) => response?.data?.result,
  });

export const useFuturesContractRefsQuery = () => {
  const dataClient = useViews();
  return useQuery({
    queryKey: ['RefData', 'FuturesContractRefs'],
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => {
      const response = await dataClient.getFuturesContractRef();
      return response.data?.result;
    },
  });
};

export const useFuturesOptionMonthsQuery = () => {
  const dataClient = useFuturesOptionMonths();
  return useQuery({
    queryKey: ['RefData', 'FuturesOptionMonths'],
    queryFn: async () => {
      const response = await dataClient.getFuturesOptionMonthsByYear();
      return response.data?.result;
    },
  });
};

export const useBrokerAdjustmentTypeRefsQuery = () =>
  useQuery({
    queryKey: ['RefData', 'BrokerAdjustmentTypeRef'],
    queryFn: useViews().getBrokerAdjustmentTypeRef,
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    select: (data) => data?.data?.result,
  });

export const useFuturesContractDetailsForFuturesQuery = (
  futuresContractId: number | string,
  placeholder?: any
) => {
  const dataClient = useFuturesContracts();
  return useQuery({
    queryKey: ['Futures Contracts', 'Futures Contracts Details', futuresContractId],
    enabled: !!futuresContractId,
    queryFn: async () => {
      const response = await dataClient.getFuturesContractById(futuresContractId + '');
      return response.data?.result;
    },
    placeholderData: placeholder,
  });
};

export const useFuturesContractsListQuery = (filterForm: FuturesContractsFilterForm) => {
  console.log('useFuturesContractsListQuery :: filterForm :: ', filterForm);
  const dataClient = useFuturesContracts();
  const queryParams = Object.assign(
    { limit: filterForm.limit }, // number of rows per page
    { offset: filterForm.offset }, // what row to start at
    filterForm.search === '' ? null : { search: filterForm.search },
    filterForm.sortOrder === '' ? null : { sortOrder: filterForm.sortOrder },
    filterForm.sortBy === '' ? null : { sortBy: filterForm.sortBy }
  );

  return useQuery({
    queryKey: ['Futures Contracts', filterForm],
    queryFn: async () => {
      console.log('queryParams ::', queryParams);
      const response = await dataClient.getFilteredFuturesContracts(queryParams);
      console.log('response.data ::', response.data);
      return response.data;
    },
  });
};
