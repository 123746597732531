import * as yup from 'yup';

import { isTradeAccountWithPositionPeriod } from '../../FuturesEntries/FuturesEntryUtils';

// Max is Contracts * ContractUnits for FuturesContract. Calc can not exceed INT
// Max in db was 1553 at time this was defined
const MAX_CONTRACT = 9999;
const FUTURES_CONTRACT_REQUIRED =
  'The referenced Futures Contract is either not set or no longer exists';
const MONTH_REQUIRED =
  'The referenced Option Month for the Selected Futures Contract is either not set or no longer exists';
const FUTURES_PRICE_REQUIRED = 'Futures Price Required';
const CONTRACTS_REQUIRED = 'Contracts Required';
const BUY_TRADE_ACCOUNT_REQUIRED =
  'The referenced Trade Account for the Selected Futures Contract is either not set or no longer exists';
const BUY_COMMODITY_REQUIRED =
  'The referenced Commodity for the Selected Trade Account is either not set or no longer exists';
const BUY_PERIOD_REQUIRED = 'A Buy Period is required for the chosen Trade Account';
const SELL_TRADE_ACCOUNT_REQUIRED =
  'The referenced Trade Account for the Selected Futures Contract is either not set or no longer exists';
const SELL_COMMODITY_REQUIRED =
  'The referenced Commodity for the Selected Trade Account is either not set or no longer exists';
const SELL_PERIOD_REQUIRED = 'A Sell Period is required for the chosen Trade Account';

const CONTRACTS_POSITIVE = 'The number of Contracts should be a positive value';
const CONTRACTS_BOUNDS = 'Contracts must be between 1 and 9,999';
const CONTRACTS_NOT_ZERO = 'The number of Contracts cannot be zero';
const FUTURES_PRICE_BOUNDS =
  'The Futures Price should be a positive value between $0.00001 and $9,999.99999';
const COMMENT_LENGTH = 'Length cannot be more than ${max} characters';
export const UNSWAPPABLE =
  'The Buy and Sell Commodities are not a valid combination of commodities that can be swapped';

export const validationSchema = yup.object().shape({
  futuresContractId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(FUTURES_CONTRACT_REQUIRED),
  optionMonthId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(MONTH_REQUIRED),
  futuresPrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(FUTURES_PRICE_REQUIRED)
    .moreThan(0, FUTURES_PRICE_BOUNDS)
    .lessThan(10000, FUTURES_PRICE_BOUNDS),
  contracts: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(CONTRACTS_REQUIRED)
    .moreThan(-1, CONTRACTS_POSITIVE)
    .max(MAX_CONTRACT, CONTRACTS_BOUNDS)
    .test({
      skipAbsent: true,
      message: CONTRACTS_NOT_ZERO,
      test: (value) => value !== 0,
    }),
  buyTradeAccountId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(BUY_TRADE_ACCOUNT_REQUIRED),
  buyCommodityId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(BUY_COMMODITY_REQUIRED),
  buyPeriodDimId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .when(['buyTradeAccountId'], {
      is: isTradeAccountWithPositionPeriod,
      then: (schema) => schema.required(BUY_PERIOD_REQUIRED),
    }),
  sellTradeAccountId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(SELL_TRADE_ACCOUNT_REQUIRED),
  sellCommodityId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(SELL_COMMODITY_REQUIRED),
  sellPeriodDimId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .when(['sellTradeAccountId'], {
      is: isTradeAccountWithPositionPeriod,
      then: (schema) => schema.required(SELL_PERIOD_REQUIRED),
    }),

  comment: yup.string().optional().max(256, COMMENT_LENGTH),
});
