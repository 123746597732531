export const TRADE_ACCOUNT_CODE_REQUIRED = 'Trade Account Code is required';
export const TRADE_ACCOUNT_NAME_REQUIRED = 'Trade Account Name is required';
export const GL_ACCOUNT_LENGTH_ERROR = 'GL Account must be 7 digits';
export const GL_SUBACCOUNT_REGEX = /^(\d{3}X{3}\d{3}|\d{9})$/;
export const GL_SUBACCOUNT_FORMAT_ERROR =
  'GL SubAccount must be 9 digits, 9 characters of form ###XXX### or null';
export const GL_SUBACCOUNT_REQUIRED_WHEN_GL_ACCOUNT =
  'GL SubAccount is required when a GL Account is specified';
export const GL_COMPANY_LENGTH_ERROR = 'GL Company Code must be 3 digits';
export const NUMBERTYPE_ERROR = 'must be all digits';
export const DIVISION_REQUIRED = 'Division is required';
export const GRAIN_POSITION_REPORTS_REQUIRED = 'Grain Position Reports is required';
export const GRAIN_APC_POSITION_REPORTS_REQUIRED = 'Grain APC Position Reports is required';
export const IS_ACTIVE_REQUIRED = 'Is Active is required';
