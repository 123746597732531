import React, { FC } from 'react';

import { GridColDef, GridRowsProp, GridValidRowModel } from '@mui/x-data-grid';

import { ChangeableGrid, yesNoGridColTypeDef } from '../../../../components';

export interface FuturesMonthsGridProps {
  isLoading?: boolean;
  initialRows?: GridRowsProp;
  changedRows?: GridRowsProp;
  setChangedRows?: (row: GridValidRowModel[]) => void;
  onSave?: () => void;
}

const columns: GridColDef[] = [
  { field: 'optionMonthCode', headerName: 'Month Code', flex: 1, sortable: false },
  { field: 'optionMonthName', headerName: 'Month Name', flex: 1, sortable: false },
  {
    field: 'isActive',
    headerName: 'Active',
    flex: 1,
    sortable: false,
    editable: true,
    ...yesNoGridColTypeDef('isActive'),
  },
  {
    field: 'isActiveInFutures',
    headerName: 'Status in Futures',
    flex: 1,
    sortable: false,
    valueGetter: (params) => (params.row['isActiveInFutures'] ? 'Active' : 'Inactive'),
  },
];

const FuturesMonthsGrid: FC<FuturesMonthsGridProps> = ({
  initialRows,
  isLoading,
  changedRows,
  setChangedRows,
  onSave,
}) => {
  return (
    <div data-testid="FuturesMonthsGrid">
      <ChangeableGrid
        getRowId={(row) => row['optionMonthId']}
        isLoading={isLoading}
        changedRows={changedRows}
        setChangedRows={setChangedRows}
        initialRows={initialRows}
        columns={columns}
        onSave={onSave}
        aria-label="Futures Months"
        initialState={{
          sorting: {
            sortModel: [{ field: 'optionMonthCode', sort: 'asc' }],
          },
        }}
      />
    </div>
  );
};

export default FuturesMonthsGrid;
