export const FUTURES_CONTRACT_CODE_REQUIRED = 'Futures Contract Code is required';
export const FUTURES_CONTRACT_NAME_REQUIRED = 'Futures Contract Name is required';
export const EXCHANGE_REQUIRED = 'Exchange is required';
// Max is Contracts * ContractUnits for FuturesContract. Calc can not exceed INT
// Max in db was 10,000 at time this was defined
export const MAX_CONTRACT_UNITS = 200000;
export const CONTRACT_UNITS_REQUIRED = 'Contract Units required';
export const CONTRACT_UNITS_POSITIVE = 'Contract Units must be greater than zero';
export const UOM_REQUIRED = 'UOM is required';
export const CONTRACT_MONTH_REQUIRED = 'At least one contract month is required';
export const CLOSE_PRICE_CONVERSION_FACTOR_REQUIRED = 'Close Price Conversion Factor is required';
export const CLOSE_PRICE_CONVERSION_FACTOR_POSITIVE =
  'Close Price Conversion Factor must be greater than zero';
export const IS_ACTIVE_REQUIRED = 'Is Active is required';
