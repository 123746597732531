/* eslint-disable */
import { FC, useContext } from 'react';

import { Badge } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { FuturesUserRole, PositionAdministratorRole } from '../../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../../Context';
import BrokerEntriesGrid, { BrokerEntriesGridProps } from '../BrokerEntriesGrid/BrokerEntriesGrid';

type BrokerEntriesDisplayProps = BrokerEntriesGridProps;

const BrokerEntriesDisplay: FC<BrokerEntriesDisplayProps> = ({
  userName,
  rows,
  loading,
  rowCountState,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  createBrokerEntry,
  getBrokerEntry,
  updateBrokerEntry,
  futuresContracts,
  divisions,
  brokers,
  updateCommissionRate,
  createCommissionRate,
  deleteCommissionRate,
  refetchFilteredBrokerEntries,
}) => {
  const userContext = useContext(UserContext);
  if (!userContext?.userName) {
    return (
      <Container maxWidth={false}>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Broker Entries</Badge>
        </Typography>
      </Container>
    );
  }
  if (!userContext?.haveRole([PositionAdministratorRole, FuturesUserRole])) {
    return (
      <Container maxWidth={false}>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Broker Entries</Badge>
        </Typography>
        <Typography paragraph>You do not have access to this page</Typography>
      </Container>
    );
  }
  return (
    <Container maxWidth={false}>
      <Typography variant="h2" mb="0.5em" role="heading">
        <Badge color="secondary">Broker Entries</Badge>
      </Typography>
      <Typography paragraph />
      <BrokerEntriesGrid
        userName={userName}
        divisions={divisions}
        brokers={brokers}
        futuresContracts={futuresContracts}
        rows={rows}
        loading={loading}
        rowCountState={rowCountState}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        getBrokerEntry={getBrokerEntry}
        createBrokerEntry={createBrokerEntry}
        updateBrokerEntry={updateBrokerEntry}
        updateCommissionRate={updateCommissionRate}
        createCommissionRate={createCommissionRate}
        deleteCommissionRate={deleteCommissionRate}
        refetchFilteredBrokerEntries={refetchFilteredBrokerEntries}
      ></BrokerEntriesGrid>
    </Container>
  );
};
export default BrokerEntriesDisplay;
