import { FC, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { commodityKeys, locationCommodityRefsKeys } from '../../../../hooks';
import { useToastMutation } from '../../../../hooks/useToastMutations';
import {
  CommodityResponse,
  CreateCommoditiesRequest,
  UpdateCommodityByIdRequest,
} from '../../../../services/backend/data-contracts';
import { RequestParams } from '../../../../services/backend/http-client';
import CommodityDetails, { CommodityDetailsProps } from '../CommodityDetails/CommodityDetails';

export interface CommodityModalProps
  extends Omit<
    CommodityDetailsProps,
    'getCommodityDetails' | 'addErrorType' | 'removeErrorType' | 'formik'
  > {
  commodityId: string;
  isNewCommodity: boolean;
  isModalOpen: boolean;
  onClose: () => void;
  userName: string;
  getCommodity: (
    id: string,
    params?: RequestParams
  ) => Promise<AxiosResponse<CommodityResponse, any>>;
  createCommodity: (
    data: CreateCommoditiesRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  updateCommodity: (
    id: string,
    data: UpdateCommodityByIdRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
}

const CommodityModal: FC<CommodityModalProps> = ({
  isModalOpen,
  onClose,
  userName,
  getCommodity,
  createCommodity,
  updateCommodity,
  commodityId,
  isNewCommodity,
  commodityGroups,
  ...props
}) => {
  const [detailsErrors, setDetailsErrors] = useState<string[]>([]);

  const buildInitialNewCommodity = () => {
    return {
      result: {
        isActive: true,
        commodityGroup: { commodityGroupId: '' },
        tradeAccounts: [],
        futuresContracts: [],
        swappableCommodities: [],
        locations: [],
      },
    };
  };

  const { data: commodityData, isPending } = useQuery({
    queryKey: commodityKeys.details(commodityId),
    queryFn: async () => {
      return commodityId ? (await getCommodity(commodityId)).data : buildInitialNewCommodity();
    },
    select: (response) => {
      const initialDetails = response.result;
      return {
        ...initialDetails,
        commodityGroupId: initialDetails.commodityGroup?.commodityGroupId,
        initialLocationIds: initialDetails.locations?.map((l: any) => l.locationId),
        initialContractIds: initialDetails.futuresContracts?.map((c: any) => c.futuresContractId),
        initialAccountIds: initialDetails.tradeAccounts?.map((t: any) => t.tradeAccountId),
        initialSwappableCommodityIds: initialDetails.swappableCommodities?.map(
          (c) => c.commodityId
        ),
      };
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...commodityData,
    },
    validationSchema: yup.object().shape({
      commodityCode: yup
        .string()
        .required('Code Required')
        .max(6, 'Commodity Code must be 6 characters or fewer'),
      commodityName: yup.string().required('Name Required'),
      commodityGroupId: yup
        .number()
        .required('Group Required')
        .test({
          message: 'Group Inactive',
          test: (value) =>
            commodityGroups?.find((g) => '' + g.commodityGroupId === '' + value) !== undefined,
        }),
      locations: yup.array().of(
        yup.object().shape({
          locationId: yup.string().required(),
          defaultFuturesContractValue: yup.string().required(),
        })
      ),
    }),
    onSubmit: () => {
      doSave({ ...formik.values });
    },
  });

  const queryClient = useQueryClient();
  const createMutation = useToastMutation({
    queryKey: commodityKeys.all,
    mutationFn: createCommodity,
    afterSuccess: () => queryClient.invalidateQueries({ queryKey: locationCommodityRefsKeys.all }),
  });

  const updateMutation = useToastMutation({
    queryKey: commodityKeys.all,
    mutationFn: ({ id, data }: any) => updateCommodity(id, data),
    afterSuccess: () => queryClient.invalidateQueries({ queryKey: locationCommodityRefsKeys.all }),
  });

  const handleDetailCancelClick = () => {
    onClose();
    setDetailsErrors([]);
  };

  const handleDetailSaveClick = () => {
    formik.submitForm();
  };

  const doSave = (currentDetails: any) => {
    const now = new Date().toISOString();
    const commodity = {
      ...currentDetails,
      updatedOn: now,
      updatedBy: userName,
      commodityGroup: commodityGroups?.find(
        (cg) => '' + currentDetails.commodityGroupId === '' + cg.commodityGroupId
      ),
    };
    if (isNewCommodity) {
      createMutation.mutate(
        {
          commodity: {
            ...commodity,
            createdOn: now,
            createdBy: userName,
          },
        },
        { onSuccess: () => onClose() }
      );
    } else {
      updateMutation.mutate(
        {
          id: commodityId,
          data: { commodity },
        },
        { onSuccess: () => onClose() }
      );
    }
    setDetailsErrors([]);
  };

  return isPending ? (
    <LoadingSpinner />
  ) : (
    <Dialog fullWidth maxWidth="md" open={isModalOpen} data-testid="CommodityModal">
      <DialogTitle>{'Commodity ' + (isNewCommodity ? 'Create' : 'Update')}</DialogTitle>
      <DialogContent>
        <CommodityDetails
          formik={formik}
          commodityGroups={commodityGroups}
          {...props}
        ></CommodityDetails>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDetailCancelClick}>Cancel</Button>
        <Button
          onClick={handleDetailSaveClick}
          disabled={
            createMutation.isPending || updateMutation.isPending || detailsErrors.length != 0
          }
        >
          {isNewCommodity ? 'Create' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommodityModal;
