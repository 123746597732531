/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateFutureEntryRequest,
  FutureEntriesResponse,
  FutureEntryResponse,
  FutureFilteredEntriesResponse,
  UpdateFutureEntryRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class FutureEntries<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetFutureEntry
   * @request GET:/futureEntries/{id}
   * @secure
   */
  getFutureEntry = (id: string, params: RequestParams = {}) =>
    this.request<FutureEntryResponse, any>({
      path: `/futureEntries/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateFutureEntry
   * @request PUT:/futureEntries/{id}
   * @secure
   */
  updateFutureEntry = (id: string, data: UpdateFutureEntryRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futureEntries/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name DeleteFutureEntry
   * @request DELETE:/futureEntries/{id}
   * @secure
   */
  deleteFutureEntry = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futureEntries/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFutureEntries
   * @request OPTIONS:/futureEntries/{id}
   */
  optionsFutureEntries = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futureEntries/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFutureEntries
   * @request GET:/futureEntries
   * @secure
   */
  getFutureEntries = (params: RequestParams = {}) =>
    this.request<FutureEntriesResponse, any>({
      path: `/futureEntries`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name CreateFutureEntry
   * @request POST:/futureEntries
   * @secure
   */
  createFutureEntry = (data: CreateFutureEntryRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futureEntries`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFutureEntries2
   * @request OPTIONS:/futureEntries
   * @originalName optionsFutureEntries
   * @duplicate
   */
  optionsFutureEntries2 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futureEntries`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFilteredFutureEntries
   * @request GET:/futureEntries/filter
   * @secure
   */
  getFilteredFutureEntries = (
    query: {
      commodityId?: string;
      sortOrder?: string;
      limit: string;
      tradeAccountId?: string;
      brokerId?: string;
      offset?: string;
      search?: string;
      sortBy?: string;
      futuresContractId?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<FutureFilteredEntriesResponse, any>({
      path: `/futureEntries/filter`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFutureEntries3
   * @request OPTIONS:/futureEntries/filter
   * @originalName optionsFutureEntries
   * @duplicate
   */
  optionsFutureEntries3 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futureEntries/filter`,
      method: 'OPTIONS',
      ...params,
    });
}
