/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateTradeAccountRequest,
  FilteredTradeAccountsResponse,
  TradeAccountResponse,
  UpdateTradeAccountRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class TradeAccounts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetFilteredTradeAccounts
   * @request GET:/tradeAccounts/filter
   * @secure
   */
  getFilteredTradeAccounts = (
    query: {
      sortOrder?: string;
      limit: string;
      search?: string;
      sortBy?: string;
      offset?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<FilteredTradeAccountsResponse, any>({
      path: `/tradeAccounts/filter`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsTradeAccounts
   * @request OPTIONS:/tradeAccounts/filter
   */
  optionsTradeAccounts = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/tradeAccounts/filter`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetTradeAccountById
   * @request GET:/tradeAccounts/{id}
   * @secure
   */
  getTradeAccountById = (id: string, params: RequestParams = {}) =>
    this.request<TradeAccountResponse, any>({
      path: `/tradeAccounts/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateTradeAccount
   * @request PUT:/tradeAccounts/{id}
   * @secure
   */
  updateTradeAccount = (id: string, data: UpdateTradeAccountRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/tradeAccounts/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsTradeAccounts2
   * @request OPTIONS:/tradeAccounts/{id}
   * @originalName optionsTradeAccounts
   * @duplicate
   */
  optionsTradeAccounts2 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/tradeAccounts/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name CreateTradeAccount
   * @request POST:/tradeAccounts
   * @secure
   */
  createTradeAccount = (data: CreateTradeAccountRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/tradeAccounts`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsTradeAccounts3
   * @request OPTIONS:/tradeAccounts
   * @originalName optionsTradeAccounts
   * @duplicate
   */
  optionsTradeAccounts3 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/tradeAccounts`,
      method: 'OPTIONS',
      ...params,
    });
}
