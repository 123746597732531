import { FC } from 'react';
import { useFormik } from 'formik';

import { MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
  GridRenderEditCellParams,
  GridValidRowModel,
  renderEditSingleSelectCell,
} from '@mui/x-data-grid';

import { SmallChangeableGrid } from '../../../../components';
import FormikAutocomplete from '../../../../components/FormikAutocomplete/FormikAutocomplete';
import {
  CommoditiesSummaryResponse,
  FuturesContractsSummaryResponse,
  LocationGroupsResponse,
} from '../../../../services/backend/data-contracts';

export interface LocationDetailsProps {
  formik: ReturnType<typeof useFormik<any>>;
  locationId: string;
  isNewLocation: boolean;
  locationGroups?: LocationGroupsResponse['result'];
  commoditySummaries?: CommoditiesSummaryResponse['result'];
  futuresContractsSummaries?: FuturesContractsSummaryResponse['result'];
}

const LocationDetails: FC<LocationDetailsProps> = ({
  formik,
  locationGroups,
  commoditySummaries,
  futuresContractsSummaries,
}) => {
  const locationGroupsMap = new Map<number, string>();
  locationGroups?.forEach((lg) => {
    if (lg.locationGroupId) {
      locationGroupsMap.set(
        lg.locationGroupId,
        lg.locationGroupCode + ' - ' + lg.locationGroupName
      );
    }
  });

  const updateCommodityId = (selectedCommodity: GridValidRowModel) => {
    if (selectedCommodity.commodityCode) {
      const foundCommodity = commoditySummaries?.find(
        (c) => selectedCommodity.commodityCode === c.commodityCode
      );
      selectedCommodity.commodityId = foundCommodity?.commodityId;
      selectedCommodity.commodityName = foundCommodity?.commodityName;
    }
  };
  const updateDefaultContractIdForCommodity = (selectedCommodity: GridValidRowModel) => {
    if (selectedCommodity.defaultFuturesContractValue) {
      const foundContract = futuresContractsSummaries?.find(
        (fc) => selectedCommodity.defaultFuturesContractValue === fc.futuresContractValue
      );
      selectedCommodity.defaultFuturesContractId = foundContract?.futuresContractId;
    }
  };

  const isAlreadySelected = (selectedItems: any[], item: any, idName: string) => {
    return !!selectedItems?.find((si: any) => si[idName] === item[idName]);
  };

  const handleCommoditiesChange = (selectedCommodities: GridValidRowModel[]) => {
    selectedCommodities.forEach(updateCommodityId);
    selectedCommodities.forEach(updateDefaultContractIdForCommodity);

    const incompleteCommodities = selectedCommodities.filter(
      (c) => !c.defaultFuturesContractValue != !c.commodityCode
    );
    const completeCommodities = selectedCommodities.filter(
      (c) => incompleteCommodities.indexOf(c) < 0
    );

    const errorMessage = 'Commodity or Default Contract Missing';
    incompleteCommodities.forEach((c) => (c.errors = [errorMessage]));
    completeCommodities.forEach((c) => (c.errors = undefined));
    const displayedCommodities = selectedCommodities.filter(
      (c) => (c.commodityId || c.defaultFuturesContractValue) && !c.remove
    );

    formik.setFieldValue('commodities', displayedCommodities);
    formik.setFieldError(
      'commodities',
      incompleteCommodities.length > 0 ? errorMessage : undefined
    );
  };

  return (
    <Box data-testid="LocationDetails">
      <Grid container spacing={2}>
        <Grid container item alignItems="Center" xs={12}>
          <Grid item xs={3} sx={{ fontWeight: 'bold' }} id="locationCode-label">
            Location Code:
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="locationCode"
              value={formik.values.locationCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              error={formik.touched.locationCode && Boolean(formik.errors.locationCode)}
              helperText={formik.touched.locationCode && formik.errors.locationCode?.toString()}
              InputProps={{ 'aria-labelledby': 'locationCode-label' }}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems="Center" xs={12}>
          <Grid item xs={3} sx={{ fontWeight: 'bold' }} id="locationName-label">
            Location Name:
          </Grid>
          <TextField
            id="locationName"
            value={formik.values.locationName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            size="small"
            error={formik.touched.locationName && Boolean(formik.errors.locationName)}
            helperText={formik.touched.locationName && formik.errors.locationName?.toString()}
            InputProps={{ 'aria-labelledby': 'locationName-label' }}
          />
        </Grid>

        <Grid container item alignItems="Center" xs={12}>
          <Grid item xs={3} id="locationGroupId-select-label">
            Location Group:
          </Grid>
          <Grid item xs={3}>
            <FormikAutocomplete
              formik={formik}
              field="locationGroupId"
              required
              optionsAsMap={locationGroupsMap}
              openOnFocus
            ></FormikAutocomplete>
          </Grid>
        </Grid>

        <Grid container item alignItems="center" xs={12}>
          <Grid item xs={3} sx={{ fontWeight: 'bold' }} id="isActive-select-label">
            Active:
          </Grid>
          <Grid item xs={3}>
            <Select
              value={formik.values.isActive ? '1' : '0'}
              size="small"
              onChange={(event) =>
                formik.setFieldValue('isActive', '' + event.target.value === '1')
              }
              SelectDisplayProps={{ 'aria-labelledby': 'isActive-select-label' }}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <SmallChangeableGrid
            tableTitle="Commodities"
            columns={[
              {
                field: 'commodityCode',
                headerName: 'Commodity',
                type: 'singleSelect',
                valueOptions: commoditySummaries
                  ?.filter((c) => !isAlreadySelected(formik.values.commodities, c, 'commodityId'))
                  .map((c) => c.commodityCode ?? '') ?? [''],
                editable: true,
                renderEditCell: (params) =>
                  renderEditSingleSelectCell({
                    ...params,
                    onBlur: () => saveChangeToRowData(params),
                  }),
                flex: 1,
              },
              {
                field: 'defaultFuturesContractValue',
                headerName: 'Default Futures Contract',
                type: 'singleSelect',
                valueOptions: futuresContractsSummaries?.map(
                  (fc) => fc.futuresContractValue || ''
                ) ?? [''],
                editable: true,
                renderEditCell: (params) =>
                  renderEditSingleSelectCell({
                    ...params,
                    onBlur: saveChangeToRowData(params),
                  }),
                flex: 1,
              },
            ]}
            data={formik.values.commodities}
            onAddButtonClick={(generatedId) => {
              return {
                commodityId: '',
                commodityCode: '',
                commodityName: '',
                defaultFuturesContractId: '',
                defaultFuturesContractValue: '',
                id: generatedId,
              };
            }}
            uniqueFields={['commodityCode']}
            setDataChanged={handleCommoditiesChange}
            isCellEditable={(props) => {
              return (
                props.field !== 'commodityCode' ||
                !formik.values.initialCommodityIds?.includes(props.row.commodityId)
              );
            }}
            canRemoveAny
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LocationDetails;

function saveChangeToRowData(params: GridRenderEditCellParams) {
  return () => {
    const id = params.id;
    const field = params.field;
    params.api.stopCellEditMode({ id, field });
  };
}
