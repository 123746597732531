import React, { FC, PropsWithChildren } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

interface NestedListProps {
  title: string;
  name: string;
  opened: string;
  setOpen: (name: string) => void;
  display: boolean;
}

const NestedList: FC<PropsWithChildren<NestedListProps>> = ({
  title,
  name,
  opened,
  setOpen,
  children,
  display,
}) => {
  const isOpen = () => name === opened;
  const handleClick = () => (isOpen() ? setOpen('') : setOpen(name));
  if (!display) {
    return <></>;
  }
  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={title} />
        {isOpen() ? (
          <ExpandLess onClick={() => setOpen('')} />
        ) : (
          <ExpandMore onClick={() => setOpen(title)} />
        )}
      </ListItemButton>
      <Collapse in={isOpen()} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense sx={{ pl: 4 }}>
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default NestedList;
