import React, { FC, useContext } from 'react';
import { AxiosResponse } from 'axios';
import * as yup from 'yup';

import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';

import { CompuWeighRole, PositionAdministratorRole } from '../../../../app/UserWrapper/UserWrapper';
import { ChangeableDisplay, dateValueFormatter, errorCellClassName } from '../../../../components';
import { UserContext } from '../../../../Context';
import { UseBackendQueryResult } from '../../../../hooks/useBackendQueries';
import {
  CommodityFuturesContractsRefResponse,
  CompuweighResponse,
  CreateCompuweighRequest,
  DeleteCompuweighRequest,
  FuturesContractOptionMonthRefResponse,
  LocationCommodityRefResponse,
  LocationsResponse,
  UpdateCompuweighRequest,
} from '../../../../services/backend/data-contracts';

const compuWeightSchema = yup.object({
  locationId: yup.number().required(),
  commodityId: yup.number().required(),
  futuresContractId: yup.number().required(),
  optionMonthId: yup.number().required(),
  basisPrice: yup.number().required(),
});

interface CompuWeighListProps {
  compuWeighQuery: () => UseBackendQueryResult<CompuweighResponse['result'][number][]>;
  locations?: LocationsResponse['result'];
  getCommoditiesForLocation: (
    locationId: number
  ) => LocationCommodityRefResponse['result'][number][];
  getFuturesContractsForCommodity: (
    commodityId: number
  ) => CommodityFuturesContractsRefResponse['result'][number][];
  getOptionMonthsForFuturesContract: (
    futuresContractId: number
  ) => FuturesContractOptionMonthRefResponse['result'][number][];
  createSpotPricing: (data: CreateCompuweighRequest) => Promise<AxiosResponse>;
  updateSpotPricing: (data: UpdateCompuweighRequest) => Promise<AxiosResponse>;
  deleteSpotPricing?: (data: DeleteCompuweighRequest) => Promise<AxiosResponse>;
  isLoading?: boolean;
}

const CompuWeighList: FC<CompuWeighListProps> = ({
  compuWeighQuery,
  locations,
  getCommoditiesForLocation,
  getFuturesContractsForCommodity,
  getOptionMonthsForFuturesContract,
  createSpotPricing,
  updateSpotPricing,
  deleteSpotPricing,
  isLoading,
}) => {
  const columns: GridColDef[] = [
    {
      field: 'locationId',
      headerName: 'Location',
      type: 'singleSelect',
      editable: true,
      valueOptions: locations?.sort((a, b) =>
        (a.locationCode ?? '').localeCompare(b.locationCode ?? '')
      ),
      getOptionLabel: (value: any) => `${value['locationCode']} - ${value['locationName']}`,
      getOptionValue: (value: any) => Number(value['locationId']),
      cellClassName: errorCellClassName,
      flex: 1,
    },
    {
      field: 'commodityId',
      headerName: 'Commodity',
      type: 'singleSelect',
      editable: true,
      valueOptions: ({ row }) => {
        const options = getCommoditiesForLocation(row['locationId']).map((v) => ({
          value: v.commodityId,
          label: v.commodityValue,
        }));
        return options?.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
      },
      cellClassName: errorCellClassName,
      flex: 1,
    },
    {
      field: 'futuresContractId',
      headerName: 'Futures Contract',
      type: 'singleSelect',
      editable: true,
      valueOptions: ({ row }) => {
        const options = getFuturesContractsForCommodity(row['commodityId']).map((v) => ({
          value: v.futuresContractId,
          label: v.futuresContractValue,
        }));
        if (!options.find((v) => v.value === row['futuresContractId'])) {
          options.push({ value: row['futuresContractId'], label: row['futuresContract'] });
        }
        return options?.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
      },
      cellClassName: errorCellClassName,
      flex: 1,
    },
    {
      field: 'optionMonthId',
      headerName: 'Futures Month',
      type: 'singleSelect',
      editable: true,
      valueOptions: ({ row }) => {
        const options = getOptionMonthsForFuturesContract(row['futuresContractId']).map((v) => ({
          value: v.optionMonthId,
          label: v.optionMonthValue,
        }));
        if (!options.find((v) => v.value === row['optionMonthId'])) {
          options.push({ value: row['optionMonthId'], label: row['futuresMonth'] });
        }
        return options;
      },
      cellClassName: errorCellClassName,
      flex: 1,
    },
    {
      field: 'basisPrice',
      headerName: 'Basis Price',
      type: 'number',
      editable: true,
      cellClassName: errorCellClassName,
      flex: 1,
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      valueFormatter: dateValueFormatter,
      flex: 1,
    },
    { field: 'createdBy', headerName: 'Created By', flex: 1 },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      valueFormatter: dateValueFormatter,
      flex: 1,
    },
    { field: 'updatedBy', headerName: 'Updated By', flex: 1 },
  ];

  let create, update, add, remove;
  const userContext = useContext(UserContext);
  const canCreate = userContext.haveRole([CompuWeighRole, PositionAdministratorRole]);
  if (canCreate) {
    create = (newRows: GridValidRowModel[]) => {
      return newRows.length
        ? createSpotPricing({
            entries: newRows.map((r) => ({
              basisPrice: r['basisPrice'],
              optionMonthId: r['optionMonthId'],
              locationId: r['locationId'],
              futuresContractId: r['futuresContractId'],
              commodityId: r['commodityId'],
            })),
          })
        : Promise.resolve();
    };
    update = (oldRows: GridValidRowModel[]) => {
      return oldRows.length
        ? updateSpotPricing({
            entries: oldRows.map((r) => ({
              basisPrice: r['basisPrice'],
              optionMonthId: r['optionMonthId'],
              locationId: r['locationId'],
              futuresContractId: r['futuresContractId'],
              commodityId: r['commodityId'],
            })),
          })
        : Promise.resolve();
    };
    add = (fakeId: number | undefined) => ({
      id: fakeId,
      locationId: '',
      commodityId: '',
      futuresContractId: '',
      optionMonthId: '',
      basisPrice: '',
    });
    remove = (row: GridValidRowModel) =>
      deleteSpotPricing
        ? deleteSpotPricing({
            entries: [{ locationId: row['locationId'], commodityId: row['commodityId'] }],
          })
        : Promise.reject();
  }
  return (
    <ChangeableDisplay
      fullWidth={true}
      pageTitle="CompuWeigh Spot Pricing"
      columns={columns}
      getRowId={(row) => row['id']}
      fnUseQuery={compuWeighQuery}
      create={create}
      update={update}
      onAddButtonClick={!isLoading ? add : undefined}
      onRemoveSavedRow={!isLoading ? remove : undefined}
      schema={compuWeightSchema}
    />
  );
};

export default CompuWeighList;
