import React from 'react';
import { redirect, RouteObject } from 'react-router-dom';

import { LoginCallback } from '@okta/okta-react';

import { Loading } from '../components';
import { AboutPage } from '../pages/About';
import UsersPage from '../pages/Admin/Users/UsersPage';
import AllocatedCommissionAdjustmentPage from '../pages/BrokerAdjustments/AllocatedCommissionAdjustment/AllocatedCommissionAdjustmentPage';
import CashAdjustmentsPage from '../pages/BrokerAdjustments/CashAdjustments/CashAdjustmentsPage';
import CompuWeighSpotPricingPage from '../pages/DailyProcessing/CompuWeighSpotPricing/CompuWeighSpotPricingPage';
import EndOfDayProcessPage from '../pages/DailyProcessing/EndOfDayProcessPage/EndOfDayProcessPage';
import ErrorPage from '../pages/Error/ErrorPage';
import BrokersPage from '../pages/Maintenance/Brokers/BrokersPage';
import FuturesContractsListPage from '../pages/Maintenance/FuturesContracts/FuturesContractList/FuturesContractsListPage';
import HedgedCommoditiesPage from '../pages/Maintenance/HedgedCommodities/HedgedCommoditiesPage';
import TradeAccountsPage from '../pages/Maintenance/TradeAccounts/TradeAccountsList/TradeAccountsPage';
import CommoditiesPage from '../pages/ReferenceData/Commodities/CommoditiesPage';
import CommodityGroupsPage from '../pages/ReferenceData/CommodityGroups/CommodityGroupsPage';
import FuturesBrokersPage from '../pages/ReferenceData/FuturesBrokers/FuturesBrokersPage';
import FuturesContractsPage from '../pages/ReferenceData/FuturesContracts/FuturesContractsPage';
import FuturesMonthsPage from '../pages/ReferenceData/FuturesMonths/FuturesMonthsPage';
import FuturesTradeAccountsPage from '../pages/ReferenceData/FuturesTradeAccounts/FuturesTradeAccountsPage';
import ImportFuturesReferencePage from '../pages/ReferenceData/ImportFuturesReference/ImportFuturesReferencePage';
import LocationGroupsPage from '../pages/ReferenceData/LocationGroups/LocationGroupsPage';
import LocationsPage from '../pages/ReferenceData/Locations/LocationsPage';
import TransactionTypesPage from '../pages/ReferenceData/TransactionTypes/TransactionTypesPage';
import FuturesTradesPage from '../pages/Trades/FuturesTrades/FuturesTradesList/FuturesTradesPage';
import FuturesEntriesPage from '../pages/Transactions/FuturesEntries/FuturesEntriesPage';
import PositionEntriesPage from '../pages/Transactions/PositionEntries/PositionEntriesPage';
import SwapEntriesPage from '../pages/Transactions/SwapEntries/SwapEntriesPage';

export const appRoutes: RouteObject[] = [
  {
    path: '',
    loader: ({ request }) => {
      const regex = /[?&]error=(\w+)(?=[&#]|$)/g;
      const url = request.url;
      const matches = url.match(regex);
      const errors = matches ? matches.map((match) => match.split('=')[1]) : [];
      return redirect(`/transactions/positionEntries${errors.length ? `?error=${errors[0]}` : ''}`);
    },
  },
  {
    path: 'transactions/*',
    children: [
      {
        path: 'positionEntries',
        element: <PositionEntriesPage />,
        loader: () => {
          ineum('page', 'PositionEntries');
          return {};
        },
      },
      {
        path: 'futuresEntries',
        element: <FuturesEntriesPage />,
        loader: () => {
          ineum('page', 'FuturesEntries');
          return {};
        },
      },
      {
        path: 'swapEntries',
        element: <SwapEntriesPage />,
        loader: () => {
          ineum('page', 'SwapEntries');
          return {};
        },
      },
      { path: '*', element: <ErrorPage error="Page not found" /> },
    ],
  },
  {
    path: 'dailyProcessing/*',
    children: [
      {
        path: 'endOfDayProcess',
        element: <EndOfDayProcessPage />,
        loader: () => {
          ineum('page', 'EndOfDayProcess');
          return {};
        },
      },
      {
        path: 'compuweigh',
        element: <CompuWeighSpotPricingPage />,
        loader: () => {
          ineum('page', 'CompuWeighSpotPricing');
          return {};
        },
      },
      { path: '*', element: <ErrorPage error="Page not found" /> },
    ],
  },
  {
    path: 'referenceData/*',
    children: [
      {
        path: 'commodityGroups',
        element: <CommodityGroupsPage />,
        loader: () => {
          ineum('page', 'CommodityGroups');
          return {};
        },
      },
      {
        path: 'commodities',
        element: <CommoditiesPage />,
        loader: () => {
          ineum('page', 'Commodities');
          return {};
        },
      },
      {
        path: 'locationGroups',
        element: <LocationGroupsPage />,
        loader: () => {
          ineum('page', 'LocationGroups');
          return {};
        },
      },
      {
        path: 'locations',
        element: <LocationsPage />,
        loader: () => {
          ineum('page', 'Locations');
          return {};
        },
      },
      {
        path: 'transactionTypes',
        element: <TransactionTypesPage />,
        loader: () => {
          ineum('page', 'TransactionTypes');
          return {};
        },
      },
      {
        path: 'futuresBrokers',
        element: <FuturesBrokersPage />,
        loader: () => {
          ineum('page', 'FuturesBrokers');
          return {};
        },
      },
      {
        path: 'futuresContracts',
        element: <FuturesContractsPage />,
        loader: () => {
          ineum('page', 'FuturesContracts');
          return {};
        },
      },
      {
        path: 'futuresMonths',
        element: <FuturesMonthsPage />,
        loader: () => {
          ineum('page', 'FuturesMonths');
          return {};
        },
      },
      {
        path: 'futuresTradeAccounts',
        element: <FuturesTradeAccountsPage />,
        loader: () => {
          ineum('page', 'FuturesTradeAccounts');
          return {};
        },
      },
      {
        path: 'importFuturesReference',
        element: <ImportFuturesReferencePage />,
        loader: () => {
          ineum('page', 'ImportFuturesReference');
          return {};
        },
      },
      { path: '*', element: <ErrorPage error="Page not found" /> },
    ],
  },
  {
    path: 'administration/*',
    children: [
      {
        path: 'users',
        element: <UsersPage />,
        loader: () => {
          ineum('page', 'Users');
          return {};
        },
      },
      { path: '*', element: <ErrorPage error="Page not found" /> },
    ],
  },
  {
    path: 'about',
    element: <AboutPage />,
    loader: () => {
      ineum('page', 'About');
      return {};
    },
  },
  {
    path: 'trades/*',
    children: [
      {
        path: 'futuresTrades',
        element: <FuturesTradesPage />,
        loader: () => {
          ineum('page', 'FuturesTrades');
          return {};
        },
      },
      { path: '*', element: <ErrorPage error="Page not found" /> },
    ],
  },
  {
    path: 'brokerAdjustments/*',
    children: [
      {
        path: 'cashAdjustments/:brokerCode',
        element: <CashAdjustmentsPage />,
        loader: () => {
          ineum('page', 'CashAdjustments');
          return {};
        },
      },
      {
        path: 'cashAdjustments',
        element: <CashAdjustmentsPage />,
        loader: () => {
          ineum('page', 'CashAdjustments');
          return {};
        },
      },
      {
        path: 'allocatedCommissionAdjustment',
        element: <AllocatedCommissionAdjustmentPage />,
        loader: () => {
          ineum('page', 'AllocatedCommissionAdjustment');
          return {};
        },
      },
      { path: '*', element: <ErrorPage error="Page not found" /> },
    ],
  },
  {
    path: 'maintenance/*',
    children: [
      {
        path: 'brokers',
        element: <BrokersPage />,
        loader: () => {
          ineum('page', 'Brokers');
          return {};
        },
      },
      {
        path: 'futuresContracts',
        element: <FuturesContractsListPage />,
        loader: () => {
          ineum('page', 'FuturesContractsList');
          return {};
        },
      },
      {
        path: 'hedgedCommodities',
        element: <HedgedCommoditiesPage />,
        loader: () => {
          ineum('page', 'HedgedCommodities');
          return {};
        },
      },
      {
        path: 'tradeAccounts',
        element: <TradeAccountsPage />,
        loader: () => {
          ineum('page', 'TradeAccounts');
          return {};
        },
      },
      { path: '*', element: <ErrorPage error="Page not found" /> },
    ],
  },
  { path: 'login/callback', element: <LoginCallback loadingElement={<Loading />} /> },
  {
    path: 'logout',
    loader: () => {
      return redirect(`/transactions/positionEntries`);
    },
  },
  { path: '*', element: <ErrorPage error="Page not found" /> },
];
