/* eslint-disable */
import { createContext } from 'react';

export const LogoutContext = createContext({
  logout: () => {
    console.error('no logout function found');
  },
});

export const UserContext = createContext({
  userName: undefined,
  // we want to always return false until the roles have been populated.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  haveRole: (requiredRoles: string[]): boolean => false,
} as {
  userName: string | undefined;
  haveRole: (requiredRoles: string[]) => boolean;
});

export const ColorModeContext = createContext({ toggleColorMode: () => {} });
