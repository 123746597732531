import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';

import { Box, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import BrokerMarginAmountTextField from '../../../../../components/BrokerMarginAmountTextField/BrokerMarginAmountTextField';
import FormikAutocomplete from '../../../../../components/FormikAutocomplete/FormikAutocomplete';
import {
  BrokerAdjustmentTypeRef,
  BrokerRefResponse,
  HedgedCommodityRefResponse,
  TradeAccountRefResponse,
} from '../../../../../services/backend/data-contracts';

interface BrokerMarginAdjustmentFormProps {
  formik: ReturnType<typeof useFormik<any>>;
  loadingBrokers?: boolean;
  brokerRefs?: BrokerRefResponse['result'];
  loadingTradeAccounts?: boolean;
  tradeAccountRefs?: TradeAccountRefResponse['result'];
  loadingCommodities?: boolean;
  hedgedCommodityRefs?: HedgedCommodityRefResponse['result'];
  loadingAdjustmentTypes?: boolean;
  adjustmentTypeRefs?: BrokerAdjustmentTypeRef['result'];
  handleManualTradeDateChange: () => void;
}

const BrokerMarginAdjustmentForm: FC<BrokerMarginAdjustmentFormProps> = ({
  formik,
  loadingBrokers,
  brokerRefs,
  loadingTradeAccounts,
  tradeAccountRefs,
  loadingCommodities,
  hedgedCommodityRefs,
  loadingAdjustmentTypes,
  adjustmentTypeRefs,
  handleManualTradeDateChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTradeAccountReq, setTradeAccountReq] = useState(false);
  useEffect(() => {
    if (formik.values.adjustmentTypeId) {
      setTradeAccountReq(
        adjustmentTypeRefs?.find((s) => s.adjustmentTypeId == formik.values.adjustmentTypeId)
          ?.isTradeAcctRequired ?? false
      );
    }
    if (!isTradeAccountReq) {
      formik.setFieldValue('tradeAccountId', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adjustmentTypeRefs,
    setTradeAccountReq,
    formik.values.adjustmentTypeId,
    formik.setFieldValue,
    isTradeAccountReq,
  ]);

  const brokerMap = (brokerRefs ?? [])
    .sort((a, b) => (a.brokerName ?? '').localeCompare(b.brokerName ?? ''))
    .reduce((rec, curr) => {
      // duplicate names is a data problem?
      // keeping since they have unique ids and should probably be enforced at the broker entry
      // if (curr.brokerId && curr.brokerName && !Object.values(rec).includes(curr.brokerName)) {
      if (curr.brokerId) {
        rec.set(curr.brokerId, curr.brokerName);
      }
      return rec;
    }, new Map());

  const adjustmentTypeMap = (adjustmentTypeRefs ?? [])
    .sort((a, b) => (a.adjustmentName ?? '').localeCompare(b.adjustmentName ?? ''))
    .reduce((rec, curr) => {
      if (curr.adjustmentTypeId && curr.adjustmentName) {
        rec.set(curr.adjustmentTypeId, curr.adjustmentName);
      }
      return rec;
    }, new Map<number, string>());

  const tradeAccountMap = (tradeAccountRefs ?? [])
    .sort((a, b) => (a.tradeAccountName ?? '').localeCompare(b.tradeAccountName ?? ''))
    .reduce((rec, curr) => {
      if (curr.tradeAccountId && curr.tradeAccountName) {
        rec.set(curr.tradeAccountId, curr.tradeAccountName);
      }
      return rec;
    }, new Map<number, string>());

  const hedgedCommodityMap = (hedgedCommodityRefs ?? [])
    .sort((a, b) => (a.hedgedCommodityName ?? '').localeCompare(b.hedgedCommodityName ?? ''))
    .reduce((rec, curr) => {
      if (curr.hedgedCommodityId && curr.hedgedCommodityName) {
        rec.set(curr.hedgedCommodityId, curr.hedgedCommodityName);
      }
      return rec;
    }, new Map<number, string>());

  return (
    <Box data-testid="BrokerMarginAdjustmentForm">
      {!formik.values ? null : (
        <form autoComplete="off">
          <Grid container spacing={2}>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="date-label" sx={{ fontWeight: 'bold' }}>
                Date:
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  value={moment.utc(formik?.values['adjustmentDate'])}
                  onChange={(value) => {
                    formik.setFieldValue('adjustmentDate', value, true);
                    handleManualTradeDateChange();
                  }}
                  open={isOpen}
                  onClose={() => setIsOpen(false)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                      name: 'adjustmentDate',
                      onBlur: (e) => {
                        formik.handleBlur(e);
                      },
                      onClick: () => setIsOpen(true),
                      error: formik.touched.adjustmentDate && Boolean(formik.errors.adjustmentDate),
                      helperText:
                        formik.touched.adjustmentDate && formik.errors.adjustmentDate?.toString(),
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="broker-label" sx={{ fontWeight: 'bold' }}>
                Broker:
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="brokerId"
                  optionsAsMap={brokerMap}
                  loading={loadingBrokers}
                ></FormikAutocomplete>
              </Grid>
            </Grid>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="type-label" sx={{ fontWeight: 'bold' }}>
                Type:
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="adjustmentTypeId"
                  optionsAsMap={adjustmentTypeMap}
                  loading={loadingAdjustmentTypes}
                ></FormikAutocomplete>
              </Grid>
            </Grid>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="amount-label" sx={{ fontWeight: 'bold' }}>
                Amount:
              </Grid>
              <Grid item xs={6}>
                <BrokerMarginAmountTextField formik={formik}></BrokerMarginAmountTextField>
              </Grid>
            </Grid>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="trade-account-label">
                Trade Account:
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="tradeAccountId"
                  optionsAsMap={tradeAccountMap}
                  disabled={!isTradeAccountReq}
                  loading={loadingTradeAccounts}
                ></FormikAutocomplete>
              </Grid>
            </Grid>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="hedged-commodity-label">
                Hedged Commodity:
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="hedgedCommodityId"
                  optionsAsMap={hedgedCommodityMap}
                  loading={loadingCommodities}
                ></FormikAutocomplete>
              </Grid>
            </Grid>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="comment-label">
                Comment:
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="comment"
                  rows={3}
                  size="small"
                  fullWidth
                  multiline
                  value={formik.values['comment']}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.comment && Boolean(formik.errors.comment)}
                  helperText={formik.touched.comment && formik.errors.comment?.toString()}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default BrokerMarginAdjustmentForm;
