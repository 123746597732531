import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { NumericFormatProps } from 'react-number-format/types/types';

import { InputAdornment, InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material';

interface CurrencyTextFieldProps
  extends Omit<NumericFormatProps<TextFieldProps>, 'InputProps' | 'type'> {
  inputProps?: InputBaseComponentProps;
  id?: string;
  name?: string;
  error?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
}

const CurrencyTextField: FC<CurrencyTextFieldProps> = ({ inputProps, value, ...props }) => {
  const [newValue, setNewValue] = useState(value);
  useEffect(() => {
    const parsedValue = parseFloat(value?.toString() ?? '');
    if (!isNaN(parsedValue) && 0 !== parsedValue) {
      setNewValue(parsedValue.toFixed(5));
    } else {
      setNewValue(value);
    }
  }, [value]);
  return (
    <NumericFormat
      fullWidth={true}
      customInput={TextField}
      onFocus={(event) => event.target.select()}
      InputProps={{
        size: 'small',
        inputProps: {
          onBlur: (event: FocusEvent): void => {
            const target = event.target as HTMLInputElement;
            const parsedValue = parseFloat(target.value);
            if (!isNaN(parsedValue)) {
              setNewValue(parsedValue.toFixed(5));
            }
          },
          ...inputProps,
        },
        'aria-labelledby': props?.id + '-label',
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      decimalScale={5}
      value={newValue}
      {...props}
    ></NumericFormat>
  );
};

export default CurrencyTextField;
