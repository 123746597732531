import React, { useContext, useEffect } from 'react';

import Typography from '@mui/material/Typography';

import { PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';
import {
  useCommoditiesSummaryQuery,
  useFuturesContractsSummaryQuery,
  useLocationGroupsQuery,
  useLocationsQuery,
  useOktaUser,
} from '../../../hooks';
import { useLocations } from '../../../hooks/backendDataClients/index';

import LocationsList from './LocationsList/LocationsList';

export default function LocationsPage() {
  const locationsDataClient = useLocations();
  const commodityData = useCommoditiesSummaryQuery()?.data?.sort((a, b) =>
    (a.commodityCode ?? '').localeCompare(b.commodityCode ?? '')
  );

  const { data: contractData } = useFuturesContractsSummaryQuery();
  const { data: locationGroups } = useLocationGroupsQuery();

  const { user } = useOktaUser();
  const userName = user?.preferred_username ?? '';

  useEffect(() => {
    document.title = 'Locations | Positions and Futures';
  }, []);
  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="LocationsPage">
          <h2>Locations</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  return (
    <>
      <main style={{ padding: '24px' }} data-testid="LocationsPage">
        <LocationsList
          userName={userName}
          getLocations={useLocationsQuery}
          getLocationDetails={locationsDataClient.getLocation}
          createLocation={locationsDataClient.createLocation}
          updateLocation={locationsDataClient.updateLocationById}
          locationGroups={locationGroups}
          commoditySummaries={commodityData}
          futuresContractsSummaries={contractData}
        />
      </main>
    </>
  );
}
