import React, { FC } from 'react';

import { CircularProgress } from '@mui/material';

import { useHedgedCommoditiesQuery } from '../../hooks';
import FormikAutocomplete from '../FormikAutocomplete/FormikAutocomplete';

export interface FuturesHedgedCommodityAutocompleteProps {
  formik: any;
  openOnFocus?: boolean;
}

const FuturesHedgedCommodityAutocomplete: FC<FuturesHedgedCommodityAutocompleteProps> = (props) => {
  const allOptions = useHedgedCommoditiesQuery().data;
  if (!allOptions) {
    return <CircularProgress />;
  }
  const activeOptions = allOptions.sort((a, b) =>
    (a.hedgedCommodityName ?? '').localeCompare(b.hedgedCommodityName ?? '')
  );

  const optionsById = new Map(
    activeOptions?.map((ta) => [ta.hedgedCommodityId as number, ta.hedgedCommodityName as string])
  );

  return (
    <FormikAutocomplete
      formik={props.formik}
      field="hedgedCommodityId"
      optionsAsMap={optionsById}
      openOnFocus={props.openOnFocus}
    ></FormikAutocomplete>
  );
};

export default FuturesHedgedCommodityAutocomplete;
