import React, { FC, useEffect, useState } from 'react';
import { Schema, ValidationError } from 'yup';

import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {
  GridCellParams,
  GridColDef,
  GridRowSelectionModel,
  GridSortDirection,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useMutation } from '@tanstack/react-query';

import { UseRefDataQueryResult } from '../../hooks';
import ChangeableGrid from '../ChangeableGrid/ChangeableGrid';
import UnsavedChangesPrompt from '../UnsavedChangesPrompt/UnsavedChangesPrompt';

interface ChangeableDisplayProps {
  pageTitle: string;
  columns: GridColDef[];
  getRowId: (row: GridValidRowModel) => number;
  fnUseQuery?: () => UseRefDataQueryResult<any[]>;
  create?: (rows: GridValidRowModel[]) => Promise<any>;
  update?: (rows: GridValidRowModel[]) => Promise<any>;
  onAddButtonClick?: (fakeId: number | undefined) => GridValidRowModel | void;
  onRemoveSavedRow?: (row: GridValidRowModel) => Promise<any>;
  schema?: Schema;
  uniqueFields?: string[];
  gridFilter?: (row: GridValidRowModel) => boolean;
  sortField?: string;
  isCellEditable?: (params: GridCellParams) => boolean;
  infoIndicator?: (row: GridValidRowModel) => string | null;
  hideUnsavedPrompt?: boolean;
  modalEdit?: (
    setter: React.Dispatch<React.SetStateAction<GridValidRowModel>>
  ) => React.ReactElement;
  fullWidth?: boolean;
}

const firstFakeId = Math.pow(2, 50);

const ChangeableDisplay: FC<ChangeableDisplayProps> = ({
  pageTitle,
  columns,
  getRowId,
  create,
  update,
  onAddButtonClick,
  onRemoveSavedRow,
  fnUseQuery,
  schema,
  uniqueFields,
  gridFilter,
  sortField,
  isCellEditable,
  infoIndicator,
  hideUnsavedPrompt,
  modalEdit,
  fullWidth,
}) => {
  const [changedRows, setChangedRows] = useState<GridValidRowModel[]>([]);
  const [fakeId, setFakeId] = useState<number>(firstFakeId);
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  useEffect(() => {
    document.title = `${pageTitle} ${
      changedRows?.length ? '(' + changedRows.length + ')' : ''
    } | Positions and Futures`;
  }, [pageTitle, changedRows.length]);

  const { data, isPending, refetch } = fnUseQuery
    ? fnUseQuery()
    : { data: [], isPending: false, refetch: () => {} };
  const mutation = useMutation({
    mutationFn: (rowsToSave: GridValidRowModel[]) => {
      const oldRows = rowsToSave.filter((r) => getRowId(r) < firstFakeId);
      const newRows = rowsToSave.filter((r) => getRowId(r) >= firstFakeId);
      return Promise.all([
        newRows.length && !!create ? create(newRows) : Promise.resolve(),
        oldRows.length && !!update ? update(oldRows) : Promise.resolve(),
      ]);
    },
    onSuccess: () => {
      setChangedRows([]);
      if (refetch) {
        return refetch();
      } else {
        console.error('no refetch method');
      }
    },
  });

  const handleSave = () => {
    mutation.mutate(changedRows);
  };

  const handleAdd = onAddButtonClick
    ? () => {
        const newRowOrUndefined = onAddButtonClick(fakeId);
        if (newRowOrUndefined) {
          setFakeId((cur) => cur + 1);
          setChangedRows([...changedRows, newRowOrUndefined]);
          setRowSelectionModel([fakeId]);
        }
      }
    : undefined;

  const handleRemove = onAddButtonClick
    ? () => {
        const selectedRow = rowSelectionModel.length && rowSelectionModel[0];
        if (typeof selectedRow === 'number' && selectedRow >= firstFakeId) {
          setChangedRows(changedRows.filter((d) => getRowId(d) !== selectedRow));
        } else if (onRemoveSavedRow) {
          onRemoveSavedRow(data?.find((d) => getRowId(d) === selectedRow)).then(() => {
            if (refetch) {
              refetch();
            } else {
              console.error('no refetch method');
            }
          });
        } else {
          console.log('unable to remove row');
        }
      }
    : undefined;

  const validateAndSetChangedRows = (unvalidated: GridValidRowModel[]) => {
    const unvalidatedUndeletedRows = unvalidated.filter((d) => getRowId(d) !== undefined);
    const changedIds = unvalidatedUndeletedRows.map(getRowId);
    const allRows = unvalidatedUndeletedRows.concat(
      (data || []).filter((d) => !changedIds.includes(getRowId(d)))
    );
    const validated = unvalidatedUndeletedRows.map((r) => {
      let errors: string[] | null = null;
      try {
        schema?.validateSync(r, { abortEarly: false });
      } catch (ex) {
        const err = ex as ValidationError;
        errors = err.errors;
      }
      (uniqueFields || []).forEach((f) => {
        if (
          allRows
            .filter((ar) => getRowId(ar) !== getRowId(r))
            .map((ar) => ar[f])
            .includes(r[f])
        ) {
          errors = (errors || []).concat(`${f} must be unique`);
        }
      });

      return {
        ...r,
        errors: errors,
      };
    });
    setChangedRows(validated.sort((a, b) => getRowId(a) - getRowId(b)));
  };

  const badgeProps: { badgeContent: string | number; color: 'error' | 'secondary' } =
    changedRows?.some((r) => r.errors && r.errors.length)
      ? { badgeContent: 'X', color: 'error' }
      : { badgeContent: changedRows?.length, color: 'secondary' };

  const initialState = sortField
    ? {
        sorting: {
          sortModel: [{ field: sortField, sort: 'asc' as GridSortDirection }],
        },
      }
    : undefined;

  return (
    <Container
      sx={fullWidth ? { minWidth: '100%' } : {}}
      disableGutters={fullWidth}
      data-testid="ChangeableDisplay"
    >
      <Typography
        sx={fullWidth ? { paddingLeft: '24px', minWidth: '100%' } : {}}
        variant="h2"
        mb="0.5em"
        role="heading"
      >
        <Badge {...badgeProps}>{pageTitle}</Badge>
      </Typography>
      <ChangeableGrid
        sx={fullWidth ? { minWidth: '100%' } : {}}
        columns={columns}
        initialRows={data || []}
        isLoading={isPending}
        getRowId={getRowId}
        onSave={handleSave}
        onAdd={handleAdd}
        onRemove={handleRemove}
        changedRows={changedRows}
        setChangedRows={validateAndSetChangedRows}
        onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        gridFilter={gridFilter}
        initialState={initialState}
        isCellEditable={isCellEditable}
        infoIndicator={infoIndicator}
        canRemoveAny={!!onRemoveSavedRow}
      />
      {!hideUnsavedPrompt ? <UnsavedChangesPrompt changedRows={changedRows} /> : null}
      {modalEdit &&
        modalEdit((oneRow) =>
          setChangedRows((prev) => [
            oneRow,
            ...prev.filter((p) => getRowId(p) !== getRowId(oneRow)),
          ])
        )}
    </Container>
  );
};

export default ChangeableDisplay;
