import React, { FC, PropsWithChildren } from 'react';
import { useRouteError } from 'react-router-dom';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

interface ErrorPageProps {
  error?: string;
}

const ErrorPage: FC<PropsWithChildren<ErrorPageProps>> = ({ error, children }) => {
  const routeError = useRouteError();
  console.error({ error, routeError });

  const errorMessage = error || 'An unexpected error has occurred.';

  return (
    <Container style={{ padding: '24px' }} id="error-page">
      <Typography variant="h2" mb="0.5em" color="error">
        Error
      </Typography>
      {children || (
        <Typography color="error" paragraph>
          {errorMessage}
        </Typography>
      )}
    </Container>
  );
};

export default ErrorPage;
