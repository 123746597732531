/* eslint-disable */
import { FC, useContext, useState } from 'react';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import {
  dateValueFormatter,
  errorCellClassName,
  yesNoGridColTypeDef,
} from '../../../../components';
import ChangeableGrid from '../../../../components/ChangeableGrid/ChangeableGrid';
import BrokerEntryModalWrapper from '../BrokerEntryDetails/BrokerEntryModalWrapper';
import { Link } from '@mui/material';
import { UserContext } from '../../../../Context';
import { PositionAdministratorRole } from '../../../../app/UserWrapper/UserWrapper';
import {
  BrokerEntryResponse,
  CreateBrokerEntryRequest,
  UpdateBrokerEntryRequest,
  DivisionRefResponse,
  UpdateCommissionRateRequest,
  CreateCommissionRateRequest,
  DeleteCommissionRateRequest,
  CreateBrokerEntryResponse,
} from '../../../../services/backend/data-contracts';
import { RequestParams } from '../../../../services/backend/http-client';
import { AxiosResponse } from 'axios';
import {
  FuturesBrokerResponse,
  FuturesContractsSummaryResponse,
} from '../../../../services/backend/data-contracts';

export interface BrokerEntriesGridProps {
  futuresContracts?: FuturesContractsSummaryResponse['result'];
  divisions?: DivisionRefResponse['result'];
  brokers?: FuturesBrokerResponse['result'];
  userName: string;
  rows: GridRowsProp[];
  loading: boolean;
  rowCountState?: number;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  createBrokerEntry: (
    data: CreateBrokerEntryRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<CreateBrokerEntryResponse, any>>;
  getBrokerEntry: (
    id: string,
    params?: RequestParams
  ) => Promise<AxiosResponse<BrokerEntryResponse, any>>;
  updateBrokerEntry: (
    id: string,
    data: UpdateBrokerEntryRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  updateCommissionRate: (
    data: UpdateCommissionRateRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  createCommissionRate: (
    data: CreateCommissionRateRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  deleteCommissionRate: (
    data: DeleteCommissionRateRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  refetchFilteredBrokerEntries: () => void;
}

const BrokerEntriesGrid: FC<BrokerEntriesGridProps> = ({
  userName,
  rows,
  loading,
  rowCountState,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  createBrokerEntry,
  getBrokerEntry,
  updateBrokerEntry,
  futuresContracts,
  brokers,
  updateCommissionRate,
  createCommissionRate,
  deleteCommissionRate,
  refetchFilteredBrokerEntries,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedEntryId, setSelectedEntryId] = useState<number | undefined>();
  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedEntryId(undefined);
  };

  const userContext = useContext(UserContext);
  const canCreate = userContext.haveRole([PositionAdministratorRole]);
  let handleAdd, editLink;
  if (canCreate) {
    handleAdd = () => {
      setSelectedEntryId(undefined);
      setIsModalOpen(true);
    };
    editLink = ({ value, row }: GridRenderCellParams) => (
      <Link
        onClick={() => {
          setIsModalOpen(true);
          setSelectedEntryId(row.brokerId);
        }}
        sx={{ cursor: 'pointer' }}
        data-testid="LinkToModal"
      >
        {value || '______'}
      </Link>
    );
  } else {
    editLink = ({ value }: GridRenderCellParams) => value || '______';
  }
  const columns: GridColDef[] = [
    {
      field: 'brokerCode',
      headerName: 'Broker Code',
      flex: 1,
      type: 'number',
      editable: false,
      align: 'left',
      headerAlign: 'left',
      cellClassName: errorCellClassName,
      renderCell: editLink,
    },
    {
      field: 'brokerName',
      headerName: 'Broker Name',
      flex: 1,
      type: 'number',
      editable: false,
      align: 'left',
      headerAlign: 'left',
      cellClassName: errorCellClassName,
    },
    {
      field: 'glAccount',
      headerName: 'GL Account',
      flex: 1,
      type: 'number',
      editable: false,
      align: 'left',
      headerAlign: 'left',
      cellClassName: errorCellClassName,
    },
    {
      field: 'glCompanyCode',
      headerName: 'GL Company',
      flex: 1,
      type: 'number',
      editable: false,
      align: 'left',
      headerAlign: 'left',
      cellClassName: errorCellClassName,
    },
    {
      field: 'divisionName',
      headerName: 'Division',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 1,
      type: 'boolean',
      editable: false,
      cellClassName: errorCellClassName,
      renderCell: ({ value }) => {
        if (value) return 'Yes';
        else return 'No';
      },
    },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
  ];
  return (
    <div data-testid="BrokerEntriesGrid">
      <ChangeableGrid
        initialRows={rows}
        isLoading={loading}
        columns={columns}
        rowCount={rowCountState}
        onAdd={handleAdd}
        pageSizeOptions={[25, 50, 100]}
        paginationModel={paginationModel}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        onProcessRowUpdateError={console.error}
      />
      {isModalOpen && (
        <BrokerEntryModalWrapper
          futuresContracts={futuresContracts}
          isModalOpen={isModalOpen}
          selectedEntryId={selectedEntryId}
          getBrokerEntry={getBrokerEntry}
          createBrokerEntry={createBrokerEntry}
          updateBrokerEntry={updateBrokerEntry}
          onClose={handleModalClose}
          userName={userName}
          brokers={brokers}
          updateCommissionRate={updateCommissionRate}
          createCommissionRate={createCommissionRate}
          deleteCommissionRate={deleteCommissionRate}
          refetchFilteredBrokerEntries={refetchFilteredBrokerEntries}
        ></BrokerEntryModalWrapper>
      )}
    </div>
  );
};

export default BrokerEntriesGrid;
