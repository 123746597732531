import { AuthState } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

import { securityWorker } from '../../models/Security';
import { CashAdjustment } from '../../services/backend/CashAdjustment';

export const useCashAdjustment: () => CashAdjustment<AuthState> = () => {
  const { oktaAuth } = useOktaAuth();
  const dataClient = new CashAdjustment({
    securityWorker,
    baseURL: process.env.REACT_APP_BE_URL,
  });
  if (oktaAuth.authStateManager.getAuthState()?.isAuthenticated) {
    dataClient.setSecurityData(oktaAuth.authStateManager.getAuthState());
  }
  oktaAuth.authStateManager.subscribe((authState) => {
    dataClient.setSecurityData(authState);
  });
  return dataClient;
};
