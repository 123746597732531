import React from 'react';

import BrokerEntriesDisplay from './BrokerEntriesDisplay/BrokerEntriesDisplay';
import useBrokerEntries from './useBrokerEntries';

export default function BrokerEntriesPage() {
  const {
    userName,
    rowCountState,
    rows,
    isLoadingRows,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
    createBrokerEntry,
    getBrokerEntry,
    updateBrokerEntry,
    futuresContracts,
    brokers,
    updateCommissionRate,
    createCommissionRate,
    deleteCommissionRate,
    refetchFilteredBrokerEntries,
  } = useBrokerEntries();

  return (
    <>
      <main data-testid="BrokersPage" style={{ minWidth: 1400, padding: '24px' }}>
        <BrokerEntriesDisplay
          userName={userName}
          futuresContracts={futuresContracts}
          brokers={brokers}
          rows={rows}
          loading={isLoadingRows}
          rowCountState={rowCountState}
          paginationModel={paginationModel}
          setPaginationModel={onPaginationModelChange}
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          getBrokerEntry={getBrokerEntry}
          createBrokerEntry={createBrokerEntry}
          updateBrokerEntry={updateBrokerEntry}
          updateCommissionRate={updateCommissionRate}
          createCommissionRate={createCommissionRate}
          deleteCommissionRate={deleteCommissionRate}
          refetchFilteredBrokerEntries={refetchFilteredBrokerEntries}
        ></BrokerEntriesDisplay>
      </main>
    </>
  );
}
