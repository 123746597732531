import { useContext, useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

import {
  FuturesUserRole,
  GrainTraderRole,
  GranelerasTraderRole,
  MillingTraderRole,
  SuperUserRole,
} from '../../../../app/UserWrapper/UserWrapper';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { UserContext } from '../../../../Context';
import FuturesContractDetailsPage from '../FuturesContractDetails/FuturesContractDetailsPage';

import FuturesContractsDisplay from './FuturesContractsDisplay/FuturesContractsDisplay';
import useFuturesContractsPage from './useFuturesContractsPage';

export default function FuturesContractsListPage() {
  useEffect(() => {
    document.title = 'Futures Contracts | Positions and Futures';
  }, []);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const pageData = useFuturesContractsPage();
  const [selectedEntry, setSelectedEntry] = pageData.selectedEntryState;

  const userContext = useContext(UserContext);
  if (!userContext.userName) {
    return (
      <>
        <main style={{ padding: '24px' }} data-testid="FuturesContractsListPage">
          <h2>Futures Contracts Page</h2>
          <CircularProgress />
        </main>
      </>
    );
  }
  const hasViewFuturesContractsPermission = userContext.haveRole([
    GrainTraderRole,
    GranelerasTraderRole,
    MillingTraderRole,
    SuperUserRole,
    FuturesUserRole,
  ]);

  if (!hasViewFuturesContractsPermission) {
    return (
      <>
        <main style={{ padding: '24px' }} data-testid="FuturesContractsListPage">
          <h2>Futures Contracts Page</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  const handleNewButtonClick = () => {
    setSelectedEntry('');
    setIsDetailsModalOpen(true);
  };
  const handleEditButtonClick = (id: string) => {
    setSelectedEntry(id);
    setIsDetailsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsDetailsModalOpen(false);
    pageData.detailsQuery.refetch();
    pageData.listQuery.refetch();
  };

  return (
    <>
      <main data-testid="FuturesContractsListPage" style={{ minWidth: 1400, padding: '24px' }}>
        <FuturesContractsDisplay
          userName={pageData.userName}
          listQuery={pageData.listQuery}
          paginationModel={pageData.paginationModel}
          setPaginationModel={pageData.onPaginationModelChange}
          onSortModelChange={pageData.onSortModelChange}
          onFilterModelChange={pageData.onFilterModelChange}
          onNewButtonClick={handleNewButtonClick}
          onEditButtonClick={handleEditButtonClick}
        ></FuturesContractsDisplay>

        {
          // I'm thinking of moving the isPending check to be inside the details page
          isDetailsModalOpen && !!selectedEntry && pageData.detailsQuery.isPending ? (
            <LoadingSpinner />
          ) : isDetailsModalOpen ? (
            <FuturesContractDetailsPage
              isOpen={isDetailsModalOpen}
              isEditingExistingEntry={!!selectedEntry}
              onClose={handleModalClose}
              existingContractDetails={pageData.detailsQuery.data}
            ></FuturesContractDetailsPage>
          ) : null
        }
      </main>
    </>
  );
}
