import { useEffect, useState } from 'react';

import { UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

export const useOktaUser = () => {
  const [user, setUser] = useState<UserClaims | null>(null);

  const { authState } = useOktaAuth();

  useEffect(() => {
    setUser((authState?.isAuthenticated && authState?.idToken?.claims) || null);
  }, [authState]); // Update if authState changes

  return { user };
};
