import { createTheme } from '@mui/material/styles';

// export const primaryDark = '#0b74a5';
// export const primaryMain = '#10a6ed';
// export const primaryLight = '#3fb7f0';
// const primaryLight2 = '#33c0ff';
// const primaryExtraLight = '#71d3ff';
// export const secondaryMain = '#3c4481';
// export const secondaryDark = '#2a2f5a';
// export const secondaryLight = '#63699a';
// export const errorDark = '#91373a';
// export const errorLight = '#d97275';
// export const errorMain = '#d04f53';
// export const warningMain = '#f19c00';
// export const warningDark = '#a86d00';
// export const warningLight = '#f3af33';
// export const infoDark = '#0e3f80';
// export const infoMain = '#155bb7';
// export const infoLight = '#437bc5';
// export const successMain = '#4caf50';
// export const successDark = '#357a38';
// export const successLight = '#6fbf73';
// const misc01Dark = '#85277b';
// const misc01Main = '#be39b1';
// const misc01Light = '#cb60c0';
// const neutral06 = '#000';
// export const neutral05 = '#424242';
// export const neutral04 = '#5f6367';
// export const neutral03 = '#a0a0a0';
// export const neutral02 = '#f3f3f3';
// export const neutral01 = '#fff';

export const lightTheme = createTheme({});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

//   typography: {
//     allVariants: {
//       fontFamily: [
//         'Open Sans',
//         'Segoe UI',
//         'Roboto',
//         'Oxygen',
//         'Ubuntu',
//         'Cantarell',
//         'Fira Sans',
//         'Droid Sans',
//         'Helvetica Neue',
//       ].join(','),
//     },
//   },
//   palette: {
//     background: {
//       default: neutral02,
//       paper: neutral01,
//     },
//     primary: {
//       main: primaryMain,
//       light: primaryLight,
//       dark: primaryDark,
//       contrastText: neutral01,
//     },
//     secondary: {
//       main: secondaryMain,
//       light: secondaryLight,
//       dark: secondaryDark,
//       contrastText: neutral01,
//     },
//     error: {
//       main: errorMain,
//       light: errorLight,
//       dark: errorDark,
//       contrastText: neutral01,
//     },
//     warning: {
//       main: warningMain,
//       light: warningLight,
//       dark: warningDark,
//       contrastText: neutral01,
//     },
//     info: {
//       main: infoMain,
//       light: infoLight,
//       dark: infoDark,
//       contrastText: neutral01,
//     },
//     success: {
//       main: successMain,
//       light: successLight,
//       dark: successDark,
//       contrastText: neutral01,
//     },
//     grey: {
//       A100: neutral02, // Neutral 02
//       A200: neutral03, // Neutral 03
//       A400: neutral04, // Neutral 04
//       A700: neutral05, // Neutral 05
//     },
//   },
//   components: {
//     MuiAppBar: {
//       styleOverrides: {
//         colorPrimary: {
//           backgroundImage: 'linear-gradient(to bottom, #3b4679 0%, #242c4f 100%)',
//           boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
//         },
//       },
//     },
//     MuiDrawer: {
//       styleOverrides: {
//         paper: {
//           backgroundColor: secondaryMain,
//           color: neutral02,
//         },
//       },
//     },
//   },
// });
