import { FC, useState } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Breakpoint, Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';

export interface TransactionEntryModalProps {
  children: JSX.Element | JSX.Element[];
  isOpen: boolean;
  canSave: boolean;
  isCreateModal: boolean;
  onCreate: any;
  onUpdate: any;
  onDelete?: any;
  onClear: any;
  onCancel: any;
  maxWidth?: Breakpoint;
  formXs?: number;
  extraButtonText?: string;
  onExtraButtonClick?: () => void;
}

const TransactionEntryModal: FC<TransactionEntryModalProps> = ({
  children,
  isOpen,
  canSave: canSave,
  isCreateModal,
  onCreate,
  onUpdate,
  onDelete,
  onClear,
  onCancel,
  maxWidth = 'md',
  formXs = 12,
  extraButtonText,
  onExtraButtonClick,
}) => {
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);

  const handleDeleteConfirmation = () => {
    onDelete();
    setDeleteConfirmationOpen(false);
  };
  return (
    <Dialog open={children && isOpen} fullWidth maxWidth={maxWidth}>
      <DialogContent>{Array.isArray(children) ? children[0] : children}</DialogContent>
      <DialogActions>
        <Grid container>
          <Grid container item xs={formXs} direction="row">
            {onDelete && !isCreateModal ? (
              <Button
                tabIndex={-1}
                onClick={() => setDeleteConfirmationOpen(true)}
                color="error"
                disabled={!canSave}
              >
                <DeleteForeverIcon />
              </Button>
            ) : null}

            <div style={{ flex: '1 0 0' }} />

            {isCreateModal ? (
              <>
                <Button variant="contained" onClick={onCreate} disabled={!canSave}>
                  Create
                </Button>
                <Button onClick={onClear} disabled={!canSave}>
                  Clear
                </Button>
              </>
            ) : (
              <>
                <Button variant="contained" onClick={onUpdate} disabled={!canSave}>
                  Update
                </Button>
              </>
            )}
            <Button onClick={onCancel} disabled={!canSave}>
              Cancel
            </Button>
            {extraButtonText && onExtraButtonClick ? (
              <Button variant="contained" onClick={onExtraButtonClick} disabled={!canSave}>
                {extraButtonText}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </DialogActions>

      {Array.isArray(children) && children.length >= 2 ? (
        <DialogContent>{children[1]}</DialogContent>
      ) : null}

      <Dialog open={isDeleteConfirmationOpen}>
        <DialogContent>Are you sure you want to delete this Entry?</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmation} disabled={!canSave} color="error">
            Delete
          </Button>
          <div style={{ flex: '1 0 0' }} />
          <Button onClick={() => setDeleteConfirmationOpen(false)} disabled={!canSave}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default TransactionEntryModal;
