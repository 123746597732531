import * as yup from 'yup';

export const LOCATION_REQUIRED = 'Location Required';
export const COMMODITY_REQUIRED = 'Commodity Required';
export const PURCHASE_SALE_REQUIRED = 'Purchase or Sale Required';
export const TRANSACTION_TYPE_REQUIRED = 'Transaction Type Required';
export const QUANTITY_REQUIRED = 'Quantity Required';
export const FLAT_PRICE_REQUIRED = 'Flat Price Required';
export const MONTH_REQUIRED = 'Month Required';
export const FUTURES_PRICE_REQUIRED = 'Futures Price Required';

export const COMMENT_CONTRACT_CUSTOMER_REQUIRED =
  'At least one of the following must be provided: Contract, Customer or Comment';
export const PRICE_TOTAL = 'Flat Price must equal Futures Price + Basis Price';

export const QUANTITY_BOUNDS = 'Quantity must be between -99,999,999.99 and 99,999,999.99';
export const QUANTITY_NOT_ZERO = 'Quantity Cannot be 0';
export const FLAT_PRICE_BOUNDS =
  'The Flat Price should be a positive value between $0.00001 and $999.99999';
export const FUTURES_PRICE_BOUNDS =
  'The Futures Price should be a positive value between $0.00001 and $999.99999';
export const BASIS_PRICE_BOUNDS =
  'The Basis Price should be a positive value between -$999.99999 and $999.99999';
export const PURCHASE_NEGATIVE_QUANTITY_WARNING =
  'Purchases are usually associated with a positive quantity.  Please review Purchase Sale Type and Quantity.';
export const SALE_POSITIVE_QUANTITY_WARNING =
  'Sales are usually associated with a negative quantity.  Please review Purchase Sale Type and Quantity';

export const validationSchema = yup.object().shape({
  locationId: yup.string().required(LOCATION_REQUIRED),
  commodityId: yup.string().required(COMMODITY_REQUIRED),
  purchaseSale: yup.string().required(PURCHASE_SALE_REQUIRED),
  contract: yup.string().when(['customer', 'comment'], {
    is: (customer: string, comment: string) => !customer && !comment,
    then: (schema) => schema.required(COMMENT_CONTRACT_CUSTOMER_REQUIRED),
    otherwise: (schema) => schema.notRequired(),
  }),
  customer: yup.string().notRequired(),
  transactionTypeId: yup.string().required(TRANSACTION_TYPE_REQUIRED),
  quantity: yup
    .number()
    .moreThan(-100000000, QUANTITY_BOUNDS)
    .lessThan(100000000, QUANTITY_BOUNDS)
    .test({
      skipAbsent: true,
      message: QUANTITY_NOT_ZERO,
      test: (value) => value !== 0,
    })
    .required(QUANTITY_REQUIRED),
  flatPrice: yup
    .number()
    .moreThan(0, FLAT_PRICE_BOUNDS)
    .lessThan(1000, FLAT_PRICE_BOUNDS)
    .required(FLAT_PRICE_REQUIRED)
    .when(['basisPrice', 'futuresPrice'], ([basisPrice, futuresPrice], schema) => {
      if (futuresPrice) {
        return schema.test('priceEquality', PRICE_TOTAL, (value) => {
          const flatInteger = (value ?? 0) * 100000;
          const basisInteger = (basisPrice ?? 0) * 100000;
          const futuresInteger = (futuresPrice ?? 0) * 100000;
          return flatInteger.toFixed(0) === (futuresInteger + basisInteger).toFixed(0);
        });
      } else {
        return schema;
      }
    }),
  basisPrice: yup
    .number()
    .moreThan(-1000, BASIS_PRICE_BOUNDS)
    .lessThan(1000, BASIS_PRICE_BOUNDS)
    .notRequired(),
  optionMonthId: yup.string().required(MONTH_REQUIRED),
  futuresPrice: yup
    .number()
    .moreThan(0, FUTURES_PRICE_BOUNDS)
    .lessThan(1000, FUTURES_PRICE_BOUNDS)
    .required(FUTURES_PRICE_REQUIRED),
  comment: yup.string().notRequired(),
});
