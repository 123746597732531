/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateSwapEntryRequest,
  SwapEntriesResponse,
  SwapEntryResponse,
  SwapFilteredEntriesResponse,
  UpdateSwapEntryRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SwapEntries<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetSwapEntries
   * @request GET:/swapEntries
   * @secure
   */
  getSwapEntries = (params: RequestParams = {}) =>
    this.request<SwapEntriesResponse, any>({
      path: `/swapEntries`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name CreateSwapEntry
   * @request POST:/swapEntries
   * @secure
   */
  createSwapEntry = (data: CreateSwapEntryRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/swapEntries`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsSwapEntries
   * @request OPTIONS:/swapEntries
   */
  optionsSwapEntries = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/swapEntries`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFilteredSwapEntries
   * @request GET:/swapEntries/filter
   * @secure
   */
  getFilteredSwapEntries = (
    query: {
      sortOrder?: string;
      limit: string;
      buyTradeAccountId?: string;
      sellCommodityId?: string;
      offset?: string;
      buyCommodityId?: string;
      search?: string;
      sortBy?: string;
      sellTradeAccountID?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<SwapFilteredEntriesResponse, any>({
      path: `/swapEntries/filter`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsSwapEntries2
   * @request OPTIONS:/swapEntries/filter
   * @originalName optionsSwapEntries
   * @duplicate
   */
  optionsSwapEntries2 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/swapEntries/filter`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetSwapEntry
   * @request GET:/swapEntries/{id}
   * @secure
   */
  getSwapEntry = (id: string, params: RequestParams = {}) =>
    this.request<SwapEntryResponse, any>({
      path: `/swapEntries/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateSwapEntry
   * @request PUT:/swapEntries/{id}
   * @secure
   */
  updateSwapEntry = (id: string, data: UpdateSwapEntryRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/swapEntries/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name DeleteSwapEntry
   * @request DELETE:/swapEntries/{id}
   * @secure
   */
  deleteSwapEntry = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/swapEntries/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsSwapEntries3
   * @request OPTIONS:/swapEntries/{id}
   * @originalName optionsSwapEntries
   * @duplicate
   */
  optionsSwapEntries3 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/swapEntries/${id}`,
      method: 'OPTIONS',
      ...params,
    });
}
