import React, { PropsWithChildren } from 'react';

import { useQuery } from '@tanstack/react-query';

import { UserContext } from '../../Context';
import { useOktaUser } from '../../hooks';
import { useWhoAmI } from '../../hooks/useWhoAmI';

export const AdministratorRole = 'Administrator';
export const CompuWeighRole = 'Compuweigh';
export const HedgeDeskRole = 'Hedge Desk';
export const PositionAdministratorRole = 'Position Administrator';
export const PositionClerkRole = 'Position Clerk';
export const PositionRecRole = 'Position Rec';
export const ViewOnlyRole = 'View Only';
export const FuturesMaintenanceRole = 'Futures Maintenance';
export const GrainTraderRole = 'Grain Trader';
export const GranelerasTraderRole = 'Graneleras Trader';
export const MillingTraderRole = 'Milling Trader';
export const FuturesUserRole = 'Futures User';
export const SuperUserRole = 'Super User';

const UserWrapper = ({ children }: PropsWithChildren) => {
  const whoAmIClient = useWhoAmI();
  const { user: oktaUser } = useOktaUser();
  const { data: currentUser } = useQuery({
    queryKey: ['whoami', oktaUser?.email],
    queryFn: async () => {
      if (oktaUser?.email) {
        return (await whoAmIClient.whoami({}))?.data.result;
      }
      return { roles: [] };
    },
  });
  ineum('user', currentUser?.userId + '', currentUser?.userName, currentUser?.userEmail);

  let isAdminUser = false;

  if (currentUser && currentUser.roles && currentUser.roles.length > 0) {
    isAdminUser = !!currentUser?.roles.find((t) => t.roleName === AdministratorRole);
  }
  // need to change the definition on backend to match the output
  if (!currentUser?.userName) {
    return <>{children}</>;
  }
  const haveRole = (requiredRoles: string[]): boolean => {
    if (isAdminUser) {
      return true;
    }
    if (requiredRoles.length === 0 || currentUser?.roles?.length === 0) {
      return false;
    }
    if (currentUser?.roles) {
      const userRoles = currentUser?.roles.map((t) => t.roleName);
      for (let i = 0; i < requiredRoles.length; i++) {
        for (let y = 0; y < userRoles.length; y++) {
          if (requiredRoles[i] == userRoles[y]) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const userContext = {
    userName: currentUser?.userName,
    roles: currentUser?.roles ? currentUser?.roles.map((t) => t.roleName) : [],
    haveRole,
  };
  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
};

export default UserWrapper;
