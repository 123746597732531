/* eslint-disable */
import { Box, Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useFormik, setNestedObjectValues, FormikTouched, FormikValues } from 'formik';
import { FC } from 'react';

import TransactionEntryModal from '../../../../components/TransactionEntryModal/TransactionEntryModal';
import { backendDataClients } from '../../../../hooks';
import { useToastMutation } from '../../../../hooks/useToastMutations';
import * as yup from 'yup';
import {
  DIVISION_REQUIRED,
  GL_ACCOUNT_LENGTH_ERROR,
  GL_COMPANY_LENGTH_ERROR,
  GL_SUBACCOUNT_FORMAT_ERROR,
  GL_SUBACCOUNT_REGEX,
  GL_SUBACCOUNT_REQUIRED_WHEN_GL_ACCOUNT,
  GRAIN_APC_POSITION_REPORTS_REQUIRED,
  GRAIN_POSITION_REPORTS_REQUIRED,
  IS_ACTIVE_REQUIRED,
  NUMBERTYPE_ERROR,
  TRADE_ACCOUNT_CODE_REQUIRED,
  TRADE_ACCOUNT_NAME_REQUIRED,
} from '../TradeAccountErrorMessages';

import { nanToUndefined } from '../../../../components/formUtils';
import DivisionAutocomplete from '../../../../components/DivisionAutocomplete/DivisionAutocomplete';

const { useTradeAccounts } = backendDataClients;

export interface TradeAccountDetailPageProps {
  isOpen: boolean;
  isEditingExistingEntry: boolean;
  onClose: () => void;
  existingTradeDetails: any;
}

const TradeAccountDetailsPage: FC<TradeAccountDetailPageProps> = ({
  isOpen,
  isEditingExistingEntry,
  onClose,
  existingTradeDetails,
}) => {
  const dataClient = useTradeAccounts();
  const updateMutation = useToastMutation({
    mutationFn: ({ id, data }: any) => {
      return dataClient.updateTradeAccount(id, data);
    },
    queryKey: ['TradeAccounts'],
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Trade Account Updated',
  });

  const createMutation = useToastMutation({
    mutationFn: dataClient.createTradeAccount,
    queryKey: ['TradeAccounts'],
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Trade Account Created',
  });

  const blankEntryDetails = {
    tradeAccountId: 0,
    tradeAccountCode: undefined,
    tradeAccountName: undefined,
    glAccount: undefined,
    glSubAccount: undefined,
    glCompanyCode: undefined,
    divisionId: undefined,
    grainPositionReports: false,
    grainAPCPositionReports: false,
    isActive: true,
  };

  const handleModalClose = async () => {
    onClose();
    await formik.setValues(blankEntryDetails);
    formik.setTouched({}, false);
    formik.setErrors({});
  };

  const handleClearClick = async () => {
    await formik.setValues(blankEntryDetails);
    formik.resetForm();
    // Trigger touched on all fields
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      // Form is valid, do any success call
    } else {
      formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
    }
  };
  const handleSaveClick = async () => {
    formik.submitForm();
  };
  const handleIsActiveChange = (event: SelectChangeEvent) => {
    let value;
    if (event.target.value === 'true') value = true;
    else if (event.target.value === 'false') value = false;
    formik.setFieldValue('isActive', value, true);
  };

  const handleGrainChange = (event: SelectChangeEvent) => {
    let value;
    if (event.target.value === 'true') value = true;
    else if (event.target.value === 'false') value = false;
    formik.setFieldValue('grainPositionReports', value, true);
  };

  const handleGrainAPCChange = (event: SelectChangeEvent) => {
    let value;
    if (event.target.value === 'true') value = true;
    else if (event.target.value === 'false') value = false;
    formik.setFieldValue('grainAPCPositionReports', value, true);
  };

  let initialValues = blankEntryDetails;
  if (existingTradeDetails) {
    initialValues = {
      tradeAccountId: existingTradeDetails.tradeAccountId,
      tradeAccountCode: existingTradeDetails.tradeAccountCode,
      tradeAccountName: existingTradeDetails.tradeAccountName,
      glAccount: existingTradeDetails.glAccount,
      glSubAccount: existingTradeDetails.glSubAccount,
      glCompanyCode: existingTradeDetails.glCompanyCode,
      divisionId: existingTradeDetails.divisionId,
      grainPositionReports: existingTradeDetails.grainPositionReports,
      grainAPCPositionReports: existingTradeDetails.grainAPCPositionReports,
      isActive: existingTradeDetails.isActive,
    };
  }

  const doSave = (currentDetails: any) => {
    const entry = {
      tradeAccountId: currentDetails.tradeAccountId,
      tradeAccountCode: currentDetails.tradeAccountCode,
      tradeAccountName: currentDetails.tradeAccountName,
      glAccount: currentDetails.glAccount,
      glSubAccount: currentDetails.glSubAccount,
      glCompanyCode: currentDetails.glCompanyCode,
      divisionId: Number(currentDetails.divisionId),
      grainPositionReports: currentDetails.grainPositionReports,
      grainAPCPositionReports: currentDetails.grainAPCPositionReports,
      isActive: currentDetails.isActive,
    };

    if (existingTradeDetails) {
      updateMutation.mutate(
        {
          id: existingTradeDetails.tradeAccountId,
          data: { entry },
        },
        { onSuccess: () => handleModalClose() }
      );
    } else {
      createMutation.mutate({ entry });
      formik.setValues(blankEntryDetails, false);
      formik.setTouched({});
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      tradeAccountCode: yup.string().required(TRADE_ACCOUNT_CODE_REQUIRED),
      tradeAccountName: yup.string().required(TRADE_ACCOUNT_NAME_REQUIRED),
      glAccount: yup
        .string()
        .nullable()
        .test(
          'glAccount',
          'glAccount ' + NUMBERTYPE_ERROR,
          (str) => !str || Number(str).toString() !== 'NaN'
        )
        .test('glAccount', GL_ACCOUNT_LENGTH_ERROR, (val) => !val || val.length === 7),
      glSubAccount: yup
        .string()
        .nullable()
        .matches(GL_SUBACCOUNT_REGEX, GL_SUBACCOUNT_FORMAT_ERROR)
        .test({
          test: () => hasGlAccount(),
          message: GL_SUBACCOUNT_REQUIRED_WHEN_GL_ACCOUNT,
        }),
      glCompanyCode: yup
        .string()
        .nullable()
        .test(
          'glCompanyCode',
          'glCompanyCode ' + NUMBERTYPE_ERROR,
          (str) => !str || Number(str).toString() !== 'NaN'
        )
        .test('glCompanyCode', GL_COMPANY_LENGTH_ERROR, (val) => !val || val.length === 3),
      divisionId: yup.number().transform(nanToUndefined).required(DIVISION_REQUIRED),
      grainPositionReports: yup.string().required(GRAIN_POSITION_REPORTS_REQUIRED),
      grainAPCPositionReports: yup.string().required(GRAIN_APC_POSITION_REPORTS_REQUIRED),
      isActive: yup.boolean().required(IS_ACTIVE_REQUIRED),
    }),
    onSubmit: () => {
      doSave({ ...formik.values });
    },
  });

  function hasGlAccount(): boolean {
    return formik.values.glAccount ? (formik.values.glSubAccount ? true : false) : true;
  }

  const changeAndValidate = (name: string) => {
    return async (e: any, value: any) => {
      await formik.setFieldValue(name, value, true);
      formik.setFieldTouched(name);
    };
  };

  const labelWidth = 6;
  const inputWidth = 6;
  return (
    <TransactionEntryModal
      isOpen={isOpen}
      canSave={!updateMutation.isPending && !createMutation.isPending}
      isCreateModal={!isEditingExistingEntry}
      onCancel={handleModalClose}
      onClear={handleClearClick}
      onCreate={handleSaveClick}
      onUpdate={handleSaveClick}
    >
      <Box data-testid="TradeAccountDetailsPage">
        {!formik.values ? null : (
          <form autoComplete="off">
            <Grid container spacing={3}>
              <Grid container item spacing={1} xs={7}>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    <label>Trade Account Code:</label>
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      type="text"
                      fullWidth
                      id="tradecode-input"
                      size="small"
                      name="tradeAccountCode"
                      value={formik.values.tradeAccountCode ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 6 }}
                      error={
                        formik.touched.tradeAccountCode && Boolean(formik.errors.tradeAccountCode)
                      }
                      helperText={
                        formik.touched.tradeAccountCode &&
                        formik.errors.tradeAccountCode?.toString()
                      }
                      InputProps={{ 'aria-labelledby': 'tradescode-input-label' }}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    <label>Trade Account Name:</label>
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      type="text"
                      fullWidth
                      id="tradename-input"
                      size="small"
                      name="tradeAccountName"
                      value={formik.values.tradeAccountName ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 35 }}
                      error={
                        formik.touched.tradeAccountName && Boolean(formik.errors.tradeAccountName)
                      }
                      helperText={
                        formik.touched.tradeAccountName &&
                        formik.errors.tradeAccountName?.toString()
                      }
                      InputProps={{ 'aria-labelledby': 'tradesname-input-label' }}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth}>
                    <label>GL Account:</label>
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      type="text"
                      fullWidth
                      id="glaccount-input"
                      size="small"
                      name="glAccount"
                      value={formik.values.glAccount ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 7 }}
                      error={formik.touched.glAccount && Boolean(formik.errors.glAccount)}
                      helperText={formik.touched.glAccount && formik.errors.glAccount?.toString()}
                      InputProps={{ 'aria-labelledby': 'glaccount-input-label' }}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth}>
                    <label>GL SubAccount:</label>
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      type="text"
                      fullWidth
                      id="glsubaccount-input"
                      size="small"
                      name="glSubAccount"
                      value={formik.values.glSubAccount ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 9 }}
                      error={formik.touched.glSubAccount && Boolean(formik.errors.glSubAccount)}
                      helperText={
                        formik.touched.glSubAccount && formik.errors.glSubAccount?.toString()
                      }
                      InputProps={{ 'aria-labelledby': 'glsubaccount-input-label' }}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth}>
                    <label>GL Company:</label>
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      type="text"
                      fullWidth
                      id="glcompanycode-input"
                      size="small"
                      name="glCompanyCode"
                      value={formik.values.glCompanyCode ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 3 }}
                      error={formik.touched.glCompanyCode && Boolean(formik.errors.glCompanyCode)}
                      helperText={
                        formik.touched.glCompanyCode && formik.errors.glCompanyCode?.toString()
                      }
                      InputProps={{ 'aria-labelledby': 'glcompanycode-input-label' }}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    <label>Division: </label>
                  </Grid>
                  <Grid item xs={6}>
                    <DivisionAutocomplete formik={formik} />
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" xs={12}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }} id="grain-label">
                    Grain Position Reports:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <Select
                      value={formik.values.grainPositionReports + ''}
                      size="small"
                      onChange={handleGrainChange}
                    >
                      <MenuItem value="true">Include Open Position</MenuItem>
                      <MenuItem value="false">Not Applicable</MenuItem>
                    </Select>
                    {formik.errors.grainPositionReports && formik.touched.grainPositionReports && (
                      <div id="feedback">{formik.errors.grainPositionReports?.toString()}</div>
                    )}
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" xs={12}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }} id="grain-apc-label">
                    Grain APC Position Reports:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <Select
                      value={formik.values.grainAPCPositionReports + ''}
                      size="small"
                      onChange={handleGrainAPCChange}
                    >
                      <MenuItem value="true">Include Open Position</MenuItem>
                      <MenuItem value="false">Not Applicable</MenuItem>
                    </Select>
                    {formik.errors.grainAPCPositionReports &&
                      formik.touched.grainAPCPositionReports && (
                        <div id="feedback">{formik.errors.grainAPCPositionReports?.toString()}</div>
                      )}
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" xs={12}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }} id="is-active-label">
                    Active:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <Select
                      value={formik.values.isActive + ''}
                      size="small"
                      onChange={handleIsActiveChange}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                    {formik.errors.isActive && formik.touched.isActive && (
                      <div id="feedback">{formik.errors.isActive?.toString()}</div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
    </TransactionEntryModal>
  );
};

export default TradeAccountDetailsPage;
