import { useEffect, useState } from 'react';

import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { useQuery } from '@tanstack/react-query';

import {
  backendDataClients,
  useFuturesBrokersQuery,
  useFuturesContractsQuery,
  useOktaUser,
} from '../../../hooks';

const {
  useBrokerEntries: useBrokerEntriesDataClient,
  useCommissionRateMassCreates,
  useCommissionRateMassUpdates,
  useCommissionRateMassDeletes,
} = backendDataClients;

export interface BrokerEntriesFilterForm {
  sortOrder?: string;
  limit: number;
  offset?: number;
  search?: string;
  sortBy?: string;
}

export default function useBrokerEntries() {
  const { user } = useOktaUser();
  const userName = user?.preferred_username ?? '';

  const filterFormState = useState<BrokerEntriesFilterForm>({
    sortOrder: '',
    limit: 50,
    offset: 0,
    search: '',
    sortBy: '',
  });

  const [filterForm, setFilterForm] = filterFormState;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: filterForm.limit,
  });

  const dataClient = useBrokerEntriesDataClient();
  const commissionRateMassCreates = useCommissionRateMassCreates();
  const commissionRateMassUpdates = useCommissionRateMassUpdates();
  const commissionRateMassDeletes = useCommissionRateMassDeletes();
  const futuresContracts =
    useFuturesContractsQuery().data?.sort((a: any, b: any) =>
      (a.futuresContractValue ?? '').localeCompare(b.futuresContractValue ?? '')
    ) ?? [];

  const brokers = useFuturesBrokersQuery()?.data?.sort((a, b) =>
    (a.brokerName ?? '').localeCompare(b.brokerName ?? '')
  );

  const queryParams = Object.assign(
    {
      limit: filterForm.limit + '', // number of rows per page
      offset: filterForm.offset + '', // what row to start at
    },
    filterForm.search === '' ? null : { search: filterForm.search },
    filterForm.sortOrder === '' ? null : { sortOrder: filterForm.sortOrder },
    filterForm.sortBy === '' ? null : { sortBy: filterForm.sortBy }
  );
  const brokerEntriesQuery = useQuery({
    enabled: true,
    queryKey: ['BrokerEntries', filterForm],
    queryFn: () => dataClient.getFilteredBrokerEntries(queryParams),
    select: (response) => response?.data?.result,
  });
  const brokerEntries = brokerEntriesQuery.data;
  let rows: GridRowsProp[] = [];
  if (brokerEntries?.entries) {
    rows = brokerEntries.entries.map((t) => {
      return { id: t.brokerId, ...t } as unknown as GridRowsProp;
    });
  }

  const [rowCountState, setRowCountState] = useState(brokerEntries?.totalCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      brokerEntries?.totalCount !== undefined ? brokerEntries?.totalCount : prevRowCountState
    );
  }, [brokerEntries?.totalCount, setRowCountState]);
  const onPaginationModelChange = (pageChange: GridPaginationModel) => {
    if (!isNaN(pageChange.page) && !isNaN(pageChange.pageSize)) {
      const offset = +pageChange.page * +pageChange.pageSize;
      setFilterForm({ ...filterForm, limit: pageChange.pageSize, offset });
      setPaginationModel(pageChange);
    }
  };
  const onSortModelChange = (model: GridSortModel) => {
    if (model[0] && model[0].field && model[0].sort) {
      const sortBy = model[0].field;
      setFilterForm({ ...filterForm, sortBy: sortBy, sortOrder: model[0].sort });
    } else {
      setFilterForm({ ...filterForm, sortOrder: '', sortBy: '' });
    }
  };
  const onFilterModelChange = (model: GridFilterModel) => {
    if (model && model.quickFilterValues && model.quickFilterValues.length > 0) {
      setFilterForm({ ...filterForm, search: model.quickFilterValues[0] });
    } else {
      setFilterForm({ ...filterForm, search: '' });
    }
  };

  useEffect(() => {
    brokerEntriesQuery.refetch();
  }, [brokerEntriesQuery]);

  return {
    filterForm,
    rowCountState,
    setFilterForm,
    rows,
    isLoadingRows: brokerEntriesQuery.isPending,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
    userName,
    createBrokerEntry: dataClient.createBrokerEntry,
    getBrokerEntry: dataClient.getBrokerEntry,
    updateBrokerEntry: dataClient.updateBrokerEntry,
    updateCommissionRate: commissionRateMassUpdates.updateCommissionRate,
    deleteCommissionRate: commissionRateMassDeletes.deleteCommissionRate,
    createCommissionRate: commissionRateMassCreates.createCommissionRate,
    futuresContracts,
    brokers,
    refetchFilteredBrokerEntries: brokerEntriesQuery.refetch,
  };
}
