import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GridValidRowModel } from '@mui/x-data-grid';

import { SmallChangeableGrid } from '../../../../components';
import { CommoditiesSummaryResponse } from '../../../../services/backend/data-contracts';

export interface FuturesContractDetailsProps {
  futuresContract: any | null;
  commodities?: CommoditiesSummaryResponse['result'];
  setShowEditForRow?: any;
}

const FuturesContractDetails: FC<FuturesContractDetailsProps> = ({
  futuresContract,
  commodities,
  setShowEditForRow,
}) => {
  const handleChange = (change: SelectChangeEvent) => {
    setShowEditForRow &&
      setShowEditForRow((prev: GridValidRowModel) => ({
        ...prev,
        isActive: !!change.target.value,
      }));
  };
  const handleCommoditiesChange = (newCommodities: GridValidRowModel[]) => {
    setShowEditForRow &&
      setShowEditForRow((prev: GridValidRowModel) => ({
        ...prev,
        newCommodities: newCommodities
          .filter((c) => !c.remove)
          .map((c) => ({
            ...commodities?.find((r) => r.commodityCode === c.commodityCode),
            changed: c.changed,
          })),
      }));
  };
  return (
    <Box data-testid="FuturesContractEdit">
      {futuresContract ? (
        <Grid container spacing={2}>
          <Grid container item xs={6}>
            <Grid item xs={6}>
              Futures Contract Code:
            </Grid>
            <Grid item xs={6}>
              {futuresContract.futuresContractCode}
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={6}>
              Exchange Name:
            </Grid>
            <Grid item xs={6}>
              {futuresContract.exchangeName}
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={6}>
              Futures Contract Name:
            </Grid>
            <Grid item xs={6}>
              {futuresContract.futuresContractName}
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={6}>
              Exchange Code:
            </Grid>
            <Grid item xs={6}>
              {futuresContract.exchangeCode}
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={6}>
              <b>Active:</b>
            </Grid>
            <Grid item xs={6}>
              <Select value={futuresContract.isActive + 0} size="small" onChange={handleChange}>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={6}>
              Contract Months:
            </Grid>
            <Grid item xs={6}>
              {futuresContract.contractMonths}
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={6}>
              Status in Futures:
            </Grid>
            <Grid item xs={6}>
              {futuresContract.isActiveInFutures ? 'Active' : 'Inactive'}
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={6}>
              Contract Units:
            </Grid>
            <Grid item xs={6}>
              {futuresContract.contractUnits.toLocaleString()}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <SmallChangeableGrid
              tableTitle="Hedged Commodities"
              columns={[
                {
                  field: 'commodityCode',
                  headerName: 'Commodities',
                  type: 'singleSelect',
                  valueOptions: commodities?.map((c) => c.commodityCode ?? '') ?? [''],
                  editable: true,
                  flex: 1,
                },
              ]}
              data={futuresContract.commodities}
              getRowId={(r) => r.commodityId}
              onAddButtonClick={(commodityId) => ({ commodityId, commodityCode: '' })}
              // isCellEditable={({ id }) => id > 2}
              uniqueFields={['commodityCode']}
              setDataChanged={handleCommoditiesChange}
              canRemoveAny
            />
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={6}>
              Unit of Measure:
            </Grid>
            <Grid item xs={6}>
              {futuresContract.uomCode}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export default FuturesContractDetails;
