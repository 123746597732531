/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateLocationRequest,
  LocationResponse,
  LocationsResponse,
  UpdateLocationByIdRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Locations<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetLocations
   * @request GET:/locations
   * @secure
   */
  getLocations = (params: RequestParams = {}) =>
    this.request<LocationsResponse, any>({
      path: `/locations`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name CreateLocation
   * @request POST:/locations
   * @secure
   */
  createLocation = (data: CreateLocationRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/locations`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsLocations
   * @request OPTIONS:/locations
   */
  optionsLocations = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/locations`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetLocation
   * @request GET:/locations/{id}
   * @secure
   */
  getLocation = (id: string, params: RequestParams = {}) =>
    this.request<LocationResponse, any>({
      path: `/locations/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateLocationById
   * @request PUT:/locations/{id}
   * @secure
   */
  updateLocationById = (id: string, data: UpdateLocationByIdRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/locations/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsLocations2
   * @request OPTIONS:/locations/{id}
   * @originalName optionsLocations
   * @duplicate
   */
  optionsLocations2 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/locations/${id}`,
      method: 'OPTIONS',
      ...params,
    });
}
