import React, { useContext, useEffect } from 'react';

import Typography from '@mui/material/Typography';

import { PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';
import {
  useCommoditiesQuery,
  useCommoditiesSummaryQuery,
  useCommodityGroupsSummaryQuery,
  useFuturesContractsSummaryQuery,
  useFuturesTradeAccountsSummaryQuery,
  useLocationsSummaryQuery,
  useOktaUser,
} from '../../../hooks';
import { useCommodities } from '../../../hooks/backendDataClients';

import CommoditiesList from './CommoditiesList/CommoditiesList';

export default function CommoditiesPage() {
  const dataClient = useCommodities();

  const { data: commodityGroupData } = useCommodityGroupsSummaryQuery();

  const locationsData = useLocationsSummaryQuery().data?.sort((a, b) =>
    (a.locationValue ?? '').localeCompare(b.locationValue ?? '')
  );

  const futuresContractsData = useFuturesContractsSummaryQuery('true').data?.sort((a, b) =>
    (a.futuresContractValue ?? '').localeCompare(b.futuresContractValue ?? '')
  );

  const futuresTradeAccountsData = useFuturesTradeAccountsSummaryQuery().data?.sort((a, b) =>
    (a.tradeAccountCode ?? '').localeCompare(b.tradeAccountCode ?? '')
  );

  const { data: commoditiesSummaryData } = useCommoditiesSummaryQuery();

  const { user } = useOktaUser();
  const userName = user?.preferred_username ?? '';

  useEffect(() => {
    document.title = 'Commodities | Positions and Futures';
  }, []);

  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="CommoditiesPage">
          <h2>Commodities</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  return (
    <main style={{ padding: '24px' }} data-testid="CommoditiesPage">
      <CommoditiesList
        userName={userName}
        createCommodity={dataClient.createCommodities}
        getCommodities={useCommoditiesQuery}
        getCommodity={dataClient.getCommodity}
        updateCommodity={dataClient.updateCommodity}
        commodityGroups={commodityGroupData}
        locationSummaries={locationsData}
        futuresContractsSummaries={futuresContractsData}
        futuresTradeAccountsSummaries={futuresTradeAccountsData}
        commoditySummaries={commoditiesSummaryData}
      />
    </main>
  );
}
