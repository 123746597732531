import { FC } from 'react';
import { AxiosResponse } from 'axios';
import { FormikTouched, FormikValues, setNestedObjectValues, useFormik } from 'formik';

import { DialogContent, DialogTitle } from '@mui/material';

import TransactionEntryModal from '../../../../components/TransactionEntryModal/TransactionEntryModal';
import { useToastMutation } from '../../../../hooks/useToastMutations';
import {
  BrokerEntryResponse,
  CreateBrokerEntryRequest,
  CreateBrokerEntryResponse,
  CreateCommissionRateRequest,
  DeleteCommissionRateRequest,
  FuturesBrokerResponse,
  FuturesContractsSummaryResponse,
  UpdateBrokerEntryRequest,
  UpdateCommissionRateRequest,
} from '../../../../services/backend/data-contracts';
import { RequestParams } from '../../../../services/backend/http-client';

import { getValidationSchema } from './BrokerEntry.schema';
import BrokerEntryDetails from './BrokerEntryDetails';

interface BrokerEntryModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  selectedEntryId?: number;
  createBrokerEntry: (
    data: CreateBrokerEntryRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<CreateBrokerEntryResponse, any>>;
  getBrokerEntry: (
    id: string,
    params?: RequestParams
  ) => Promise<AxiosResponse<BrokerEntryResponse, any>>;
  updateBrokerEntry: (
    id: string,
    data: UpdateBrokerEntryRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  updateCommissionRate: (
    data: UpdateCommissionRateRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  createCommissionRate: (
    data: CreateCommissionRateRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  deleteCommissionRate: (
    data: DeleteCommissionRateRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  userName: string;
  futuresContracts?: FuturesContractsSummaryResponse['result'];
  brokers?: FuturesBrokerResponse['result'];
  initialDetails: any;
  refetch: () => void;
  refetchFilteredBrokerEntries: () => void;
}

const BrokerEntryModal: FC<BrokerEntryModalProps> = ({
  isModalOpen,
  selectedEntryId,
  onClose,
  createBrokerEntry,
  updateBrokerEntry,
  userName,
  futuresContracts,
  brokers,
  initialDetails,
  updateCommissionRate,
  createCommissionRate,
  deleteCommissionRate,
  refetch,
  refetchFilteredBrokerEntries,
}) => {
  const brokerCodes: (string | undefined)[] = (brokers || [])
    .filter((broker: any) => broker?.brokerId !== initialDetails?.brokerId)
    .map((broker) => broker.brokerCode);
  const validationSchema = getValidationSchema(brokerCodes);

  const formik = useFormik({
    initialValues: initialDetails,
    validationSchema,
    onSubmit: () => {
      console.log('Submitting Form...');
    },
  });

  const getChangedCommissionRates = () => {
    const deleted = (initialDetails.commissionRates || []).filter(
      (prev: any) =>
        !(formik.values.commissionRates || []).find(
          (curr: any) =>
            prev.brokerId === curr.brokerId && prev.futuresContractId === curr.futuresContractId
        )
    );
    const created = (formik.values.commissionRates || []).filter(
      (curr: any) =>
        !(initialDetails.commissionRates || []).find(
          (prev: any) =>
            prev.brokerId === curr.brokerId && prev.futuresContractId === curr.futuresContractId
        )
    );
    const updated = (formik.values.commissionRates || []).filter((curr: any) => {
      const prev = initialDetails.commissionRates.find(
        (commission: any) =>
          commission.brokerId === curr.brokerId &&
          commission.futuresContractId === curr.futuresContractId
      );
      return (
        prev &&
        (prev.commissionRate !== curr.commissionRate ||
          curr.commissionRateType !== prev.commissionRateType)
      );
    });
    return { deleted, created, updated };
  };

  const updateMutation = useToastMutation({
    mutationFn: async ({ id, data }: any) => {
      await updateBrokerEntry(id, data);
      const { created, updated, deleted } = getChangedCommissionRates();
      console.log({ created, updated, deleted });
      if (deleted.length) {
        await deleteCommissionRate({ entries: deleted });
      }
      if (updated.length) {
        await updateCommissionRate({ entries: updated });
      }
      if (created.length) {
        await createCommissionRate({ entries: created });
      }
    },
    queryKey: ['BrokerEnteries'],
    onMutateMessage: 'Updating...',
    onSuccessMessage: 'Broker Entry Updated',
  });

  const createMutation = useToastMutation({
    mutationFn: async ({ data }: any) => {
      const createBrokerResponse = await createBrokerEntry(data);
      const brokerId = createBrokerResponse.data?.result?.brokerId;
      if ((formik.values.commissionRates || []).length && brokerId) {
        const entries = formik.values.commissionRates.map((commission: any) => ({
          ...commission,
          brokerId,
        }));
        await createCommissionRate({ entries });
      }
    },
    queryKey: ['BrokerEnteries'],
    onMutateMessage: 'Creating...',
    onSuccessMessage: 'Broker Entry Created',
  });

  const handleSave = () => {
    const entry = {
      brokerCode: formik.values.brokerCode,
      glCompanyCode: formik.values.glCompanyCode,
      glAccount: formik.values.glAccount,
      divisionId: formik.values.divisionId,
      brokerName: formik.values.brokerName,
      isActive: formik.values.isActive,
      entryUser: userName,
    };
    if (selectedEntryId) {
      updateMutation.mutate(
        {
          id: selectedEntryId,
          data: {
            entry,
          },
        },
        { onSuccess: () => handleSuccess() }
      );
    } else {
      createMutation.mutate({ data: { entry } }, { onSuccess: () => handleSuccess() });
    }
  };

  const handleModalClose = () => {
    formik.setTouched({}, false);
    formik.setErrors({});
    onClose();
  };

  const handleClearClick = async () => {
    formik.resetForm();
    // Trigger touched on all fields
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      // Form is valid, do any success call
    } else {
      formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
    }
  };
  const handleSuccess = () => {
    refetchFilteredBrokerEntries();
    refetch();
    handleModalClose();
  };

  return (
    <TransactionEntryModal
      isOpen={isModalOpen}
      canSave={!updateMutation.isPending && !createMutation.isPending}
      isCreateModal={!selectedEntryId}
      onCancel={handleModalClose}
      onClear={handleClearClick}
      onCreate={handleSave}
      onUpdate={handleSave}
      maxWidth="xl"
      formXs={6}
    >
      <>
        <DialogTitle>{(selectedEntryId ? 'Edit' : 'Create') + ' Broker Entry'}</DialogTitle>
        <DialogContent>
          <BrokerEntryDetails
            futuresContracts={futuresContracts}
            brokers={brokers}
            formik={formik}
            selectedEntryId={selectedEntryId}
          ></BrokerEntryDetails>
        </DialogContent>
      </>
    </TransactionEntryModal>
  );
};

export default BrokerEntryModal;
