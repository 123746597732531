import { FC } from 'react';

import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';

import { dateValueFormatter, errorCellClassName, StripedDataGrid } from '../../../../components';

export interface EndOfDayProcessGridProps {
  endOfDayRecords?: GridRowsProp;
}

const yesNoValueFormatter = (params: GridValueFormatterParams<string>) => {
  if (params.value === undefined) {
    return '';
  }
  return params.value ? 'Yes' : 'No';
};

const columns: GridColDef[] = [
  {
    field: 'startDateTime',
    headerName: 'Start Date Time',
    flex: 1,
    type: 'string',
    editable: false,
    cellClassName: errorCellClassName,
    valueFormatter: dateValueFormatter,
  },
  {
    field: 'closeDate',
    headerName: 'Close Date',
    flex: 1,
    type: 'string',
    editable: false,
    cellClassName: errorCellClassName,
  },
  {
    field: 'isCurrent',
    headerName: 'Current Trading Day',
    flex: 1,
    type: 'string',
    editable: false,
    cellClassName: errorCellClassName,
    valueFormatter: yesNoValueFormatter,
  },
  {
    field: 'marketPricesImported',
    headerName: 'Market Prices Imported',
    flex: 1,
    type: 'string',
    editable: false,
    cellClassName: errorCellClassName,
    valueFormatter: yesNoValueFormatter,
  },
  {
    field: 'commodityBalancesUpdated',
    headerName: 'Commodity Balances Updated',
    flex: 1,
    type: 'string',
    editable: false,
    cellClassName: errorCellClassName,
    valueFormatter: yesNoValueFormatter,
  },
  {
    field: 'reportsScheduled',
    headerName: 'Reports Scheduled',
    flex: 1,
    type: 'string',
    editable: false,
    cellClassName: errorCellClassName,
    valueFormatter: yesNoValueFormatter,
  },
  {
    field: 'crossAppliedLoadsImported',
    headerName: 'Cross Applied Loads Imported',
    flex: 1,
    type: 'string',
    editable: false,
    cellClassName: errorCellClassName,
    valueFormatter: yesNoValueFormatter,
  },
  {
    field: 'futuresExported',
    headerName: 'Futures Exported',
    flex: 1,
    type: 'string',
    editable: false,
    cellClassName: errorCellClassName,
    valueFormatter: yesNoValueFormatter,
  },
  {
    field: 'updatedOn',
    headerName: 'Updated On',
    flex: 1,
    type: 'string',
    editable: false,
    cellClassName: errorCellClassName,
    valueFormatter: dateValueFormatter,
  },
  {
    field: 'updatedBy',
    headerName: 'Updated By',
    flex: 1,
    type: 'string',
    editable: false,
    cellClassName: errorCellClassName,
  },
];

const EndOfDayProcessGrid: FC<EndOfDayProcessGridProps> = ({ endOfDayRecords }) => {
  return (
    <div data-testid="EndOfDayProcessGrid">
      <StripedDataGrid
        rows={endOfDayRecords ?? []}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
      />
    </div>
  );
};

export default EndOfDayProcessGrid;
