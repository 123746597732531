import { FC } from 'react';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { locationCommodityRefsKeys, locationKeys } from '../../../../hooks';
import { useToastMutation } from '../../../../hooks/useToastMutations';
import {
  CreateLocationRequest,
  LocationResponse,
  UpdateLocationByIdRequest,
} from '../../../../services/backend/data-contracts';
import { RequestParams } from '../../../../services/backend/http-client';
import LocationDetails, { LocationDetailsProps } from '../LocationDetails/LocationDetails';

export interface LocationModalProps
  extends Omit<
    LocationDetailsProps,
    'excludedCommodityIds' | 'addErrorType' | 'removeErrorType' | 'formik'
  > {
  isModalOpen: boolean;
  onClose: () => void;
  userName: string;
  getLocation: (
    id: string,
    params?: RequestParams
  ) => Promise<AxiosResponse<LocationResponse, any>>;
  createLocation: (
    data: CreateLocationRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  updateLocation: (
    id: string,
    data: UpdateLocationByIdRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
}

const LocationModal: FC<LocationModalProps> = ({
  isModalOpen,
  onClose,
  isNewLocation,
  userName,
  locationId,
  createLocation,
  updateLocation,
  getLocation,
  locationGroups,
  ...props
}) => {
  const newLocation = {
    commodities: [],
    isActive: true,
    locationName: '',
    locationCode: '',
    locationGroup: { locationGroupId: '' },
  };
  const { data: locationData, isPending } = useQuery({
    queryKey: locationKeys.details(locationId),
    queryFn: async () => {
      return locationId
        ? (await getLocation(locationId)).data
        : ({ result: {} } as LocationResponse);
    },
    select: (data) => {
      const initialDetails = isNewLocation ? newLocation : data?.result;
      return {
        ...initialDetails,
        locationGroupId: initialDetails?.locationGroup?.locationGroupId,
        initialCommodityIds: initialDetails?.commodities?.map((c: any) => c.commodityId) ?? [],
      };
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...locationData,
    },
    validationSchema: yup.object().shape({
      locationCode: yup.string().required().max(6, 'Location Code must be 6 characters or fewer'),
      locationGroupId: yup.number().required(),
      commodities: yup.array().of(
        yup.object().shape({
          commodityId: yup.string().required(),
          defaultFuturesContractValue: yup.string().required(),
        })
      ),
    }),
    onSubmit: () => {
      doSave({ ...formik.values });
    },
  });

  const queryClient = useQueryClient();
  const createMutation = useToastMutation({
    mutationFn: createLocation,
    queryKey: locationKeys.all,
    afterSuccess: () => queryClient.invalidateQueries({ queryKey: locationCommodityRefsKeys.all }),
  });

  const updateMutation = useToastMutation({
    mutationFn: ({ id, data }: any) => updateLocation(id, data),
    queryKey: locationKeys.all,
    afterSuccess: () => queryClient.invalidateQueries({ queryKey: locationCommodityRefsKeys.all }),
  });

  const handleDetailSaveClick = () => {
    console.log('attempt to save', formik.values);
    formik.submitForm();
  };

  const handleDetailCancelClick = () => {
    onClose();
  };

  const doSave = (currentDetails: any) => {
    const now = new Date().toISOString();
    const location = {
      ...currentDetails,
      updatedOn: now,
      updatedBy: userName,
      locationGroup: locationGroups?.find(
        (lg) => '' + currentDetails.locationGroupId === '' + lg.locationGroupId
      ),
    };
    if (isNewLocation) {
      createMutation.mutate(
        {
          location: {
            ...location,
            createdOn: now,
            createdBy: userName,
          },
        },
        { onSuccess: () => onClose() }
      );
    } else {
      updateMutation.mutate(
        {
          id: locationId,
          data: {
            location: location,
          },
        },
        { onSuccess: () => onClose() }
      );
    }
  };

  return isPending ? (
    <LoadingSpinner />
  ) : (
    <Dialog fullWidth maxWidth="md" open={isModalOpen} data-testid="LocationModal">
      <DialogTitle>{'Location ' + (isNewLocation ? 'Create' : 'Update')}</DialogTitle>
      <DialogContent>
        <LocationDetails
          formik={formik}
          locationId={locationId}
          isNewLocation={isNewLocation}
          locationGroups={locationGroups}
          {...props}
        ></LocationDetails>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDetailCancelClick}>Cancel</Button>
        <Button
          onClick={handleDetailSaveClick}
          disabled={createMutation.isPending || updateMutation.isPending}
        >
          {isNewLocation ? 'Create' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationModal;
