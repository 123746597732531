import { useState } from 'react';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';

import { backendDataClients, useFuturesBrokersViewQuery } from '../../../hooks';
import { useToastMutation } from '../../../hooks/useToastMutations';
import {
  AllocatedCommissionCalcResponse,
  CreateAllocatedCommissionRequest,
} from '../../../services/backend/data-contracts';

type AllocatedCommissionAdjustmentRow = AllocatedCommissionCalcResponse['result'] & GridRowsProp;

const { useAllocatedCommission } = backendDataClients;

export default function useAllocatedCommissionAdjustment() {
  const navigate = useNavigate();
  const initialData = {
    isBackDated: false,
    rows: null,
    adjustmentDate: moment(),
    brokerId: undefined,
    amount: 0,
    endDate: moment(),
    startDate: moment(),
  };
  const dataClient = useAllocatedCommission();
  const [isBackDated, setIsBackDated] = useState<boolean>(initialData.isBackDated);
  const [rows, setRows] = useState<GridRowsProp[] | any[] | null>(initialData.rows);
  const calculateMutation = useToastMutation({
    mutationFn: (entry: any) =>
      dataClient.calculateAllocatedCommission(entry).then((result) => {
        setRows(
          result.data.result
            .sort((a: any, b: any) => {
              if (a.tradeAccountName && b.tradeAccountName) {
                return a.tradeAccountName?.localeCompare(b.tradeAccountName);
              }
              return 0;
            })
            .map((t: any, index: number) => {
              return { id: index, ...t } as unknown as AllocatedCommissionAdjustmentRow;
            })
        );
      }),
    queryKey: ['CashAdjustments'],
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Adjustment Created',
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: yup.object().shape({
      adjustmentDate: yup.object().required('Adjustment Date required'),
      brokerId: yup.string().required('Broker required'),
      amount: yup.string().required('Amount required'),
      startDate: yup.object().required('Start Date required'),
      endDate: yup.object().required('End Date required'),
    }),
    onSubmit: (values: any) => {
      const entry = {
        brokerId: Number(values.brokerId),
        amount: Number(values.amount),
        startDate: (values.startDate as unknown as Moment)?.toJSON(),
        endDate: (values.endDate as unknown as Moment)?.toJSON(),
      };
      return calculateMutation.mutateAsync(entry);
    },
  });
  const brokers = useFuturesBrokersViewQuery().data;

  const handleBackDateButtonClick = () => {
    formik.setFieldValue('adjustmentDate', moment().subtract(1, 'months').endOf('month'));
  };

  const createMutation = useToastMutation({
    mutationFn: (entry: CreateAllocatedCommissionRequest) =>
      dataClient.createAllocatedCommission(entry),
    queryKey: ['CreateAllocatedCommissionRequest'],
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Allocated Commission Saved',
  });

  const handleClear = () => {
    formik.resetForm();
    setRows(initialData.rows);
    setIsBackDated(initialData.isBackDated);
  };
  const handleSave = async () => {
    if (rows && rows?.length > 0) {
      const request = {
        entry: {
          date: formik.values.adjustmentDate.toISOString(),
          isBackDated,
          calcId: rows[0].calcId,
        },
      } as CreateAllocatedCommissionRequest;

      await createMutation.mutateAsync(request).then(() => {
        const broker = brokers?.find((t) => t.brokerId === formik.values.brokerId);
        handleClear();
        if (broker) {
          navigate('/brokerAdjustments/cashAdjustments/' + broker.brokerCode);
        }
      });
    }
  };

  const disableButtons = calculateMutation.isPending || createMutation.isPending;

  return {
    formik,
    rows,
    isLoadingRows: calculateMutation.isPending,
    disableButtons,
    handleBackDateButtonClick,
    handleSave,
    handleClear,
  };
}
