import { useContext } from 'react';

import Typography from '@mui/material/Typography';

import { PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';
import { useCommoditiesSummaryQuery, useFuturesTradeAccountsQuery } from '../../../hooks';
import { useFuturesTradeAccountsMassUpdates } from '../../../hooks/backendDataClients/index';

import FuturesTradeAccountsList from './FuturesTradeAccountsList/FuturesTradeAccountsList';

export default function FuturesTradeAccountsPage() {
  const dataClient = useFuturesTradeAccountsMassUpdates();
  const { data } = useCommoditiesSummaryQuery();
  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="FuturesTradeAccountsPage">
          <h2>Futures Trade Accounts</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  return (
    <main style={{ padding: '24px' }} data-testid="FuturesTradeAccountsPage">
      <FuturesTradeAccountsList
        getFuturesTradeAccounts={useFuturesTradeAccountsQuery}
        updateFuturesTradeAccounts={dataClient.updateFuturesTradeAccounts}
        commodities={data}
      />
    </main>
  );
}
