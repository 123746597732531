import React, { FC } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { ToastProvider } from 'use-toast-mui';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { useQueryClientWithNotifications } from '../hooks/useQueryClientWithNotifications';
import { ErrorPage } from '../pages';
import { appRoutes } from '../routes/appRoutes';

import SessionTimeoutWrapper from './SessionTimeoutWrapper/SessionTimeoutWrapper';
import { Layout } from './Layout';
import { RequiredAuth } from './RequiredAuth';
import { SecurityWrapper } from './SecurityWrapper';
import { UserWrapper } from './UserWrapper';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const router = createBrowserRouter([
  {
    path: '/*',
    element: (
      <RequiredAuth>
        <UserWrapper>
          <SessionTimeoutWrapper>
            <Layout>
              <Outlet />
            </Layout>
          </SessionTimeoutWrapper>
        </UserWrapper>
      </RequiredAuth>
    ),
    children: appRoutes,
    errorElement: <ErrorPage />,
  },
]);
const AppWithQuery: FC = () => {
  const queryClient = useQueryClientWithNotifications();
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

const App = () => {
  return (
    <ToastProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={4000}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SecurityWrapper>
          <AppWithQuery></AppWithQuery>
        </SecurityWrapper>
      </LocalizationProvider>
    </ToastProvider>
  );
};

export default App;
