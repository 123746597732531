/* eslint-disable */
/* tslint:disable */
import React, { FC } from 'react';

import { FormControl, InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import SelectDate from '../../../../components/SelectDate/SelectDate';
import { FuturesBrokerResponse } from '../../../../services/backend/data-contracts';
import { Moment } from 'moment';

export interface CashAdjustmentsFilterFormProps {
  brokers?: FuturesBrokerResponse['result'];
  filterForm?: any;
  setFilterForm: (filterForm: any) => void;
}

const CashAdjustmentsFilterForm: FC<CashAdjustmentsFilterFormProps> = ({
  brokers,
  filterForm,
  setFilterForm,
}) => {
  const handleSinceDateFilterChange = (value: Moment | null): void => {
    setFilterForm({ ...filterForm, sinceDate: value ? value.toISOString() : value });
  };
  const handleBrokerFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, brokerCode: event.target.value });
  };
  return (
    <div className="filterBox" data-testid="commodity-select">
      <FormControl fullWidth sx={{ mr: 2 }}>
        <InputLabel id="broker-label">Broker Code</InputLabel>
        <Select
          labelId="broker-label"
          id="broker-select"
          value={filterForm.brokerCode}
          label="Transaction Type"
          onChange={handleBrokerFilterChange}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {brokers
            ?.map((b) => b.brokerCode)
            .filter((value, index, array) => array.indexOf(value) === index)
            .map((brokerCode) => (
              <MenuItem key={brokerCode} value={brokerCode}>
                {brokerCode}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mr: 2 }}>
        <SelectDate onChange={handleSinceDateFilterChange} label="Since Date" />
      </FormControl>
    </div>
  );
};

export default CashAdjustmentsFilterForm;
