import { useEffect, useState } from 'react';

import { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { useQueries, useQuery } from '@tanstack/react-query';

import {
  backendDataClients,
  useCommoditiesQuery,
  useFuturesContractsQuery,
  useFuturesContractTradeAccountRefQuery,
  useFuturesTradeAccountsQuery,
  useOktaUser,
  useSwappableCommoditiesQuery,
  useTradeAccountCommodityRefQuery,
} from '../../../hooks';
import { SwapFilteredEntriesResponse } from '../../../services/backend/data-contracts';

const { useSwapEntries: useSwapEntriesDataClient, useViews } = backendDataClients;

interface PaginatedFilterForm {
  limit: number;
  offset: number;
  sortOrder?: string;
  search?: string;
  sortBy?: string;
}

interface SwapEntriesForm {
  futuresContractId: string;
  buyCommodityId: string;
  buyTradeAccountId: string;
  sellCommodityId: string;
  sellTradeAccountId: string;
}

export interface FilterForm extends PaginatedFilterForm, SwapEntriesForm {}

export default function useSwapEntries() {
  const [filterForm, setFilterForm] = useState<FilterForm>({
    sortOrder: '',
    limit: 50,
    offset: 0,
    search: '',
    sortBy: '',
    futuresContractId: '',
    buyCommodityId: '',
    buyTradeAccountId: '',
    sellCommodityId: '',
    sellTradeAccountId: '',
  });

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: filterForm.limit,
  });

  const queryParams = Object.assign(
    {
      limit: filterForm.limit.toString(), // number of rows per page
      offset: filterForm.offset.toString(), // what row to start at
    },
    filterForm.search === '' ? null : { search: filterForm.search },
    filterForm.sortOrder === '' ? null : { sortOrder: filterForm.sortOrder },
    filterForm.sortBy === '' ? null : { sortBy: filterForm.sortBy },
    filterForm.futuresContractId === ''
      ? null
      : { futuresContractId: filterForm.futuresContractId },
    filterForm.buyCommodityId === '' ? null : { buyCommodityId: filterForm.buyCommodityId },
    filterForm.buyTradeAccountId === ''
      ? null
      : { buyTradeAccountId: filterForm.buyTradeAccountId },
    filterForm.sellCommodityId === '' ? null : { sellCommodityId: filterForm.sellCommodityId },
    filterForm.sellTradeAccountId === ''
      ? null
      : { sellTradeAccountId: filterForm.sellTradeAccountId }
  );

  const { user } = useOktaUser();
  const swapEntriesDataClient = useSwapEntriesDataClient();
  const viewDataClient = useViews();
  const swapEntriesQuery = useQuery({
    queryKey: ['SwapEntries', queryParams],
    queryFn: () => swapEntriesDataClient.getFilteredSwapEntries(queryParams),
    select: (response) => response?.data?.result,
  });
  const swapEntries = swapEntriesQuery.data;
  const rows: SwapFilteredEntriesResponse['result']['entries'] = swapEntries?.entries ?? [];

  const [rowCountState, setRowCountState] = useState(swapEntries?.totalCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      swapEntries?.totalCount !== undefined ? swapEntries?.totalCount : prevRowCountState
    );
  }, [swapEntries?.totalCount, setRowCountState]);
  const onPaginationModelChange = (pageChange: GridPaginationModel) => {
    if (!isNaN(pageChange.page) && !isNaN(pageChange.pageSize)) {
      const offset = +pageChange.page * +pageChange.pageSize;
      setFilterForm({ ...filterForm, limit: pageChange.pageSize, offset });
      setPaginationModel(pageChange);
    }
  };
  const onSortModelChange = (model: GridSortModel) => {
    if (model[0] && model[0].field && model[0].sort) {
      const sortBy = model[0].field;
      setFilterForm({ ...filterForm, sortBy: sortBy, sortOrder: model[0].sort });
    } else {
      setFilterForm({ ...filterForm, sortOrder: '', sortBy: '' });
    }
  };
  const onFilterModelChange = (model: GridFilterModel) => {
    if (model && model.quickFilterValues && model.quickFilterValues.length > 0) {
      setFilterForm({ ...filterForm, search: model.quickFilterValues[0] });
    } else {
      setFilterForm({ ...filterForm, search: '' });
    }
  };

  const commoditiesQuery = useCommoditiesQuery();
  const commodities = commoditiesQuery.data
    ?.filter((c) => c.isActive)
    ?.sort((a, b) => (a.commodityCode ?? '').localeCompare(b.commodityCode ?? ''))
    ?.map((c) => ({
      commodityCode: c.commodityCode,
      commodityId: c.commodityId,
      commodityName: c.commodityName,
    }));
  const swappableCommoditiesQuery = useSwappableCommoditiesQuery();
  const futuresContractsQuery = useFuturesContractsQuery();
  const futuresContractSummaries = futuresContractsQuery.data
    ?.filter((f) => f.isActive)
    ?.sort((a, b) => (a.futuresContractCode ?? '').localeCompare(b.futuresContractCode ?? ''))
    ?.map((f) => ({
      futuresContractValue: f.futuresContractValue,
      futuresContractId: f.futuresContractId,
      contractMonths: f.contractMonths,
    }));
  const tradeAccountsQuery = useFuturesTradeAccountsQuery();
  const tradeAccountSummaries = tradeAccountsQuery.data
    ?.filter((t) => t.isActive)
    ?.sort((a, b) => (a.tradeAccountCode ?? '').localeCompare(b.tradeAccountCode ?? ''))
    ?.map((t) => ({
      tradeAccountId: t.tradeAccountId,
      tradeAccountName: t.tradeAccountName,
      tradeAccountCode: t.tradeAccountCode,
      affectsPosition: t.affectsPosition,
    }));
  const futuresContractsTradeAccountsQuery = useFuturesContractTradeAccountRefQuery();
  const tradeAccountCommoditiesQuery = useTradeAccountCommodityRefQuery();

  const results = useQueries({
    queries: (futuresContractSummaries ?? [])
      .filter((f) => f?.futuresContractId !== undefined)
      .map((f) => ({
        queryKey: ['RefData', 'Futures Contract Option Months', f.futuresContractId?.toString()],
        queryFn: async () => ({
          futuresContractId: f.futuresContractId,
          months: (
            await viewDataClient.getFuturesContractOptionMonthRef({
              futuresContractId: f.futuresContractId?.toString() || '',
            })
          ).data.result.sort(
            (a: any, b: any) => (a.optionMonthOrder ?? 0) - (b.optionMonthOrder ?? 0)
          ),
        }),
      })),
  });

  const isLoading =
    swapEntriesQuery.isPending ||
    commoditiesQuery.isPending ||
    swappableCommoditiesQuery.isPending ||
    futuresContractsQuery.isPending ||
    tradeAccountsQuery.isPending ||
    futuresContractsTradeAccountsQuery.isPending ||
    tradeAccountCommoditiesQuery.isPending ||
    results.reduce((prev, result) => prev || result.isLoading, false);

  const futuresContractsToMonths = results.reduce(
    (monthsMap, result) => {
      const { futuresContractId, months } = result.data ?? {};
      if (futuresContractId) {
        monthsMap[futuresContractId] =
          months?.reduce((monthIdsMap: any, month: any) => {
            const { optionMonthId, optionMonthValue } = month;
            if (optionMonthId) {
              monthIdsMap.set(optionMonthId, optionMonthValue ?? '');
            }
            return monthIdsMap;
          }, new Map<number, string>()) ?? new Map<number, string>();
      }
      return monthsMap;
    },
    {} as Record<number, Map<number, string>>
  );

  const [isOpen, setModalOpen] = useState(false);
  const [swapEntry, setSwapEntry] = useState(null);

  const getOptionMonthId = (map: Map<number, string>, searchValue: string): number | undefined =>
    [...map.entries()].find(([key, value]) => key && value === searchValue)?.[0];

  const openModalWith = (row?: any) => {
    if (row && !row.optionMonthId) {
      row.optionMonthId = getOptionMonthId(
        futuresContractsToMonths?.[row?.futuresContractId] ?? new Map<number, string>(),
        row.optionMonth
      );
    }
    setSwapEntry(row);
    setModalOpen(true);
  };

  const onClose = () => {
    setModalOpen(false);
  };

  return {
    filterForm,
    rowCountState,
    setFilterForm,
    rows,
    isLoadingSwapEntries: swapEntriesQuery.isPending,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
    commodities,
    futuresContractSummaries,
    tradeAccountSummaries,
    swappableCommodities: swappableCommoditiesQuery.data,
    futuresContractTradeAccounts: futuresContractsTradeAccountsQuery.data,
    tradeAccountCommodities: tradeAccountCommoditiesQuery.data,
    openModalWith,
    isOpen,
    onClose,
    userName: user?.preferred_username ?? '',
    swapEntry,
    futuresContractsToMonths,
    updateSwapEntry: swapEntriesDataClient.updateSwapEntry,
    createSwapEntry: swapEntriesDataClient.createSwapEntry,
    deleteSwapEntry: swapEntriesDataClient.deleteSwapEntry,
    invalidateQueries: ['SwapEntries'],
    isLoading,
  };
}
