import React, { FC, useState } from 'react';
import { AxiosResponse } from 'axios';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';

import { ChangeableDisplay, yesNoGridColTypeDef } from '../../../../components';
import { UseRefDataQueryResult } from '../../../../hooks';
import { FuturesContractsResponse } from '../../../../services/backend/data-contracts';
import { RequestParams } from '../../../../services/backend/http-client';
import FuturesContractDetails, {
  FuturesContractDetailsProps,
} from '../FuturesContractDetails/FuturesContractDetails';

export interface FuturesContractsListProps
  extends Omit<FuturesContractDetailsProps, 'futuresContract' | 'setShowEditForRow'> {
  getFuturesContracts: () => UseRefDataQueryResult<FuturesContractsResponse['result']>;
  updateFuturesContracts: (data: any, params?: RequestParams) => Promise<AxiosResponse<void, any>>;
  hideUnsavedPrompt?: boolean;
}

const FuturesContractsList: FC<FuturesContractsListProps> = ({
  getFuturesContracts,
  updateFuturesContracts,
  hideUnsavedPrompt,
  ...props
}) => {
  const [showEditForRow, setShowEditForRow] = useState<GridValidRowModel | null>(null);

  const update = (oldRows: GridValidRowModel[]) => {
    return oldRows?.length
      ? updateFuturesContracts({
          contracts: oldRows.map((r) => ({
            futuresContractId: r.futuresContractId,
            isActive: r.isActive,
            commodities: r.commodities,
          })),
        })
      : Promise.resolve();
  };

  const columns: GridColDef[] = [
    {
      field: 'futuresContractCode',
      headerName: 'Futures Contract Code',
      flex: 1,
      type: 'string',
      editable: false,
      renderCell: ({ value, row }) => (
        <Link
          onClick={() => setShowEditForRow(row)}
          sx={{ cursor: 'pointer' }}
          data-testid="LinkToModal"
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'futuresContractName',
      headerName: 'Futures Contract Name',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 1,
      editable: true,
      ...yesNoGridColTypeDef('isActive'),
    },
    {
      field: 'isActiveInFutures',
      headerName: 'Status in Futures',
      flex: 1,
      valueGetter: (params) => (params.row['isActiveInFutures'] ? 'Active' : 'Inactive'),
    },
  ];

  return (
    <>
      <ChangeableDisplay
        pageTitle="Futures Contracts"
        columns={columns}
        getRowId={(row) => row['futuresContractId']}
        update={update}
        fnUseQuery={getFuturesContracts}
        uniqueFields={['futuresContractCode']}
        sortField="futuresContractCode"
        isCellEditable={({ row }) => row.isActiveInFutures}
        infoIndicator={(row) =>
          !row.isActiveInFutures
            ? 'Futures Contracts that are inactive in Futures cannot be modified in the Position System.'
            : null
        }
        hideUnsavedPrompt={hideUnsavedPrompt}
        modalEdit={(setter) => (
          <Dialog fullWidth maxWidth="md" open={!!showEditForRow}>
            <DialogTitle>Futures Contract Edit</DialogTitle>
            <DialogContent>
              <FuturesContractDetails
                futuresContract={showEditForRow}
                setShowEditForRow={setShowEditForRow}
                {...props}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowEditForRow(null)}>Cancel</Button>
              <Button
                onClick={() => {
                  showEditForRow &&
                    setter({
                      ...showEditForRow,
                      commodities: showEditForRow.newCommodities || showEditForRow.commodities,
                    });
                  setShowEditForRow(null);
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
      />
    </>
  );
};

export default FuturesContractsList;
