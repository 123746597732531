import * as yup from 'yup';

export const HEDGED_COMMODITY_NAME_REQUIRED = 'Commodity Name Required';
export const ACTIVE_REQUIRED = 'Active Required';
export const DIVISION_REQUIRED = 'Active Required';
export const GL_COMMODITY_CODE_REQUIRED = 'GL Commodity Code Required';
export const GL_COMMODITY_LENGTH_ERROR = 'GL Commodity Code must be exactly 3 digits';
export const STRING_ONLY_DIGITS = 'GL Commodity Code must be only digits';
export const HEDGED_COMMODITY_CODE_REQUIRED = 'Commodity Code Required';
export const HEDGED_COMMODITY_LENGTH_ERROR = 'Commodity Code must be 2-4 letters';

export const prioritizedErrorsByFieldName: { [index: string]: string[] } = {
  hedgedCommodityName: [HEDGED_COMMODITY_NAME_REQUIRED],
  isActive: [ACTIVE_REQUIRED],
  glCommodityCode: [
    GL_COMMODITY_CODE_REQUIRED,
    STRING_ONLY_DIGITS,
    GL_COMMODITY_LENGTH_ERROR,
    GL_COMMODITY_LENGTH_ERROR,
  ],
  hedgedCommodityCode: [HEDGED_COMMODITY_CODE_REQUIRED, HEDGED_COMMODITY_LENGTH_ERROR],
};

const filterErrorsByPriority = (errors: string[]) =>
  Object.keys(prioritizedErrorsByFieldName)
    .map((field: string) => {
      const pList = prioritizedErrorsByFieldName[field];
      return pList.filter((item) => errors.includes(item))[0];
    })
    .filter((error) => error);

export const hedgedCommoditySchema = yup.object().shape({
  hedgedCommodityName: yup.string().required(HEDGED_COMMODITY_NAME_REQUIRED),
  isActive: yup.boolean().required(ACTIVE_REQUIRED),
  glCommodityCode: yup
    .string()
    .required(GL_COMMODITY_CODE_REQUIRED)
    .matches(/^[0-9]+$/, STRING_ONLY_DIGITS)
    .min(3, GL_COMMODITY_LENGTH_ERROR)
    .max(3, GL_COMMODITY_LENGTH_ERROR),
  hedgedCommodityCode: yup
    .string()
    .required(HEDGED_COMMODITY_CODE_REQUIRED)
    .min(2, HEDGED_COMMODITY_LENGTH_ERROR)
    .max(4, HEDGED_COMMODITY_LENGTH_ERROR),
});

export const validate = async (item: any) => {
  try {
    await hedgedCommoditySchema.validate(item, { abortEarly: false });
    if (item.errors) delete item.errors;
  } catch (error: any) {
    item.errors = filterErrorsByPriority(error.errors);
  }
  return item;
};

export const validateAll = async (items: any[]) => {
  return await Promise.all(items.map(validate));
};
