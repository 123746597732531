/* eslint-disable */
import Typography from '@mui/material/Typography';

import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { useFormik } from 'formik';
import { FC } from 'react';
import {
  currencyValueFormatterTo2,
  errorCellClassName,
  formatCurrencyTo2,
  Loading,
  numberWithCommaFormatter,
} from '../../../../components';
import ChangeableGrid from '../../../../components/ChangeableGrid/ChangeableGrid';

export interface AllocatedCommissionAdjustmentGridProps {
  rows: GridRowsProp[] | null;
  isLoadingRows: boolean;
  formik: ReturnType<typeof useFormik<any>>;
}

const AllocatedCommissionAdjustmentGrid: FC<AllocatedCommissionAdjustmentGridProps> = ({
  rows,
  isLoadingRows,
  formik,
}) => {
  if (isLoadingRows) {
    return <Loading />;
  }
  if (!rows) {
    return null;
  }
  if (rows?.length === 0) {
    return <Typography>There are no results.</Typography>;
  }
  const columns: GridColDef[] = [
    {
      field: 'tradeAccountName',
      headerName: 'Trade Account',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'futuresContractName',
      headerName: 'Futures Contract',
      type: 'string',
      width: 160,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'hedgedCommodityName',
      headerName: 'Hedged Commodity',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'contracts',
      headerName: 'Contracts',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: numberWithCommaFormatter,
    },
    {
      field: 'percentOfTotal',
      headerName: '% of Total',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: currencyValueFormatterTo2,
    },
  ];
  return (
    <div data-testid="AllocatedCommissionAdjustmentGrid" style={{ marginTop: 10 }}>
      <ChangeableGrid
        initialRows={rows}
        isLoading={isLoadingRows}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        onProcessRowUpdateError={console.error}
      />
      <div data-testid="AllocatedCommissionAdjustmentControls" style={{ marginTop: 10 }}>
        <Typography>Total Allocation Amount: </Typography>
        <Typography>{formatCurrencyTo2(formik.values.amount)}</Typography>
      </div>
    </div>
  );
};

export default AllocatedCommissionAdjustmentGrid;
