import React, { useState } from 'react';

import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { useQuery } from '@tanstack/react-query';

import { backendDataClients, useOktaUser } from '../../../../hooks';
import { TradeAccountResponse } from '../../../../services/backend/data-contracts';

const { useTradeAccounts } = backendDataClients;

export interface TradeAccountsFilterForm {
  sortOrder?: string;
  limit: number;
  offset?: number;
  search?: string;
  sortBy?: string;
}

export default function useTradeAccountsPage() {
  const { user } = useOktaUser();
  const selectedEntryState = useState<string>('');
  const dataClient = useTradeAccounts();
  const [entryToEdit] = selectedEntryState;

  const userName = user?.preferred_username ?? '';

  const filterFormState = useState<TradeAccountsFilterForm>({
    sortOrder: '',
    limit: 50,
    offset: 0,
    search: '',
    sortBy: '',
  });
  const [filterForm, setFilterForm] = filterFormState;
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: filterForm.limit,
  });

  const queryParams = Object.assign(
    { limit: filterForm.limit }, // number of rows per page
    { offset: filterForm.offset }, // what row to start at
    filterForm.search === '' ? null : { search: filterForm.search },
    filterForm.sortOrder === '' ? null : { sortOrder: filterForm.sortOrder },
    filterForm.sortBy === '' ? null : { sortBy: filterForm.sortBy }
  );

  const listQuery = useQuery({
    queryKey: ['Trade Accounts', filterForm],
    queryFn: () => dataClient.getFilteredTradeAccounts(queryParams),
    select: (response) => response?.data,
  });

  const detailsQuery = useQuery({
    queryKey: ['Trade Accounts', 'TradeAccounts Details', entryToEdit],
    queryFn: async () => {
      if (entryToEdit) {
        const response: TradeAccountResponse = (await dataClient.getTradeAccountById(entryToEdit))
          .data;
        return response.result;
      } else {
        return null;
      }
    },
  });

  const onPaginationModelChange = (pageChange: GridPaginationModel) => {
    if (
      !isNaN(pageChange.page) &&
      !isNaN(pageChange.pageSize) &&
      (pageChange.page != paginationModel.page || pageChange.pageSize != paginationModel.pageSize)
    ) {
      const offset = +pageChange.page * +pageChange.pageSize;
      setFilterForm({ ...filterForm, limit: pageChange.pageSize, offset });
      setPaginationModel(pageChange);
    }
  };
  const onSortModelChange = (model: GridSortModel) => {
    if (model[0] && model[0].field && model[0].sort) {
      setFilterForm({ ...filterForm, sortBy: model[0].field, sortOrder: model[0].sort });
    } else {
      setFilterForm({ ...filterForm, sortOrder: '', sortBy: '' });
    }
  };
  const onFilterModelChange = (model: GridFilterModel) => {
    if (model && model.quickFilterValues && model.quickFilterValues.length > 0) {
      setFilterForm({ ...filterForm, search: model.quickFilterValues[0] });
    } else {
      setFilterForm({ ...filterForm, search: '' });
    }
  };
  return {
    userName,
    filterFormState,
    selectedEntryState,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
    listQuery,
    detailsQuery,
  };
}
