import React, { useContext, useEffect } from 'react';

import Typography from '@mui/material/Typography';

import { PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';
import { useCommodityGroupsQuery, useOktaUser } from '../../../hooks';
import {
  useCommodityGroups,
  useCommodityGroupsMassUpdates,
} from '../../../hooks/backendDataClients/index';

import CommodityGroupsDisplay from './CommodityGroupsDisplay';

export default function CommodityGroupsPage() {
  const { user } = useOktaUser();
  const dataClientCommodityGroups = useCommodityGroups();
  const dataClientMassUpdates = useCommodityGroupsMassUpdates();
  const userName = user?.preferred_username ?? '';

  useEffect(() => {
    document.title = 'Commodity Groups | Positions and Futures';
  }, []);
  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="CommodityGroupsPage">
          <h2>Commodity Groups</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  return (
    <>
      <main style={{ padding: '24px' }} data-testid="CommodityGroupsPage">
        <CommodityGroupsDisplay
          userName={userName}
          getCommodityGroups={useCommodityGroupsQuery}
          createCommodityGroups={dataClientCommodityGroups.createCommodityGroups}
          updateCommodityGroups={dataClientMassUpdates.updateCommodityGroups}
        />
      </main>
    </>
  );
}
