import React, { FC } from 'react';

import { FormControl, InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';

import {
  CommoditiesSummaryResponse,
  FuturesBrokerResponse,
  FuturesContractsResponse,
  FuturesTradeAccountSummaryResponse,
} from '../../../../services/backend/data-contracts';

export interface FuturesEntriesFilterFormProps {
  commodities?: CommoditiesSummaryResponse['result'];
  futuresContracts?: FuturesContractsResponse['result'];
  brokers?: FuturesBrokerResponse['result'];
  tradeAccountSummaries?: FuturesTradeAccountSummaryResponse['result'];
  filterForm?: any;
  setFilterForm: (filterForm: any) => void;
}

const FuturesEntriesFilterForm: FC<FuturesEntriesFilterFormProps> = ({
  futuresContracts,
  commodities,
  brokers,
  tradeAccountSummaries,
  filterForm,
  setFilterForm,
}) => {
  const handleCommodityFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, commodityId: event.target.value });
  };
  const handleFuturesContractFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, futuresContractId: event.target.value });
  };
  const handleBrokerFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, brokerId: event.target.value });
  };
  const handleTradeAccountsChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, tradeAccountId: event.target.value });
  };
  return (
    <div className="filterBox" data-testid="commodity-select">
      <FormControl fullWidth sx={{ mr: 2 }}>
        <InputLabel id="commodity-label">Commodity</InputLabel>
        <Select
          labelId="commodity-label"
          id="commodity-select"
          value={filterForm.commodityId}
          label="Commodity"
          onChange={handleCommodityFilterChange}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {commodities?.map((t) => (
            <MenuItem key={t.commodityId} value={t.commodityId}>
              ({t.commodityCode}) - {t.commodityName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mr: 2 }}>
        <InputLabel id="futuresContract-label">Futures Contract</InputLabel>
        <Select
          labelId="futuresContract-label"
          id="futuresContract-select"
          value={filterForm.futuresContractId}
          label="futuresContract"
          onChange={handleFuturesContractFilterChange}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {futuresContracts?.map((t) => (
            <MenuItem key={t.futuresContractId} value={t.futuresContractId}>
              {t.futuresContractValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mr: 2 }}>
        <InputLabel id="broker-label">Broker</InputLabel>
        <Select
          labelId="broker-label"
          id="broker-select"
          value={filterForm.brokerId}
          label="Transaction Type"
          onChange={handleBrokerFilterChange}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {brokers?.map((t) => (
            <MenuItem key={t.brokerId} value={t.brokerId}>
              {t.brokerValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="tradeAccounts-label">Trade Account</InputLabel>
        <Select
          labelId="tradeAccounts-label"
          id="tradeAccounts-select"
          value={filterForm.tradeAccountId}
          label="tradeAccounts"
          onChange={handleTradeAccountsChange}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {tradeAccountSummaries?.map((t) => (
            <MenuItem key={t.tradeAccountId} value={t.tradeAccountId}>
              {t.tradeAccountName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default FuturesEntriesFilterForm;
