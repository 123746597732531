import { FC } from 'react';

import { Button } from '@mui/material';
import { GridRowsProp } from '@mui/x-data-grid';

import EndOfDayProcessGrid from '../EndOfDayProcessGrid/EndOfDayProcessGrid';

export interface EndOfDayProcessDisplayProps {
  endOfDayStatus: string;
  endOfDayError: string;
  endOfDayRecords?: GridRowsProp;
  resetEndOfDay: () => void;
  runEndOfDayProcess: () => void;
}

const EndOfDayProcessDisplay: FC<EndOfDayProcessDisplayProps> = ({
  endOfDayStatus,
  endOfDayError,
  endOfDayRecords,
  resetEndOfDay,
  runEndOfDayProcess,
}) => {
  let statusContent;
  if (endOfDayStatus === 'error') {
    statusContent = <p>{endOfDayError}</p>;
  } else if (endOfDayStatus === 'loading') {
    statusContent = <p>Loading ...</p>;
  } else if (endOfDayStatus === 'readyToProcess') {
    statusContent = (
      <p>
        <Button onClick={runEndOfDayProcess}>Run End of Day Process</Button>
      </p>
    );
  } else if (endOfDayStatus === 'notReadyToProcess') {
    statusContent = (
      <p>
        End of Day Processing is Disabled until Market Prices have been imported for the current
        Trading Day
      </p>
    );
  } else if (endOfDayStatus === 'processing') {
    statusContent = <p>Processing End of Day. Please wait...</p>;
  } else if (endOfDayStatus === 'processed') {
    statusContent = (
      <>
        <p>End of day Processed</p>
        <ul>
          <li>Rolling the Trading Day</li>
          <li>Calculating End of Day Balances for each Commodity</li>
          <li>Scheduling End of Day Report Subscriptions</li>
          <li>Scheduling Import of Cross Applied Loads</li>
          <li>Scheduling Export of Futures Transactions</li>
        </ul>
        <p>All steps in the End of Day Process have completed or have been scheduled</p>
        <p>
          <button onClick={resetEndOfDay}>Click here </button> to refresh the screen to view status
          of scheduled processes
        </p>
      </>
    );
  } else {
    statusContent = <p>{endOfDayStatus}</p>;
  }

  return (
    <>
      <main data-testid="EndOfDayProcessPage">
        <h2>End of Day Process</h2>
        <EndOfDayProcessGrid endOfDayRecords={endOfDayRecords}></EndOfDayProcessGrid>
      </main>
      {statusContent}
    </>
  );
};

export default EndOfDayProcessDisplay;
