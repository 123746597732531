import { FC, useEffect, useMemo, useState } from 'react';
import { FormikTouched, FormikValues, setNestedObjectValues, useFormik } from 'formik';

import SaveUnsavedChangesDialog from '../../../../components/SaveUnsavedChangesDialog/SaveUnsavedChangesDialog';
import TransactionEntryModal from '../../../../components/TransactionEntryModal/TransactionEntryModal';
import {
  useFuturesBrokersQuery,
  useFuturesContractOptionMonthRefQuery,
  useFuturesContractRefsByCommodityQuery,
  useFutureTradeAccountDetailsQuery,
  usePositionPeriodOptionMonths,
} from '../../../../hooks';
import { isTradeAccountWithPositionPeriod } from '../FuturesEntryUtils';

import FuturesEntryDetails from './FuturesEntryDetails';
import { getValidationSchema } from './FuturesEntryErrorMessages';
import useFuturesEntryDetails from './useFuturesEntryDetails';

interface FuturesEntryModalProps {
  onClose: () => void;
  selectedEntryId?: string;
  userName: string;
}

const FuturesEntryModal: FC<FuturesEntryModalProps> = ({ selectedEntryId, onClose, userName }) => {
  const [fieldToAutoFocus, setFieldToAutoFocus] = useState<string>('');
  const clearFieldToAutoFocus = () => setFieldToAutoFocus('');
  const [openOnAutoFocus, setOpenOnAutoFocus] = useState<boolean>(true);
  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] = useState<boolean>(false);
  enum AutoFields {
    COMMODITY,
    FUTURES_CONTRACT,
    MONTH,
    POSITION_PERIOD,
  }
  const [currentAutoField, setCurrentAutoField] = useState<AutoFields>();

  const autoUpdateCommodity = () => {
    if (commoditiesMap.size === 1) {
      const [firstKey] = commoditiesMap.keys();
      if (formik.values.commodityId !== firstKey) {
        formik.setFieldValue('commodityId', firstKey, true);
      }
      setCurrentAutoField(AutoFields.FUTURES_CONTRACT);
    } else {
      setCurrentAutoField(undefined);
      formik.setFieldValue('futuresContractId', '');
      formik.setFieldValue('commodityId', '');
      formik.setFieldValue('optionMonthId', '');
      formik.setFieldValue('positionPeriodDimId', '');
    }
  };

  const autoUpdateFuturesContract = () => {
    if (futuresContractsMap.size === 1) {
      const [firstKey] = futuresContractsMap.keys();
      if (formik.values.futuresContractId !== firstKey) {
        formik.setFieldValue('futuresContractId', firstKey, true);
      }
      setCurrentAutoField(AutoFields.MONTH);
    } else {
      formik.setFieldValue('futuresContractId', '');
      formik.setFieldValue('optionMonthId', '');
      formik.setFieldValue('positionPeriodDimId', '');
      setCurrentAutoField(undefined);
    }
  };

  const autoUpdateMonth = () => {
    formik.setFieldValue('optionMonthId', '');
    formik.setFieldValue('positionPeriodDimId', '');
    setCurrentAutoField(undefined);
  };

  const autoUpdatePositionPeriod = () => {
    if (periodsActive && periodMonths?.length && !formik.values.positionPeriodDimId) {
      formik.setFieldValue(
        'positionPeriodDimId',
        periodMonths[periodMonths?.length - 1].positionPeriodDimId,
        true
      );
    }
    setCurrentAutoField(undefined);
  };

  useEffect(() => {
    if (!selectedEntryId) {
      setFieldToAutoFocus('tradeAccountId');
    } else {
      setFieldToAutoFocus('');
    }
  }, [selectedEntryId]);

  // BROKERS
  // String sort instead of number sort intentional
  const brokersQuery = useFuturesBrokersQuery();
  const brokersValues =
    brokersQuery.data
      ?.filter((c) => c.isActive)
      .sort((a, b) => (a.brokerValue ?? '').localeCompare(b.brokerValue ?? '')) ?? [];
  const brokerOptions = brokersValues
    ?.filter((c) => c.isActiveInFutures)
    .map((b) => b.brokerId)
    .filter((id) => id !== undefined) as number[];
  const brokersMap = new Map<number, string>();
  brokersValues?.forEach((b) => {
    if (b.brokerId) {
      brokersMap.set(b.brokerId, b.brokerCode + ' - ' + b.brokerName);
    }
  });

  const {
    getEntryQuery,
    createMutation,
    updateMutation,
    deleteMutation,
    tradeAccountSummariesQuery,
  } = useFuturesEntryDetails(selectedEntryId);

  // TRADE ACCOUNTS
  const tradeAccountSummaries = tradeAccountSummariesQuery.data ?? [];
  const tradeAccountsMap = new Map<number, string>();
  tradeAccountSummaries.forEach((ta) => {
    if (ta.tradeAccountId) {
      tradeAccountsMap.set(ta.tradeAccountId, ta.tradeAccountName ?? '');
    }
  });
  const tradeAccountOptions = [...tradeAccountsMap.keys()];

  const prepareForNextEntry = () => {
    setOpenOnAutoFocus(false);
    formik.resetForm({ values: { ...blankEntryDetails } });
    setFieldToAutoFocus('tradeAccountId');
  };

  const onSave = (currentDetails: any) => {
    const tradeAccount = tradeAccountSummaries?.find(
      (t) => t.tradeAccountId === currentDetails.tradeAccountId
    );

    const entry = {
      ...currentDetails,
      tradeAccountId: Number(currentDetails.tradeAccountId),
      commodityId: Number(currentDetails.commodityId),
      futuresContractId: Number(currentDetails.futuresContractId),
      brokerId: Number(currentDetails.brokerId),
      optionMonthId: Number(currentDetails.optionMonthId),
      positionPeriodDimId: currentDetails.positionPeriodDimId
        ? Number(currentDetails.positionPeriodDimId)
        : undefined,
      futuresPrice: Number(currentDetails.futuresPrice),
      affectsPosition: tradeAccount?.affectsPosition,
      contracts: Number(currentDetails.contracts),
      brokerOrderNumber: currentDetails.brokerOrderNumber
        ? currentDetails.brokerOrderNumber
        : undefined,
      entryUser: userName,
    };
    if (selectedEntryId) {
      updateMutation.mutate(
        {
          id: selectedEntryId,
          data: {
            entry: entry,
          },
        },
        { onSuccess: onClose }
      );
    } else {
      createMutation.mutate({ entry: entry }, { onSuccess: prepareForNextEntry });
    }
  };

  const blankEntryDetails = {
    tradeAccountId: '',
    commodityId: '',
    futuresContractId: '',
    brokerId: '',
    brokerOrderNumber: '',
    contracts: 0,
    futuresPrice: '0.00000',
    optionMonthId: '',
    positionPeriodDimId: '',
    comment: '',
  } as any;

  const initialDetails = getEntryQuery.data ?? blankEntryDetails;

  const formik = useFormik({
    initialValues: initialDetails,
    validationSchema: getValidationSchema(
      tradeAccountOptions,
      brokerOptions,
      getValidCommodityOptions,
      getValidContractOptions,
      getValidMonths,
      isPeriodRequired,
      getValidPeriods
    ),
    onSubmit: () => {
      onSave({ ...formik.values });
    },
  });

  // COMMODITIES (for selected Trade Account)
  const tradeAccountQuery = useFutureTradeAccountDetailsQuery(formik.values.tradeAccountId);
  const commoditiesMap = useMemo(() => {
    const commodities =
      tradeAccountQuery.data?.commodities?.sort((a, b) =>
        ('' + a.commodityCode).localeCompare('' + b.commodityCode)
      ) ?? [];
    return new Map(commodities.map((ta) => [ta.commodityId as number, ta.commodityCode as string]));
  }, [tradeAccountQuery.data?.commodities]);

  if (AutoFields.COMMODITY === currentAutoField && !tradeAccountQuery.isPending) {
    autoUpdateCommodity();
  }

  function getValidCommodityOptions(): Array<number> {
    return [...commoditiesMap.keys()];
  }

  // FUTURES CONTRACTS (for selected commodity)
  const futuresContractRefsQuery = useFuturesContractRefsByCommodityQuery(
    formik.values.commodityId
  );

  const futuresContractsMap = useMemo(() => {
    const mapValues = new Map<number, string>();
    futuresContractRefsQuery.data?.forEach((fc) => {
      if (fc.futuresContractId) {
        mapValues.set(fc.futuresContractId, fc.futuresContractValue ?? '');
      }
    });
    return mapValues;
  }, [futuresContractRefsQuery.data]);
  function getValidContractOptions(): Array<number> {
    return [...futuresContractsMap.keys()];
  }

  if (AutoFields.FUTURES_CONTRACT === currentAutoField && !futuresContractRefsQuery.isPending) {
    autoUpdateFuturesContract();
  }

  // MONTHS (for selected Futures Contract)
  const monthsQuery = useFuturesContractOptionMonthRefQuery(formik.values.futuresContractId);
  const monthsMap = useMemo(() => {
    const mapValues = new Map<number, string>();
    monthsQuery.data?.forEach((m) => {
      if (m.optionMonthId) {
        mapValues.set(m.optionMonthId, m.optionMonthValue ?? '');
      }
    });
    return mapValues;
  }, [monthsQuery.data]);

  if (AutoFields.MONTH === currentAutoField && !monthsQuery.isPending) {
    autoUpdateMonth();
  }

  // POSITION PERIOD (for selected month)
  const periodsQuery = usePositionPeriodOptionMonths(formik.values.optionMonthId);
  const periodsMap = new Map<number, string>();
  let periodsActive = false;
  const periodMonths = periodsQuery.data?.months;
  // nothing in tables to lookup for this. Hard coding
  if (isTradeAccountWithPositionPeriod(formik.values.tradeAccountId)) {
    periodsActive = true;
    periodMonths?.forEach((p) => {
      if (p.positionPeriodDimId) {
        periodsMap.set(p.positionPeriodDimId, p.positionMonth ?? '');
      }
    });
  }
  if (AutoFields.POSITION_PERIOD === currentAutoField && !periodsQuery.isPending) {
    autoUpdatePositionPeriod();
  }

  function getValidMonths(): Array<number> {
    return [...monthsMap.keys()];
  }

  function isPeriodRequired(): boolean {
    return periodsActive;
  }

  function getValidPeriods(): Array<number> {
    return [...periodsMap.keys()];
  }

  // CLICK HANDLERS
  const handleDeleteConfirmedClick = () => {
    deleteMutation.mutate(selectedEntryId, { onSuccess: onClose });
  };
  const handleCancelClick = async () => {
    if (formik.dirty) {
      setIsUnsavedChangesDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleSaveClick = () => {
    setIsUnsavedChangesDialogOpen(false);
    formik.submitForm();
  };
  const handleClearClick = async () => {
    formik.resetForm({ values: blankEntryDetails });
    // Trigger touched on all fields
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      // Form is valid, do any success call
    } else {
      formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
    }
  };

  return (
    <>
      <TransactionEntryModal
        isOpen={true}
        canSave={!updateMutation.isPending && !createMutation.isPending}
        isCreateModal={!selectedEntryId}
        onCancel={handleCancelClick}
        onClear={handleClearClick}
        onCreate={handleSaveClick}
        onUpdate={handleSaveClick}
        onDelete={handleDeleteConfirmedClick}
      >
        <FuturesEntryDetails
          formik={formik}
          tradeAccountsMap={tradeAccountsMap}
          isLoadingTradeAccountOptions={tradeAccountSummariesQuery.isPending}
          futuresContractsMap={futuresContractsMap}
          isLoadingFuturesContractOptions={futuresContractRefsQuery.isPending}
          brokersMap={brokersMap}
          isLoadingBrokers={brokersQuery.isPending}
          commoditiesMap={commoditiesMap}
          isLoadingCommodityOptions={tradeAccountQuery.isPending}
          monthsMap={monthsMap}
          periodsMap={periodsMap}
          periodsActive={periodsActive}
          isLoadingMonthOptions={monthsQuery.isPending}
          isLoadingPeriods={periodsActive && periodsQuery.isPending}
          fieldToAutoFocus={fieldToAutoFocus}
          onAutoFocus={clearFieldToAutoFocus}
          openOnAutoFocus={openOnAutoFocus}
          onTradeAccountChange={() => setCurrentAutoField(AutoFields.COMMODITY)}
          onCommodityChange={() => setCurrentAutoField(AutoFields.FUTURES_CONTRACT)}
          onFuturesContractChange={() => setCurrentAutoField(AutoFields.MONTH)}
          onMonthsChange={() => setCurrentAutoField(AutoFields.POSITION_PERIOD)}
        ></FuturesEntryDetails>
      </TransactionEntryModal>
      <SaveUnsavedChangesDialog
        isActive={isUnsavedChangesDialogOpen}
        onSave={handleSaveClick}
        onDiscard={onClose}
        onCancel={() => setIsUnsavedChangesDialogOpen(false)}
      ></SaveUnsavedChangesDialog>
    </>
  );
};

export default FuturesEntryModal;
