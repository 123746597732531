import React, { FC, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { CreateUserRequest } from '../../../../services/backend/data-contracts';
import UserRoleDetails, { UserDetailsRef } from '../UserRoleDetails/UserRoleDetails';

import useUserModal from './useUserModal';

interface UserModalProps {
  selectedUserId: number;
  handleModalClose: () => void;
}

const UserModal: FC<UserModalProps> = ({ selectedUserId, handleModalClose }) => {
  const [detailsErrors, setDetailsErrors] = useState<string[]>([]);

  const { details, updateUserMutation, isProcessing, createUserMutation } =
    useUserModal(selectedUserId);
  const isNewUser = !selectedUserId;
  const entryRef = React.useRef<UserDetailsRef | null>(null);

  const handleCreateClick = () => {
    entryRef?.current?.handleSaveClick().catch(() => {});
  };

  const handleCreateUserSaveClick = async (formValues: CreateUserRequest) => {
    createUserMutation.mutate({
      entry: formValues,
      onSuccess: () => {
        handleModalClose();
        setDetailsErrors([]);
      },
      onerror: (e: any) => {
        if (e.response?.data?.data) {
          setDetailsErrors(e.response.data.data);
        }
        if (e.response?.data?.message) {
          setDetailsErrors([...detailsErrors, e.response.data.message]);
        }
      },
    });
  };

  const handleSaveClick = (roleId: number, doesUserHaveRole: boolean) => {
    updateUserMutation.mutate({ roleId, doesUserHaveRole });
  };

  const disableCreateButton = detailsErrors.length != 0 || isProcessing;

  return (
    <Dialog fullWidth maxWidth="md" open={true} data-testid="UserModal">
      <DialogTitle>{'User ' + (isNewUser ? 'Create' : 'Update')}</DialogTitle>
      <DialogContent>
        <UserRoleDetails
          selectedUserId={selectedUserId}
          details={details}
          handleCreateUserSaveClick={handleCreateUserSaveClick}
          updateUserRole={handleSaveClick}
          ref={entryRef}
          disableRoleChanges={isProcessing}
        ></UserRoleDetails>
      </DialogContent>
      <DialogActions>
        {detailsErrors.length > 0 ? (
          <div>
            <p>There was an error: </p>
            <ul>
              {detailsErrors.map((detailError, i) => (
                <li key={i}>{detailError}</li>
              ))}
            </ul>
          </div>
        ) : null}
        {isNewUser ? (
          <>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button onClick={handleCreateClick} disabled={disableCreateButton}>
              Create
            </Button>
          </>
        ) : (
          <Button onClick={handleModalClose}>Close</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UserModal;
