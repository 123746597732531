import { useState } from 'react';

import { useRolesQuery, useUserListQuery } from '../../../hooks/useUsersQueries';

export default function useUsersPage() {
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const getUsersResults = useUserListQuery();

  const usersQueryFn = () => getUsersResults;
  const handleModalClose = () => {
    setSelectedUserId(0);
    setIsModalOpen(false);
  };

  const handleModalOpen = (userId: number) => {
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };

  // Prefetch
  useRolesQuery();

  return {
    usersQueryFn,
    isModalOpen,
    selectedUserId,
    handleModalOpen,
    handleModalClose,
  };
}
