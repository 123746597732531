import { FC, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';

import { ChangeableDisplay, yesNoGridColTypeDef } from '../../../../components';
import { UseRefDataQueryResult } from '../../../../hooks';
import { FuturesTradeAccountResponse } from '../../../../services/backend/data-contracts';
import { RequestParams } from '../../../../services/backend/http-client';
import FuturesTradeAccountDetails, {
  FuturesTradeAccountDetailsProps,
} from '../FuturesTradeAccountDetails/FuturesTradeAccountDetails';

export interface FuturesTradeAccountsListProps
  extends Omit<FuturesTradeAccountDetailsProps, 'futuresTradeAccount' | 'setShowEditForRow'> {
  getFuturesTradeAccounts: () => UseRefDataQueryResult<FuturesTradeAccountResponse['result']>;
  updateFuturesTradeAccounts: (
    data: any,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  hideUnsavedPrompt?: boolean;
}

const FuturesTradeAccountsList: FC<FuturesTradeAccountsListProps> = ({
  getFuturesTradeAccounts,
  updateFuturesTradeAccounts,
  hideUnsavedPrompt,
  ...props
}) => {
  const [showEditForRow, setShowEditForRow] = useState<GridValidRowModel | null>(null);

  const columns: GridColDef[] = [
    {
      field: 'tradeAccountCode',
      headerName: 'Trade Account Code',
      flex: 1,
      type: 'string',
      editable: false,
      renderCell: ({ value, row }) => (
        <Link
          onClick={() => setShowEditForRow(row)}
          sx={{ cursor: 'pointer' }}
          data-testid="LinkToModal"
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'tradeAccountName',
      headerName: 'Trade Account Name',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'affectsPosition',
      headerName: 'Affects Position',
      flex: 1,
      editable: true,
      ...yesNoGridColTypeDef('affectsPosition'),
    },
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 1,
      editable: true,
      ...yesNoGridColTypeDef('isActive'),
    },
    {
      field: 'isActiveInFutures',
      headerName: 'Status in Futures',
      flex: 1,
      valueGetter: (params) => (params.row['isActiveInFutures'] ? 'Active' : 'Inactive'),
    },
  ];

  const update = (oldRows: GridValidRowModel[]) => {
    return oldRows?.length
      ? updateFuturesTradeAccounts({
          accounts: oldRows.map((r) => ({
            tradeAccountId: r.tradeAccountId,
            isActive: r.isActive,
            affectsPosition: r.affectsPosition,
            commodities: r.commodities,
          })),
        })
      : Promise.resolve();
  };

  return (
    <>
      <ChangeableDisplay
        pageTitle="Futures Trade Accounts"
        columns={columns}
        getRowId={(row) => row['tradeAccountId']}
        update={update}
        fnUseQuery={getFuturesTradeAccounts}
        uniqueFields={['tradeAccountCode']}
        sortField="tradeAccountCode"
        isCellEditable={({ row }) => row.isActiveInFutures}
        infoIndicator={(row) =>
          !row.isActiveInFutures
            ? 'Futures Trade Accounts that are inactive in Futures cannot be modified in the Position System.'
            : null
        }
        hideUnsavedPrompt={hideUnsavedPrompt}
        modalEdit={(setter) => (
          <Dialog fullWidth maxWidth="md" open={!!showEditForRow}>
            <DialogTitle>Trade Account Edit</DialogTitle>
            <DialogContent>
              <FuturesTradeAccountDetails
                futuresTradeAccount={showEditForRow}
                setShowEditForRow={setShowEditForRow}
                {...props}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowEditForRow(null)}>Cancel</Button>
              <Button
                onClick={() => {
                  showEditForRow &&
                    setter({
                      ...showEditForRow,
                      commodities: showEditForRow.newCommodities || showEditForRow.commodities,
                    });
                  setShowEditForRow(null);
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
      ></ChangeableDisplay>
    </>
  );
};

export default FuturesTradeAccountsList;
