import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/system/Box';
import { useQuery } from '@tanstack/react-query';

import { backendDataClients } from '../../../../hooks';
import { CreateUserRequest } from '../../../../services/backend/data-contracts';

const { useRoles } = backendDataClients;
export interface UserRoleDetailsProps {
  selectedUserId: number;
  details: any;
  handleCreateUserSaveClick: (formValues: CreateUserRequest) => void;
  updateUserRole: (roleId: number, doesUserHaveRole: boolean) => void;
  disableRoleChanges: boolean;
}

export interface UserDetailsRef {
  handleSaveClick: () => Promise<void>;
}

const buildInitialNewUser = () => {
  return {
    userName: '',
    userEmail: '',
    isActive: true,
    roles: [],
  };
};

const UserRoleDetails = forwardRef<UserDetailsRef, UserRoleDetailsProps>(function UserRoleDetails(
  { selectedUserId, details, handleCreateUserSaveClick, updateUserRole, disableRoleChanges },
  _ref
) {
  const [entryForm, setEntryForm] = useState<any>(buildInitialNewUser);
  const [userNameInputErrorText, setUserNameInputErrorText] = useState<string>('');
  const [userEmailInputErrorText, setUserEmailInputErrorText] = useState<string>('');
  const [userRolesInputErrorText, setUserRolesInputErrorText] = useState<string>('');

  const rolesDataClient = useRoles();
  const { data: roles } = useQuery({
    queryKey: ['Roles'],
    queryFn: rolesDataClient.getRoles,
    select: (response) => response?.data?.result,
  });

  useImperativeHandle(_ref, () => ({
    handleSaveClick: async () => {
      let readyToSave = true;
      if (!entryForm.userName) {
        setUserNameInputErrorText('Name is required');
        readyToSave = false;
      } else {
        setUserNameInputErrorText('');
      }
      if (!entryForm.userEmail) {
        setUserEmailInputErrorText('Email is required');
        readyToSave = false;
      } else {
        setUserEmailInputErrorText('');
      }
      if (entryForm.roles.length === 0) {
        setUserRolesInputErrorText('At least one role is required');
        readyToSave = false;
      } else {
        setUserRolesInputErrorText('');
      }
      if (readyToSave) {
        handleCreateUserSaveClick({ entry: entryForm } as CreateUserRequest);
      }
    },
  }));

  if (selectedUserId && (details.userEmail === null || details.userEmail === undefined)) {
    return <span>Loading...</span>;
  }

  if (!roles) {
    return <span>Loading...</span>;
  }

  const isChecked = (roleId: number | undefined) => {
    if (selectedUserId) {
      return !!details.roles.find((t: any) => {
        return t.roleId === roleId;
      });
    } else {
      return !!entryForm.roles.find((t: any) => {
        return t.roleId === roleId;
      });
    }
  };

  const handleUserNameChange = (change: ChangeEvent<HTMLInputElement>) => {
    setEntryForm({
      ...entryForm,
      userName: change.target.value,
    });
  };

  const handleUserEmailChange = (change: ChangeEvent<HTMLInputElement>) => {
    setEntryForm({
      ...entryForm,
      userEmail: change.target.value,
    });
  };

  const handleRoleCheck = async (change: ChangeEvent<HTMLInputElement>) => {
    const roleId = +change.target.value;
    if (selectedUserId) {
      const doesUserHaveRole = details.roles.find((t: any) => t.roleId === roleId);
      if (doesUserHaveRole && details.roles.length === 1) {
        setUserRolesInputErrorText('At least one role is required');
      } else {
        setUserRolesInputErrorText('');
        updateUserRole(roleId, !!doesUserHaveRole);
      }
    } else {
      const doesUserHaveRole = entryForm.roles.find((t: any) => t.roleId === roleId);
      if (doesUserHaveRole) {
        const newRoles = entryForm.roles.filter((r: any) => r.roleId !== roleId);
        setEntryForm({ ...entryForm, roles: newRoles });
      } else {
        const role = roles?.find((t: any) => t.roleId === roleId);
        setEntryForm({ ...entryForm, roles: [...entryForm.roles, role] });
      }
    }
  };

  return (
    <Box data-testid="UserRoleDetails">
      {details ? (
        <Grid container spacing={2}>
          <Grid container item direction="column" xs={6} spacing={2}>
            <Grid container item alignItems="Center">
              <Grid item xs={6} id="userName-lable-id">
                Name:
              </Grid>
              <Grid item xs={6}>
                {selectedUserId ? (
                  details?.userName
                ) : (
                  <TextField
                    id="userName"
                    defaultValue={entryForm.userName}
                    onChange={handleUserNameChange}
                    size="small"
                    required
                    error={!!userNameInputErrorText}
                    helperText={userNameInputErrorText}
                    aria-labelledby="userName-label-id"
                  />
                )}
              </Grid>
            </Grid>
            <Grid container item alignItems="Center">
              <Grid item xs={6} id="userEmail-label-id">
                Email:
              </Grid>
              <Grid item xs={6}>
                {selectedUserId ? (
                  details.userEmail
                ) : (
                  <TextField
                    id="email"
                    defaultValue={entryForm.userEmail}
                    onChange={handleUserEmailChange}
                    required
                    error={!!userEmailInputErrorText}
                    helperText={userEmailInputErrorText}
                    size="small"
                    aria-labelledby="userEmail-label-id"
                  />
                )}
              </Grid>
            </Grid>
            <Grid container item alignItems="Center">
              <Grid item xs={6}>
                <FormControl
                  required
                  error={!!userRolesInputErrorText}
                  component="fieldset"
                  sx={{ m: 3 }}
                  variant="standard"
                >
                  <FormLabel component="legend">Roles</FormLabel>
                  <FormGroup>
                    {roles?.map((role: any) => {
                      return (
                        <FormControlLabel
                          key={role.roleId}
                          control={
                            <Checkbox
                              id={'role-' + role.roleId}
                              key={role.roleId}
                              value={role.roleId}
                              onChange={handleRoleCheck}
                              checked={isChecked(role.roleId)}
                              disabled={disableRoleChanges}
                            />
                          }
                          label={role.roleName}
                        ></FormControlLabel>
                      );
                    })}
                  </FormGroup>
                  {userRolesInputErrorText ? (
                    <FormHelperText>{userRolesInputErrorText}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
});

export default UserRoleDetails;
