import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';

import { Loading } from '../../components';
import NotAuthorized from '../NotAuthorized/NotAuthorized';
import { NotLoggedIn } from '../NotLoggedIn';

const RequiredAuth = (props: any) => {
  const { authState, oktaAuth }: IOktaContext = useOktaAuth();
  const [searchParams] = useSearchParams();

  if (authState?.isAuthenticated === false ?? true) {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);
    void oktaAuth.signInWithRedirect();
    return <Loading />;
  }

  const error = searchParams.get('error');
  if (error === 'access_denied') {
    return <NotAuthorized />;
  }

  if (authState?.idToken === null) {
    return <NotLoggedIn />;
  }

  return props.children;
};

export default RequiredAuth;
