import { AuthState } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

import { securityWorker } from '../models/Security';
import { Whoami } from '../services/backend/Whoami';

export const useWhoAmI: () => Whoami<AuthState> = () => {
  const { oktaAuth } = useOktaAuth();
  const whoAmIClient = new Whoami({
    securityWorker,
    baseURL: process.env.REACT_APP_BE_URL,
  });
  if (oktaAuth.authStateManager.getAuthState()?.isAuthenticated) {
    whoAmIClient.setSecurityData(oktaAuth.authStateManager.getAuthState());
  }
  oktaAuth.authStateManager.subscribe((authState) => {
    whoAmIClient.setSecurityData(authState);
  });
  return whoAmIClient;
};
