import React, { FC } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';

import { DialogContent, DialogTitle } from '@mui/material';

import TransactionEntryModal from '../../../../components/TransactionEntryModal/TransactionEntryModal';
import { backendDataClients } from '../../../../hooks';
import {
  useBrokerAdjustmentTypeRefsQuery,
  useBrokerRefsQuery,
  useHedgedCommodityRefsQuery,
  useTradeAccountRefsQuery,
} from '../../../../hooks/useFuturesDataQueries';
import { useToastMutation } from '../../../../hooks/useToastMutations';
import { BrokerAdjustmentTypeRef } from '../../../../services/backend/data-contracts';

import BrokerMarginAdjustmentForm from './BrokerMarginAdjustmentForm/BrokerMarginAdjustmentForm';
import { brokerMarginAdjustmentEntrySchema } from './brokerMarginAdjustmentEntry.schema';

const { useCashAdjustment } = backendDataClients;

interface BrokerMarginAdjustmentEntryProps {
  isOpen: boolean;
  onClose: () => void;
}

const blankEntryDetail = {
  adjustmentDate: moment(),
  brokerId: null,
  adjustmentTypeId: null,
  amount: '',
  tradeAccountId: null,
  hedgedCommodityId: null,
  comment: '',
  isBackDated: false,
};

export const useBrokerMarginAdjustmentFormik = (
  createMutation: (entry: any) => Promise<void>,
  adjustmentTypeRefs: BrokerAdjustmentTypeRef['result'] | undefined
) => {
  return useFormik({
    initialValues: blankEntryDetail,
    validationSchema: brokerMarginAdjustmentEntrySchema,
    validate: (values) => {
      if (values.adjustmentTypeId) {
        const adjustmentTypeId = +values.adjustmentTypeId;
        const adjustmentType = adjustmentTypeRefs?.find(
          (x) => x.adjustmentTypeId === adjustmentTypeId
        );
        if (adjustmentType?.isTradeAcctRequired && !values.tradeAccountId) {
          return { tradeAccountId: 'Trade Account is required' };
        } else {
          return {};
        }
      }
    },
    onSubmit: (values: any) => {
      const entry = {
        adjustmentDate: moment.utc(values.adjustmentDate).format('YYYY-MM-DD'),
        brokerId: Number(values.brokerId),
        adjustmentTypeId: Number(values.adjustmentTypeId),
        amount: Number(values.amount),
        tradeAccountId: Number(values.tradeAccountId) || null,
        hedgedCommodityId: Number(values.hedgedCommodityId) || null,
        comment: values.comment,
        isBackDated: values.isBackDated,
      };
      return createMutation(entry);
    },
  });
};

const BrokerMarginAdjustmentEntry: FC<BrokerMarginAdjustmentEntryProps> = ({ isOpen, onClose }) => {
  const backdateDate = moment().subtract(1, 'months').endOf('month');
  const adjustmentTypesQuery = useBrokerAdjustmentTypeRefsQuery();
  const dataClient = useCashAdjustment();
  const createMutation = useToastMutation({
    mutationFn: (entry: any) => dataClient.createCashAdjustment(entry),
    queryKey: ['CashAdjustments'],
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Adjustment Created',
  });
  const handleModalClose = async () => {
    onClose();
    await formik.setValues(blankEntryDetail);
    formik.setTouched({}, false);
    formik.setErrors({});
  };

  const formik = useBrokerMarginAdjustmentFormik(
    (entry) => createMutation.mutateAsync({ entry }).then(handleModalClose),
    adjustmentTypesQuery?.data
  );
  const brokersQuery = useBrokerRefsQuery();
  const tradeAccountsQuery = useTradeAccountRefsQuery();
  const hedgedCommoditiesQuery = useHedgedCommodityRefsQuery();
  const handleClear = async () => {
    formik.resetForm();
  };
  const handleSave = () => {
    formik.submitForm();
  };

  const handleBackDateButtonClick = () => {
    formik.setFieldValue('adjustmentDate', backdateDate.format('YYYY-MM-DD'));
    formik.setFieldValue('isBackDated', true);
  };
  const handleManualTradeDateChange = () => {
    if (formik.values.adjustmentDate !== backdateDate) {
      formik.setFieldValue('isBackDated', false);
    }
  };

  return (
    <div data-testid="BrokerMarginAdjustmentEntry">
      <TransactionEntryModal
        isOpen={isOpen}
        isCreateModal={true}
        onCancel={handleModalClose}
        onClear={handleClear}
        onCreate={handleSave}
        onUpdate={() => {}}
        canSave={!createMutation.isPending}
        extraButtonText="Back Date to Prior Month"
        onExtraButtonClick={handleBackDateButtonClick}
      >
        <>
          <DialogTitle>Broker Margin Adjustments Entries</DialogTitle>
          <DialogContent>
            <BrokerMarginAdjustmentForm
              formik={formik}
              loadingBrokers={brokersQuery.isPending}
              brokerRefs={brokersQuery.data}
              loadingAdjustmentTypes={adjustmentTypesQuery.isPending}
              adjustmentTypeRefs={adjustmentTypesQuery.data}
              loadingTradeAccounts={tradeAccountsQuery.isPending}
              tradeAccountRefs={tradeAccountsQuery.data}
              loadingCommodities={hedgedCommoditiesQuery.isPending}
              hedgedCommodityRefs={hedgedCommoditiesQuery.data}
              handleManualTradeDateChange={handleManualTradeDateChange}
            ></BrokerMarginAdjustmentForm>
          </DialogContent>
        </>
      </TransactionEntryModal>
    </div>
  );
};

export default BrokerMarginAdjustmentEntry;
