import { FC } from 'react';

import { Box, Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import {
  GridRenderEditCellParams,
  GridValidRowModel,
  renderEditInputCell,
  renderEditSingleSelectCell,
} from '@mui/x-data-grid';

import { SmallChangeableGrid } from '../../../../components';
import DivisionAutocomplete from '../../../../components/DivisionAutocomplete/DivisionAutocomplete';
import FormikTextAutocomplete from '../../../../components/FormikTextAutocomplete/FormikTextAutocomplete';
import {
  FuturesBrokerResponse,
  FuturesContractsResponse,
} from '../../../../services/backend/data-contracts';

interface BrokerEntryDetailsProps {
  formik: any;
  futuresContracts?: FuturesContractsResponse['result'];
  brokers?: FuturesBrokerResponse['result'];
  selectedEntryId?: number;
}

const BrokerEntryDetails: FC<BrokerEntryDetailsProps> = ({
  formik,
  futuresContracts,
  brokers,
  selectedEntryId,
}) => {
  const updateFuturesContractId = (selectedCommissionRate: GridValidRowModel) => {
    if (selectedCommissionRate.futuresContractId) {
      const foundContract = futuresContracts?.find(
        (c) => selectedCommissionRate.futuresContractId === c.futuresContractId
      );
      selectedCommissionRate.futuresContractId = foundContract?.futuresContractId;
    }
  };
  const commissionTypes = [
    { commissionTypeId: 'LOT', commissionTypeName: 'Half-Turn per Lot' },
    { commissionTypeId: '%', commissionTypeName: 'Percentage of Price' },
    { commissionTypeId: 'N/A', commissionTypeName: 'N/A' },
  ];
  const updateCommissionType = (selectedCommissionRate: GridValidRowModel) => {
    if (selectedCommissionRate.defaultFuturesContractValue) {
      const foundCommissionType = commissionTypes?.find(
        (fc) => selectedCommissionRate.commissionRateType === fc.commissionTypeId
      );
      selectedCommissionRate.commissionRateType = foundCommissionType?.commissionTypeId;
    }
  };

  const handleCommissionRateChange = (selectedCommissionRates: GridValidRowModel[]) => {
    selectedCommissionRates = selectedCommissionRates.map((commission) => ({
      ...commission,
      brokerId: selectedEntryId,
    }));

    selectedCommissionRates.forEach(updateFuturesContractId);
    selectedCommissionRates.forEach(updateCommissionType);

    const incompleteCommissionRates = selectedCommissionRates.filter(
      (c) => !c.futuresContractId || !c.commissionRate || !c.commissionRateType
    );
    const completeCommissionRates = selectedCommissionRates.filter(
      (c) => incompleteCommissionRates.indexOf(c) < 0
    );

    const errorMessage = 'Field Missing';
    incompleteCommissionRates.forEach((c) => (c.errors = [errorMessage]));
    completeCommissionRates.forEach((c) => (c.errors = undefined));

    formik.setFieldValue(
      'commissionRates',
      selectedCommissionRates.filter(
        (c) => (c.commissionRateId || c.commissionRateType || c.commissionRate) && !c.remove
      ),
      true
    );
  };

  const brokerNames = brokers?.map((broker) => broker.brokerName);

  const renderSingleSelectCell = () => {
    return (params: GridRenderEditCellParams) =>
      renderEditSingleSelectCell({
        ...params,
        onBlur: saveChangeToRowData(params),
      });
  };

  const saveChangeToRowData = (params: GridRenderEditCellParams) => {
    return () => {
      const id = params.id;
      const field = params.field;
      params.api.stopCellEditMode({ id, field });
    };
  };

  const handleIsActiveChange = (event: SelectChangeEvent) => {
    let value;
    if (event.target.value === 'true') value = true;
    else if (event.target.value === 'false') value = false;
    formik.setFieldValue('isActive', value, true);
  };

  const allFuturesContracts = futuresContracts;
  return (
    <Box data-testid="BrokerEntryDetails">
      {!formik.values ? null : (
        <form>
          <Grid container spacing={2}>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="broker-code-input-label">
                Broker Code:
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="broker-code-text"
                  name="brokerCode"
                  value={formik.values.brokerCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Broker Code"
                  error={formik.touched.brokerCode && Boolean(formik.errors.brokerCode)}
                  helperText={formik.touched.brokerCode && formik.errors.brokerCode?.toString()}
                  InputProps={{ 'aria-labelledby': 'broker-code-input-label' }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="broker-name-input-label">
                Broker Name:
              </Grid>
              <Grid item xs={6}>
                <FormikTextAutocomplete
                  formik={formik}
                  field="brokerName"
                  options={brokerNames}
                  openOnFocus
                ></FormikTextAutocomplete>
              </Grid>
            </Grid>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="division-input-label">
                Division:
              </Grid>
              <Grid item xs={6}>
                <DivisionAutocomplete formik={formik} />
              </Grid>
            </Grid>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="gl-account-label">
                GL Account:
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="gl-account-text"
                  name="glAccount"
                  value={formik.values.glAccount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="gl-account"
                  InputProps={{ 'aria-labelledby': 'gl-account-label' }}
                  error={formik.touched.glAccount && Boolean(formik.errors.glAccount)}
                  helperText={formik.touched.glAccount && formik.errors.glAccount?.toString()}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="company-code-label">
                GL Company Code:
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="company-code-text"
                  name="glCompanyCode"
                  value={formik.values.glCompanyCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="company-code"
                  InputProps={{ 'aria-labelledby': 'company-code-label' }}
                  error={formik.touched.glCompanyCode && Boolean(formik.errors.glCompanyCode)}
                  helperText={
                    formik.touched.glCompanyCode && formik.errors.glCompanyCode?.toString()
                  }
                ></TextField>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="is-active-label">
                Active:
              </Grid>
              <Grid item xs={6}>
                <Select
                  value={formik.values.isActive + ''}
                  size="small"
                  onChange={handleIsActiveChange}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
                {formik.errors.isActive && formik.touched.isActive && (
                  <div id="feedback">{formik.errors.isActive?.toString()}</div>
                )}
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <SmallChangeableGrid
                tableTitle="Commission Rates"
                columns={[
                  {
                    field: 'futuresContractId',
                    headerName: 'Futures Contract',
                    type: 'singleSelect',
                    getOptionLabel: (value: any) => value?.futuresContractValue,
                    getOptionValue: (value: any) => value?.futuresContractId,
                    valueOptions: allFuturesContracts,
                    editable: true,
                    renderEditCell: renderSingleSelectCell(),
                    flex: 1,
                  },
                  {
                    field: 'commissionRateType',
                    headerName: 'Commission Type',
                    type: 'singleSelect',
                    getOptionLabel: (value: any) => value?.commissionTypeName,
                    getOptionValue: (value: any) => value?.commissionTypeId,
                    valueOptions: commissionTypes,
                    editable: true,
                    renderEditCell: renderSingleSelectCell(),
                    flex: 1,
                  },
                  {
                    field: 'commissionRate',
                    headerName: 'Commission Rate',
                    type: 'number',
                    editable: true,
                    renderEditCell: (params) =>
                      renderEditInputCell({
                        ...params,
                        onBlur: saveChangeToRowData(params),
                      }),
                    flex: 1,
                  },
                ]}
                data={formik.values.commissionRates}
                onAddButtonClick={(rowId) => ({
                  futuresContractId: '',
                  commissionRate: '',
                  commissionRateType: '',
                  brokerId: '',
                  id: rowId,
                })}
                setDataChanged={handleCommissionRateChange}
                canRemoveAny
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default BrokerEntryDetails;
