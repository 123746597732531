import React, { useEffect, useState } from 'react';

import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  backendDataClients,
  useCommoditiesQuery,
  useFuturesBrokersQuery,
  useFuturesContractsQuery,
  useFuturesTradeAccountsSummaryQuery,
  useOktaUser,
} from '../../../hooks';
import {
  useCommodityFuturesContractQuery,
  useFuturesTradeAccountsQuery,
  usePrefetchFuturesContractOptionMonthRefs,
} from '../../../hooks/useRefDataQueries';

const { useFutureEntries } = backendDataClients;

export interface FuturesTradesFilterForm {
  sortOrder?: string;
  limit: number;
  offset?: number;
  search?: string;
  sortBy?: string;
  commodityId?: string;
  futuresContractId?: string;
  brokerId?: string;
  tradeAccountId?: string;
}

export default function useFuturesEntries() {
  const { user } = useOktaUser();
  const userName = user?.preferred_username ?? '';

  const [filterForm, setFilterForm] = useState<FuturesTradesFilterForm>({
    sortOrder: '',
    limit: 50,
    futuresContractId: '',
    offset: 0,
    brokerId: '',
    search: '',
    sortBy: '',
    tradeAccountId: '',
    commodityId: '',
  });

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: filterForm.limit,
  });

  // Prefetch for details
  useFuturesTradeAccountsQuery();
  useCommodityFuturesContractQuery(true);
  usePrefetchFuturesContractOptionMonthRefs();

  const futureEntriesDataClient = useFutureEntries();
  const queryClient = useQueryClient();
  const commodities =
    useCommoditiesQuery()
      .data?.filter((c) => c.isActive)
      ?.sort((a, b) => (a.commodityCode ?? '').localeCompare(b.commodityCode ?? '')) ?? [];
  const futuresContracts = useFuturesContractsQuery()
    .data?.filter((c) => c.isActive)
    ?.sort((a, b) => (a.futuresContractValue ?? '').localeCompare(b.futuresContractValue ?? ''));
  const brokers = useFuturesBrokersQuery()
    ?.data?.filter((c) => c.isActive)
    ?.sort((a, b) => (a.brokerValue ?? '').localeCompare(b.brokerValue ?? ''));
  const tradeAccountSummaries = useFuturesTradeAccountsSummaryQuery()?.data?.sort((a, b) =>
    (a.tradeAccountName ?? '').localeCompare(b.tradeAccountName ?? '')
  );
  const queryParams = Object.assign(
    {
      limit: filterForm.limit, // number of rows per page
      offset: filterForm.offset, // what row to start at
    },
    filterForm.futuresContractId === ''
      ? null
      : { futuresContractId: filterForm.futuresContractId },
    filterForm.commodityId === '' ? null : { commodityId: filterForm.commodityId },
    filterForm.brokerId === '' ? null : { brokerId: filterForm.brokerId },
    filterForm.tradeAccountId === '' ? null : { tradeAccountId: filterForm.tradeAccountId },
    filterForm.search === '' ? null : { search: filterForm.search },
    filterForm.sortOrder === '' ? null : { sortOrder: filterForm.sortOrder },
    filterForm.sortBy === '' ? null : { sortBy: filterForm.sortBy }
  );
  const futuresEntriesQuery = useQuery({
    queryKey: ['FuturesEntries', filterForm],
    queryFn: async () => {
      const entriesWrapper = (await futureEntriesDataClient.getFilteredFutureEntries(queryParams))
        .data?.result;
      entriesWrapper?.entries?.forEach((fe: any) => {
        if (fe.id) {
          queryClient.setQueryData(['Futures Entries', 'Details', fe.id], fe);
        }
      });
      return entriesWrapper;
    },
  });

  const rows: GridRowsProp[] =
    futuresEntriesQuery.data?.entries?.map((t: any) => {
      return { id: t.id, ...t } as unknown as GridRowsProp;
    }) ?? [];

  const totalCount = futuresEntriesQuery.data?.totalCount;
  const [rowCountState, setRowCountState] = React.useState(totalCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);
  const onPaginationModelChange = (pageChange: GridPaginationModel) => {
    if (!isNaN(pageChange.page) && !isNaN(pageChange.pageSize)) {
      const offset = +pageChange.page * +pageChange.pageSize;
      setFilterForm({ ...filterForm, limit: pageChange.pageSize, offset });
      setPaginationModel(pageChange);
    }
  };
  const onSortModelChange = (model: GridSortModel) => {
    if (model[0] && model[0].field && model[0].sort) {
      const sortBy = model[0].field;
      if (sortBy === 'orderNumber') {
        setFilterForm({ ...filterForm, sortBy: 'brokerordernumber', sortOrder: model[0].sort });
      } else {
        setFilterForm({ ...filterForm, sortBy: sortBy, sortOrder: model[0].sort });
      }
    } else {
      setFilterForm({ ...filterForm, sortOrder: '', sortBy: '' });
    }
  };
  const onFilterModelChange = (model: GridFilterModel) => {
    if (model && model.quickFilterValues && model.quickFilterValues.length > 0) {
      setFilterForm({ ...filterForm, search: model.quickFilterValues[0] });
    } else {
      setFilterForm({ ...filterForm, search: '' });
    }
  };
  return {
    userName,
    filterForm,
    rowCountState,
    setFilterForm,
    rows,
    isLoadingRows: futuresEntriesQuery.isPending,
    commodities,
    futuresContracts,
    brokers,
    tradeAccountSummaries,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
  };
}
