import { QueryClient, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';

import {
  CommodityFuturesContractsRefResponse,
  FuturesContractOptionMonthRefResponse,
  OptionMonthsRefResponse,
} from '../services/backend/data-contracts';

import {
  useCommodities,
  useCommoditiesSummaries,
  useCommodityGroups,
  useCommodityGroupsSummaries,
  useFuturesBrokers,
  useFuturesOptionMonths as useFuturesOptionMonthsHoook,
  useFuturesTradeAccounts,
  useFuturesTradeAccountsSummaries,
  useLocationGroups,
  useLocations,
  useLocationsSummaries,
  usePositionsOptionMonths,
  useRefFuturesContracts,
  useRefFuturesContractsSummaries,
  useTransactionTypes,
  useTransactionTypesSummaries,
  useViews,
} from './backendDataClients';
import { THIRTY_MINUTES } from './useQueryClientWithNotifications';

export type UseRefDataQueryResult<T = any[]> = Partial<UseQueryResult<T>>;
export const locationKeys = {
  all: ['RefData', 'Locations'] as const,
  list: () => [...locationKeys.all, 'List'] as const,
  summaries: () => [...locationKeys.all, 'Summaries'] as const,
  details: (id: string) => [...locationKeys.all, 'Details', id] as const,
  refList: () => [...locationKeys.all, 'LocationRefs'] as const,
};

export const locationCommodityRefsKeys = {
  all: ['RefData', 'LocationCommodityRefs'] as const,
};

export const commodityKeys = {
  all: ['RefData', 'Commodities'] as const,
  list: () => [...commodityKeys.all, 'List'] as const,
  details: (id: string) => [...commodityKeys.all, 'Details', id] as const,
  summaries: () => [...commodityKeys.all, 'Summaries'] as const,
  optionMonthsList: () => [...commodityKeys.all, 'CommodityOptionMonths'] as const,
  optionMonths: (id?: string) =>
    [...commodityKeys.all, 'CommodityOptionMonths', id ?? 'none'] as const,
  refList: () => [...commodityKeys.all, 'CommodityRefs'],
};

export const useCommodityGroupsQuery = () =>
  useQuery({
    queryKey: ['RefData', 'CommodityGroups'],
    queryFn: useCommodityGroups().getCommodityGroups,
    select: (response) => response?.data?.result,
  });

export const useCommodityGroupsSummaryQuery = () =>
  useQuery({
    queryKey: ['RefData', 'CommodityGroupsSummary'],
    queryFn: useCommodityGroupsSummaries().getCommodityGroupsSummary,
    select: (response) => response?.data?.result,
  });

export const useCommoditiesQuery = () =>
  useQuery({
    queryKey: commodityKeys.list(),
    queryFn: useCommodities().getCommodities,
    select: (response) => response?.data?.result,
  });

export const useCommoditiesSummaryQuery = () =>
  useQuery({
    queryKey: commodityKeys.summaries(),
    queryFn: useCommoditiesSummaries().getCommoditiesSummary,
    select: (response) => response?.data?.result,
  });

export const useLocationGroupsQuery = () =>
  useQuery({
    queryKey: ['RefData', 'LocationGroups'],
    queryFn: useLocationGroups().getLocationGroups,
    select: (response) => response?.data?.result,
  });

export const useLocationsQuery = () => {
  const dataClient = useLocations();
  return useQuery({
    queryKey: locationKeys.list(),
    queryFn: async () => (await dataClient.getLocations()).data?.result,
  });
};

export const useLocationQuery = (id: string) => {
  const dataClient = useLocations();
  return useQuery({
    queryKey: locationKeys.details(id),
    queryFn: async () => (await dataClient.getLocation(id)).data?.result,
  });
};

export const useLocationsSummaryQuery = () => {
  const dataClient = useLocationsSummaries();
  return useQuery({
    queryKey: locationKeys.summaries(),
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => (await dataClient.getLocationsSummary()).data?.result,
  });
};

export const useLocationRefQuery = () => {
  const dataClient = useViews();
  return useQuery({
    queryKey: locationKeys.refList(),
    queryFn: async () => (await dataClient.getLocationRef()).data?.result,
  });
};

export const useLocationCommodityRefQuery = () => {
  const dataClient = useViews();
  return useQuery({
    queryKey: locationCommodityRefsKeys.all,
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => (await dataClient.getLocationCommodityRef()).data?.result,
  });
};

export const useFuturesBrokersQuery = () => {
  const dataClient = useFuturesBrokers();
  return useQuery({
    queryKey: ['RefData', 'FuturesBrokers'],
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => (await dataClient.getFuturesBrokers()).data?.result,
  });
};

export const useFuturesBrokersViewQuery = () => {
  const dataClient = useViews();
  return useQuery({
    queryKey: ['RefData', 'FuturesBrokersView'],
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => {
      const response = await dataClient.getBrokerRef();
      return response.data?.result;
    },
  });
};

// Virtually never changes, and not in the UI. Could probably
// make staleTime and CacheTime really long if we wanted.
// Went with two minutes on an initial stale time just
// to make the first load of details page quick
export const useFuturesExchangesViewQuery = () =>
  useQuery({
    queryKey: ['RefData', 'FuturesExchangesView'],
    queryFn: useViews().getExchangeRef,
    select: (response) => response?.data?.result,
    staleTime: 1000 * 60 * 2,
  });

// Virtually never changes, and not in the UI. Could probably
// make staleTime and CacheTime really long if we wanted.
// Went with two minutes on an initial stale time just
// to make the first load of details page quick
export const useFuturesUnitOfMeasuresViewQuery = () =>
  useQuery({
    queryKey: ['RefData', 'FuturesUnitOfMeasuresView'],
    queryFn: useViews().getUnitOfMeasureRef,
    select: (response) => response?.data?.result,
    staleTime: 1000 * 60 * 2,
  });

export const useFuturesContractsQuery = () =>
  useQuery({
    queryKey: ['RefData', 'FuturesContracts'],
    queryFn: useRefFuturesContracts().getFuturesContracts,
    select: (response) => response?.data?.result,
  });

export const useFuturesContractsSummaryQuery = (includeInactive?: string) => {
  const dataClient = useRefFuturesContractsSummaries();
  const query = includeInactive ? { includeInactive } : { includeInactive: 'false' };
  return useQuery({
    queryKey: ['RefData', 'FuturesContractsSummary', includeInactive],
    queryFn: () => dataClient.getFuturesContractsSummary(query),
    select: (response) => response?.data?.result,
  });
};

export const useFuturesTradeAccountsQuery = () => {
  const dataClient = useFuturesTradeAccounts();
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['RefData', 'Futures Trade Accounts', 'List'],
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => {
      const tradeAccounts = (await dataClient.getFuturesTradeAccounts()).data.result;
      tradeAccounts.forEach((ta) => {
        queryClient.setQueryData(
          ['RefData', 'Futures Trade Accounts', 'Details', ta.tradeAccountId],
          ta
        );
      });
      return tradeAccounts;
    },
  });
};

export const useFutureTradeAccountDetailsQuery = (id: number) => {
  const dataClient = useFuturesTradeAccounts();
  return useQuery({
    queryKey: ['RefData', 'Futures Trade Accounts', 'Details', id],
    enabled: !!id,
    queryFn: async () => {
      return (await dataClient.getFuturesTradeAccounts()).data.result.find(
        (ta) => ta.tradeAccountId === id
      );
    },
  });
};

export const useHedgedCommoditiesQuery = () => {
  const dataClient = useViews();
  return useQuery({
    queryKey: ['RefData', 'HedgedCommodities'],
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => {
      const response = await dataClient.getHedgedCommodityRef();
      return response.data?.result;
    },
  });
};

export const useFuturesTradeAccountsSummaryQuery = () => {
  const dataClient = useFuturesTradeAccountsSummaries();
  return useQuery({
    queryKey: ['RefData', 'FuturesTradeAccountsSummary'],
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => {
      return (await dataClient.getFuturesTradeAccountsSummary()).data.result;
    },
    select: (tradeAccountSummaries) => {
      return tradeAccountSummaries.sort((a, b) =>
        ('' + a.tradeAccountCode).localeCompare('' + b.tradeAccountCode)
      );
    },
  });
};

export const useTransactionTypesQuery = () =>
  useQuery({
    queryKey: ['RefData', 'TransactionTypes'],
    queryFn: useTransactionTypes().getTransactionTypes,
    select: (response) => response?.data?.result,
  });

export const useTransactionTypesSummaryQuery = () =>
  useQuery({
    queryKey: ['RefData', 'TransactionTypesSummary'],
    queryFn: useTransactionTypesSummaries().getTransactionTypesSummary,
    select: (response) => response?.data?.result,
  });

function cacheOptionMonthRefsByCommodityId(
  refs: OptionMonthsRefResponse['result'],
  queryClient: QueryClient
) {
  const refsByCommodityId = new Map<number, any[]>();
  refs.forEach((r) => {
    if (r.commodityId) {
      refsByCommodityId.set(r.commodityId, (refsByCommodityId.get(r.commodityId) ?? []).concat(r));
    }
  });
  refsByCommodityId.forEach((v, k) => {
    queryClient.setQueryData(commodityKeys.optionMonths('' + k), v);
  });
}

export const useCommodityOptionMonthsQuery = (prefetch: boolean = false) => {
  const dataClient = useViews();
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: commodityKeys.optionMonthsList(),
    queryFn: async () => {
      const months = (await dataClient.getCommodityOptionMonths()).data.result;
      if (prefetch) {
        cacheOptionMonthRefsByCommodityId(months, queryClient);
      }
      return months;
    },
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
  });
};

export const useOptionMonthsByCommodityQuery = (commodityId?: number) => {
  const dataClient = useViews();
  return useQuery({
    queryKey: commodityKeys.optionMonths('' + commodityId),
    enabled: !!commodityId,
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => {
      return (await dataClient.getCommodityOptionMonths({ commodityId: '' + commodityId })).data
        ?.result;
    },
  });
};

export const useFuturesContractTradeAccountRefQuery = () =>
  useQuery({
    queryKey: ['RefData', 'FuturesContractTradeAccountRef'],
    queryFn: useViews().getFuturesContractTradeAccountRef,
    select: (response) => response?.data?.result,
  });

export const useTradeAccountCommodityRefQuery = () =>
  useQuery({
    queryKey: ['RefData', 'TradeAccountCommodityRef'],
    queryFn: useViews().getTradeAccountCommodityRef,
    select: (response) => response?.data?.result,
  });

export const useCommodityFuturesContractQuery = (prefetch: boolean = false) => {
  const dataClient = useViews();
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['RefData', 'CommodityFuturesContract'],
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => {
      const refs = (await dataClient.getCommodityFuturesContract()).data?.result;
      if (prefetch) {
        cacheFuturesContractRefsByCommodityId(refs, queryClient);
      }
      return refs;
    },
  });
};

function cacheFuturesContractRefsByCommodityId(
  refs: CommodityFuturesContractsRefResponse['result'],
  queryClient: QueryClient
) {
  const refsByCommodityId = new Map<number, any[]>();
  refs.forEach((r) => {
    if (r.commodityId) {
      refsByCommodityId.set(r.commodityId, (refsByCommodityId.get(r.commodityId) ?? []).concat(r));
    }
  });
  refsByCommodityId.forEach((v, k) => {
    queryClient.setQueryData(['RefData', 'FuturesContractRefs', 'byCommodityID', k], v, {});
  });
}

export const useFuturesContractRefsByCommodityQuery = (id: number) => {
  const dataClient = useViews();
  return useQuery({
    queryKey: ['RefData', 'FuturesContractRefs', 'byCommodityID', id],
    enabled: !!id,
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => {
      const refs = (await dataClient.getCommodityFuturesContract()).data?.result;
      return refs.filter((r) => r.commodityId === id);
    },
  });
};

export const useSwappableCommoditiesQuery = () =>
  useQuery({
    queryKey: ['RefData', 'SwappableCommodities'],
    queryFn: useViews().getSwappableCommodities,
    select: (response) => response?.data?.result,
  });

export const useOptionMonthsByYearQuery = (year: string) => {
  const dataClient = usePositionsOptionMonths();
  return useQuery({
    queryKey: ['RefData', 'OptionMonthsByYear', year],
    queryFn: () => dataClient.getPositionsOptionMonthsByYear(year),
    select: (response) => response?.data?.result,
  });
};

function cacheOptionMonthRefsByFuturesContractId(
  refs: FuturesContractOptionMonthRefResponse['result'],
  queryClient: QueryClient
) {
  const refsByCommodityId = new Map<number, any[]>();
  refs.forEach((r) => {
    if (r.futuresContractId) {
      refsByCommodityId.set(
        r.futuresContractId,
        (refsByCommodityId.get(r.futuresContractId) ?? []).concat(r)
      );
    }
  });
  refsByCommodityId.forEach((v, k) => {
    queryClient.setQueryData(
      ['RefData', 'FuturesContractOptionMonthRef', 'byFuturesContractID', k],
      v
    );
  });
}

export const usePrefetchFuturesContractOptionMonthRefs = () => {
  const dataClient = useViews();
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['RefData', 'FuturesContractOptionMonthRef', 'List'],
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => {
      const response = await dataClient.getFuturesContractOptionMonthRef();
      const refs = response?.data?.result;
      cacheOptionMonthRefsByFuturesContractId(refs, queryClient);
      return refs;
    },
  });
};

export const useFuturesContractOptionMonthRefQuery: (
  futuresContractId?: string
) => UseQueryResult<FuturesContractOptionMonthRefResponse['result']> = (futuresContractId) => {
  const dataClient = useViews();
  return useQuery({
    queryKey: [
      'RefData',
      'FuturesContractOptionMonthRef',
      'byFuturesContractID',
      futuresContractId,
    ],
    enabled: !!futuresContractId,
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    queryFn: async () => {
      const response = await dataClient.getFuturesContractOptionMonthRef({
        futuresContractId,
      });
      return response?.data?.result;
    },
  });
};

export const useCommodityRefQuery = () =>
  useQuery({
    queryKey: commodityKeys.refList(),
    queryFn: useViews().getCommodityRef,
    select: (response) => response?.data?.result,
  });

export const useFuturesOptionMonths = () =>
  useQuery({
    queryKey: ['RefData', 'FuturesOptionMonthsByYear'],
    queryFn: useFuturesOptionMonthsHoook().getFuturesOptionMonthsByYear,
    select: (response) => response?.data?.result,
  });

export const usePositionPeriodOptionMonths = (id: string) => {
  const dataClient = useViews();
  return useQuery({
    enabled: !!id,
    queryKey: ['RefData', 'PositionPeriod', id],
    queryFn: async () => await dataClient.getPositionPeriodRef({ optionMonthId: id }),
    select: (response) => response?.data?.result,
  });
};

export const usePositionTransactionTypeRefQuery = () =>
  useQuery({
    queryKey: ['RefData', 'PositionTransactionTypeRef'],
    queryFn: useViews().getPositionTransactionTypeRef,
    select: (response) => response?.data?.result,
  });
