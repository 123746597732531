import React, { FC, useContext } from 'react';

import { Link } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

import { HedgeDeskRole, PositionAdministratorRole } from '../../../../app/UserWrapper/UserWrapper';
import {
  currencyValueFormatter,
  dateValueFormatter,
  errorCellClassName,
  numberWithCommaFormatter,
} from '../../../../components';
import ChangeableGrid from '../../../../components/ChangeableGrid/ChangeableGrid';
import { UserContext } from '../../../../Context';
import { isTradeAccountWithPositionPeriod } from '../FuturesEntryUtils';

export interface FuturesEntriesGridProps {
  rows: GridRowsProp[];
  loading: boolean;
  rowCountState?: number;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  onNewButtonClick: () => void;
  onEditButtonClick: (id: string) => void;
}

const FuturesEntriesGrid: FC<FuturesEntriesGridProps> = ({
  rows,
  loading,
  rowCountState,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  onNewButtonClick,
  onEditButtonClick,
}) => {
  const userContext = useContext(UserContext);
  const canCreate = userContext.haveRole([HedgeDeskRole, PositionAdministratorRole]);
  let handleAdd, editLink;
  if (canCreate) {
    handleAdd = () => {
      onNewButtonClick();
    };
    editLink = ({ value, row }: GridRenderCellParams) => (
      <Link
        onClick={() => {
          onEditButtonClick(row.id);
        }}
        sx={{ cursor: 'pointer' }}
        data-testid="LinkToModal"
      >
        {value || '______'}
      </Link>
    );
  } else {
    editLink = ({ value }: GridRenderCellParams) => value || '______';
  }
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      renderCell: editLink,
    },
    {
      field: 'commodity',
      headerName: 'Commodity',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'futuresContract',
      headerName: 'Futures Contract',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'contracts',
      headerName: 'Contracts',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: numberWithCommaFormatter,
    },
    {
      field: 'optionMonth',
      headerName: 'Month',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'positionMonth',
      headerName: 'Position Period',
      flex: 2,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueGetter: (params: GridValueGetterParams) => {
        return isTradeAccountWithPositionPeriod(params.row.tradeAccountId) ? params.value : '-';
      },
      sortable: false,
    },
    {
      field: 'futuresPrice',
      headerName: 'Futures Price',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'broker',
      headerName: 'Broker',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'brokerOrderNumber',
      headerName: 'Order Number',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'tradeAccount',
      headerName: 'Trade Account',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'entryUser',
      headerName: 'Entry User',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'entryDateTime',
      headerName: 'Entry Date Time',
      flex: 1,
      type: 'string',
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: dateValueFormatter,
    },
  ];

  return (
    <div data-testid="FuturesEntriesGrid">
      <ChangeableGrid
        initialRows={rows}
        isLoading={loading}
        columns={columns}
        onAdd={handleAdd}
        rowCount={rowCountState}
        pageSizeOptions={[25, 50, 100]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        onProcessRowUpdateError={console.error}
      />
    </div>
  );
};

export default FuturesEntriesGrid;
