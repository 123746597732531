import React, { Suspense, useContext, useEffect } from 'react';

import { Badge } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {
  FuturesUserRole,
  PositionAdministratorRole,
  SuperUserRole,
} from '../../../app/UserWrapper/UserWrapper';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { UserContext } from '../../../Context';

import UserModal from './UserModal/UserModal';
import UsersGrid from './UsersGrid/UsersGrid';
import useUsersPage from './useUsersPage';

import './UsersPage.css';

export default function UsersPage() {
  useEffect(() => {
    document.title = 'Users | Positions and Futures';
  }, []);

  const displayProps = useUsersPage();
  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole, SuperUserRole, FuturesUserRole]);
  if (!userContext?.userName) {
    return (
      <Container maxWidth={false}>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Users and Roles</Badge>
        </Typography>
      </Container>
    );
  }
  return (
    <main style={{ padding: '24px' }} data-testid="UsersPage">
      <Typography variant="h2" mb="0.5em" role="heading">
        <Badge color="secondary">Users and Roles</Badge>
      </Typography>
      {!canRun ? (
        <Typography paragraph>You do not have access to this page</Typography>
      ) : (
        <>
          <UsersGrid {...displayProps}></UsersGrid>
          {displayProps.isModalOpen && (
            <Suspense fallback={<LoadingSpinner />}>
              <UserModal {...displayProps}></UserModal>
            </Suspense>
          )}
        </>
      )}
    </main>
  );
}
