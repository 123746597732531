import React, { FC } from 'react';

import { FormControl, InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';

import {
  CommoditiesResponse,
  LocationsSummaryResponse,
  TransactionTypesResponse,
} from '../../../../services/backend/data-contracts';

export interface PositionEntriesFilterFormProps {
  locationSummaries?: LocationsSummaryResponse['result'];
  commodities?: CommoditiesResponse['result'];
  transactionTypes?: TransactionTypesResponse['result'];
  filterForm?: any;
  setFilterForm: (filterForm: any) => void;
}

const PositionEntriesFilterForm: FC<PositionEntriesFilterFormProps> = ({
  locationSummaries,
  commodities,
  transactionTypes,
  filterForm,
  setFilterForm,
}) => {
  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, locationId: event.target.value });
  };
  const handleCommodityFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, commodityId: event.target.value });
  };
  const handleTransactionTypeFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, transactionTypeId: event.target.value });
  };
  const handlePurchaseSaleChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, purchaseSale: event.target.value });
  };
  return (
    <div className="filterBox">
      <FormControl fullWidth sx={{ mr: 2 }}>
        <InputLabel id="location-label">Location</InputLabel>
        <Select
          labelId="location-label"
          id="location-select"
          value={filterForm.locationId}
          label="Location"
          onChange={handleLocationChange}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {locationSummaries?.map((t) => (
            <MenuItem key={t.locationId} value={t.locationId}>
              {t.locationValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mr: 2 }}>
        <InputLabel id="commodity-label">Commodity</InputLabel>
        <Select
          labelId="commodity-label"
          id="commodity-select"
          value={filterForm.commodityId}
          label="Commodity"
          onChange={handleCommodityFilterChange}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {commodities?.map((t) => (
            <MenuItem key={t.commodityId} value={t.commodityId}>
              {t.commodityCode} - {t.commodityName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mr: 2 }}>
        <InputLabel id="transactionType-label">Transaction Type</InputLabel>
        <Select
          labelId="transactionType-label"
          id="transactionType-select"
          value={filterForm.transactionTypeId}
          label="Transaction Type"
          onChange={handleTransactionTypeFilterChange}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {transactionTypes?.map((t) => (
            <MenuItem key={t.transactionTypeId} value={t.transactionTypeId}>
              {t.transactionTypeValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="purchaseSale-label">Purchase/Sale</InputLabel>
        <Select
          labelId="purchaseSale-label"
          id="purchaseSale-select"
          value={filterForm.purchaseSale}
          label="Location"
          onChange={handlePurchaseSaleChange}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          <MenuItem value="P">Purchase</MenuItem>
          <MenuItem value="S">Sale</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default PositionEntriesFilterForm;
