import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { useQuery } from '@tanstack/react-query';

import { backendDataClients, useFuturesBrokersQuery, useOktaUser } from '../../../hooks';
import { useBrokerAdjustmentTypeRefsQuery } from '../../../hooks/useFuturesDataQueries';

const { useCashAdjustment: useCashAdjustmentDataClient } = backendDataClients;

export interface CashAdjustmentsFilterForm {
  sortOrder?: string;
  limit: number;
  offset?: number;
  search?: string;
  sortBy?: string;
  brokerCode?: string;
  sinceDate?: string;
}

export default function useCashAdjustments() {
  const params = useParams();
  const { user } = useOktaUser();
  const userName = user?.preferred_username ?? '';

  const [filterForm, setFilterForm] = useState<CashAdjustmentsFilterForm>({
    sortOrder: 'desc',
    limit: 50,
    offset: 0,
    brokerCode: params?.brokerCode || '',
    sinceDate: '',
    search: '',
    sortBy: 'adjustmentId',
  });

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: filterForm.limit,
  });

  const brokers = useFuturesBrokersQuery()?.data?.sort((a, b) =>
    (a.brokerValue ?? '').localeCompare(b.brokerValue ?? '')
  );
  const queryParams = Object.assign(
    {
      limit: filterForm.limit, // number of rows per page
      offset: filterForm.offset, // what row to start at
    },
    filterForm.sinceDate === '' ? null : { sinceDate: filterForm.sinceDate },
    filterForm.brokerCode === '' ? null : { brokerCode: filterForm.brokerCode },
    filterForm.search === '' ? null : { search: filterForm.search },
    filterForm.sortOrder === '' ? null : { sortOrder: filterForm.sortOrder },
    filterForm.sortBy === '' ? null : { sortBy: filterForm.sortBy }
  );
  const positionDataClient = useCashAdjustmentDataClient();
  const cashAdjustmentsQuery = useQuery({
    queryKey: ['CashAdjustments', filterForm],
    queryFn: async () =>
      (await positionDataClient.getFilteredCashAdjustments(queryParams)).data?.result,
  });
  const rows: GridRowsProp[] =
    cashAdjustmentsQuery.data?.entries?.map((c) => {
      return { id: c.adjustmentId, ...c } as unknown as GridRowsProp;
    }) ?? [];

  const getAllFilteredCashAdjustments = async () => {
    const today = new Date();
    const todayString = today.getMonth() + 1 + '-' + today.getDate() + '-' + today.getFullYear();
    return positionDataClient.getFilteredCashAdjustments({
      ...queryParams,
      limit: Number.MAX_SAFE_INTEGER,
      offset: 0,
      sinceDate: todayString,
    });
  };

  const totalCount = cashAdjustmentsQuery.data?.totalCount;
  const [rowCountState, setRowCountState] = React.useState(totalCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);
  const onPaginationModelChange = (pageChange: GridPaginationModel) => {
    if (!isNaN(pageChange.page) && !isNaN(pageChange.pageSize)) {
      const offset = +pageChange.page * +pageChange.pageSize;
      setFilterForm({ ...filterForm, limit: pageChange.pageSize, offset });
      setPaginationModel(pageChange);
    }
  };
  const onSortModelChange = (model: GridSortModel) => {
    const sortFieldMapper = {
      adjustmentAmount: 'amount',
      entryDateTime: 'createdOn',
      entryUser: 'createdBy',
    } as {
      [index: string]: string;
      adjustmentAmount: string;
      entryDateTime: string;
      entryUser: string;
    };
    if (model[0] && model[0].field && model[0].sort) {
      const field = model[0].field as string;
      const sortBy = sortFieldMapper[field] ? sortFieldMapper[field] : field;
      setFilterForm({ ...filterForm, sortBy: sortBy, sortOrder: model[0].sort });
    } else {
      setFilterForm({ ...filterForm, sortOrder: '', sortBy: '' });
    }
  };
  const onFilterModelChange = (model: GridFilterModel) => {
    if (model && model.quickFilterValues && model.quickFilterValues.length > 0) {
      setFilterForm({ ...filterForm, search: model.quickFilterValues[0] });
    } else {
      setFilterForm({ ...filterForm, search: '' });
    }
  };

  // Prefetch
  useBrokerAdjustmentTypeRefsQuery();

  return {
    userName,
    filterForm,
    rowCountState,
    setFilterForm,
    rows,
    getAllFilteredCashAdjustments,
    isLoadingRows: cashAdjustmentsQuery.isPending,
    brokers,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
  };
}
