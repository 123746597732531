import { Suspense, useState } from 'react';
import xlsx from 'json-as-xlsx';
import { useToast } from 'use-toast-mui';

import {
  dateStringFormatter,
  excelFormatString_Currency_5Decimals,
  excelFormatString_Number_2Decimals,
  purchaseOrSaleStringFormatter,
  zeroIfBlank,
} from '../../../components/gridUtils';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

import PositionEntriesDisplay from './PositionEntriesDisplay/PositionEntriesDisplay';
import PositionEntryDetailPage from './PositionEntryDetailPage/PositionEntryDetailPage';
import usePositionEntriesPage from './usePositionEntriesPage';

export default function PositionEntriesPage() {
  const {
    userName,
    filterForm,
    rowCountState,
    setFilterForm,
    rows,
    getAllFilteredPositions,
    isLoadingRows,
    locationSummaries,
    commodities,
    transactionTypes,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
  } = usePositionEntriesPage();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [entryToEdit, setEntryToEdit] = useState<string>('');
  const toast = useToast();
  const handleNewButtonClick = () => {
    setEntryToEdit('');
    setIsModalOpen(true);
  };
  const handleEditButtonClick = (id: string) => {
    setEntryToEdit(id);
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    // POS-354.  Deleting an entry without clearing the ID caused the initialUpdateDetails query to throw an error since the deleted entry is undefined.
    setEntryToEdit('');
    setIsModalOpen(false);
  };
  const handleReopenWith = (id: string) => {
    handleModalClose();
    setTimeout(() => handleEditButtonClick(id), 5);
  };
  const handleExportButtonClick = async (onFinish: () => void) => {
    try {
      const positionData = (await getAllFilteredPositions()).data?.result?.entries;
      if (positionData) {
        const data = [
          {
            sheet: "Today's Position Entries",
            columns: [
              { label: 'ID', value: 'id' },
              { label: 'Location', value: 'location' },
              { label: 'Commodity', value: 'commodity' },
              { label: 'P/S', value: (r: any) => purchaseOrSaleStringFormatter(r?.purchaseSale) },
              { label: 'Trans Type', value: 'transactionType' },
              { label: 'Contract', value: 'contract' },
              { label: 'Customer', value: 'customer' },
              {
                label: 'Quantity',
                value: (r: any) => zeroIfBlank(r, 'quantity'),
                format: excelFormatString_Number_2Decimals,
              },
              {
                label: 'Flat Price',
                value: (r: any) => zeroIfBlank(r, 'flatPrice'),
                format: excelFormatString_Currency_5Decimals,
              },
              {
                label: 'Basis Price',
                value: (r: any) => zeroIfBlank(r, 'basisPrice'),
                format: excelFormatString_Currency_5Decimals,
              },
              {
                label: 'Futures Price',
                value: (r: any) => zeroIfBlank(r, 'futuresPrice'),
                format: excelFormatString_Currency_5Decimals,
              },
              { label: 'Month', value: 'optionMonth' },
              { label: 'Comment', value: 'comment' },
              { label: 'Entry User', value: 'entryUser' },
              {
                label: 'Entry Date Time',
                value: (r: any) => dateStringFormatter(r?.entryDateTime),
              },
            ],
            content: positionData,
          },
        ];
        const settings = {
          fileName: "Today's Position Entries",
        };
        xlsx(data, settings);
      } else {
        toast.error('Export failed.');
      }
    } finally {
      onFinish();
    }
  };

  return (
    <>
      <main data-testid="PositionEntriesPage" style={{ minWidth: 1400, padding: '24px' }}>
        <PositionEntriesDisplay
          userName={userName}
          rows={rows}
          loading={isLoadingRows}
          rowCountState={rowCountState}
          locationSummaries={locationSummaries}
          commodities={commodities}
          transactionTypes={transactionTypes}
          filterForm={filterForm}
          setFilterForm={setFilterForm}
          paginationModel={paginationModel}
          setPaginationModel={onPaginationModelChange}
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          onNewButtonClick={handleNewButtonClick}
          onEditButtonClick={handleEditButtonClick}
          onExportButtonClick={handleExportButtonClick}
        ></PositionEntriesDisplay>
        {isModalOpen && (
          <Suspense fallback={<LoadingSpinner />}>
            <PositionEntryDetailPage
              positionEntryId={entryToEdit}
              onClose={handleModalClose}
              onReopenWith={handleReopenWith}
            ></PositionEntryDetailPage>
          </Suspense>
        )}
      </main>
    </>
  );
}
