import { FC } from 'react';

import { GridColDef, GridEventListener } from '@mui/x-data-grid';

import { StripedDataGrid } from '../../../components';
import {
  currencyValueFormatter,
  dateValueFormatter,
  numberWithComma2DecimalsFormatter,
} from '../../../components/gridUtils';
import { PositionEntriesByUserResponse } from '../../../services/backend/data-contracts';

export interface MyRecentPositionsProps {
  data: PositionEntriesByUserResponse['result'];
  handleRowClick: GridEventListener<'rowClick'>;
}

export const MyRecentPositions: FC<MyRecentPositionsProps> = ({ data, handleRowClick }) => {
  const columns: GridColDef[] = [
    {
      field: 'location',
      headerName: 'Loc',
      flex: 1,
      type: 'string',
      editable: false,
      sortable: false,
    },
    {
      field: 'commodity',
      headerName: 'Com',
      flex: 1,
      type: 'string',
      editable: false,
      sortable: false,
    },
    {
      field: 'purchaseSale',
      headerName: 'P / S',
      flex: 1,
      type: 'string',
      editable: false,
      sortable: false,
    },
    {
      field: 'transactionType',
      headerName: 'Trans Type',
      flex: 1,
      type: 'string',
      editable: false,
      sortable: false,
    },
    {
      field: 'contract',
      headerName: 'Contract',
      flex: 2,
      type: 'string',
      editable: false,
      sortable: false,
    },
    {
      field: 'customer',
      headerName: 'Customer',
      flex: 2,
      type: 'string',
      editable: false,
      sortable: false,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 2,
      type: 'string',
      editable: false,
      sortable: false,
      valueFormatter: numberWithComma2DecimalsFormatter,
    },
    {
      field: 'flatPrice',
      headerName: 'Flat Price',
      flex: 2,
      type: 'string',
      editable: false,
      sortable: false,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'basisPrice',
      headerName: 'Basis Price',
      flex: 2,
      type: 'string',
      editable: false,
      sortable: false,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'futuresPrice',
      headerName: 'Futures Price',
      flex: 2,
      type: 'string',
      editable: false,
      sortable: false,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'optionMonth',
      headerName: 'Month',
      flex: 1,
      type: 'string',
      editable: false,
      sortable: false,
    },
    {
      field: 'entryDateTime',
      headerName: 'Entry Date Time',
      flex: 3,
      type: 'string',
      editable: false,
      sortable: false,
      valueFormatter: dateValueFormatter,
    },
  ];
  return (
    <StripedDataGrid
      columns={columns}
      rows={data}
      onRowClick={handleRowClick}
      initialState={{
        sorting: {
          sortModel: [{ field: 'entryDateTime', sort: 'desc' }],
        },
        pagination: { paginationModel: { pageSize: 5 } },
      }}
      pageSizeOptions={[5, 10, 20]}
    ></StripedDataGrid>
  );
};
