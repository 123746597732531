import React, { FC } from 'react';
import { getIn } from 'formik';

import { CircularProgress } from '@mui/material';

import { useFuturesUnitOfMeasuresViewQuery } from '../../hooks';
import FormikAutocomplete from '../FormikAutocomplete/FormikAutocomplete';

export interface FuturesUnitOfMeasureAutocompleteProps {
  formik: any;
  openOnFocus?: boolean;
}

const FuturesUnitOfMeasureAutocomplete: FC<FuturesUnitOfMeasureAutocompleteProps> = (props) => {
  const allOptions = useFuturesUnitOfMeasuresViewQuery().data;
  if (!allOptions) {
    return <CircularProgress />;
  }
  let activeOptions = allOptions.sort((a, b) => (a.uomName ?? '').localeCompare(b.uomName ?? ''));

  let optionsById = new Map(activeOptions?.map((ta) => [ta.uomId as number, ta.uomName as string]));

  const value = getIn(props.formik.values, 'uomId');
  if (value && !optionsById.get(value)) {
    const missingOption = allOptions.find((t) => t.uomId === value);
    if (missingOption) {
      activeOptions = [...activeOptions, missingOption];
      optionsById = new Map(activeOptions?.map((ta) => [ta.uomId as number, ta.uomName as string]));
    }
  }

  return (
    <FormikAutocomplete
      formik={props.formik}
      field="uomId"
      optionsAsMap={optionsById}
      openOnFocus={props.openOnFocus}
    ></FormikAutocomplete>
  );
};

export default FuturesUnitOfMeasureAutocomplete;
