/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateFutureTradeEntryRequest,
  FutureFilteredTradeEntriesResponse,
  FutureTradeEntriesResponse,
  FutureTradeEntryResponse,
  UpdateFutureTradeEntryRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class FutureTradeEntries<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetFutureTradeEntry
   * @request GET:/futureTradeEntries/{id}
   * @secure
   */
  getFutureTradeEntry = (id: string, params: RequestParams = {}) =>
    this.request<FutureTradeEntryResponse, any>({
      path: `/futureTradeEntries/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateFutureTradeEntry
   * @request PUT:/futureTradeEntries/{id}
   * @secure
   */
  updateFutureTradeEntry = (
    id: string,
    data: UpdateFutureTradeEntryRequest,
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/futureTradeEntries/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name DeleteFutureTradeEntry
   * @request DELETE:/futureTradeEntries/{id}
   * @secure
   */
  deleteFutureTradeEntry = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futureTradeEntries/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFutureTradeEntries
   * @request OPTIONS:/futureTradeEntries/{id}
   */
  optionsFutureTradeEntries = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futureTradeEntries/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFilteredFutureTradeEntries
   * @request GET:/futureTradeEntries/filter
   * @secure
   */
  getFilteredFutureTradeEntries = (
    query: {
      sortOrder?: string;
      limit: string;
      search?: string;
      sortBy?: string;
      offset?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<FutureFilteredTradeEntriesResponse, any>({
      path: `/futureTradeEntries/filter`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFutureTradeEntries2
   * @request OPTIONS:/futureTradeEntries/filter
   * @originalName optionsFutureTradeEntries
   * @duplicate
   */
  optionsFutureTradeEntries2 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futureTradeEntries/filter`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFutureTradeEntries
   * @request GET:/futureTradeEntries
   * @secure
   */
  getFutureTradeEntries = (params: RequestParams = {}) =>
    this.request<FutureTradeEntriesResponse, any>({
      path: `/futureTradeEntries`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name CreateFutureTradeEntry
   * @request POST:/futureTradeEntries
   * @secure
   */
  createFutureTradeEntry = (data: CreateFutureTradeEntryRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futureTradeEntries`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFutureTradeEntries3
   * @request OPTIONS:/futureTradeEntries
   * @originalName optionsFutureTradeEntries
   * @duplicate
   */
  optionsFutureTradeEntries3 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futureTradeEntries`,
      method: 'OPTIONS',
      ...params,
    });
}
