// stolen from https://github.com/mui/mui-x/issues/2186
import React, { FC } from 'react';

import {
  DataGrid,
  DataGridProps,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  MuiEvent,
} from '@mui/x-data-grid';

export type DataGridWithSingleClickCellEditProps = Omit<
  DataGridProps,
  'cellModesModel' | 'onCellModesModelChange' | 'onCellClick' | 'experimentalFeatures'
>;

const DataGridWithSingleClickCellEdit: FC<DataGridWithSingleClickCellEditProps> = (props) => {
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  // For singleselect cells while the dropdown is open, onCellClick is also invoked to handle clicks on the backdrop.
  const handleCellClick = React.useCallback((params: GridCellParams, event: MuiEvent<any>) => {
    if (event.target.className.toString().includes('MuiModal-backdrop')) {
      return; // Let normal clickaway behavior happen
    }
    if (params.isEditable) {
      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    }
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <DataGrid
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      apiRef={props.apiRef}
      {...props}
    />
  );
};

export default DataGridWithSingleClickCellEdit;
