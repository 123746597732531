import { FC, useState } from 'react';

import { Link } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { ChangeableDisplay } from '../../../../components';
import { UseRefDataQueryResult } from '../../../../hooks';
import { CommoditiesResponse } from '../../../../services/backend/data-contracts';
import CommodityModal, { CommodityModalProps } from '../CommodityModal/CommodityModal';

interface CommoditiesListProps
  extends Omit<CommodityModalProps, 'isModalOpen' | 'onClose' | 'commodityId' | 'isNewCommodity'> {
  getCommodities: () => UseRefDataQueryResult<CommoditiesResponse['result']>;
  hideUnsavedPrompt?: boolean;
}
const CommoditiesList: FC<CommoditiesListProps> = ({
  getCommodities,
  hideUnsavedPrompt,
  ...props
}) => {
  const [selectedCommodityId, setSelectedCommodityId] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const commodityGroupValueGetter = (params: GridValueGetterParams): string => {
    const code = params.row.commodityGroup?.commodityGroupCode;
    const group = params.row.commodityGroup?.commodityGroupName;
    return code + ' - ' + group;
  };

  const columns: GridColDef[] = [
    {
      field: 'commodityCode',
      headerName: 'Commodity Code',
      flex: 1,
      type: 'string',
      editable: false,
      renderCell: ({ value, row }) => (
        <Link
          onClick={() => {
            setIsModalOpen(true);
            setSelectedCommodityId(row?.commodityId);
          }}
          sx={{ cursor: 'pointer' }}
          data-testid="LinkToModal"
        >
          {value || '_____'}
        </Link>
      ),
    },
    {
      field: 'commodityName',
      headerName: 'Commodity Name',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'commodityGroup',
      valueGetter: commodityGroupValueGetter,
      headerName: 'Commodity Group',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      flex: 1,
      type: 'string',
      editable: false,
    },
  ];

  const handleModalClose = () => {
    setSelectedCommodityId('');
    setIsModalOpen(false);
  };
  const add = () => {
    setSelectedCommodityId('');
    setIsModalOpen(true);
  };

  return (
    <ChangeableDisplay
      pageTitle="Commodities"
      columns={columns}
      getRowId={(row) => row['commodityId']}
      fnUseQuery={getCommodities}
      uniqueFields={['commodityCode']}
      sortField="commodityCode"
      onAddButtonClick={add}
      hideUnsavedPrompt={hideUnsavedPrompt}
      modalEdit={() => (
        <CommodityModal
          isModalOpen={isModalOpen}
          onClose={handleModalClose}
          commodityId={selectedCommodityId}
          isNewCommodity={!selectedCommodityId}
          {...props}
        ></CommodityModal>
      )}
    ></ChangeableDisplay>
  );
};

export default CommoditiesList;
