/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { UpdateLocationGroupsByIdRequest } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class LocationGroupsMassUpdates<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name UpdateLocationGroups
   * @request POST:/locationGroups-mass-updates
   * @secure
   */
  updateLocationGroups = (data: UpdateLocationGroupsByIdRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/locationGroups-mass-updates`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsLocationGroupsMassUpdates
   * @request OPTIONS:/locationGroups-mass-updates
   */
  optionsLocationGroupsMassUpdates = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/locationGroups-mass-updates`,
      method: 'OPTIONS',
      ...params,
    });
}
