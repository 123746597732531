import React, { FC } from 'react';
import { AxiosResponse } from 'axios';

import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';

import { ChangeableDisplay, yesNoGridColTypeDef } from '../../../components';
import { UseRefDataQueryResult } from '../../../hooks';
import {
  FuturesBrokerResponse,
  UpdateFuturesBrokersByIdRequest,
} from '../../../services/backend/data-contracts';
import { RequestParams } from '../../../services/backend/http-client';

interface FuturesBrokersDisplayProps {
  getFuturesBrokers: () => UseRefDataQueryResult<FuturesBrokerResponse['result']>;
  updateFuturesBrokers: (
    data: UpdateFuturesBrokersByIdRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  hideUnsavedPrompt?: boolean;
}

const FuturesBrokersDisplay: FC<FuturesBrokersDisplayProps> = ({
  getFuturesBrokers,
  updateFuturesBrokers,
  hideUnsavedPrompt,
}) => {
  const update = (oldRows: GridValidRowModel[]) => {
    return oldRows?.length
      ? updateFuturesBrokers({
          brokers: oldRows.map((r) => ({
            brokerId: r.brokerId,
            isActive: r.isActive,
          })),
        })
      : Promise.resolve();
  };

  const columns: GridColDef[] = [
    {
      field: 'brokerCode',
      headerName: 'Broker Code',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'brokerName',
      headerName: 'Broker Name',
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 1,
      editable: true,
      ...yesNoGridColTypeDef('isActive'),
    },
    {
      field: 'isActiveInFutures',
      headerName: 'Status in Futures',
      flex: 1,
      valueGetter: (params) => (params.row['isActiveInFutures'] ? 'Active' : 'Inactive'),
    },
  ];

  return (
    <>
      <ChangeableDisplay
        pageTitle="Futures Brokers"
        columns={columns}
        getRowId={(row) => row['brokerId']}
        update={update}
        fnUseQuery={getFuturesBrokers}
        uniqueFields={['brokerCode']}
        sortField="brokerCode"
        isCellEditable={({ row }) => row.isActiveInFutures}
        infoIndicator={(row) =>
          !row.isActiveInFutures
            ? 'Futures Brokers that are Inactive in Futures cannot be modified in the Position Systems'
            : null
        }
        hideUnsavedPrompt={hideUnsavedPrompt}
      ></ChangeableDisplay>
    </>
  );
};

export default FuturesBrokersDisplay;
