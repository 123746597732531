/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BrokerEntryResponse,
  BrokerFilteredEntriesResponse,
  CreateBrokerEntryRequest,
  CreateBrokerEntryResponse,
  UpdateBrokerEntryRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class BrokerEntries<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name CreateBrokerEntry
   * @request POST:/brokerEntries
   * @secure
   */
  createBrokerEntry = (data: CreateBrokerEntryRequest, params: RequestParams = {}) =>
    this.request<CreateBrokerEntryResponse, any>({
      path: `/brokerEntries`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsBrokerEntries
   * @request OPTIONS:/brokerEntries
   */
  optionsBrokerEntries = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/brokerEntries`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFilteredBrokerEntries
   * @request GET:/brokerEntries/filter
   * @secure
   */
  getFilteredBrokerEntries = (
    query: {
      sortOrder?: string;
      limit: string;
      search?: string;
      sortBy?: string;
      offset?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<BrokerFilteredEntriesResponse, any>({
      path: `/brokerEntries/filter`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsBrokerEntries2
   * @request OPTIONS:/brokerEntries/filter
   * @originalName optionsBrokerEntries
   * @duplicate
   */
  optionsBrokerEntries2 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/brokerEntries/filter`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetBrokerEntry
   * @request GET:/brokerEntries/{id}
   * @secure
   */
  getBrokerEntry = (id: string, params: RequestParams = {}) =>
    this.request<BrokerEntryResponse, any>({
      path: `/brokerEntries/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateBrokerEntry
   * @request PUT:/brokerEntries/{id}
   * @secure
   */
  updateBrokerEntry = (id: string, data: UpdateBrokerEntryRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/brokerEntries/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsBrokerEntries3
   * @request OPTIONS:/brokerEntries/{id}
   * @originalName optionsBrokerEntries
   * @duplicate
   */
  optionsBrokerEntries3 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/brokerEntries/${id}`,
      method: 'OPTIONS',
      ...params,
    });
}
