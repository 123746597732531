/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CashAdjustmentResponse,
  CreateCashAdjustmentRequest,
  FilteredCashAdjustmentsResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class CashAdjustment<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetFilteredCashAdjustments
   * @request GET:/cashAdjustment/filter
   * @secure
   */
  getFilteredCashAdjustments = (
    query: {
      sortOrder?: string;
      limit: string;
      offset?: string;
      sinceDate?: string;
      search?: string;
      sortBy?: string;
      brokerCode?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<FilteredCashAdjustmentsResponse, any>({
      path: `/cashAdjustment/filter`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsCashAdjustment
   * @request OPTIONS:/cashAdjustment/filter
   */
  optionsCashAdjustment = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/cashAdjustment/filter`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetCashAdjustmentById
   * @request GET:/cashAdjustment/{id}
   * @secure
   */
  getCashAdjustmentById = (id: string, params: RequestParams = {}) =>
    this.request<CashAdjustmentResponse, any>({
      path: `/cashAdjustment/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsCashAdjustment2
   * @request OPTIONS:/cashAdjustment/{id}
   * @originalName optionsCashAdjustment
   * @duplicate
   */
  optionsCashAdjustment2 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/cashAdjustment/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name CreateCashAdjustment
   * @request POST:/cashAdjustment
   * @secure
   */
  createCashAdjustment = (data: CreateCashAdjustmentRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/cashAdjustment`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsCashAdjustment3
   * @request OPTIONS:/cashAdjustment
   * @originalName optionsCashAdjustment
   * @duplicate
   */
  optionsCashAdjustment3 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/cashAdjustment`,
      method: 'OPTIONS',
      ...params,
    });
}
