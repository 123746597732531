import React from 'react';

import { ThemeProvider, Typography } from '@mui/material';

import { ErrorPage } from '../../pages';
import { lightTheme } from '../AppTheme';

export default function NotAuthorized() {
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <ErrorPage error="Access denied">
          <Typography color="error" paragraph>
            Your user does not have access to Positions and Futures in OKTA. Please file a service
            portal ticket at{' '}
            <a href="https://serviceportal.savageservices.com" target="_blank" rel="noreferrer">
              https://serviceportal.savageservices.com
            </a>
          </Typography>
        </ErrorPage>
      </ThemeProvider>
    </>
  );
}
