import { FuturesOptionMonthSortedResponse } from '../services/backend/data-contracts';

import {
  useFuturesContractDetailsForFuturesQuery,
  useFuturesContractsListQuery,
  useFuturesOptionMonthsQuery,
} from './useFuturesDataQueries';

export const useFuturesContractMonthsForFuturesQuery = (
  futuresContractId: number
): { isPending: boolean; data: FuturesOptionMonthSortedResponse['result'] | undefined } => {
  const allContractsQuery = useFuturesContractsListQuery({ limit: Number.MAX_SAFE_INTEGER });
  const placeholderContractData = allContractsQuery.data?.result.entries?.find(
    (contract) => contract.futuresContractId === futuresContractId
  );
  const monthsQuery = useFuturesOptionMonthsQuery();
  const fcQuery = useFuturesContractDetailsForFuturesQuery(
    futuresContractId,
    placeholderContractData
  );

  return {
    isPending: monthsQuery.isPending || fcQuery.isPending,
    data:
      monthsQuery.data && fcQuery.data
        ? monthsQuery.data.filter(
            (m) =>
              m.optionMonthLetter &&
              fcQuery.data.contractMonths?.split(',').includes(m.optionMonthLetter)
          )
        : undefined,
  };
};
