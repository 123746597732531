import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { NumericFormatProps } from 'react-number-format/types/types';

import { InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material';

interface IntegerTextFieldProps
  extends Omit<NumericFormatProps<TextFieldProps>, 'InputProps' | 'type'> {
  inputProps?: InputBaseComponentProps;
  id?: string;
  name?: string;
  error?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
}

const IntegerTextField: FC<IntegerTextFieldProps> = ({ inputProps, value, ...props }) => {
  const [newValue, setNewValue] = useState(value);
  useEffect(() => {
    const parsedValue = parseInt(value?.toString() ?? '');
    if (!isNaN(parsedValue)) {
      setNewValue(parsedValue);
    } else {
      setNewValue(value);
    }
  }, [value]);
  return (
    <NumericFormat
      fullWidth={true}
      decimalScale={0}
      customInput={TextField}
      onFocus={(event) => event.target.select()}
      InputProps={{
        size: 'small',
        inputProps: {
          onBlur: (event: FocusEvent): void => {
            const target = event.target as HTMLInputElement;
            const parsedValue = parseInt(target.value);
            if (!isNaN(parsedValue)) {
              setNewValue(parsedValue);
            }
          },
          ...inputProps,
        },
        'aria-labelledby': props?.id + '-label',
      }}
      value={newValue}
      {...props}
    ></NumericFormat>
  );
};

export default IntegerTextField;
