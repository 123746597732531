/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateHedgedCommodityRequest,
  FilteredHedgedCommoditiedResponse,
  UpdateHedgedCommodityRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class HedgedCommodity<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name CreateHedgedCommodity
   * @request POST:/hedgedCommodity
   * @secure
   */
  createHedgedCommodity = (data: CreateHedgedCommodityRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/hedgedCommodity`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsHedgedCommodity
   * @request OPTIONS:/hedgedCommodity
   */
  optionsHedgedCommodity = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/hedgedCommodity`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateHedgedCommodityById
   * @request PUT:/hedgedCommodity/{id}
   * @secure
   */
  updateHedgedCommodityById = (
    id: string,
    data: UpdateHedgedCommodityRequest,
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/hedgedCommodity/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsHedgedCommodity2
   * @request OPTIONS:/hedgedCommodity/{id}
   * @originalName optionsHedgedCommodity
   * @duplicate
   */
  optionsHedgedCommodity2 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/hedgedCommodity/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetFilteredHedgedCommodities
   * @request GET:/hedgedCommodity/filter
   * @secure
   */
  getFilteredHedgedCommodities = (
    query: {
      sortOrder?: string;
      limit: string;
      search?: string;
      sortBy?: string;
      offset?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<FilteredHedgedCommoditiedResponse, any>({
      path: `/hedgedCommodity/filter`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsHedgedCommodity3
   * @request OPTIONS:/hedgedCommodity/filter
   * @originalName optionsHedgedCommodity
   * @duplicate
   */
  optionsHedgedCommodity3 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/hedgedCommodity/filter`,
      method: 'OPTIONS',
      ...params,
    });
}
