import { AuthState } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

import { securityWorker } from '../models/Security';
import { Hello } from '../services/backend/Hello';

export const useHelloClient: () => Hello<AuthState> = () => {
  const { oktaAuth } = useOktaAuth();
  const helloClient = new Hello({ securityWorker, baseURL: process.env.REACT_APP_BE_URL });
  if (oktaAuth.authStateManager.getAuthState()?.isAuthenticated) {
    helloClient.setSecurityData(oktaAuth.authStateManager.getAuthState());
  }
  oktaAuth.authStateManager.subscribe((authState) => {
    helloClient.setSecurityData(authState);
  });
  return helloClient;
};
