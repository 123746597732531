import { FC, useEffect, useState } from 'react';

import { Box, Grid, TextField } from '@mui/material';

import CurrencyTextField from '../../../../components/CurrencyTextField/CurrencyTextField';
import FormikAutocomplete from '../../../../components/FormikAutocomplete/FormikAutocomplete';
import NumericFormatCustom from '../../../../components/NumericFormatCustom/NumericFormatCustom';

interface FuturesEntryDetailsProps {
  formik: any;
  tradeAccountsMap: Map<number, string>;
  isLoadingTradeAccountOptions: boolean;
  commoditiesMap: Map<number, string>;
  isLoadingCommodityOptions: boolean;
  futuresContractsMap: Map<number, string>;
  isLoadingFuturesContractOptions: boolean;
  brokersMap: Map<number, string>;
  isLoadingBrokers: boolean;
  monthsMap: Map<number, string>;
  periodsMap: Map<number, string>;
  periodsActive: boolean;
  isLoadingPeriods: boolean;
  isLoadingMonthOptions: boolean;
  fieldToAutoFocus: string;
  onAutoFocus: () => void;
  openOnAutoFocus: boolean;
  onTradeAccountChange: () => void;
  onCommodityChange: () => void;
  onFuturesContractChange: () => void;
  onMonthsChange: () => void;
}

const FuturesEntryDetails: FC<FuturesEntryDetailsProps> = ({
  formik,
  tradeAccountsMap,
  isLoadingTradeAccountOptions,
  futuresContractsMap,
  isLoadingFuturesContractOptions,
  commoditiesMap,
  isLoadingCommodityOptions,
  monthsMap,
  isLoadingMonthOptions,
  periodsMap,
  periodsActive,
  isLoadingPeriods,
  brokersMap,
  isLoadingBrokers,
  fieldToAutoFocus,
  onAutoFocus: onSetFocus,
  openOnAutoFocus,
  onTradeAccountChange,
  onCommodityChange,
  onFuturesContractChange,
  onMonthsChange,
}) => {
  const [focusedElementWhileWindowBlurred, setFocusedElementWhileWindowBlurred] = useState(
    document.activeElement
  );
  useEffect(() => {
    setFocusedElementWhileWindowBlurred(null);
    const onFocus = () => setTimeout(() => setFocusedElementWhileWindowBlurred(null), 100);
    const onBlur = () => setFocusedElementWhileWindowBlurred(document.activeElement);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return (
    <Box data-testid="FuturesEntryDetails">
      {!formik.values ? null : (
        <form autoComplete="off">
          <Grid container spacing={2}>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="tradeAccountId-select-label">
                <b>Trade Account:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="tradeAccountId"
                  required
                  optionsAsMap={tradeAccountsMap}
                  loading={isLoadingTradeAccountOptions}
                  openOnFocus={
                    (fieldToAutoFocus ? openOnAutoFocus : true) &&
                    focusedElementWhileWindowBlurred?.getAttribute('name') !== 'tradeAccountId'
                  }
                  inputRef={(input) => {
                    if (input && fieldToAutoFocus === 'tradeAccountId') {
                      input.focus();
                      onSetFocus();
                    }
                  }}
                  onValueChange={onTradeAccountChange}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="commodityId-select-label">
                <b>Commodity:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="commodityId"
                  required
                  optionsAsMap={commoditiesMap}
                  loading={isLoadingCommodityOptions}
                  openOnFocus
                  onValueChange={onCommodityChange}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="futuresContractId-select-label">
                <b>Futures Contract:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="futuresContractId"
                  required
                  optionsAsMap={futuresContractsMap}
                  loading={isLoadingFuturesContractOptions}
                  openOnFocus
                  onValueChange={onFuturesContractChange}
                />
              </Grid>
            </Grid>
            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="brokerId-select-label">
                <b>Broker:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="brokerId"
                  required
                  optionsAsMap={brokersMap}
                  loading={isLoadingBrokers}
                  openOnFocus
                />
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="order-number-input-label">
                Order Number:
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="order-number-text"
                  value={formik.values.brokerOrderNumber}
                  size="small"
                  name="brokerOrderNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.brokerOrderNumber && Boolean(formik.errors.brokerOrderNumber)
                  }
                  helperText={
                    formik.touched.brokerOrderNumber && formik.errors.brokerOrderNumber?.toString()
                  }
                  InputProps={{ 'aria-labelledby': 'order-number-input-label' }}
                ></TextField>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="contracts-input-label">
                <b>Contracts:</b>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="contracts-text"
                  size="small"
                  name="contracts"
                  value={formik.values.contracts}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  inputProps={{ maxLength: 25 }}
                  error={formik.touched.contracts && Boolean(formik.errors.contracts)}
                  helperText={formik.touched.contracts && formik.errors.contracts?.toString()}
                  InputProps={{
                    'aria-labelledby': 'contracts-input-label',
                    inputComponent: NumericFormatCustom as any,
                  }}
                ></TextField>
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="optionMonthId-select-label">
                <b>Month:</b>
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete
                  formik={formik}
                  field="optionMonthId"
                  required
                  optionsAsMap={monthsMap}
                  loading={isLoadingMonthOptions}
                  openOnFocus
                  onValueChange={onMonthsChange}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="positionPeriodDimId-select-label">
                <b>Position Period:</b>
              </Grid>
              <Grid
                item
                xs={6}
                sx={
                  !periodsActive
                    ? { pointerEvents: 'none', color: { backgroundColor: 'lightgray' } }
                    : {}
                }
              >
                <FormikAutocomplete
                  formik={formik}
                  field="positionPeriodDimId"
                  optionsAsMap={periodsMap}
                  loading={isLoadingPeriods}
                  openOnFocus
                  disabled={!periodsActive}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="futures-price-input-label">
                <b>Futures Price:</b>
              </Grid>
              <Grid item xs={6}>
                <CurrencyTextField
                  id="futures-price-text"
                  name="futuresPrice"
                  inputProps={{
                    maxLength: 12,
                  }}
                  value={formik.values.futuresPrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.futuresPrice && Boolean(formik.errors.futuresPrice)}
                  helperText={formik.touched.futuresPrice && formik.errors.futuresPrice?.toString()}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems="Center" xs={12}>
              <Grid item xs={3} id="comment-input-label">
                Comment:
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline={true}
                  id="comment-text"
                  minRows={3}
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Comment"
                  inputProps={{ maxLength: 256 }}
                  error={formik.touched.comment && Boolean(formik.errors.comment)}
                  helperText={formik.touched.comment && formik.errors.comment?.toString()}
                  InputProps={{ 'aria-labelledby': 'comment-input-label' }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default FuturesEntryDetails;
