import React, { FC, useContext, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import { useMutation } from '@tanstack/react-query';

import { PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import UnsavedChangesPrompt from '../../../components/UnsavedChangesPrompt/UnsavedChangesPrompt';
import { UserContext } from '../../../Context';
import { useOptionMonthsByYearQuery } from '../../../hooks';
import { usePositionsOptionMonthsMassUpdates } from '../../../hooks/backendDataClients/usePositionsOptionMonthsMassUpdates';

import FuturesMonthsDisplay from './FuturesMonthsDisplay';

const FuturesMonthsPage: FC = () => {
  const [futuresYear, setFuturesYear] = useState(new Date().getFullYear());
  const [changedRows, setChangedRows] = useState<GridValidRowModel[]>([]);
  const dataClient = usePositionsOptionMonthsMassUpdates();
  const monthsByYearQuery = useOptionMonthsByYearQuery(futuresYear.toString());
  const mutation = useMutation({
    mutationFn: (rowsToSave: GridValidRowModel[]) => {
      return dataClient.updatePositionsOptionMonth({
        months: rowsToSave.map((r) => ({ optionMonthId: r.optionMonthId, isActive: r.isActive })),
      });
    },
    onSuccess: () => {
      setChangedRows([]);
      monthsByYearQuery.refetch();
    },
  });
  useEffect(() => {
    document.title = `Futures Months ${
      changedRows?.length ? '(' + changedRows.length + ')' : ''
    } | Positions and Futures`;
  }, [changedRows]);

  const rows = monthsByYearQuery.data ?? [];

  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main style={{ padding: '24px' }} data-testid="FuturesMonthsPage">
          <h2>Futures Months</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  return (
    <main style={{ padding: '24px' }} data-testid="FuturesMonthsPage">
      <FuturesMonthsDisplay
        futuresYear={futuresYear}
        setFuturesYear={setFuturesYear}
        initialRows={rows}
        isLoading={monthsByYearQuery.isPending}
        changedRows={changedRows}
        setChangedRows={setChangedRows}
        onSave={() => mutation.mutate(changedRows)}
      />
      <UnsavedChangesPrompt changedRows={changedRows} />
    </main>
  );
};

export default FuturesMonthsPage;
