import React, { FC } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import LabeledBox from '../../../../components/LabeledBox/LabeledBox';
import {
  CommoditiesSummaryResponse,
  FuturesContractsSummaryResponse,
  FuturesTradeAccountSummaryResponse,
} from '../../../../services/backend/data-contracts';
import { FilterForm } from '../useSwapEntries';

export interface SwapEntriesFilterFormProps {
  commodities?: CommoditiesSummaryResponse['result'];
  futuresContractSummaries?: FuturesContractsSummaryResponse['result'];
  tradeAccountSummaries?: FuturesTradeAccountSummaryResponse['result'];
  filterForm: FilterForm;
  setFilterForm: React.Dispatch<React.SetStateAction<FilterForm>>;
}

const SwapEntriesFilterForm: FC<SwapEntriesFilterFormProps> = ({
  filterForm,
  setFilterForm,
  futuresContractSummaries,
  commodities,
  tradeAccountSummaries,
}) => {
  const handleFuturesContractFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, futuresContractId: event.target.value });
  };
  const handleBuyCommodityFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, buyCommodityId: event.target.value });
  };
  const handleBuyTradeAccountsChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, buyTradeAccountId: event.target.value });
  };
  const handleSellCommodityFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, sellCommodityId: event.target.value });
  };
  const handleSellTradeAccountsChange = (event: SelectChangeEvent<string>) => {
    setFilterForm({ ...filterForm, sellTradeAccountId: event.target.value });
  };

  return (
    <Stack spacing={1} data-testid="SwapEntriesFilterForm">
      <FormControl fullWidth>
        <InputLabel id="futuresContract-label">Futures Contract</InputLabel>
        <Select
          labelId="futuresContract-label"
          id="futuresContract-select"
          value={filterForm.futuresContractId}
          label="Futures Contract"
          onChange={handleFuturesContractFilterChange}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {futuresContractSummaries?.map((t) => (
            <MenuItem key={t.futuresContractId} value={t.futuresContractId}>
              {t.futuresContractValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack direction="row">
        <LabeledBox label="Buy">
          <Stack spacing={1}>
            <FormControl fullWidth>
              <InputLabel id="buyCommodity-label">Commodity</InputLabel>
              <Select
                labelId="buyCommodity-label"
                id="buyCommodity-select"
                value={filterForm.buyCommodityId}
                label="Commodity"
                onChange={handleBuyCommodityFilterChange}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {commodities?.map((t) => (
                  <MenuItem key={t.commodityId} value={t.commodityId}>
                    ({t.commodityCode}) - {t.commodityName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="buyTradeAccounts-label">Trade Account</InputLabel>
              <Select
                labelId="buyTradeAccounts-label"
                id="buyTradeAccounts-select"
                value={filterForm.buyTradeAccountId}
                label="tradeAccounts"
                onChange={handleBuyTradeAccountsChange}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {tradeAccountSummaries?.map((t) => (
                  <MenuItem key={t.tradeAccountId} value={t.tradeAccountId}>
                    {t.tradeAccountName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </LabeledBox>
        <LabeledBox label="Sell">
          <Stack spacing={1}>
            <FormControl fullWidth>
              <InputLabel id="sellCommodity-label">Commodity</InputLabel>
              <Select
                labelId="sellCommodity-label"
                id="sellCommodity-select"
                value={filterForm.sellCommodityId}
                label="Commodity"
                onChange={handleSellCommodityFilterChange}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {commodities?.map((t) => (
                  <MenuItem key={t.commodityId} value={t.commodityId}>
                    ({t.commodityCode}) - {t.commodityName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="sellTradeAccounts-label">Trade Account</InputLabel>
              <Select
                labelId="sellTradeAccounts-label"
                id="sellTradeAccounts-select"
                value={filterForm.sellTradeAccountId}
                label="tradeAccounts"
                onChange={handleSellTradeAccountsChange}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {tradeAccountSummaries?.map((t) => (
                  <MenuItem key={t.tradeAccountId} value={t.tradeAccountId}>
                    {t.tradeAccountName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </LabeledBox>
      </Stack>
    </Stack>
  );
};

export default SwapEntriesFilterForm;
