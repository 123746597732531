import { FC, useEffect } from 'react';
import { AxiosResponse } from 'axios';

import { useQuery } from '@tanstack/react-query';

import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import {
  BrokerEntryResponse,
  CreateBrokerEntryRequest,
  CreateBrokerEntryResponse,
  CreateCommissionRateRequest,
  DeleteCommissionRateRequest,
  FuturesBrokerResponse,
  FuturesContractsSummaryResponse,
  UpdateBrokerEntryRequest,
  UpdateCommissionRateRequest,
} from '../../../../services/backend/data-contracts';
import { RequestParams } from '../../../../services/backend/http-client';

import BrokerEntryModal from './BrokerEntryModal';

export const blankEntryDetails = {
  brokerCode: '',
  brokerName: '',
  divisionId: 0,
  divisionName: '',
  isActive: false,
  glCompanyCode: '',
  glAccount: '',
  commissionRates: [],
} as any;

interface BrokerEntryModalWrapperProps {
  isModalOpen: boolean;
  onClose: () => void;
  selectedEntryId?: number;
  createBrokerEntry: (
    data: CreateBrokerEntryRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<CreateBrokerEntryResponse, any>>;
  getBrokerEntry: (
    id: string,
    params?: RequestParams
  ) => Promise<AxiosResponse<BrokerEntryResponse, any>>;
  updateBrokerEntry: (
    id: string,
    data: UpdateBrokerEntryRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  updateCommissionRate: (
    data: UpdateCommissionRateRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  createCommissionRate: (
    data: CreateCommissionRateRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  deleteCommissionRate: (
    data: DeleteCommissionRateRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  userName: string;
  futuresContracts?: FuturesContractsSummaryResponse['result'];
  brokers?: FuturesBrokerResponse['result'];
  refetchFilteredBrokerEntries: () => void;
}

const BrokerEntryModalWrapper: FC<BrokerEntryModalWrapperProps> = ({
  isModalOpen,
  selectedEntryId,
  onClose,
  createBrokerEntry,
  getBrokerEntry,
  updateBrokerEntry,
  userName,
  futuresContracts,
  updateCommissionRate,
  createCommissionRate,
  deleteCommissionRate,
  brokers,
  refetchFilteredBrokerEntries,
}) => {
  const {
    data: initialDetails,
    refetch,
    isPending,
  } = useQuery({
    enabled: false,
    queryKey: ['BrokerEntries', 'Broker Entries', selectedEntryId],
    queryFn: async () => {
      if (selectedEntryId) {
        const response: BrokerEntryResponse = (await getBrokerEntry(selectedEntryId.toString()))
          .data;
        return response.result;
      } else {
        return {
          brokerCode: '',
          brokerName: '',
          divisionId: 0,
          divisionName: '',
          isActive: false,
          glCompanyCode: '',
          glAccount: '',
          commissionRates: [],
        } as any;
      }
    },
  });
  useEffect(() => {
    refetch && refetch();
  }, [refetch]);

  if (!isPending && initialDetails) {
    return (
      <BrokerEntryModal
        initialDetails={initialDetails}
        futuresContracts={futuresContracts}
        brokers={brokers}
        isModalOpen={isModalOpen}
        selectedEntryId={selectedEntryId}
        getBrokerEntry={getBrokerEntry}
        createBrokerEntry={createBrokerEntry}
        updateBrokerEntry={updateBrokerEntry}
        onClose={onClose}
        userName={userName}
        updateCommissionRate={updateCommissionRate}
        createCommissionRate={createCommissionRate}
        deleteCommissionRate={deleteCommissionRate}
        refetch={refetch}
        refetchFilteredBrokerEntries={refetchFilteredBrokerEntries}
      ></BrokerEntryModal>
    );
  }

  return <LoadingSpinner />;
};

export default BrokerEntryModalWrapper;
