import React, { FC } from 'react';

import { Badge } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import FuturesMonthsForm, { FuturesMonthsFormProps } from './FuturesMonthsForm/FuturesMonthsForm';
import FuturesMonthsGrid, { FuturesMonthsGridProps } from './FuturesMonthsGrid/FuturesMonthsGrid';

type FuturesMonthsDisplayProps = FuturesMonthsFormProps & FuturesMonthsGridProps;

const FuturesMonthsDisplay: FC<FuturesMonthsDisplayProps> = ({
  futuresYear,
  setFuturesYear,
  initialRows,
  isLoading,
  changedRows,
  setChangedRows,
  onSave,
}) => (
  <Container>
    <Typography variant="h2" mb="0.5em" role="heading">
      <Badge badgeContent={changedRows?.length} color="secondary">
        Futures Months
      </Badge>
    </Typography>
    <FuturesMonthsForm futuresYear={futuresYear} setFuturesYear={setFuturesYear} />
    <Typography paragraph />
    <FuturesMonthsGrid
      initialRows={initialRows}
      isLoading={isLoading}
      changedRows={changedRows}
      setChangedRows={setChangedRows}
      onSave={onSave}
    />
  </Container>
);

export default FuturesMonthsDisplay;
