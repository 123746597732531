import axios, { AxiosRequestConfig } from 'axios';

import { AuthState } from '@okta/okta-auth-js';

export const securityWorker = (
  securityData: AuthState | null
): Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void => {
  const controller = new AbortController();
  if (!securityData?.isAuthenticated) {
    controller.abort();
  }
  return {
    headers: {
      Authorization: securityData?.accessToken?.accessToken,
      'Correlation-object': JSON.stringify({ correlationId: securityData?.idToken?.claims.email }),
    },
    baseURL: axios.defaults.baseURL,
    signal: controller.signal,
  };
};
