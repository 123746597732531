import { FC, useState } from 'react';

import {
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowsProp,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid';

import {
  ChangeableGrid,
  dateValueFormatter,
  errorCellClassName,
  yesNoGridColTypeDef,
} from '../../../components';

import { validate, validateAll } from './HedgedCommodity.schema';

export const columns: GridColDef[] = [
  {
    field: 'hedgedCommodityCode',
    headerName: 'Commodity Code',
    flex: 1,
    type: 'string',
    editable: true,
    cellClassName: errorCellClassName,
  },
  {
    field: 'hedgedCommodityName',
    headerName: 'Commodity Name',
    flex: 1,
    type: 'string',
    editable: true,
    cellClassName: errorCellClassName,
  },
  {
    field: 'glCommodityCode',
    headerName: 'GL Commodity Code',
    flex: 1,
    type: 'string',
    editable: true,
    cellClassName: errorCellClassName,
  },
  {
    field: 'isActive',
    headerName: 'Active',
    flex: 1,
    editable: true,
    ...yesNoGridColTypeDef('isActive'),
  },
  {
    field: 'updatedOn',
    headerName: 'Updated On',
    flex: 1,
    valueFormatter: dateValueFormatter,
  },
  { field: 'updatedBy', headerName: 'Updated By', flex: 1 },
];

interface HedgedCommoditiesDisplayProps {
  userName?: string;
  rows?: GridRowsProp[];
  loading?: boolean;
  rowCountState?: number;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  onSave?: (changedRows: GridValidRowModel[]) => void;
  changedRows?: GridValidRowModel[];
  setChangedRows?: (changedRows: GridValidRowModel[]) => void;
  getNextId?: (offset?: number) => number;
}

const HedgedCommoditiesDisplay: FC<HedgedCommoditiesDisplayProps> = ({
  userName,
  rows,
  loading,
  rowCountState,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  getNextId,
  ...props
}) => {
  const [_changedRows, _setChangedRows] = useState<GridValidRowModel[]>([]);
  const changedRows = props.changedRows ? props.changedRows : _changedRows;
  const setChangedRows = props.setChangedRows ? props.setChangedRows : _setChangedRows;

  const handleChangedRows = async (changedRows: GridValidRowModel[]) => {
    const validated = await validateAll(changedRows);
    setChangedRows(validated);
    return validated;
  };

  const onAdd = async (id?: any) => {
    const newCount = (changedRows || []).filter(({ isNew }) => isNew).length;
    const hedgedCommodityId = getNextId
      ? getNextId(newCount)
      : !isNaN(id)
        ? id
        : 1000 + newCount + 1;
    const newRow = (await validate({
      hedgedCommodityName: '',
      updatedBy: userName,
      updatedOn: new Date().toISOString(),
      hedgedCommodityId,
      isActive: false,
      hedgedCommodityCode: '',
      glCommodityCode: '',
      isNew: true,
    })) as GridValidRowModel;

    setChangedRows([...(changedRows || []), newRow] as GridValidRowModel[]);
    return newRow;
  };

  const onSave = async () => {
    if (props.onSave) return await props.onSave(changedRows as GridValidRowModel[]);
    return Promise.resolve(changedRows);
  };

  return (
    <ChangeableGrid
      initialRows={rows}
      isLoading={loading}
      columns={columns}
      rowCount={rowCountState}
      changedRows={changedRows}
      setChangedRows={handleChangedRows}
      getRowId={(row: any) => row.hedgedCommodityId}
      onAdd={onAdd}
      onSave={onSave}
      pageSizeOptions={[25, 50, 100]}
      paginationModel={paginationModel}
      paginationMode="server"
      sortingMode="server"
      filterMode="server"
      onPaginationModelChange={setPaginationModel}
      onSortModelChange={onSortModelChange}
      onFilterModelChange={onFilterModelChange}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableDensitySelector
      onProcessRowUpdateError={console.error}
      changesToTop={true}
    />
  );
};

export default HedgedCommoditiesDisplay;
