import React, { useContext } from 'react';

import Typography from '@mui/material/Typography';
import { useQueries } from '@tanstack/react-query';

import { CompuWeighRole, PositionAdministratorRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';
import {
  useCommodityFuturesContractQuery,
  useFuturesContractsQuery,
  useLocationCommodityRefQuery,
  useLocationsQuery,
} from '../../../hooks';
import {
  useCompuweighMassCreates,
  useCompuweighMassDeletes,
  useCompuweighMassUpdates,
  useViews,
} from '../../../hooks/backendDataClients';
import { useCompuWeighQuery } from '../../../hooks/useBackendQueries';

import CompuWeighList from './CompuWeighList/CompuWeighList';

const MASK = 0x1000;

export default function CompuWeighSpotPricingPage() {
  const compuWeighQuery = useCompuWeighQuery;
  const fakeCompuWeighQuery = () => {
    const cws = compuWeighQuery();
    return cws.data
      ? {
          ...cws,
          data: cws.data.map((cw) => ({
            ...cw,
            id: Number(cw['locationId']) + Number(cw['commodityId']) * MASK,
          })),
        }
      : cws;
  };
  const locationsQuery = useLocationsQuery();
  const locationCommodityRefQuery = useLocationCommodityRefQuery();
  const getCommoditiesForLocation = (locationId: number) =>
    locationCommodityRefQuery.data?.filter((lc) => lc.locationId === locationId) ?? [];
  const commodityFuturesContractQuery = useCommodityFuturesContractQuery();
  const getFuturesContractsForCommodity = (commodityId: number) =>
    commodityFuturesContractQuery.data?.filter((cfc) => cfc.commodityId === commodityId) ?? [];

  const futuresContractsQuery = useFuturesContractsQuery();
  const viewsDataClient = useViews();
  const compuweighMassCreatesDataClients = useCompuweighMassCreates();
  const compuweighMassUpdatessDataClients = useCompuweighMassUpdates();
  const compuweighMassDeletesDataClients = useCompuweighMassDeletes();
  const results = useQueries({
    queries: (futuresContractsQuery.data ?? [])
      .filter((f) => f?.futuresContractId !== undefined)
      .map((f) => ({
        queryKey: ['CompuWeigh', 'Futures Contract Option Months', f.futuresContractId?.toString()],
        queryFn: async () => ({
          futuresContractId: f.futuresContractId,
          months: (
            await viewsDataClient.getFuturesContractOptionMonthRef({
              futuresContractId: f.futuresContractId?.toString() || '',
            })
          ).data.result.sort((a, b) => (a.optionMonthOrder ?? 0) - (b.optionMonthOrder ?? 0)),
        }),
      })),
  });
  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([CompuWeighRole, PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="CompuWeighSpotPricingPage">
          <h2>CompuWeigh Spot Pricing</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }

  const getOptionMonthsForFuturesContract = (futuresContractId: number) =>
    results.map((r) => r.data).find((d) => d?.futuresContractId === futuresContractId)?.months ??
    [];
  const isLoadingOptionMonthsForFuturesContract = results
    .map((r) => r.isPending)
    .reduce((prev, curr) => prev || curr, false);
  const isLoading =
    locationsQuery.isPending ||
    locationCommodityRefQuery.isPending ||
    commodityFuturesContractQuery.isPending ||
    futuresContractsQuery.isPending ||
    isLoadingOptionMonthsForFuturesContract;
  return (
    <main style={{ minWidth: '100%', padding: '24px' }} data-testid="CompuWeighSpotPricingPage">
      <CompuWeighList
        compuWeighQuery={fakeCompuWeighQuery}
        locations={locationsQuery.data}
        getCommoditiesForLocation={getCommoditiesForLocation}
        getFuturesContractsForCommodity={getFuturesContractsForCommodity}
        getOptionMonthsForFuturesContract={getOptionMonthsForFuturesContract}
        createSpotPricing={compuweighMassCreatesDataClients.createCompuweigh}
        updateSpotPricing={compuweighMassUpdatessDataClients.updateCompuweigh}
        deleteSpotPricing={compuweighMassDeletesDataClients.deleteCompuweigh}
        isLoading={isLoading}
      />
    </main>
  );
}
