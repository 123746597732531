import { useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import { FuturesTradesFilterForm } from '../pages/Transactions/FuturesEntries/useFuturesEntries';

import { useFutureTradeEntries } from './backendDataClients';
import { useToastMutation } from './useToastMutations';

export const futuresTradesKeys = {
  all: ['Futures Trades'] as const,
  lists: () => [...futuresTradesKeys.all, 'list'] as const,
  details: (id: string) => [...futuresTradesKeys.all, 'detail', id],
};

function asQueryParams(filters: FuturesTradesFilterForm) {
  return Object.assign(
    { limit: filters.limit }, // number of rows per page
    { offset: filters.offset }, // what row to start at
    filters.search === '' ? null : { search: filters.search },
    filters.sortOrder === '' ? null : { sortOrder: filters.sortOrder },
    filters.sortBy === '' ? null : { sortBy: filters.sortBy }
  );
}

export const useFuturesTradesFilteredListQuery = (filters: FuturesTradesFilterForm) => {
  const dataClient = useFutureTradeEntries();
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [...futuresTradesKeys.lists(), filters],
    queryFn: async () => {
      const response = await dataClient.getFilteredFutureTradeEntries(asQueryParams(filters));
      const responseBody = response.data?.result;
      responseBody?.entries?.forEach((ft) => {
        if (ft.tradeId) {
          queryClient.setQueryData(futuresTradesKeys.details(ft.tradeId + ''), ft);
        }
      });
      return responseBody;
    },
  });
};

export const useFuturesTradesFilteredCompleteListFetchFn = (filters: FuturesTradesFilterForm) => {
  const dataClient = useFutureTradeEntries();
  return async () => {
    return dataClient.getFilteredFutureTradeEntries({
      ...asQueryParams(filters),
      limit: Number.MAX_SAFE_INTEGER,
      offset: 0,
    });
  };
};

export const useFuturesTradeEntrySuspenseQuery = (entryToEdit: string) => {
  const dataClient = useFutureTradeEntries();
  return useSuspenseQuery({
    queryKey: futuresTradesKeys.details(entryToEdit),
    queryFn: async () => {
      if (!entryToEdit) {
        return null;
      }
      const response = await dataClient.getFutureTradeEntry(entryToEdit);
      return response.data?.result;
    },
  });
};

export const useFuturesTradeUpdateMutation = (entryToEdit: string) => {
  const dataClient = useFutureTradeEntries();
  const queryClient = useQueryClient();
  return useToastMutation({
    mutationFn: ({ id, data }: any) => {
      return dataClient.updateFutureTradeEntry(id, data);
    },
    queryKey: futuresTradesKeys.details(entryToEdit),
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Futures Trade Updated',
    afterSuccess: () => {
      queryClient.invalidateQueries({ queryKey: futuresTradesKeys.all });
    },
  });
};

export const useFuturesTradeDeleteMutation = (entryToEdit: string) => {
  const dataClient = useFutureTradeEntries();
  const queryClient = useQueryClient();
  return useToastMutation({
    mutationFn: ({ id }: any) => {
      return dataClient.deleteFutureTradeEntry(id);
    },
    queryKey: futuresTradesKeys.details(entryToEdit),
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Futures Trade Deleted',
    afterSuccess: () => {
      queryClient.invalidateQueries({ queryKey: futuresTradesKeys.all });
    },
    isDelete: true,
  });
};

export const useFuturesTradeCreateMutation = () => {
  return useToastMutation({
    mutationFn: useFutureTradeEntries().createFutureTradeEntry,
    queryKey: futuresTradesKeys.all,
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Futures Trade Created',
  });
};
