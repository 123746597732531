import React, { FC } from 'react';
import { AxiosResponse } from 'axios';
import { object, string } from 'yup';

import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';

import {
  ChangeableDisplay,
  dateValueFormatter,
  errorCellClassName,
  stringComparatorWithBlanksLast,
  yesNoGridColTypeDef,
} from '../../../components';
import { UseRefDataQueryResult } from '../../../hooks/';
import {
  CreateLocationGroupsRequest,
  LocationGroupsResponse,
  UpdateLocationGroupsByIdRequest,
} from '../../../services/backend/data-contracts';
import { RequestParams } from '../../../services/backend/http-client';

const locationGroupSchema = object({
  locationGroupName: string().required().max(35),
  locationGroupCode: string().required().max(6),
});

const columns: GridColDef[] = [
  {
    field: 'locationGroupCode',
    headerName: 'Location Group Code',
    flex: 1,
    type: 'string',
    editable: true,
    cellClassName: errorCellClassName,
    sortComparator: stringComparatorWithBlanksLast,
  },
  {
    field: 'locationGroupName',
    headerName: 'Location Group Name',
    flex: 1,
    type: 'string',
    editable: true,
    cellClassName: errorCellClassName,
  },
  {
    field: 'isActive',
    headerName: 'Active',
    flex: 1,
    editable: true,
    ...yesNoGridColTypeDef('isActive'),
  },
  {
    field: 'createdOn',
    headerName: 'Created On',
    flex: 1,
    valueFormatter: dateValueFormatter,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    flex: 1,
  },
  {
    field: 'updatedOn',
    headerName: 'Updated On',
    flex: 1,
    valueFormatter: dateValueFormatter,
  },
  { field: 'updatedBy', headerName: 'Updated By', flex: 1 },
];

interface LocationGroupsDisplayProps {
  userName: string;
  getLocationGroups: () => UseRefDataQueryResult<LocationGroupsResponse['result']>;
  createLocationGroups: (
    createLocationGroupsRequest: CreateLocationGroupsRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  updateLocationGroups: (
    updateLocationGroupsByIdRequest: UpdateLocationGroupsByIdRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  hideUnsavedPrompt?: boolean;
}

const LocationGroupsDisplay: FC<LocationGroupsDisplayProps> = ({
  userName,
  getLocationGroups,
  createLocationGroups,
  updateLocationGroups,
  hideUnsavedPrompt,
}) => {
  const create = (newRows: GridValidRowModel[]) => {
    const now = new Date().toISOString();
    return newRows.length
      ? createLocationGroups({
          groups: newRows.map((r) => ({
            locationGroupCode: r.locationGroupCode,
            updatedBy: userName,
            createdBy: r.createdBy,
            updatedOn: now,
            isActive: r.isActive,
            createdOn: r.createdOn,
            locationGroupName: r.locationGroupName,
          })),
        })
      : Promise.resolve();
  };
  const update = (oldRows: GridValidRowModel[]) => {
    const now = new Date().toISOString();
    return oldRows.length
      ? updateLocationGroups({
          groups: oldRows.map((r) => ({
            locationGroupCode: r.locationGroupCode,
            updatedBy: userName,
            updatedOn: now,
            isActive: r.isActive,
            locationGroupId: r.locationGroupId,
            locationGroupName: r.locationGroupName,
          })),
        })
      : Promise.resolve();
  };
  const add = (fakeId: number | undefined) => {
    const now = new Date().toISOString();
    return {
      locationGroupId: fakeId,
      locationGroupCode: '',
      locationGroupName: '',
      isActive: true,
      createdOn: now,
      updatedOn: now,
      createdBy: userName,
      updatedBy: userName,
    };
  };
  return (
    <ChangeableDisplay
      pageTitle="Location Groups"
      columns={columns}
      getRowId={(row) => row['locationGroupId']}
      create={create}
      update={update}
      onAddButtonClick={add}
      fnUseQuery={getLocationGroups}
      schema={locationGroupSchema}
      uniqueFields={['locationGroupCode']}
      sortField="locationGroupCode"
      hideUnsavedPrompt={hideUnsavedPrompt}
    />
  );
};

export default LocationGroupsDisplay;
