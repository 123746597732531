import React, { FC, useContext } from 'react';

import { Badge } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {
  FuturesUserRole,
  PositionAdministratorRole,
} from '../../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../../Context';
import CashAdjustmentsFilterForm, {
  CashAdjustmentsFilterFormProps,
} from '../CashAdjustmentsFilterForm/CashAdjustmentsFilterForm';
import CashAdjustmentsGrid, {
  CashAdjustmentsGridProps,
} from '../CashAdjustmentsGrid/CashAdjustmentsGrid';

type CashAdjustmentsDisplayProps = CashAdjustmentsFilterFormProps & CashAdjustmentsGridProps;

const CashAdjustmentsDisplay: FC<CashAdjustmentsDisplayProps> = ({
  rows,
  loading,
  rowCountState,
  brokers,
  filterForm,
  setFilterForm,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  onExportButtonClick,
  onAddButtonClick,
}) => {
  const userContext = useContext(UserContext);
  if (!userContext?.userName) {
    return (
      <Container maxWidth={false}>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Cash Adjustments</Badge>
        </Typography>
      </Container>
    );
  }
  if (!userContext?.haveRole([PositionAdministratorRole, FuturesUserRole])) {
    return (
      <Container maxWidth={false}>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Cash Adjustments</Badge>
        </Typography>
        <Typography paragraph>You do not have access to this page</Typography>
      </Container>
    );
  }
  return (
    <Container maxWidth={false}>
      <Typography variant="h2" mb="0.5em" role="heading">
        <Badge color="secondary">Cash Adjustments</Badge>
      </Typography>
      <CashAdjustmentsFilterForm
        brokers={brokers}
        filterForm={filterForm}
        setFilterForm={setFilterForm}
      ></CashAdjustmentsFilterForm>
      <Typography paragraph />
      <CashAdjustmentsGrid
        rows={rows}
        loading={loading}
        rowCountState={rowCountState}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        onExportButtonClick={onExportButtonClick}
        onAddButtonClick={onAddButtonClick}
      ></CashAdjustmentsGrid>
    </Container>
  );
};

export default CashAdjustmentsDisplay;
