/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateUserRequest,
  UpdateUserRoleRequest,
  UserResponse,
  UsersResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class User<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetUsers
   * @request GET:/user
   * @secure
   */
  getUsers = (params: RequestParams = {}) =>
    this.request<UsersResponse, any>({
      path: `/user`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name CreateUser
   * @request POST:/user
   * @secure
   */
  createUser = (data: CreateUserRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/user`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsUser
   * @request OPTIONS:/user
   */
  optionsUser = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/user`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetUser
   * @request GET:/user/{id}
   * @secure
   */
  getUser = (id: string, params: RequestParams = {}) =>
    this.request<UserResponse, any>({
      path: `/user/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateUser
   * @request PUT:/user/{id}
   * @secure
   */
  updateUser = (id: string, data: UpdateUserRoleRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/user/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name DeleteUser
   * @request DELETE:/user/{id}
   * @secure
   */
  deleteUser = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/user/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsUser2
   * @request OPTIONS:/user/{id}
   * @originalName optionsUser
   * @duplicate
   */
  optionsUser2 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/user/${id}`,
      method: 'OPTIONS',
      ...params,
    });
}
