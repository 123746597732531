import { useEffect, useState } from 'react';

import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { useQuery } from '@tanstack/react-query';

import { backendDataClients, useOktaUser } from '../../../hooks';
import { useToastMutation } from '../../../hooks/useToastMutations';
import { FilteredFuturesContractsResponse } from '../../../services/backend/data-contracts';

const { useHedgedCommodity } = backendDataClients;

export interface HedgedCommoditiesFilterForm {
  sortOrder?: string;
  limit: number;
  offset?: number;
  search?: string;
  sortBy?: string;
}

export interface HedgedCommoditiesPaginationModel {
  page: number;
  pageSize: number;
}

export const hedgedCommoditiesToRows = (
  hedgedCommodities?: FilteredFuturesContractsResponse
): GridRowsProp[] => {
  let rows: GridRowsProp[] = [];
  if (hedgedCommodities?.result?.entries) {
    rows = hedgedCommodities?.result.entries.map((h: any) => {
      return { ...h } as unknown as GridRowsProp;
    });
  }
  return rows;
};

export const getPaginationModelDefault = (
  filterForm?: HedgedCommoditiesFilterForm
): HedgedCommoditiesPaginationModel => ({
  page: 0,
  pageSize: filterForm ? filterForm.limit : getFilterFormDefault().limit,
});
export const getFilterFormDefault = (): HedgedCommoditiesFilterForm => ({
  sortOrder: '',
  limit: 50,
  offset: 0,
  search: '',
  sortBy: '',
});

export default function useHedgedCommodities() {
  const { user } = useOktaUser();
  const userName = user?.preferred_username ?? '';

  const [filterForm, setFilterForm] = useState<HedgedCommoditiesFilterForm>(getFilterFormDefault());

  const [initialTotalCount, setInitialTotalCount] = useState<number | undefined>(0);
  const [paginationModel, setPaginationModel] = useState<HedgedCommoditiesPaginationModel>(
    getPaginationModelDefault(filterForm)
  );

  const getNextId = (offset?: number) => {
    return (initialTotalCount || 0) + (offset || 0) + 1;
  };

  const queryParams = Object.assign(
    {
      limit: filterForm.limit + '', // number of rows per page
      offset: filterForm.offset + '', // what row to start at
    },
    filterForm.search === '' ? null : { search: filterForm.search },
    filterForm.sortOrder === '' ? null : { sortOrder: filterForm.sortOrder },
    filterForm.sortBy === '' ? null : { sortBy: filterForm.sortBy }
  );
  const hedgedCommodityDataClient = useHedgedCommodity();
  const {
    data: hedgedCommodities,
    isPending: isLoadingRows,
    refetch: refetchHedgedCommodities,
  } = useQuery({
    queryKey: ['HedgedCommodities', filterForm],
    queryFn: () => hedgedCommodityDataClient.getFilteredHedgedCommodities(queryParams),
    select: (response) => response?.data,
  });

  const saveHedgedCommodity = async ({
    isNew,
    hedgedCommodityName,
    isActive,
    hedgedCommodityCode,
    glCommodityCode,
    hedgedCommodityId,
  }: any) => {
    const payload = {
      entry: {
        hedgedCommodityName,
        isActive,
        hedgedCommodityCode,
        glCommodityCode,
      },
    };
    if (isNew) {
      return await hedgedCommodityDataClient.createHedgedCommodity(payload);
    } else {
      return await hedgedCommodityDataClient.updateHedgedCommodityById(hedgedCommodityId, payload);
    }
  };

  const saveMutation = useToastMutation({
    mutationFn: async (changedRows: any) => {
      return await Promise.all(changedRows.map(saveHedgedCommodity));
    },
    queryKey: ['HedgedCommodities'],
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Hedged Commodities Saved',
  });

  const [rowCountState, setRowCountState] = useState(hedgedCommodities?.result?.totalCount || 0);
  useEffect(() => {
    if ((hedgedCommodities?.result?.totalCount || 0) > (initialTotalCount || 0)) {
      setInitialTotalCount(hedgedCommodities?.result?.totalCount);
    }
    setRowCountState((prevRowCountState) =>
      hedgedCommodities?.result?.totalCount !== undefined
        ? hedgedCommodities?.result?.totalCount
        : prevRowCountState
    );
  }, [
    hedgedCommodities?.result?.totalCount,
    setRowCountState,
    initialTotalCount,
    setInitialTotalCount,
  ]);
  const onPaginationModelChange = (pageChange: GridPaginationModel) => {
    if (!isNaN(pageChange.page) && !isNaN(pageChange.pageSize)) {
      const offset = +pageChange.page * +pageChange.pageSize;
      setFilterForm({ ...filterForm, limit: pageChange.pageSize, offset });
      setPaginationModel(pageChange);
    }
  };
  const onSortModelChange = (model: GridSortModel) => {
    if (model[0] && model[0].field && model[0].sort) {
      const sortBy = model[0].field;
      setFilterForm({ ...filterForm, sortBy: sortBy, sortOrder: model[0].sort });
    } else {
      setFilterForm({ ...filterForm, sortOrder: '', sortBy: '' });
    }
  };
  const onFilterModelChange = (model: GridFilterModel) => {
    if (model && model.quickFilterValues && model.quickFilterValues.length > 0) {
      setFilterForm({ ...filterForm, search: model.quickFilterValues[0] });
    } else {
      setFilterForm({ ...filterForm, search: '' });
    }
  };

  return {
    filterForm,
    rowCountState,
    setFilterForm,
    rows: hedgedCommoditiesToRows(hedgedCommodities),
    isLoadingRows,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
    userName,
    saveChanges: saveMutation,
    refetchHedgedCommodities,
    getNextId,
  };
}
