/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  EndOfDayEnabledResponse,
  EndOfDayResponse,
  ProcessEndOfDayEntryRequest,
  ProcessEndOfDayEntryResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class EndOfDay<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name OptionsEndOfDay
   * @request OPTIONS:/endOfDay/{id}
   */
  optionsEndOfDay = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/endOfDay/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name ProcessEndOfDay
   * @request POST:/endOfDay/process
   * @secure
   */
  processEndOfDay = (data: ProcessEndOfDayEntryRequest, params: RequestParams = {}) =>
    this.request<ProcessEndOfDayEntryResponse, any>({
      path: `/endOfDay/process`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsEndOfDay2
   * @request OPTIONS:/endOfDay/process
   * @originalName optionsEndOfDay
   * @duplicate
   */
  optionsEndOfDay2 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/endOfDay/process`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name IsEnabledEndOfDay
   * @request GET:/endOfDay/isEnabled
   * @secure
   */
  isEnabledEndOfDay = (params: RequestParams = {}) =>
    this.request<EndOfDayEnabledResponse, any>({
      path: `/endOfDay/isEnabled`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsEndOfDay3
   * @request OPTIONS:/endOfDay/isEnabled
   * @originalName optionsEndOfDay
   * @duplicate
   */
  optionsEndOfDay3 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/endOfDay/isEnabled`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetEndOfDay
   * @request GET:/endOfDay
   * @secure
   */
  getEndOfDay = (params: RequestParams = {}) =>
    this.request<EndOfDayResponse, any>({
      path: `/endOfDay`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsEndOfDay4
   * @request OPTIONS:/endOfDay
   * @originalName optionsEndOfDay
   * @duplicate
   */
  optionsEndOfDay4 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/endOfDay`,
      method: 'OPTIONS',
      ...params,
    });
}
