import React, { FC, useContext } from 'react';

import { Badge } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {
  PositionAdministratorRole,
  PositionClerkRole,
  ViewOnlyRole,
} from '../../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../../Context';
import PositionEntriesFilterForm, {
  PositionEntriesFilterFormProps,
} from '../PositionEntriesFilterForm/PositionEntriesFilterForm';
import PositionEntriesGrid, {
  PositionEntriesGridProps,
} from '../PositionEntriesGrid/PositionEntriesGrid';

type FuturesMonthsDisplayProps = PositionEntriesFilterFormProps & PositionEntriesGridProps;
const PositionEntriesDisplay: FC<FuturesMonthsDisplayProps> = ({
  userName,
  rows,
  loading,
  rowCountState,
  commodities,
  locationSummaries,
  transactionTypes,
  filterForm,
  setFilterForm,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  onNewButtonClick,
  onEditButtonClick,
  onExportButtonClick,
}) => {
  const userContext = useContext(UserContext);
  if (!userContext?.userName) {
    return (
      <Container maxWidth={false}>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Position Entries</Badge>
        </Typography>
      </Container>
    );
  }
  if (!userContext?.haveRole([PositionClerkRole, ViewOnlyRole, PositionAdministratorRole])) {
    return (
      <Container maxWidth={false}>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Position Entries</Badge>
        </Typography>
        <Typography paragraph>You do not have access to this page</Typography>
      </Container>
    );
  }
  return (
    <Container maxWidth={false}>
      <Typography variant="h2" mb="0.5em" role="heading">
        <Badge color="secondary">Position Entries</Badge>
      </Typography>
      <PositionEntriesFilterForm
        locationSummaries={locationSummaries}
        commodities={commodities}
        transactionTypes={transactionTypes}
        filterForm={filterForm}
        setFilterForm={setFilterForm}
      ></PositionEntriesFilterForm>
      <Typography paragraph />
      <PositionEntriesGrid
        userName={userName}
        rows={rows}
        loading={loading}
        rowCountState={rowCountState}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        onNewButtonClick={onNewButtonClick}
        onEditButtonClick={onEditButtonClick}
        onExportButtonClick={onExportButtonClick}
      ></PositionEntriesGrid>
    </Container>
  );
};

export default PositionEntriesDisplay;
