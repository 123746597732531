import React, { useContext, useEffect } from 'react';

import Typography from '@mui/material/Typography';

import { PositionAdministratorRole, PositionRecRole } from '../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../Context';

import EndOfDayProcessDisplay from './EndOfDayProcessDisplay/EndOfDayProcessDisplay';
import useEndOfDayProcess from './useEndOfDayProcess';

export default function EndOfDayProcessPage() {
  useEffect(() => {
    document.title = 'End of Day Process | Positions and Futures';
  }, []);

  const { endOfDayStatus, endOfDayError, endOfDayRecords, resetEndOfDay, runEndOfDayProcess } =
    useEndOfDayProcess();
  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([PositionRecRole, PositionAdministratorRole]);
  if (!canRun) {
    return (
      <>
        <main data-testid="EndOfDayProcessPage">
          <h2>End of Day Process</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }
  return (
    <>
      <main style={{ padding: '24px' }} data-testid="EndOfDayProcessPage">
        <EndOfDayProcessDisplay
          endOfDayStatus={endOfDayStatus}
          endOfDayError={endOfDayError}
          endOfDayRecords={endOfDayRecords}
          resetEndOfDay={resetEndOfDay}
          runEndOfDayProcess={runEndOfDayProcess}
        ></EndOfDayProcessDisplay>
      </main>
    </>
  );
}
