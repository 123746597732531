import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export interface NumericFormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  thousandSeparator?: boolean;
  valueIsNumericString?: boolean;
}

const NumericFormatCustom = React.forwardRef<
  Omit<NumericFormatProps, 'getInputRef' | 'onValueChange'>,
  NumericFormatCustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={other.thousandSeparator ?? true}
      valueIsNumericString={other.valueIsNumericString ?? true}
    />
  );
});

export default NumericFormatCustom;
