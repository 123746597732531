import React, { FC, useContext, useState } from 'react';
import { useFormik } from 'formik';
import { Moment } from 'moment';

import { Typography } from '@mui/material';

import { FuturesUserRole, SuperUserRole } from '../../../app/UserWrapper/UserWrapper';
import TransactionEntryModal from '../../../components/TransactionEntryModal/TransactionEntryModal';
import { UserContext } from '../../../Context';
import { useBrokerSwap } from '../../../hooks/backendDataClients/index';
import { useToastMutation } from '../../../hooks/useToastMutations';

import BrokerSwapEntryDetail from './BrokerSwapEntryDetail/BrokerSwapEntryDetail';
import { brokerSwapEntrySchema } from './BrokerSwapEntry.schema';

interface BrokerSwapPageProps {
  isOpen: boolean;
  onClose: () => void;
}

const BrokerSwapPage: FC<BrokerSwapPageProps> = ({ isOpen, onClose }) => {
  const userContext = useContext(UserContext);
  const canRun = userContext.haveRole([SuperUserRole, FuturesUserRole]);
  const [isAutoFocus, setIsAutoFocus] = useState<boolean>(true);
  const createMutation = useToastMutation({
    mutationFn: useBrokerSwap().createBrokerSwap,
    queryKey: ['Futures Trades'],
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Swap Created',
  });
  const blankEntryDetails = {
    unitPrice: '',
    optionMonthId: undefined,
    sellBrokerId: null,
    tradeAccountId: null,
    futuresContractId: null,
    hedgedCommodityId: null,
    comment: '',
    tradeDate: null,
    buyBrokerId: null,
    contracts: '',
  };
  const handleModalClose = async () => {
    onClose();
    await formik.setValues(blankEntryDetails);
    formik.setTouched({}, false);
    formik.setErrors({});
  };
  const formik = useFormik({
    initialValues: blankEntryDetails,
    validationSchema: brokerSwapEntrySchema,
    onSubmit: (values) => {
      const entry = {
        unitPrice: Number(values.unitPrice),
        optionMonthId: Number(values.optionMonthId),
        sellBrokerId: Number(values.sellBrokerId),
        tradeAccountId: Number(values.tradeAccountId),
        futuresContractId: Number(values.futuresContractId),
        hedgedCommodityId: Number(values.hedgedCommodityId),
        comment: values.comment,
        buyBrokerId: Number(values.buyBrokerId),
        contracts: Number(values.contracts),
        tradeDate: (values.tradeDate as unknown as Moment)?.toJSON(),
      };
      return createMutation.mutateAsync({ entry }).then(handleModalClose);
    },
  });
  if (!canRun) {
    return (
      <>
        <main data-testid="BrokerSwapPage">
          <h2>Broker Swap Page</h2>
          <Typography paragraph>You do not have access to this page</Typography>
        </main>
      </>
    );
  }
  const handleClear = async () => {
    formik.resetForm();
    // Trigger touched on all fields
    // const errors = await formik.validateForm();
    // if (Object.keys(errors).length > 0) {
    //   formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
    // }
  };
  const handleSave = () => {
    formik.submitForm();
  };
  return (
    <main style={{ padding: '24px' }} data-testid="BrokerSwapPageMain">
      <TransactionEntryModal
        data-testid="BrokerSwapPage"
        isOpen={isOpen}
        isCreateModal={true}
        onCancel={handleModalClose}
        onClear={handleClear}
        onCreate={handleSave}
        onUpdate={() => {}}
        canSave={true}
      >
        <BrokerSwapEntryDetail
          formik={formik}
          isAutoFocus={isAutoFocus}
          onAutoFocus={() => setIsAutoFocus(false)}
        ></BrokerSwapEntryDetail>
      </TransactionEntryModal>
    </main>
  );
};

export default BrokerSwapPage;
