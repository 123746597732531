import { FC } from 'react';
import { getIn } from 'formik';

import { CircularProgress } from '@mui/material';

import { useFuturesContractRefsQuery } from '../../hooks/useFuturesDataQueries';
import FormikAutocomplete from '../FormikAutocomplete/FormikAutocomplete';

export interface FuturesContractAccountAutocompleteProps {
  formik: any;
  openOnFocus?: boolean;
}

const FuturesContractAccountAutocomplete: FC<FuturesContractAccountAutocompleteProps> = (props) => {
  const allOptions = useFuturesContractRefsQuery().data;
  if (!allOptions) {
    return <CircularProgress />;
  }
  let activeOptions = allOptions.sort((a, b) =>
    (a.futuresContractName ?? '').localeCompare(b.futuresContractName ?? '')
  );

  let optionsById = new Map(
    activeOptions?.map((ta) => [ta.futuresContractId as number, ta.futuresContractName as string])
  );

  const value = getIn(props.formik.values, 'futuresContractId');
  if (value && !optionsById.get(value)) {
    const missingOption = allOptions.find((t) => t.futuresContractId === value);
    if (missingOption) {
      activeOptions = [...activeOptions, missingOption];
      optionsById = new Map(
        activeOptions?.map((ta) => [
          ta.futuresContractId as number,
          ta.futuresContractName as string,
        ])
      );
    }
  }

  return (
    <FormikAutocomplete
      formik={props.formik}
      field="futuresContractId"
      optionsAsMap={optionsById}
      openOnFocus={props.openOnFocus}
    ></FormikAutocomplete>
  );
};

export default FuturesContractAccountAutocomplete;
