import { useQueryClient } from '@tanstack/react-query';

import { backendDataClients } from '../../../../hooks';
import { useToastMutation } from '../../../../hooks/useToastMutations';
import {
  useCreateUserMutation,
  useGetUserQuery,
  useRolesQuery,
} from '../../../../hooks/useUsersQueries';
import { UpdateUserRoleRequest } from '../../../../services/backend/data-contracts';

const { useUser } = backendDataClients;

export default function useUserModal(selectedUserId: number) {
  const queryClient = useQueryClient();
  const userDataClient = useUser();
  const buildInitialNewUser = () => {
    return {
      userName: '',
      userEmail: '',
      isActive: true,
      roles: [],
    };
  };

  const { data: roles } = useRolesQuery();

  const { data: existingUserDetails, isPending: isUserQueryPending } =
    useGetUserQuery(selectedUserId);

  const updateUserMutation = useToastMutation({
    queryKey: ['Users', 'Details', selectedUserId],
    mutationFn: async (data: any) => {
      const roleId: number = data.roleId;
      const doesUserHaveRole: boolean = data.doesUserHaveRole;
      const request = {
        entry: {
          userId: selectedUserId,
        },
      } as UpdateUserRoleRequest;
      if (existingUserDetails) {
        const updatedDetails = { ...existingUserDetails };
        if (doesUserHaveRole) {
          updatedDetails.roles = existingUserDetails.roles?.filter((r: any) => r.roleId !== roleId);
          request.entry.removeRoleId = roleId;
        } else {
          const role = roles?.find((t) => t.roleId === roleId);
          if (role) {
            updatedDetails.roles = [...(existingUserDetails.roles ?? []), role];
          }
          request.entry.addRoleId = roleId;
        }
        await userDataClient.updateUser(selectedUserId?.toString(), request);
      }
    },
    afterSuccess: (data: any) => {
      queryClient.setQueryData(['Users', 'Details', selectedUserId], data);
    },
  });

  const createUserMutation = useCreateUserMutation();

  return {
    details: existingUserDetails ?? buildInitialNewUser(),
    isUserLoading: isUserQueryPending,
    isProcessing: createUserMutation.isPending || updateUserMutation.isPending,
    createUserMutation,
    updateUserMutation,
  };
}
