import React, { FC } from 'react';
import { AxiosResponse } from 'axios';
import { object, string } from 'yup';

import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';

import {
  ChangeableDisplay,
  dateValueFormatter,
  errorCellClassName,
  stringComparatorWithBlanksLast,
  yesNoGridColTypeDef,
} from '../../../components';
import { UseRefDataQueryResult } from '../../../hooks';
import {
  CreateTransactionTypesRequest,
  TransactionTypesResponse,
  UpdateTransactionTypesByIdRequest,
} from '../../../services/backend/data-contracts';
import { RequestParams } from '../../../services/backend/http-client';

export const transactionTypeSchema = object({
  transactionTypeName: string().required().max(35),
  transactionTypeCode: string().required().max(10),
});

const columns: GridColDef[] = [
  {
    field: 'transactionTypeCode',
    headerName: 'Transaction Type Code',
    flex: 1,
    type: 'string',
    editable: true,
    cellClassName: errorCellClassName,
    sortComparator: stringComparatorWithBlanksLast,
  },
  {
    field: 'transactionTypeName',
    headerName: 'Transaction Type Name',
    flex: 1,
    type: 'string',
    editable: true,
    cellClassName: errorCellClassName,
  },
  {
    field: 'affectsPosition',
    headerName: 'Affects Position',
    flex: 1,
    editable: true,
    ...yesNoGridColTypeDef('affectsPosition'),
  },
  {
    field: 'isActive',
    headerName: 'Active',
    flex: 1,
    editable: true,
    ...yesNoGridColTypeDef('isActive'),
  },
  {
    field: 'createdOn',
    headerName: 'Created On',
    flex: 1,
    valueFormatter: dateValueFormatter,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    flex: 1,
  },
  {
    field: 'updatedOn',
    headerName: 'Updated On',
    flex: 1,
    valueFormatter: dateValueFormatter,
  },
  { field: 'updatedBy', headerName: 'Updated By', flex: 1 },
];

interface TransactionTypesDisplayProps {
  userName: string;
  getTransactionTypes: () => UseRefDataQueryResult<TransactionTypesResponse['result']>;
  createTransactionTypes: (
    data: CreateTransactionTypesRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  updateTransactionTypes: (
    data: UpdateTransactionTypesByIdRequest,
    params?: RequestParams
  ) => Promise<AxiosResponse<void, any>>;
  hideUnsavedPrompt?: boolean;
}

const TransactionTypesDisplay: FC<TransactionTypesDisplayProps> = ({
  userName,
  getTransactionTypes,
  createTransactionTypes,
  updateTransactionTypes,
  hideUnsavedPrompt,
}) => {
  const create = (newRows: GridValidRowModel[]) => {
    const now = new Date().toISOString();
    return newRows?.length
      ? createTransactionTypes({
          types: newRows.map((r) => ({
            transactionTypeCode: r.transactionTypeCode,
            updatedBy: userName,
            createdBy: r.createdBy,
            updatedOn: now,
            isActive: r.isActive,
            createdOn: r.createdOn,
            transactionTypeName: r.transactionTypeName,
            affectsPosition: r.affectsPosition,
          })),
        })
      : Promise.resolve();
  };

  const update = (oldRows: GridValidRowModel[]) => {
    const now = new Date().toISOString();
    return oldRows?.length
      ? updateTransactionTypes({
          types: oldRows.map((r) => ({
            transactionTypeCode: r.transactionTypeCode,
            updatedBy: userName,
            updatedOn: now,
            isActive: r.isActive,
            transactionTypeId: r.transactionTypeId,
            transactionTypeName: r.transactionTypeName,
            affectsPosition: r.affectsPosition,
          })),
        })
      : Promise.resolve();
  };

  const add = (fakeId: number | undefined) => {
    const now = new Date().toISOString();
    return {
      transactionTypeId: fakeId,
      transactionTypeCode: '',
      transactionTypeName: '',
      isActive: true,
      affectsPosition: true,
      createdOn: now,
      updatedOn: now,
      createdBy: userName,
      updatedBy: userName,
      positionType: 'P',
    };
  };

  return (
    <ChangeableDisplay
      pageTitle="Transaction Types"
      columns={columns}
      getRowId={(row) => row['transactionTypeId']}
      create={create}
      update={update}
      onAddButtonClick={add}
      fnUseQuery={getTransactionTypes}
      schema={transactionTypeSchema}
      uniqueFields={['transactionTypeCode']}
      sortField="transactionTypeCode"
      gridFilter={(row) => row['positionType'] === 'P'}
      isCellEditable={({ row }) => !row.isSystem}
      infoIndicator={(row) =>
        row.isSystem
          ? 'Transaction Types used by other processes cannot be modified. Contact the IS Department for assistance.'
          : null
      }
      hideUnsavedPrompt={hideUnsavedPrompt}
    />
  );
};

export default TransactionTypesDisplay;
