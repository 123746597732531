/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PositionsOptionMonthByYearSortedResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class PositionsOptionMonths<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name OptionsPositionsOptionMonths
   * @request OPTIONS:/positionsOptionMonths
   */
  optionsPositionsOptionMonths = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/positionsOptionMonths`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsPositionsOptionMonths2
   * @request OPTIONS:/positionsOptionMonths/year
   * @originalName optionsPositionsOptionMonths
   * @duplicate
   */
  optionsPositionsOptionMonths2 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/positionsOptionMonths/year`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name GetPositionsOptionMonthsByYear
   * @request GET:/positionsOptionMonths/year/{year}
   * @secure
   */
  getPositionsOptionMonthsByYear = (year: string, params: RequestParams = {}) =>
    this.request<PositionsOptionMonthByYearSortedResponse, any>({
      path: `/positionsOptionMonths/year/${year}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsPositionsOptionMonths3
   * @request OPTIONS:/positionsOptionMonths/year/{year}
   * @originalName optionsPositionsOptionMonths
   * @duplicate
   */
  optionsPositionsOptionMonths3 = (year: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/positionsOptionMonths/year/${year}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsPositionsOptionMonths4
   * @request OPTIONS:/positionsOptionMonths/{id}
   * @originalName optionsPositionsOptionMonths
   * @duplicate
   */
  optionsPositionsOptionMonths4 = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/positionsOptionMonths/${id}`,
      method: 'OPTIONS',
      ...params,
    });
}
