import React, { PropsWithChildren } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import useSessionTimeout from './useSessionTimeout';

const SessionTimeoutWrapper = ({ children }: PropsWithChildren) => {
  const { showDialog, handleRestart, handleLogout } = useSessionTimeout();
  return (
    <>
      <>{children}</>
      <Dialog
        open={showDialog}
        aria-labelledby="session-timeout-dialog-title"
        aria-describedby="session-timeout-dialog-description"
      >
        <DialogTitle id="session-timeout-dialog-title">{'Session Timeout'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="session-timeout-dialog-description">
            Would you like to stay logged in?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRestart}>Yes</Button>
          <Button onClick={handleLogout}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SessionTimeoutWrapper;
