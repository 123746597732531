/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from './http-client';

export class FuturesReferences<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name OptionsFuturesReferences
   * @request OPTIONS:/futuresReferences/{id}
   */
  optionsFuturesReferences = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futuresReferences/${id}`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFuturesReferences2
   * @request OPTIONS:/futuresReferences
   * @originalName optionsFuturesReferences
   * @duplicate
   */
  optionsFuturesReferences2 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futuresReferences`,
      method: 'OPTIONS',
      ...params,
    });
  /**
   * No description
   *
   * @name ImportFuturesReference
   * @request POST:/futuresReferences/import
   * @secure
   */
  importFuturesReference = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futuresReferences/import`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name OptionsFuturesReferences3
   * @request OPTIONS:/futuresReferences/import
   * @originalName optionsFuturesReferences
   * @duplicate
   */
  optionsFuturesReferences3 = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/futuresReferences/import`,
      method: 'OPTIONS',
      ...params,
    });
}
