import React, { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ReactRouterPrompt from '../ReactRouterPrompt/ReactRouterPrompt';

import UnsavedChangesPromptDialog from './UnsavedChangesPromptDialog';

interface UnsavedChangesPromptProps {
  changedRows: any[];
}

const UnsavedChangesPrompt: FC<UnsavedChangesPromptProps> = ({ changedRows }) => {
  return (
    <ErrorBoundary fallback={<></>}>
      <ReactRouterPrompt when={() => !!changedRows && changedRows.length > 0}>
        {(args) => <UnsavedChangesPromptDialog {...args} />}
      </ReactRouterPrompt>
    </ErrorBoundary>
  );
};

export default UnsavedChangesPrompt;
