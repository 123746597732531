import * as yup from 'yup';

export const BROKER_CODE_REQUIRED = 'Broker Code Required';
export const BROKER_NAME_REQUIRED = 'Broker Name Required';
export const BROKER_ACTIVE_REQUIRED = 'Active Required';
export const GL_COMPANY_CODE_REQUIRED = 'GL Company Code Required';
export const GL_ACCOUNT_REQUIRED = 'GL Account Required';
export const DIVISION_REQUIRED = 'Active Required';
export const BROKER_VALID = 'The referenced Futures Broker is either not set or no longer exists';
export const GL_ACCOUNT_LENGTH_ERROR = 'Must be exactly 7 digits';
export const GL_COMPANY_LENGTH_ERROR = 'Must be exactly 3 digits';
export const STRING_ONLY_DIGITS = 'Must be only digits';

export const getValidationSchema = (brokerCodes?: (string | undefined)[]) => {
  return yup.object().shape({
    brokerCode: yup
      .string()
      .required(BROKER_CODE_REQUIRED)
      .test('unique-broker-code', (brokerCode, { createError, path }) => {
        if (brokerCodes?.includes(brokerCode))
          return createError({
            path,
            message: `"${brokerCode}" already exists. Broker Code must be unique.`,
          });
        return true;
      }),
    brokerName: yup.string().required(BROKER_NAME_REQUIRED),
    isActive: yup.boolean().required(BROKER_ACTIVE_REQUIRED),
    glCompanyCode: yup
      .string()
      .required(GL_COMPANY_CODE_REQUIRED)
      .matches(/^[0-9]+$/, STRING_ONLY_DIGITS)
      .min(3, GL_COMPANY_LENGTH_ERROR)
      .max(3, GL_COMPANY_LENGTH_ERROR),
    glAccount: yup
      .string()
      .required(GL_ACCOUNT_REQUIRED)
      .matches(/^[0-9]+$/, STRING_ONLY_DIGITS)
      .min(7, GL_ACCOUNT_LENGTH_ERROR)
      .max(7, GL_ACCOUNT_LENGTH_ERROR),
    divisionId: yup.number().required(DIVISION_REQUIRED),
    commissionRates: yup.array(
      yup.object({
        brokerId: yup.number().required(),
        futuresContractId: yup.number().required(),
        commissionRateType: yup.string().required(),
        commissionRate: yup.number().required(),
      })
    ),
  });
};
