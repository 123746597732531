import React, { FC } from 'react';

import { Box, Button, Grid } from '@mui/material';
import Container from '@mui/material/Container';

import AllocatedCommissionAdjustmentFilterForm from '../AllocatedCommissionAdjustmentFilterForm/AllocatedCommissionAdjustmentFilterForm';
import AllocatedCommissionAdjustmentGrid from '../AllocatedCommissionAdjustmentGrid/AllocatedCommissionAdjustmentGrid';
import useAllocatedCommissionAdjustment from '../useAllocatedCommissionAdjustment';

const AllocatedCommissionAdjustmentDisplay: FC = () => {
  const props = useAllocatedCommissionAdjustment();
  return (
    <Container maxWidth={false}>
      <AllocatedCommissionAdjustmentFilterForm {...props}></AllocatedCommissionAdjustmentFilterForm>
      <AllocatedCommissionAdjustmentGrid {...props}></AllocatedCommissionAdjustmentGrid>
      <Box>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: 'right', paddingBottom: 30 }}>
              {props.rows && props.rows?.length > 0 ? (
                <Button
                  variant="contained"
                  onClick={props.handleSave}
                  disabled={props.disableButtons}
                >
                  Save
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};
export default AllocatedCommissionAdjustmentDisplay;
