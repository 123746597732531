import { FC, useContext, useEffect, useState } from 'react';

import { Button, Link } from '@mui/material';
import {
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid';

import {
  FuturesUserRole,
  GrainTraderRole,
  GranelerasTraderRole,
  MillingTraderRole,
  SuperUserRole,
} from '../../../../../app/UserWrapper/UserWrapper';
import { ChangeableGrid } from '../../../../../components';
import {
  dateOnlyValueFormatter,
  dateValueFormatter,
  errorCellClassName,
  formatCurrencyValueTo2WithNegativesInParens,
  shortLongToBuySellValueFormatter,
} from '../../../../../components/gridUtils';
import { UserContext } from '../../../../../Context';

export interface FuturesTradesGridProps {
  userName: string;
  listQuery: any;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  onNewButtonClick: () => void;
  onEditButtonClick: (id: string) => void;
  onBrokerSwapButtonClick: () => void;
  onExportButtonClick: (onFinish: () => void) => void;
}

const FuturesTradesGrid: FC<FuturesTradesGridProps> = ({
  // userName,
  listQuery,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  onNewButtonClick,
  onEditButtonClick,
  onBrokerSwapButtonClick,
  onExportButtonClick,
}) => {
  let rows: GridRowsProp[] = [];
  if (listQuery.data?.entries) {
    rows = listQuery.data?.entries.map((t: any) => {
      return {
        ...t,
        id: t.tradeId,
        unitPrice: t.unitPrice?.toFixed(5),
        commissionRateType:
          t.commissionRateType === 'N/A' ? 'Not Applicable' : t.commissionRateType,
      } as unknown as GridRowsProp;
    });
  }
  const rowCount = listQuery.data?.totalCount;
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const userContext = useContext(UserContext);
  const hasEnterFuturesTradesPermission = userContext.haveRole([
    GrainTraderRole,
    GranelerasTraderRole,
    MillingTraderRole,
    FuturesUserRole,
    SuperUserRole,
  ]);

  const columns: GridColDef[] = [
    {
      field: 'tradeId',
      headerName: 'Trade id',
      type: 'string',
      width: 100,
      editable: false,
      cellClassName: errorCellClassName,
      renderCell: ({ value, row }) => {
        return hasEnterFuturesTradesPermission ? (
          <Link
            onClick={() => onEditButtonClick(row.id)}
            sx={{ cursor: 'pointer' }}
            data-testid="LinkToModal"
          >
            {value || '______'}
          </Link>
        ) : (
          <>{value || '______'}</>
        );
      },
    },
    {
      field: 'tradeDate',
      headerName: 'Trade Date',
      type: 'string',
      width: 100,
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: dateOnlyValueFormatter,
    },
    {
      field: 'longShort',
      headerName: 'Buy Sell',
      type: 'string',
      width: 75,
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: shortLongToBuySellValueFormatter,
    },
    {
      field: 'futuresContractName',
      headerName: 'Futures Contract',
      type: 'string',
      width: 160,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'optionMonthName',
      headerName: 'Futures Month',
      type: 'string',
      width: 115,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'contracts',
      headerName: 'Contracts',
      type: 'string',
      width: 90,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'unitPrice',
      headerName: 'Price',
      type: 'string',
      width: 85,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'tradeAccountName',
      headerName: 'Trade Account',
      type: 'string',
      width: 200,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'hedgedCommodityName',
      headerName: 'Hedged Commodity',
      type: 'string',
      width: 200,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'brokerName',
      headerName: 'Broker',
      type: 'string',
      width: 150,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'commission',
      headerName: 'Commission',
      type: 'string',
      width: 105,
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: formatCurrencyValueTo2WithNegativesInParens,
    },
    {
      field: 'commissionRateType',
      headerName: 'Commission Rate Type',
      type: 'string',
      width: 175,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'commissionRate',
      headerName: 'Commission Rate',
      type: 'string',
      width: 140,
      editable: false,
      cellClassName: errorCellClassName,
      valueFormatter: formatCurrencyValueTo2WithNegativesInParens,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      type: 'string',
      width: 200,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'divisionName',
      headerName: 'Division',
      type: 'string',
      width: 100,
      editable: false,
      cellClassName: errorCellClassName,
    },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      type: 'string',
      width: 180,
      editable: false,
      valueFormatter: dateValueFormatter,
      cellClassName: errorCellClassName,
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      type: 'string',
      width: 220,
      editable: false,
      cellClassName: errorCellClassName,
    },
  ];

  return (
    <div data-testid="FuturesTradesGrid">
      <ChangeableGrid
        initialRows={rows}
        isLoading={listQuery.isPending}
        columns={columns}
        onAdd={hasEnterFuturesTradesPermission ? onNewButtonClick : undefined}
        rowCount={rowCountState}
        pageSizeOptions={[25, 50, 100]}
        paginationModel={paginationModel}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        onProcessRowUpdateError={console.error}
        initialState={{
          sorting: {
            sortModel: [{ field: 'tradeId', sort: 'desc' }],
          },
          pagination: { paginationModel: { pageSize: 50 } },
        }}
        onExport={onExportButtonClick}
        extraToolbarComponents={
          <>
            <Button onClick={onBrokerSwapButtonClick}>Broker Swap</Button>
          </>
        }
      />
    </div>
  );
};

export default FuturesTradesGrid;
