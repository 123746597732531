import { useSuspenseQuery } from '@tanstack/react-query';

import { backendDataClients, useFuturesTradeAccountsSummaryQuery } from '../../../../hooks';
import { useToastMutation } from '../../../../hooks/useToastMutations';
import { isTradeAccountWithPositionPeriod } from '../FuturesEntryUtils';

const { useFutureEntries } = backendDataClients;

export default function useFuturesEntryDetails(futuresEntryId?: string) {
  const dataClient = useFutureEntries();
  const tradeAccountSummariesQuery = useFuturesTradeAccountsSummaryQuery();

  const getEntryQuery = useSuspenseQuery({
    queryKey: ['Futures Entries', 'Details', futuresEntryId],
    queryFn: async () => {
      if (!futuresEntryId) {
        return null;
      }
      const response = await dataClient.getFutureEntry(futuresEntryId);
      return response?.data.result;
    },
    select: (entry) => {
      // If Trade Account does not use the position period field, clear the value so we don't display anything
      //  in the disabled field and so we don't leave an old position period after updating the month.
      if (entry?.tradeAccountId && !isTradeAccountWithPositionPeriod(entry?.tradeAccountId)) {
        entry.positionPeriodDimId = undefined;
        entry.positionMonth = undefined;
      }
      return entry;
    },
  });

  //  MUTATIONS
  const createMutation = useToastMutation({
    mutationFn: dataClient.createFutureEntry,
    queryKey: ['FuturesEntries'],
    onMutateMessage: 'Creating...',
    onSuccessMessage: 'Futures Entry Created',
  });
  const updateMutation = useToastMutation({
    mutationFn: ({ id, data }: any) => dataClient.updateFutureEntry(id, data),
    queryKey: ['FuturesEntries'],
    onMutateMessage: 'Updating...',
    onSuccessMessage: 'Futures Entry Updated',
  });
  const deleteMutation = useToastMutation({
    mutationFn: dataClient.deleteFutureEntry,
    queryKey: ['FuturesEntries'],
    onMutateMessage: 'Deleting...',
    onSuccessMessage: 'Futures Entry Deleted',
    isDelete: true,
  });

  return {
    getEntryQuery,
    createMutation,
    updateMutation,
    deleteMutation,
    tradeAccountSummariesQuery,
  };
}
