import { Backdrop, CircularProgress, Paper } from '@mui/material';

const LoadingSpinner = () => {
  return (
    <Backdrop open={true} invisible={true}>
      {/* Numbers here are a multiplier for a base of 8. CircularProgress is taller than it is wide, so only 1 pixel of padding-bottom is needed to make the Paper square*/}
      <Paper sx={{ padding: 1, paddingBottom: 0.125 }}>
        <CircularProgress />
      </Paper>
    </Backdrop>
  );
};

export default LoadingSpinner;
