import React, { Suspense, useState } from 'react';

import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

import FuturesEntriesDisplay from './FuturesEntriesDisplay/FuturesEntriesDisplay';
import FuturesEntryModal from './FuturesEntryDetails/FuturesEntryModal';
import useFuturesEntries from './useFuturesEntries';

export default function FuturesEntriesPage() {
  const {
    userName,
    filterForm,
    rowCountState,
    setFilterForm,
    rows,
    isLoadingRows,
    futuresContracts,
    commodities,
    brokers,
    tradeAccountSummaries,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
  } = useFuturesEntries();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [entryToEdit, setEntryToEdit] = useState<string>('');
  const handleNewButtonClick = () => {
    setEntryToEdit('');
    setIsModalOpen(true);
  };
  const handleEditButtonClick = (id: string) => {
    setEntryToEdit(id);
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setEntryToEdit('');
    setIsModalOpen(false);
  };

  return (
    <>
      <main data-testid="FuturesEntriesPage" style={{ minWidth: 1400, padding: '24px' }}>
        <FuturesEntriesDisplay
          rows={rows}
          loading={isLoadingRows}
          rowCountState={rowCountState}
          futuresContracts={futuresContracts}
          commodities={commodities}
          brokers={brokers}
          tradeAccountSummaries={tradeAccountSummaries}
          filterForm={filterForm}
          setFilterForm={setFilterForm}
          paginationModel={paginationModel}
          setPaginationModel={onPaginationModelChange}
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          onNewButtonClick={handleNewButtonClick}
          onEditButtonClick={handleEditButtonClick}
        ></FuturesEntriesDisplay>
        {isModalOpen && (
          <Suspense fallback={<LoadingSpinner />}>
            <FuturesEntryModal
              selectedEntryId={entryToEdit}
              onClose={handleModalClose}
              userName={userName}
            ></FuturesEntryModal>
          </Suspense>
        )}
      </main>
    </>
  );
}
