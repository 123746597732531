import React, { FC, useContext } from 'react';

import { Badge } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {
  HedgeDeskRole,
  PositionAdministratorRole,
  ViewOnlyRole,
} from '../../../../app/UserWrapper/UserWrapper';
import { UserContext } from '../../../../Context';
import FuturesEntriesFilterForm, {
  FuturesEntriesFilterFormProps,
} from '../FuturesEntriesFilterForm/FuturesEntriesFilterForm';
import FuturesEntriesGrid, {
  FuturesEntriesGridProps,
} from '../FuturesEntriesGrid/FuturesEntriesGrid';

type FuturesEntriesDisplayProps = FuturesEntriesFilterFormProps & FuturesEntriesGridProps;

const FuturesEntriesDisplay: FC<FuturesEntriesDisplayProps> = ({
  rows,
  loading,
  rowCountState,
  futuresContracts,
  commodities,
  brokers,
  tradeAccountSummaries,
  filterForm,
  setFilterForm,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  onFilterModelChange,
  onNewButtonClick,
  onEditButtonClick,
}) => {
  const userContext = useContext(UserContext);
  if (!userContext?.userName) {
    return (
      <Container maxWidth={false}>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Futures Entries</Badge>
        </Typography>
      </Container>
    );
  }
  if (!userContext?.haveRole([HedgeDeskRole, ViewOnlyRole, PositionAdministratorRole])) {
    return (
      <Container maxWidth={false}>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Futures Entries</Badge>
        </Typography>
        <Typography paragraph>You do not have access to this page</Typography>
      </Container>
    );
  }
  return (
    <Container maxWidth={false}>
      <Typography variant="h2" mb="0.5em" role="heading">
        <Badge color="secondary">Futures Entries</Badge>
      </Typography>
      <FuturesEntriesFilterForm
        futuresContracts={futuresContracts}
        commodities={commodities}
        brokers={brokers}
        tradeAccountSummaries={tradeAccountSummaries}
        filterForm={filterForm}
        setFilterForm={setFilterForm}
      ></FuturesEntriesFilterForm>
      <Typography paragraph />
      <FuturesEntriesGrid
        rows={rows}
        loading={loading}
        rowCountState={rowCountState}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        onNewButtonClick={onNewButtonClick}
        onEditButtonClick={onEditButtonClick}
      ></FuturesEntriesGrid>
    </Container>
  );
};

export default FuturesEntriesDisplay;
