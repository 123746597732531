import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { getIn, useFormik } from 'formik';

import { Button, CircularProgress, TextField } from '@mui/material';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  createFilterOptions,
  default as Autocomplete,
} from '@mui/material/Autocomplete';

interface FormikAutocompleteProps
  extends Omit<
    AutocompleteProps<string | number, false, true, false, 'div'>,
    | 'fullWidth'
    | 'autoComplete'
    | 'autoHighlight'
    | 'autoSelect'
    | 'clearOnEscape'
    | 'options'
    | 'renderInput'
    | 'fullWidth'
    | 'disableClearable'
    | 'id'
    | 'size'
    | 'getOptionLabel'
    | 'value'
    | 'onBlur'
    | 'filterOptions'
    | 'onChange'
    | 'loadingText'
  > {
  formik: ReturnType<typeof useFormik<any>>;
  label?: string;
  field: string;
  required?: boolean;
  optionsAsMap?: Map<number | string, string>;
  initialOption?: [number | string, string];
  queryError?: boolean;
  onManualRetry?: () => void;
  onValueChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: number | string,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<number | string> | undefined
  ) => void;
  inputRef?: React.Ref<any>;
}

const FormikAutocomplete: FC<FormikAutocompleteProps> = (props) => {
  const {
    formik,
    field,
    onValueChange,
    inputRef,
    label,
    queryError,
    onManualRetry,
    optionsAsMap,
    initialOption,
    loading,
    ...muiAutocompleteProps
  } = props;
  const [highlightedOption, setHighlightedOption] = useState<number | string | null>(null);
  const [manualRetry, setManualRetry] = useState(false);
  useEffect(() => {
    if (!loading) {
      setManualRetry(false);
    }
  }, [loading]);

  if (loading && manualRetry) {
    return <CircularProgress />;
  }
  if (queryError) {
    return (
      <Button
        onClick={() => {
          if (onManualRetry) {
            setManualRetry(true);
            onManualRetry();
          }
        }}
      >
        {'Error.  Retry?'}
      </Button>
    );
  }

  let options = Array.from(optionsAsMap?.keys() ?? []);
  const getOptionLabel = (option: number | string) => {
    if (option === null || option === '' || option === undefined) {
      return '';
    }
    let value = optionsAsMap?.get(option);
    if (!value && !Number.isNaN(Number(option))) {
      value = optionsAsMap?.get(+option);
    }
    if (!value && initialOption && initialOption[0] === option) {
      value = '(Invalid Option) ' + initialOption[1];
    }
    return value ?? '';
  };

  const handleTab = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (highlightedOption && getIn(formik.values, field) !== highlightedOption) {
      formik.setFieldValue(field, highlightedOption);
      onValueChange && onValueChange(event, highlightedOption, 'blur');
      setHighlightedOption(null);
    }
  };
  if (options.length > 0) {
    options = ['', ...options];
  }
  return (
    <Autocomplete
      fullWidth
      autoComplete
      autoHighlight
      clearOnEscape
      openOnFocus={props.openOnFocus ?? true}
      filterOptions={createFilterOptions({ matchFrom: 'start' })}
      id={`${field}-select`}
      size="small"
      value={getIn(formik.values, field) ?? null}
      options={options}
      getOptionLabel={getOptionLabel}
      onHighlightChange={(event, option, reason) => {
        if (reason !== 'mouse' && !!option) {
          setHighlightedOption(option);
        }
      }}
      onChange={(event, value, reason, details) => {
        setHighlightedOption(null);
        formik.setFieldValue(field, value);
        onValueChange && onValueChange(event, value, reason, details);
      }}
      onKeyDown={(event) => {
        if (event.code === 'Tab') {
          handleTab(event);
        } else if (event.code === 'Escape') {
          setHighlightedOption(null);
        }
      }}
      onBlur={formik.handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={field}
          required={props.required}
          error={!!getIn(formik.touched, field) && !!getIn(formik.errors, field)}
          helperText={getIn(formik.touched, field) && getIn(formik.errors, field)}
          aria-labelledby={!label ? `${field}-select-label` : undefined}
          {...(inputRef && { inputRef })}
          variant={muiAutocompleteProps.disabled ? 'filled' : 'outlined'}
        />
      )}
      loading={loading}
      loadingText={'Loading...'}
      {...muiAutocompleteProps}
      data-testid="FormikAutocomplete"
    ></Autocomplete>
  );
};

export default FormikAutocomplete;
