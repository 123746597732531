import React, { FC } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface UnsavedChangesPromptDialogProps {
  isActive: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const UnsavedChangesPromptDialog: FC<UnsavedChangesPromptDialogProps> = ({
  isActive,
  onConfirm,
  onCancel,
}) => (
  <Dialog
    open={isActive}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{'Unsaved Changes'}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        There are unsaved changes, do you wish to discard them?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} variant="contained" startIcon={<ArrowBackIcon />}>
        Keep editing
      </Button>
      <Button
        onClick={onConfirm}
        variant="contained"
        color="warning"
        startIcon={<DeleteForeverIcon />}
      >
        Discard changes
      </Button>
    </DialogActions>
  </Dialog>
);

export default UnsavedChangesPromptDialog;
