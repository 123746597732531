import React, { FC } from 'react';

import Typography from '@mui/material/Typography';

interface AboutTextProps {
  version: string;
  data: string;
}

const AboutText: FC<AboutTextProps> = ({ version, data }) => (
  <div className="AboutText" data-testid="AboutText">
    <Typography paragraph>
      We began providing transportation and material handling services in 1946, when Kenneth Savage
      and his father C.A. Savage purchased a single truck and started hauling coal, cinder block,
      and timber for Customers in Utah and surrounding states. Neal and Luke Savage joined the
      family business and together the Savage brothers grew the company into a leading global
      provider of supply chain and logistics solutions—with a commitment to doing the right thing,
      finding a better way, and making a difference.
    </Typography>
    <Typography paragraph>
      After nearly 75 years, we continue to safely move and manage materials that matter via trains,
      trucks, and boats; and we design, build, own, operate, and maintain industrial facilities,
      terminals, and equipment around the world. Guided by the principles established by our
      founders, our teams deliver solutions and results that add value for Customers and Partners
      across the Agriculture, Energy & Chemical, and Environmental sectors, enabling their essential
      work to Feed the World, Power Our Lives, and Sustain the Planet.
    </Typography>
    <Typography paragraph variant="body2" role="note" aria-label="version">
      <strong>Version:</strong> {version ?? ''}
    </Typography>
    <Typography paragraph variant="body2" role="note" aria-label="data">
      {data}
    </Typography>
  </div>
);

export default AboutText;
