import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { NumericFormatProps } from 'react-number-format/types/types';

import { InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material';

interface DecimalTextFieldProps
  extends Omit<NumericFormatProps<TextFieldProps>, 'InputProps' | 'type'> {
  inputProps?: InputBaseComponentProps;
  id?: string;
  name?: string;
  error?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
}

const DecimalTextField: FC<DecimalTextFieldProps> = ({ inputProps, value, ...props }) => {
  const [newValue, setNewValue] = useState({ value: value, scale: props?.decimalScale as number });
  useEffect(() => {
    const parsedValue = parseFloat(value?.toString() ?? '');
    if (!isNaN(parsedValue) && 0 !== parsedValue) {
      setNewValue({ value: parsedValue.toFixed(newValue.scale), scale: newValue.scale });
    } else {
      setNewValue({ value: value, scale: newValue.scale });
    }
  }, [value, newValue.scale]);
  return (
    <NumericFormat
      fullWidth={true}
      customInput={TextField}
      onFocus={(event) => event.target.select()}
      InputProps={{
        size: 'small',
        inputProps: {
          onBlur: (event: FocusEvent): void => {
            const target = event.target as HTMLInputElement;
            const parsedValue = parseFloat(target.value);
            if (!isNaN(parsedValue)) {
              setNewValue({ value: parsedValue.toFixed(newValue.scale), scale: newValue.scale });
            }
          },
          ...inputProps,
        },
        'aria-labelledby': props?.id + '-label',
      }}
      value={newValue.value}
      {...props}
    ></NumericFormat>
  );
};

export default DecimalTextField;
