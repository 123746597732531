import React, { FC, PropsWithChildren } from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

interface LabeledBox extends PropsWithChildren {
  label: string;
}

const LabeledBox: FC<LabeledBox> = ({ label, children }) => {
  const theme = useTheme();
  const grey = theme.palette.grey[500];

  return (
    <Box
      component="fieldset"
      sx={{
        border: `1px solid ${grey}`,
        borderRadius: theme.shape.borderRadius,
        width: '100%',
      }}
      data-testid="LabeledBox"
    >
      <legend style={{ color: grey }}>{label}</legend>
      {children}
    </Box>
  );
};

export default LabeledBox;
