import React, { FC } from 'react';
import { getIn, useFormik } from 'formik';

import { TextField } from '@mui/material';
import {
  AutocompleteProps,
  createFilterOptions,
  default as Autocomplete,
} from '@mui/material/Autocomplete';

interface FormikAutocompleteProps
  extends Omit<
    AutocompleteProps<string | number, false, true, false, 'div'>,
    | 'fullWidth'
    | 'autoComplete'
    | 'autoHighlight'
    | 'autoSelect'
    | 'clearOnEscape'
    | 'options'
    | 'renderInput'
    | 'fullWidth'
    | 'disableClearable'
    | 'id'
    | 'size'
    | 'getOptionLabel'
    | 'value'
    | 'onBlur'
    | 'filterOptions'
    | 'onChange'
  > {
  formik: ReturnType<typeof useFormik<any>>;
  label?: string;
  field: string;
  required?: boolean;
  options?: (string | undefined)[];
  inputRef?: React.Ref<any>;
}

const FormikTextAutocomplete: FC<FormikAutocompleteProps> = (props) => {
  const { formik, field, options, inputRef, label, ...muiAutocompleteProps } = props;
  const value = getIn(formik.values, field) ?? null;
  const optionsWithNewValue = (
    value && !options?.includes(value) ? [...(options || []), value] : options || []
  ).filter((value, index, array) => array.indexOf(value) === index);

  const onTextChange = async (event: any) =>
    await formik.setFieldValue(field, event.target.value, true);

  return (
    <Autocomplete
      fullWidth
      autoComplete
      autoHighlight
      clearOnEscape
      openOnFocus={props.openOnFocus ?? true}
      filterOptions={createFilterOptions({ matchFrom: 'start' })}
      id={`${field}-select`}
      size="small"
      value={getIn(formik.values, field) ?? null}
      options={['', ...optionsWithNewValue] || []}
      onChange={async (e, value) => {
        await formik.setFieldValue(field, value, true);
      }}
      onBlur={formik.handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={field}
          required={props.required}
          onChange={onTextChange}
          error={!!getIn(formik.touched, field) && !!getIn(formik.errors, field)}
          helperText={getIn(formik.touched, field) && getIn(formik.errors, field)}
          aria-labelledby={!label ? `${field}-select-label` : undefined}
          {...(inputRef && { inputRef })}
        />
      )}
      {...muiAutocompleteProps}
      data-testid="FormikTextAutocomplete"
    ></Autocomplete>
  );
};

export default FormikTextAutocomplete;
