import React, { Suspense, useState } from 'react';
import xlsx from 'json-as-xlsx';
import { useToast } from 'use-toast-mui';

import {
  dateStringFormatter,
  excelFormatString_Currency_5Decimals,
  zeroIfBlank,
} from '../../../components/gridUtils';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

import BrokerMarginAdjustmentEntry from './BrokerMarginAdjustmentEntry/BrokerMarginAdjustmentEntry';
import CashAdjustmentsDisplay from './CashAdjustmentsDisplay/CashAdjustmentsDisplay';
import useCashAdjustments from './useCashAdjustments';

export default function CashAdjustmentsPage() {
  const {
    filterForm,
    rowCountState,
    setFilterForm,
    rows,
    getAllFilteredCashAdjustments,
    isLoadingRows,
    brokers,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
  } = useCashAdjustments();
  const toast = useToast();
  const [isOpen, setIsOpen] = useState<boolean>();

  const handleAddButtonClick = () => {
    setIsOpen(true);
  };

  const handleExportButtonClick = async (onFinish: () => void) => {
    try {
      const cashAdjustmentData = (await getAllFilteredCashAdjustments()).data?.result?.entries;
      if (cashAdjustmentData) {
        const data = [
          {
            sheet: 'Broker Margin Adjustments',
            columns: [
              { label: 'ID', value: 'adjustmentId' },
              { label: 'Date', value: (r: any) => dateStringFormatter(r?.adjustmentDate) },
              { label: 'Broker', value: 'brokerName' },
              { label: 'Type', value: 'adjustmentName' },
              {
                label: 'Amount',
                value: (r: any) => zeroIfBlank(r, 'adjustmentAmount'),
                format: excelFormatString_Currency_5Decimals,
              },
              { label: 'Trade Account', value: 'tradeAccountName' },
              { label: 'Hedged Commodity', value: 'hedgedCommodityName' },
              { label: 'Comment', value: 'comment' },
              {
                label: 'Created On',
                value: (r: any) => dateStringFormatter(r?.entryDateTime),
              },
              { label: 'Created By', value: 'entryUser' },
            ],
            content: cashAdjustmentData,
          },
        ];
        const settings = {
          fileName: 'Broker Margin Adjustments',
        };
        xlsx(data, settings);
      } else {
        toast.error('Export failed.');
      }
    } finally {
      onFinish();
    }
  };

  return (
    <>
      <main data-testid="CashAdjustmentsPage" style={{ minWidth: 1400, padding: '24px' }}>
        <CashAdjustmentsDisplay
          rows={rows}
          loading={isLoadingRows}
          rowCountState={rowCountState}
          brokers={brokers}
          filterForm={filterForm}
          setFilterForm={setFilterForm}
          paginationModel={paginationModel}
          setPaginationModel={onPaginationModelChange}
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          onExportButtonClick={handleExportButtonClick}
          onAddButtonClick={handleAddButtonClick}
        ></CashAdjustmentsDisplay>
        {isOpen && (
          <Suspense fallback={<LoadingSpinner />}>
            <BrokerMarginAdjustmentEntry isOpen={isOpen} onClose={() => setIsOpen(false)} />
          </Suspense>
        )}
      </main>
    </>
  );
}
