import React, { PropsWithChildren } from 'react';

import { OktaAuth, OktaAuthOptions, SignoutOptions, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import { LogoutContext } from '../../Context';

const oktaAuthOpts: OktaAuthOptions = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  issuer: process.env.REACT_APP_ISSUER,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  pkce: true,
  tokenManager: {
    autoRenew: true,
  },
};
const oktaAuth = new OktaAuth(oktaAuthOpts);

const signOutOpts: SignoutOptions = {
  postLogoutRedirectUri: window.location.origin + '/logout',
};
const logout = async () => await oktaAuth.signOut(signOutOpts);

const SecurityWrapper = ({ children }: PropsWithChildren) => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <LogoutContext.Provider value={{ logout }}>{children}</LogoutContext.Provider>
    </Security>
  );
};
export default SecurityWrapper;
