import { ChangeEvent, FC, useState } from 'react';
import { FormikTouched, FormikValues, setNestedObjectValues, useFormik } from 'formik';
import * as yup from 'yup';

import {
  Box,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import DecimalTextField from '../../../../components/DecimalTextField/DecimalTextField';
import { nanToUndefined } from '../../../../components/formUtils';
import FuturesExchangeAutocomplete from '../../../../components/FuturesExchangeAutocomplete/FuturesExchangeAutocomplete';
import FuturesUnitOfMeasureAutocomplete from '../../../../components/FuturesUnitOfMeasureAutocomplete/FuturesUnitOfMeasureAutocomplete';
import IntegerTextField from '../../../../components/IntegerTextField/IntegerTextField';
import TransactionEntryModal from '../../../../components/TransactionEntryModal/TransactionEntryModal';
import { backendDataClients } from '../../../../hooks';
import { useToastMutation } from '../../../../hooks/useToastMutations';
import { ExchangeRefResponse } from '../../../../services/backend/data-contracts';
import {
  CLOSE_PRICE_CONVERSION_FACTOR_POSITIVE,
  CLOSE_PRICE_CONVERSION_FACTOR_REQUIRED,
  CONTRACT_MONTH_REQUIRED,
  CONTRACT_UNITS_POSITIVE,
  CONTRACT_UNITS_REQUIRED,
  EXCHANGE_REQUIRED,
  FUTURES_CONTRACT_CODE_REQUIRED,
  FUTURES_CONTRACT_NAME_REQUIRED,
  IS_ACTIVE_REQUIRED,
  MAX_CONTRACT_UNITS,
  UOM_REQUIRED,
} from '../FuturesContractErrorMessages';

const { useFuturesContracts } = backendDataClients;

/**
 * @param stringMonths comma delimited strings
 **/
export const formatMonthsForSave = (stringMonths: string) => {
  const inputArray: string[] = stringMonths.split(',');
  return inputArray.map((monthChar: string) => ({ monthChar }));
};

export interface FuturesContractDetailPageProps {
  exchangeSummaries?: ExchangeRefResponse['result'];
  isOpen: boolean;
  isEditingExistingEntry: boolean;
  onClose: () => void;
  existingContractDetails: any;
}

const FuturesContractDetailsPage: FC<FuturesContractDetailPageProps> = ({
  isOpen,
  isEditingExistingEntry,
  onClose,
  existingContractDetails,
}) => {
  const [contractMonthsInputErrorText, setContractMonthsInputErrorText] = useState<string>('');
  interface CheckboxItem {
    key: string;
    label: string;
    checked: boolean;
  }
  const futuresContractsDataClient = useFuturesContracts();

  const updateMutation = useToastMutation({
    mutationFn: ({ id, data }: any) => {
      return futuresContractsDataClient.updateFuturesContractById(id, data);
    },
    queryKey: ['FuturesContracts'],
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Futures Contract Updated',
  });

  const createMutation = useToastMutation({
    mutationFn: ({ data }: any) => {
      return futuresContractsDataClient.createFuturesContract(data);
    },
    queryKey: ['FuturesContracts'],
    onMutateMessage: 'Saving...',
    onSuccessMessage: 'Futures Contract Created',
  });

  const blankEntryDetails = {
    futuresContractId: 0,
    futuresContractCode: undefined,
    futuresContractName: undefined,
    exchangeId: undefined,
    contractUnits: 0,
    uomId: undefined,
    contractMonths: [''],
    closePriceConversionFactor: 0,
    pricingUnitDescription: undefined,
    isActive: true,
  };

  let initialValues = blankEntryDetails;
  let splitMonths = [''];
  if (existingContractDetails) {
    splitMonths = existingContractDetails.contractMonths.split(',');
    initialValues = {
      futuresContractId: existingContractDetails.futuresContractId,
      futuresContractCode: existingContractDetails.futuresContractCode,
      futuresContractName: existingContractDetails.futuresContractName,
      exchangeId: existingContractDetails.exchangeId,
      contractUnits: existingContractDetails.contractUnits,
      uomId: existingContractDetails.uomId,
      contractMonths: splitMonths,
      closePriceConversionFactor: existingContractDetails.closePriceConversionFactor,
      pricingUnitDescription: existingContractDetails.pricingUnitDescription,
      isActive: existingContractDetails.isActive,
    };
  } else {
    initialValues.contractMonths = [];
  }
  const initialCheckboxes: CheckboxItem[] = [
    { key: 'F', label: 'Jan / F', checked: splitMonths.includes('F') },
    { key: 'G', label: 'Feb / G', checked: splitMonths.includes('G') },
    { key: 'H', label: 'Mar / H', checked: splitMonths.includes('H') },
    { key: 'J', label: 'Apr / J', checked: splitMonths.includes('J') },
    { key: 'K', label: 'May / K', checked: splitMonths.includes('K') },
    { key: 'M', label: 'Jun / M', checked: splitMonths.includes('M') },
    { key: 'N', label: 'Jul / N', checked: splitMonths.includes('N') },
    { key: 'Q', label: 'Aug / Q', checked: splitMonths.includes('Q') },
    { key: 'U', label: 'Sep / U', checked: splitMonths.includes('U') },
    { key: 'V', label: 'Oct / V', checked: splitMonths.includes('V') },
    { key: 'X', label: 'Nov / X', checked: splitMonths.includes('X') },
    { key: 'Z', label: 'Dec / Z', checked: splitMonths.includes('Z') },
  ];
  const [checkboxes, setCheckboxes] = useState<CheckboxItem[]>(initialCheckboxes);

  const handleModalClose = async () => {
    onClose();
    await formik.setValues(blankEntryDetails);
    formik.setTouched({}, false);
    formik.setErrors({});
  };

  const handleClearClick = async () => {
    await formik.setValues(blankEntryDetails);
    formik.values.contractMonths = [];
    formik.setFieldValue('contractMonths', formik.values.contractMonths, true);
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) => ({ ...checkbox, checked: false }))
    );
    setContractMonthsInputErrorText(CONTRACT_MONTH_REQUIRED);
    formik.resetForm();
    // Trigger touched on all fields
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      // Form is valid, do any success call
    } else {
      formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
    }
  };
  const handleSaveClick = async () => {
    const hasMonths = await formik.values.contractMonths.length;
    if (!hasMonths) {
      setContractMonthsInputErrorText(CONTRACT_MONTH_REQUIRED);
    } else {
      setContractMonthsInputErrorText('');
      formik.submitForm();
    }
  };
  const handleIsActiveChange = (event: SelectChangeEvent) => {
    let value;
    if (event.target.value === 'true') value = true;
    else if (event.target.value === 'false') value = false;
    formik.setFieldValue('isActive', value, true);
  };

  const handleCheckboxChange = (key: string) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.key === key ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
  };

  const handleMonthCheck = async (change: ChangeEvent<HTMLInputElement>) => {
    handleCheckboxChange(change.target.value);
    const monthId = change.target.value;
    const alreadySelectedMonth = formik.values.contractMonths.find((t: string) => t === monthId);

    if (alreadySelectedMonth && formik.values.contractMonths.length === 1) {
      setContractMonthsInputErrorText(CONTRACT_MONTH_REQUIRED);
    } else {
      setContractMonthsInputErrorText('');
      if (alreadySelectedMonth) {
        const index = formik.values.contractMonths.indexOf(monthId, 0);
        if (index > -1) {
          formik.values.contractMonths.splice(index, 1);
        }
      } else {
        formik.values.contractMonths.push(monthId);
      }
    }
    formik.values.contractMonths?.sort((a, b) => a.localeCompare(b));
    formik.setFieldValue('contractMonths', formik.values.contractMonths, true);
  };

  const doSave = (currentDetails: any) => {
    const entry = {
      futuresContractId: currentDetails.futuresContractId,
      futuresContractCode: currentDetails.futuresContractCode,
      futuresContractName: currentDetails.futuresContractName,
      exchangeId: Number(currentDetails.exchangeId),
      contractUnits: Number(currentDetails.contractUnits),
      uomId: Number(currentDetails.uomId),
      contractMonths: formatMonthsForSave(currentDetails.contractMonths.toString()),
      closePriceConversionFactor: Number(currentDetails.closePriceConversionFactor),
      pricingUnitDescription: currentDetails.pricingUnitDescription,
      isActive: currentDetails.isActive,
    };

    if (existingContractDetails) {
      updateMutation.mutate(
        {
          id: existingContractDetails.futuresContractId,
          data: { entry },
        },
        { onSuccess: () => handleModalClose() }
      );
    } else {
      createMutation.mutate(
        {
          data: { entry },
        },
        {
          onSuccess: () => {
            formik.setValues(blankEntryDetails, false);
            formik.setTouched({});
          },
        }
      );
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      futuresContractCode: yup.string().required(FUTURES_CONTRACT_CODE_REQUIRED),
      futuresContractName: yup.string().required(FUTURES_CONTRACT_NAME_REQUIRED),
      exchangeId: yup.number().transform(nanToUndefined).required(EXCHANGE_REQUIRED),
      uomId: yup.number().transform(nanToUndefined).required(UOM_REQUIRED),
      contractUnits: yup
        .number()
        .transform(nanToUndefined)
        .required(CONTRACT_UNITS_REQUIRED)
        .max(MAX_CONTRACT_UNITS)
        .positive(CONTRACT_UNITS_POSITIVE),
      contractMonths: yup.array().required(CONTRACT_MONTH_REQUIRED),
      closePriceConversionFactor: yup
        .number()
        .transform(nanToUndefined)
        .required(CLOSE_PRICE_CONVERSION_FACTOR_REQUIRED)
        .positive(CLOSE_PRICE_CONVERSION_FACTOR_POSITIVE)
        .max(99999.99999),
      pricingUnitDescription: yup.string(),
      isActive: yup.boolean().required(IS_ACTIVE_REQUIRED),
    }),
    onSubmit: () => {
      doSave({ ...formik.values });
    },
  });

  // const changeAndValidate = (name: string) => {
  //   return async (e: any, value: any) => {
  //     await formik.setFieldValue(name, value, true);
  //     formik.setFieldTouched(name);
  //   };
  // };

  const labelWidth = 5;
  const inputWidth = 7;
  return (
    <TransactionEntryModal
      isOpen={isOpen}
      canSave={!updateMutation.isPending && !createMutation.isPending}
      isCreateModal={!isEditingExistingEntry}
      onCancel={handleModalClose}
      onClear={handleClearClick}
      onCreate={handleSaveClick}
      onUpdate={handleSaveClick}
    >
      <Box data-testid="FuturesContractsDetailsPage">
        {!formik.values ? null : (
          <form autoComplete="off">
            <Grid container spacing={3}>
              <Grid container item spacing={1} xs={5}>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid
                    item
                    xs={labelWidth}
                    sx={{ fontWeight: 'bold' }}
                    id="contractscode-input-label"
                  >
                    Contract Code:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      type="text"
                      fullWidth
                      id="contractcode-input"
                      size="small"
                      name="futuresContractCode"
                      value={formik.values.futuresContractCode ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 6 }}
                      error={
                        formik.touched.futuresContractCode &&
                        Boolean(formik.errors.futuresContractCode)
                      }
                      helperText={
                        formik.touched.futuresContractCode &&
                        formik.errors.futuresContractCode?.toString()
                      }
                      aria-labelledby="contractscode-input-label"
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    Contract Name:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      type="text"
                      fullWidth
                      id="contractname-input"
                      size="small"
                      name="futuresContractName"
                      value={formik.values.futuresContractName ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 35 }}
                      error={
                        formik.touched.futuresContractName &&
                        Boolean(formik.errors.futuresContractName)
                      }
                      helperText={
                        formik.touched.futuresContractName &&
                        formik.errors.futuresContractName?.toString()
                      }
                      InputProps={{ 'aria-labelledby': 'contractsname-input-label' }}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    Exchange:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <FuturesExchangeAutocomplete
                      formik={formik}
                      openOnFocus
                    ></FuturesExchangeAutocomplete>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid
                    item
                    xs={labelWidth}
                    sx={{ fontWeight: 'bold' }}
                    id="contractunits-input-label"
                  >
                    Units per Contract:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <IntegerTextField
                      fullWidth
                      onFocus={(event) => event.target.select()}
                      id="contractunits-input"
                      name="contractUnits"
                      allowNegative={false}
                      value={formik.values.contractUnits}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 15 }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.contractUnits && Boolean(formik.errors.contractUnits)}
                      helperText={
                        formik.touched.contractUnits && formik.errors.contractUnits?.toString()
                      }
                    ></IntegerTextField>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }}>
                    Unit of Measure:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <FuturesUnitOfMeasureAutocomplete
                      formik={formik}
                      openOnFocus
                    ></FuturesUnitOfMeasureAutocomplete>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid item xs={labelWidth}>
                    Priced As:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <TextField
                      fullWidth
                      id="pricingunitdescription-input"
                      size="small"
                      name="pricingUnitDescription"
                      value={formik.values.pricingUnitDescription ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 50 }}
                      error={
                        formik.touched.pricingUnitDescription &&
                        Boolean(formik.errors.pricingUnitDescription)
                      }
                      InputProps={{ 'aria-labelledby': 'pricingUnitDescription-input-label' }}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" spacing={1}>
                  <Grid
                    item
                    xs={labelWidth}
                    sx={{ fontWeight: 'bold' }}
                    id="closepriceconversionfactor-input-label"
                  >
                    Close Price Conversion Factor:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <DecimalTextField
                      fullWidth
                      allowNegative={false}
                      id="closepriceconversionfactor-input"
                      name="closePriceConversionFactor"
                      inputProps={{
                        step: '0.00001',
                        maxLength: 12,
                      }}
                      decimalScale={5}
                      value={formik.values.closePriceConversionFactor}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.closePriceConversionFactor &&
                        Boolean(formik.errors.closePriceConversionFactor)
                      }
                      helperText={
                        formik.touched.closePriceConversionFactor &&
                        formik.errors.closePriceConversionFactor?.toString()
                      }
                    ></DecimalTextField>
                  </Grid>
                </Grid>
                <Grid container item alignItems="Center" xs={12}>
                  <Grid item xs={labelWidth} sx={{ fontWeight: 'bold' }} id="is-active-label">
                    Active:
                  </Grid>
                  <Grid item xs={inputWidth}>
                    <Select
                      value={formik.values.isActive + ''}
                      size="small"
                      onChange={handleIsActiveChange}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                    {formik.errors.isActive && formik.touched.isActive && (
                      <div id="feedback">{formik.errors.isActive?.toString()}</div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item spacing={1} xs={1}></Grid>
              <Grid container item paddingTop={3} spacing={2} xs={6}>
                <FormControl
                  required
                  error={!!contractMonthsInputErrorText}
                  component="fieldset"
                  sx={{ m: 3 }}
                  variant="standard"
                >
                  <Grid
                    item
                    alignItems="center"
                    xs={12}
                    sx={{ height: '2px;', textAlign: 'center', fontWeight: 'bold' }}
                  >
                    Months Traded:
                  </Grid>
                  <FormGroup>
                    <Grid item container spacing={2} alignItems="Center" xs={9}>
                      {checkboxes.map((month) => {
                        return (
                          <Grid
                            item
                            container
                            spacing={1}
                            alignItems="Center"
                            xs={6}
                            key={'month-cb-container' + month.key}
                          >
                            <Grid
                              item
                              xs={7}
                              sx={{ fontWeight: 'bold' }}
                              key={'month-cb-label' + month.key}
                            >
                              <label id={'month-' + month.key + '-label'}>{month.label}:</label>
                            </Grid>
                            <Grid item xs={2} key={'month-cb-' + month.key}>
                              <Checkbox
                                id={'month-' + month.key}
                                key={month.key}
                                value={month.key}
                                checked={month.checked}
                                onChange={handleMonthCheck}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </FormGroup>
                  {contractMonthsInputErrorText ? (
                    <FormHelperText>{contractMonthsInputErrorText}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
    </TransactionEntryModal>
  );
};

export default FuturesContractDetailsPage;
