import React, { FC, useContext } from 'react';

import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {
  HedgeDeskRole,
  PositionAdministratorRole,
  ViewOnlyRole,
} from '../../../../app/UserWrapper/UserWrapper';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { UserContext } from '../../../../Context';
import SwapEntriesFilterForm, {
  SwapEntriesFilterFormProps,
} from '../SwapEntriesFilterForm/SwapEntriesFilterForm';
import SwapEntriesGrid, { SwapEntriesGridProps } from '../SwapEntriesGrid/SwapEntriesGrid';
import SwapEntryDetailPage, {
  SwapEntryDetailPageProps,
} from '../SwapEntryDetailPage/SwapEntryDetailPage';

interface SwapEntriesDisplayProps
  extends SwapEntriesGridProps,
    SwapEntriesFilterFormProps,
    SwapEntryDetailPageProps {
  isLoading: boolean;
}

const SwapEntriesDisplay: FC<SwapEntriesDisplayProps> = (props) => {
  const userContext = useContext(UserContext);
  if (!userContext?.userName) {
    return (
      <Container maxWidth={false}>
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Swap Entries</Badge>
        </Typography>
      </Container>
    );
  }
  if (!userContext?.haveRole([HedgeDeskRole, ViewOnlyRole, PositionAdministratorRole])) {
    return (
      <Container maxWidth={false} data-testid="SwapEntriesDisplay">
        <Typography variant="h2" mb="0.5em" role="heading">
          <Badge color="secondary">Swap Entries</Badge>
        </Typography>
        <Typography paragraph>You do not have access to this page</Typography>
      </Container>
    );
  }
  return (
    <Container maxWidth={false} data-testid="SwapEntriesDisplay">
      <Typography variant="h2" mb="0.5em" role="heading">
        <Badge color="secondary">Swap Entries</Badge>
      </Typography>
      <SwapEntriesFilterForm {...props}></SwapEntriesFilterForm>
      <Typography paragraph />
      <SwapEntriesGrid {...props}></SwapEntriesGrid>
      {props.isOpen &&
        (props.isLoading ? (
          <LoadingSpinner />
        ) : (
          <SwapEntryDetailPage {...props}></SwapEntryDetailPage>
        ))}
    </Container>
  );
};

export default SwapEntriesDisplay;
