/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Moment } from 'moment';

interface SelectDateProps {
  dateFormat?: string;
  value?: Moment | null;
  label?: string;
  onChange?: (value: Moment | null) => void;
}

const defaultProps = {
  dateFormat: 'MM-DD-YYYY',
  value: null,
};

const SelectDate: React.FC<SelectDateProps> = (initialProps) => {
  const { value: initialValue, label, onChange } = { ...defaultProps, ...(initialProps || {}) };
  const [value, setValue] = React.useState<Moment | null>(initialValue);

  function onChangeCallback(dateObject: Moment | null) {
    if (dateObject === null || dateObject.isValid()) {
      setValue(dateObject);
      if (onChange) onChange(dateObject);
    }
  }

  return (
    <DatePicker
      label={label || ''}
      value={value}
      openTo={'day'}
      views={['year', 'month', 'day']}
      onChange={onChangeCallback}
    />
  );
};

export default SelectDate;
